import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import plansService from 'services/plansService';
import workersService from 'services/wokersService';
import projectsService from 'services/projectsService';
import { IPageQueries, IPlan } from 'models';
import PlanCreate from './components/planCreate';
import CreateWorkingLogDialog from './components/CreateWorkingLog';
import PlanUpdate from './components/planUpdate';
import { alertError } from 'utils/helper/appHelper';
import { StylesPlansContainer } from './styled';
import { getTextPercentPlan } from 'utils/helper/planHelper';

interface DataType {
  id?: number;
  name?: string;
  progress_percentage?: number;
}
interface ICurrentPlan {
  id?: number;
  name?: string;
}
interface IWorker {
  id?: number;
  name?: string;
}

interface IProject {
  id?: number;
  plans?: IPlan[];
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',
    margin: '0'
  },
}));

const PlanSP = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const listStatus = ['見積', '受注', '着工', '請求', '入金', '完了'];
  const [listPlans, setListPlans] = useState<DataType[]>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageQueries, setPageQueries] = useState<IPageQueries>({
    page: 1,
    per_page: 20,
  });
  const [opentCreateModal, setOpentCreateModal] = useState(false);
  const [isChangeProject, setChangeProject] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<ICurrentPlan>({});
  const [openCreateWorkingLog, setOpenCreateWorkingLog] = useState(false);
  const [currentProject, setCurrentProject] = useState<IProject>();
  const [workersList, setWorkersList] = useState<IWorker[] | undefined>();
  const [opentUpdateModal, setOpentUpdateModal] = useState(false);
  const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
    'root',
    {
      preventScroll: true,
      closeOnOverlayClick: false,
    },
  );

  const handleDetailPlan = (row) => {
    setCurrentPlan(row);
    setOpenCreateWorkingLog(true);
  };

  const handleCreatedWorkingLog = () => {
    setOpenCreateWorkingLog(false);
    setChangeProject(!isChangeProject);
  };

  const handleEditPlan = (row) => {
    setCurrentPlan(row);
    setOpentUpdateModal(true);
  };

  const handleUpdatedPlan = () => {
    setOpentUpdateModal(false);
    setChangeProject(!isChangeProject);
    getListPlan(Number(id), pageQueries.page, pageQueries.per_page);
  };

  const handleDeletePlan = (row) => {
    setCurrentPlan(row);
    openDelete();
  };
  
  const deletePlan = async () => {
    if(!currentPlan?.id || !currentProject?.id) return;
    await plansService
      .deletePlanSP(currentPlan?.id, currentProject?.id)
      .then((data) => {
        setChangeProject(!isChangeProject);
      })
      .catch((err) => {
        const response = err?.response;
        if (!response || typeof response === 'undefined') {
          navigate('login');
          return;
        }
        alertError(response.data?.error);
      })
      .finally(() => {
        setCurrentPlan({});
        closeDelete();
      });
  };

  const getDetailProject = async (id: number) => {
    if (!id) return;
    try {
      const response = await projectsService.getProject(id);
      setCurrentProject(response?.project);
    } catch (error) {
      console.error(error);
      navigate('/projects');
    }
  };
  const getListWorkers = async () => {
    try {
      const response = await workersService.getListWorkers();
      if (response !== null && response !== undefined) {
        setWorkersList(response?.workers);
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const columns: ColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'num',
      key: 'num',
      className: 'max-w-[50px]',
      render: (text, record) => <div className="vehicle-name min-w-[80px]">{text}</div>,
    },
    {
      title: '工程',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <div className="vehicle-name min-w-[80px]">{text}</div>,
    },
    {
      title: '進捗',
      dataIndex: 'progress_percentage',
      key: 'progress_percentage',
      render: (text, record) => <div className="vehicle-name min-w-[80px]">{getTextPercentPlan(
        text
    )}</div>,
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      className: 'min-w-[200px]',
      render: (_, record) => (
        <div className="flex items-center gap-[4px]">
          <button
            className="btn-tb"
            onClick={() =>
              handleDetailPlan(
                record,
              )
            }
          >
            進捗
          </button>
          <button
            className="btn-tb"
            onClick={() =>
              handleEditPlan(
                record,
              )
            }
          >
            編集
          </button>
          <button
            className="btn-tb btn-delete"
            onClick={() =>
              handleDeletePlan(
                record,
              )
            }
          >
            削除
          </button>
        </div>
      ),
    },
  ];
  const handleCreatedPlan = () => {
    // closeCreatePlan();
    setOpentCreateModal(false);
    setChangeProject(!isChangeProject);
  };
  const getListPlan = async (
    project_id: number,
    page = 1,
    per_page = 0,
    data = {},
  ) => {
    try {
      if (!project_id) return;
      const res = await plansService.getListPlanSP(project_id, {
        page,
        per_page,
        ...data,
      });
      if (!(res as { plans: DataType[] })?.plans) return;
      const plans = (res as { plans: DataType[] })?.plans;
      const plansData = plans?.map((item, index) => {
        return { num: index + 1, ...item }
      })
      setListPlans(plansData);
      setTotalCount(res?.meta?.total_count);
    } catch (err) {
      console.log('err', err);
    }
  };
  useEffect(() => {
    if (id) {
      getDetailProject(Number(id));
      getListPlan(Number(id), pageQueries.page, pageQueries.per_page);
    }
  }, [id, isChangeProject]);
  useEffect(() => {
    getListWorkers();
  }, []);
  return (
    <StylesPlansContainer>
      <BootstrapDialog
        open={opentCreateModal}
        onClose={() =>
          setOpentCreateModal(false)
        }
        className="customBootstrapDialog projectPlanDialog"
        aria-labelledby="customized-dialog-title"
      >
        <PlanCreate
          project_id={id}
          onClose={handleCreatedPlan}
          onCancel={() =>
            setOpentCreateModal(false)
          }
        />
      </BootstrapDialog>

      <BootstrapDialog
        open={opentUpdateModal}
        onClose={() =>
          setOpentUpdateModal(false)
        }
        className="customBootstrapDialog projectPlanDialog"
        aria-labelledby="customized-dialog-title"
      >
        <PlanUpdate
          id={currentPlan?.id}
          project_id={id}
          originPlan={currentPlan}
          setChangeProject={
            setChangeProject
          }
          isChangeProject={
            isChangeProject
          }
          onClose={handleUpdatedPlan}
          onCancel={() =>
            setOpentUpdateModal(false)
          }
        />
      </BootstrapDialog>

      <CreateWorkingLogDialog
        open={openCreateWorkingLog}
        setOpen={setOpenCreateWorkingLog}
        project_id={id}
        onFinishCreate={() =>
          handleCreatedWorkingLog()
        }
        plans={currentProject?.plans ? currentProject?.plans : []}
        workers={workersList ? workersList : []}
        currentPlan={currentPlan}
      />

      <ModalDelete>
        <div
          className="modal-create-user d-flex flex-column"
          style={{ height: '152px' }}
        >
          <div className="modal-create-user-title d-flex flex-row">
            本当に削除しますか?
          </div>
          <div
            className="modal-create-user-buttons d-flex flex-row"
            style={{
              marginTop: '50px',
            }}
          >
            <button
              className="modal-create-user-button1 d-flex flex-row"
              onClick={closeDelete}
            >
              キャンセル
            </button>
            <button
              className="modal-create-user-button2 d-flex flex-row"
              onClick={deletePlan}
            >
              はい
            </button>
          </div>
        </div>
      </ModalDelete>

      <div className="flex justify-between">
        <p className="plan-title">工程</p>
        <Button
          onClick={() =>
            setOpentCreateModal(true)
          }
          sx={{ mb: '20px', fontWeight: 'bold', padding: '5px 15px' }}
          variant="outlined"
        >
          <Add /> 新規作成
        </Button>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        pagination={false}
        dataSource={listPlans}
        className='overflow-auto'
      />
    </StylesPlansContainer>
  )
}
export default PlanSP;
