import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    TextField,
    Autocomplete,
    Radio,
    RadioGroup,
    FormControlLabel,
    Grid,
    CircularProgress,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import pick from 'lodash/pick';
import calendarIcon from 'assets/icons/calendar.png';
import DialogHeader from '../../common/DialogHeader';
import { EDIT_SUGOROKU_MODE, VIEW_SUGOROKU_MODE } from 'utils/constants';
import StyledDialogWrapper from './styles';
import moment from 'moment';
import { SugorokuIndexEnum } from 'utils/enums';
import sugorokuService from 'services/sugorokuService';
import { formatDateTime } from 'utils/helper/dateHelper';
import { useFormik, FormikProps } from 'formik';
import { IBreakingGroundPayload } from 'models';
import AutocompleteCustom from 'components/AutocompleteCustom';

interface IProps {
    step: number;
    onClose?: any;
    data_id?: number;
    workerOptions: any[];
    onFinish: () => void;
    disabled?: boolean;
}

interface IBreakingGroundFormikValues {
    contruction_recycle_document_submitted_at?: Date | null;
    construction_recycle_document_submitted_worker?: any;

    construction_notice_document_submitted_at?: Date | null;
    construction_notice_document_submitted_worker?: any;

    plan_submitted_at?: Date | null;
    plan_submitted_worker?: any;

    road_usage_application_is_necessary?: boolean | null;
    road_usage_application_submitted_at?: Date | null;
    road_usage_application_submitted_worker?: any;

    road_occupation_application_is_necessary?: boolean | null;
    road_occupation_application_submitted_at?: Date | null;
    road_occupation_application_submitted_worker?: any;

    cutoff_application_water_is_necessary?: boolean | null;
    cutoff_application_water_business_worker?: any;
    cutoff_application_water_at?: Date | null;
    cutoff_application_water_request_worker?: any;

    cutoff_application_gas_is_necessary?: boolean | null;
    cutoff_application_gas_business_worker?: any;
    cutoff_application_gas_at?: Date | null;
    cutoff_application_gas_request_worker?: any;

    cutoff_application_ele_is_necessary?: boolean | null;
    cutoff_application_ele_business_worker?: any;
    cutoff_application_ele_at?: Date | null;
    cutoff_application_ele_request_worker?: any;

    cutoff_application_com_is_necessary?: boolean | null;
    cutoff_application_com_business_worker?: any;
    cutoff_application_com_at?: Date | null;
    cutoff_application_com_request_worker?: any;

    plan_from?: Date | null;
    plan_to?: Date | null;
    business_worker?: any;
    agent_worker?: any;

    completed_at?: Date | null;
}

const applicationRemovalOfLifeLineInitial = [
    { id: 1, title: '水道', requirement: '1', scheduled_date: new Date() },
    { id: 2, title: 'ガス', requirement: '1', scheduled_date: new Date() },
    { id: 3, title: '電気', requirement: '1', scheduled_date: new Date() },
    { id: 4, title: '通信', requirement: '1', scheduled_date: new Date() },
];

const applicationInitial = [
    { id: 1, title: '道路使用許可申請書', requirement: '1' },
    { id: 2, title: '道路占用許可申請書', requirement: '1' },
];
// road_usage_application_is_necessary

const START_PERIOD = '2023-01-01';
const END_PERIOD = '2023-01-31';

const BreakingGroundModal = (props: IProps) => {
    const { step, onClose, data_id, workerOptions, onFinish } = props;
    const [mode, setMode] = useState(VIEW_SUGOROKU_MODE);
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<any>(true);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const hasData = !!data_id;
    const { id } = useParams();

    const [recyclingDate, setRecyclingDate] = useState(new Date());
    const [implementNotificationDate, setImplementNotificationDate] = useState(
        new Date(),
    );
    const [engineeringDate, setEngineeringDate] = useState(new Date());

    const [applicationState, setApplicationState] =
        useState(applicationInitial);
    const [removalLifeLineState, setRemovalLifeLineState] = useState(
        () => applicationRemovalOfLifeLineInitial,
    );

    const [constructionStartPeriod, setConstructionStartPeriod] = useState(
        new Date(START_PERIOD),
    );
    const [constructionEndPeriod, setConstructionEndPeriod] = useState(
        new Date(END_PERIOD),
    );
    const [completionDate, setCompletionDate] = useState(new Date());

    const formik: FormikProps<IBreakingGroundFormikValues> =
        useFormik<IBreakingGroundFormikValues>({
            initialValues: {
                // has_deal_price: null,
                contruction_recycle_document_submitted_at: null,
                construction_recycle_document_submitted_worker: null,

                construction_notice_document_submitted_at: null,
                construction_notice_document_submitted_worker: null,

                plan_submitted_at: null,
                plan_submitted_worker: null,

                road_usage_application_is_necessary: null,
                road_usage_application_submitted_at: null,
                road_usage_application_submitted_worker: null,

                road_occupation_application_is_necessary: null,
                road_occupation_application_submitted_at: null,
                road_occupation_application_submitted_worker: null,

                cutoff_application_water_is_necessary: null,
                cutoff_application_water_business_worker: null,
                cutoff_application_water_at: null,
                cutoff_application_water_request_worker: null,

                cutoff_application_gas_is_necessary: null,
                cutoff_application_gas_business_worker: null,
                cutoff_application_gas_at: null,
                cutoff_application_gas_request_worker: null,

                cutoff_application_ele_is_necessary: null,
                cutoff_application_ele_business_worker: null,
                cutoff_application_ele_at: null,
                cutoff_application_ele_request_worker: null,

                cutoff_application_com_is_necessary: null,
                cutoff_application_com_business_worker: null,
                cutoff_application_com_at: null,
                cutoff_application_com_request_worker: null,

                plan_from: null,
                plan_to: null,
                business_worker: null,
                agent_worker: null,

                completed_at: null,
            },
            onSubmit: (values: IBreakingGroundFormikValues) => {
                setSubmitLoading(true);
                let data: IBreakingGroundPayload = pick(values, [
                    'road_usage_application_is_necessary',
                    'road_occupation_application_is_necessary',
                    'cutoff_application_water_is_necessary',
                    'cutoff_application_gas_is_necessary',
                    'cutoff_application_ele_is_necessary',
                    'cutoff_application_com_is_necessary',
                ]);
                if (!id) {
                    setSubmitLoading(false);
                    return;
                }

                data.project_id = +id;
                data.sugoroku_index = SugorokuIndexEnum.BREAKING_GROUND.value;

                if (values.contruction_recycle_document_submitted_at) {
                    data.contruction_recycle_document_submitted_at =
                        formatDateTime(
                            values.contruction_recycle_document_submitted_at,
                            'YYYY-MM-DD',
                        );
                } else {
                    data.contruction_recycle_document_submitted_at = null;
                }
                if (values.construction_recycle_document_submitted_worker) {
                    data.construction_recycle_document_submitted_worker_id =
                        +values.construction_recycle_document_submitted_worker
                            .value;
                } else {
                    data.construction_recycle_document_submitted_worker_id =
                        null;
                }

                if (values.construction_notice_document_submitted_at) {
                    data.construction_notice_document_submitted_at =
                        formatDateTime(
                            values.construction_notice_document_submitted_at,
                            'YYYY-MM-DD',
                        );
                } else {
                    data.construction_notice_document_submitted_at = null;
                }
                if (values.construction_notice_document_submitted_worker) {
                    data.construction_notice_document_submitted_worker_id =
                        +values.construction_notice_document_submitted_worker
                            .value;
                } else {
                    data.construction_notice_document_submitted_worker_id =
                        null;
                }

                if (values.plan_submitted_at) {
                    data.plan_submitted_at = formatDateTime(
                        values.plan_submitted_at,
                        'YYYY-MM-DD',
                    );
                } else {
                    data.plan_submitted_at = null;
                }
                if (values.plan_submitted_worker) {
                    data.plan_submitted_worker_id =
                        +values.plan_submitted_worker.value;
                } else {
                    data.plan_submitted_worker_id = null;
                }

                if (values.road_usage_application_submitted_at) {
                    data.road_usage_application_submitted_at = formatDateTime(
                        values.road_usage_application_submitted_at,
                        'YYYY-MM-DD',
                    );
                } else {
                    data.road_usage_application_submitted_at = null;
                }
                if (values.road_usage_application_submitted_worker) {
                    data.road_usage_application_submitted_worker_id =
                        +values.road_usage_application_submitted_worker.value;
                } else {
                    data.road_usage_application_submitted_worker_id = null;
                }

                if (values.road_occupation_application_submitted_at) {
                    data.road_occupation_application_submitted_at =
                        formatDateTime(
                            values.road_occupation_application_submitted_at,
                            'YYYY-MM-DD',
                        );
                } else {
                    data.road_occupation_application_submitted_at = null;
                }
                if (values.road_occupation_application_submitted_worker) {
                    data.road_occupation_application_submitted_worker_id =
                        +values.road_occupation_application_submitted_worker
                            .value;
                } else {
                    data.road_occupation_application_submitted_worker_id = null;
                }

                if (values.cutoff_application_water_business_worker) {
                    data.cutoff_application_water_business_worker_id =
                        +values.cutoff_application_water_business_worker.value;
                } else {
                    data.cutoff_application_water_business_worker_id = null;
                }
                if (values.cutoff_application_water_at) {
                    data.cutoff_application_water_at = formatDateTime(
                        values.cutoff_application_water_at,
                        'YYYY-MM-DD',
                    );
                } else {
                    data.cutoff_application_water_at = null;
                }
                if (values.cutoff_application_water_request_worker) {
                    data.cutoff_application_water_request_worker_id =
                        +values.cutoff_application_water_request_worker.value;
                } else {
                    data.cutoff_application_water_request_worker_id = null;
                }

                if (values.cutoff_application_gas_business_worker) {
                    data.cutoff_application_gas_business_worker_id =
                        +values.cutoff_application_gas_business_worker.value;
                } else {
                    data.cutoff_application_gas_business_worker_id = null;
                }
                if (values.cutoff_application_gas_at) {
                    data.cutoff_application_gas_at = formatDateTime(
                        values.cutoff_application_gas_at,
                        'YYYY-MM-DD',
                    );
                } else {
                    data.cutoff_application_gas_at = null;
                }
                if (values.cutoff_application_gas_request_worker) {
                    data.cutoff_application_gas_request_worker_id =
                        +values.cutoff_application_gas_request_worker.value;
                } else {
                    data.cutoff_application_gas_request_worker_id = null;
                }

                if (values.cutoff_application_ele_business_worker) {
                    data.cutoff_application_ele_business_worker_id =
                        +values.cutoff_application_ele_business_worker.value;
                } else {
                    data.cutoff_application_ele_business_worker_id = null;
                }
                if (values.cutoff_application_ele_at) {
                    data.cutoff_application_ele_at = formatDateTime(
                        values.cutoff_application_ele_at,
                        'YYYY-MM-DD',
                    );
                } else {
                    data.cutoff_application_ele_at = null;
                }
                if (values.cutoff_application_ele_request_worker) {
                    data.cutoff_application_ele_request_worker_id =
                        +values.cutoff_application_ele_request_worker.value;
                } else {
                    data.cutoff_application_ele_request_worker_id = null;
                }

                if (values.cutoff_application_com_business_worker) {
                    data.cutoff_application_com_business_worker_id =
                        +values.cutoff_application_com_business_worker.value;
                } else {
                    data.cutoff_application_com_business_worker_id = null;
                }
                if (values.cutoff_application_com_at) {
                    data.cutoff_application_com_at = formatDateTime(
                        values.cutoff_application_com_at,
                        'YYYY-MM-DD',
                    );
                } else {
                    data.cutoff_application_com_at = null;
                }
                if (values.cutoff_application_com_request_worker) {
                    data.cutoff_application_com_request_worker_id =
                        +values.cutoff_application_com_request_worker.value;
                } else {
                    data.cutoff_application_com_request_worker_id = null;
                }

                if (values.plan_from) {
                    data.plan_from = formatDateTime(
                        values.plan_from,
                        'YYYY-MM-DD',
                    );
                } else {
                    data.plan_from = null;
                }
                if (values.plan_to) {
                    data.plan_to = formatDateTime(values.plan_to, 'YYYY-MM-DD');
                } else {
                    data.plan_to = null;
                }
                if (values.business_worker) {
                    data.business_worker_id = +values.business_worker.value;
                } else {
                    data.business_worker_id = null;
                }
                if (values.agent_worker) {
                    data.agent_worker_id = +values.agent_worker.value;
                } else {
                    data.agent_worker_id = null;
                }

                if (values.completed_at) {
                    data.completed_at = formatDateTime(
                        values.completed_at,
                        'YYYY-MM-DD',
                    );
                } else {
                    data.completed_at = null;
                }

                if (hasData) {
                    updateSugoroku(data);
                } else {
                    createSugoroku(data);
                }
            },
        });

    const roadApplications = [
        {
            id: 'road_usage_application',
            title: '道路使用許可申請書',
            requirement: '1',
            isNecessary: formik.values.road_usage_application_is_necessary,
            onChangeIsNecessary: (e) => {
                if (
                    e.target.value ===
                    formik.values.road_usage_application_is_necessary
                ) {
                    formik.setFieldValue(
                        'road_usage_application_is_necessary',
                        null,
                    );
                } else {
                    formik.setFieldValue(
                        'road_usage_application_is_necessary',
                        e.target.value,
                    );
                }
            },
            viewIsNecessary:
                formik.values.road_usage_application_is_necessary && data_id
                    ? '要'
                    : formik.values.road_usage_application_is_necessary ===
                          false && data_id
                    ? '不要'
                    : formik.values.road_usage_application_is_necessary ===
                          null && data_id
                    ? ''
                    : !data_id
                    ? ''
                    : '',
            submittedAt: formik.values.road_usage_application_submitted_at,
            onChangeSubmittedAt: (date) => {
                formik.setFieldValue(
                    'road_usage_application_submitted_at',
                    date,
                );
            },
            viewSubmittedAt: formatDateTime(
                formik.values.road_usage_application_submitted_at,
                'YYYY-MM-DD',
            ),
            submittedWorker:
                formik.values.road_usage_application_submitted_worker,
            onChangeSubmittedWorker: (event, newValue) => {
                formik.setFieldValue(
                    'road_usage_application_submitted_worker',
                    newValue,
                );
            },
            viewSubmittedWorker:
                formik.values.road_usage_application_submitted_worker?.label ||
                '',
        },
        {
            id: 'road_occupation_application',
            title: '道路占用許可申請書',
            requirement: '1',
            isNecessary: formik.values.road_occupation_application_is_necessary,
            onChangeIsNecessary: (e) => {
                if (
                    e.target.value ===
                    formik.values.road_occupation_application_is_necessary
                ) {
                    formik.setFieldValue(
                        'road_occupation_application_is_necessary',
                        null,
                    );
                } else {
                    formik.setFieldValue(
                        'road_occupation_application_is_necessary',
                        e.target.value,
                    );
                }
            },
            viewIsNecessary:
                formik.values.road_occupation_application_is_necessary &&
                data_id
                    ? '要'
                    : formik.values.road_occupation_application_is_necessary ===
                          false && data_id
                    ? '不要'
                    : formik.values.road_occupation_application_is_necessary ===
                          null && data_id
                    ? ''
                    : !data_id
                    ? ''
                    : '',
            submittedAt: formik.values.road_occupation_application_submitted_at,
            onChangeSubmittedAt: (date) => {
                formik.setFieldValue(
                    'road_occupation_application_submitted_at',
                    date,
                );
            },
            viewSubmittedAt: formatDateTime(
                formik.values.road_occupation_application_submitted_at,
                'YYYY-MM-DD',
            ),
            submittedWorker:
                formik.values.road_occupation_application_submitted_worker,
            onChangeSubmittedWorker: (event, newValue) => {
                formik.setFieldValue(
                    'road_occupation_application_submitted_worker',
                    newValue,
                );
            },
            viewSubmittedWorker:
                formik.values.road_occupation_application_submitted_worker
                    ?.label || '',
        },
    ];

    const cutoffApplications = [
        {
            id: 'cutoff_application_water',
            title: '水道',
            requirement: '1',
            scheduled_date: new Date(),
            isNecessary: formik.values.cutoff_application_water_is_necessary,
            onChangeIsNecessary: (e) => {
                if (
                    e.target.value ===
                    formik.values.cutoff_application_water_is_necessary
                ) {
                    formik.setFieldValue(
                        'cutoff_application_water_is_necessary',
                        null,
                    );
                } else {
                    formik.setFieldValue(
                        'cutoff_application_water_is_necessary',
                        e.target.value,
                    );
                }
            },
            viewIsNecessary:
                formik.values.cutoff_application_water_is_necessary && data_id
                    ? '要'
                    : formik.values.cutoff_application_water_is_necessary ===
                          false && data_id
                    ? '不要'
                    : formik.values.cutoff_application_water_is_necessary ===
                          null && data_id
                    ? ''
                    : !data_id
                    ? ''
                    : '',
            businessWorker:
                formik.values.cutoff_application_water_business_worker,
            onChangeBusinessWorker: (event, newValue) => {
                formik.setFieldValue(
                    'cutoff_application_water_business_worker',
                    newValue,
                );
            },
            viewBusinessWorker:
                formik.values.cutoff_application_water_business_worker?.label ||
                '',
            at: formik.values.cutoff_application_water_at,
            onChangeAt: (date) => {
                formik.setFieldValue('cutoff_application_water_at', date);
            },
            viewAt: formatDateTime(
                formik.values.cutoff_application_water_at,
                'YYYY-MM-DD',
            ),
            requestWorker:
                formik.values.cutoff_application_water_request_worker,
            onChangeRequestWorker: (event, newValue) => {
                formik.setFieldValue(
                    'cutoff_application_water_request_worker',
                    newValue,
                );
            },
            viewRequestWorker:
                formik.values.cutoff_application_water_request_worker?.label ||
                '',
        },
        {
            id: 'cutoff_application_gas',
            title: 'ガス',
            requirement: '1',
            scheduled_date: new Date(),
            isNecessary: formik.values.cutoff_application_gas_is_necessary,
            onChangeIsNecessary: (e) => {
                if (
                    e.target.value ===
                    formik.values.cutoff_application_gas_is_necessary
                ) {
                    formik.setFieldValue(
                        'cutoff_application_gas_is_necessary',
                        null,
                    );
                } else {
                    formik.setFieldValue(
                        'cutoff_application_gas_is_necessary',
                        e.target.value,
                    );
                }
            },
            viewIsNecessary:
                formik.values.cutoff_application_gas_is_necessary && data_id
                    ? '要'
                    : formik.values.cutoff_application_gas_is_necessary ===
                          false && data_id
                    ? '不要'
                    : formik.values.cutoff_application_gas_is_necessary ===
                          null && data_id
                    ? ''
                    : !data_id
                    ? ''
                    : '',
            businessWorker:
                formik.values.cutoff_application_gas_business_worker,
            onChangeBusinessWorker: (event, newValue) => {
                formik.setFieldValue(
                    'cutoff_application_gas_business_worker',
                    newValue,
                );
            },
            viewBusinessWorker:
                formik.values.cutoff_application_gas_business_worker?.label ||
                '',
            at: formik.values.cutoff_application_gas_at,
            onChangeAt: (date) => {
                formik.setFieldValue('cutoff_application_gas_at', date);
            },
            viewAt: formatDateTime(
                formik.values.cutoff_application_gas_at,
                'YYYY-MM-DD',
            ),
            requestWorker: formik.values.cutoff_application_gas_request_worker,
            onChangeRequestWorker: (event, newValue) => {
                formik.setFieldValue(
                    'cutoff_application_gas_request_worker',
                    newValue,
                );
            },
            viewRequestWorker:
                formik.values.cutoff_application_gas_request_worker?.label ||
                '',
        },
        {
            id: 'cutoff_application_ele',
            title: '電気',
            requirement: '1',
            scheduled_date: new Date(),
            isNecessary: formik.values.cutoff_application_ele_is_necessary,
            onChangeIsNecessary: (e) => {
                if (
                    e.target.value ===
                    formik.values.cutoff_application_ele_is_necessary
                ) {
                    formik.setFieldValue(
                        'cutoff_application_ele_is_necessary',
                        null,
                    );
                } else {
                    formik.setFieldValue(
                        'cutoff_application_ele_is_necessary',
                        e.target.value,
                    );
                }
            },
            viewIsNecessary:
                formik.values.cutoff_application_ele_is_necessary && data_id
                    ? '要'
                    : formik.values.cutoff_application_ele_is_necessary ===
                          false && data_id
                    ? '不要'
                    : formik.values.cutoff_application_ele_is_necessary ===
                          null && data_id
                    ? ''
                    : !data_id
                    ? ''
                    : '',
            businessWorker:
                formik.values.cutoff_application_ele_business_worker,
            onChangeBusinessWorker: (event, newValue) => {
                formik.setFieldValue(
                    'cutoff_application_ele_business_worker',
                    newValue,
                );
            },
            viewBusinessWorker:
                formik.values.cutoff_application_ele_business_worker?.label ||
                '',
            at: formik.values.cutoff_application_ele_at,
            onChangeAt: (date) => {
                formik.setFieldValue('cutoff_application_ele_at', date);
            },
            viewAt: formatDateTime(
                formik.values.cutoff_application_ele_at,
                'YYYY-MM-DD',
            ),
            requestWorker: formik.values.cutoff_application_ele_request_worker,
            onChangeRequestWorker: (event, newValue) => {
                formik.setFieldValue(
                    'cutoff_application_ele_request_worker',
                    newValue,
                );
            },
            viewRequestWorker:
                formik.values.cutoff_application_ele_request_worker?.label ||
                '',
        },
        {
            id: 'cutoff_application_com',
            title: '通信',
            requirement: '1',
            scheduled_date: new Date(),
            isNecessary: formik.values.cutoff_application_com_is_necessary,
            onChangeIsNecessary: (e) => {
                if (
                    e.target.value ===
                    formik.values.cutoff_application_com_is_necessary
                ) {
                    formik.setFieldValue(
                        'cutoff_application_com_is_necessary',
                        null,
                    );
                } else {
                    formik.setFieldValue(
                        'cutoff_application_com_is_necessary',
                        e.target.value,
                    );
                }
            },
            viewIsNecessary:
                formik.values.cutoff_application_com_is_necessary && data_id
                    ? '要'
                    : formik.values.cutoff_application_com_is_necessary ===
                          false && data_id
                    ? '不要'
                    : formik.values.cutoff_application_com_is_necessary ===
                          null && data_id
                    ? ''
                    : !data_id
                    ? ''
                    : '',
            businessWorker:
                formik.values.cutoff_application_com_business_worker,
            onChangeBusinessWorker: (event, newValue) => {
                formik.setFieldValue(
                    'cutoff_application_com_business_worker',
                    newValue,
                );
            },
            viewBusinessWorker:
                formik.values.cutoff_application_com_business_worker?.label ||
                '',
            at: formik.values.cutoff_application_com_at,
            onChangeAt: (date) => {
                formik.setFieldValue('cutoff_application_com_at', date);
            },
            viewAt: formatDateTime(
                formik.values.cutoff_application_com_at,
                'YYYY-MM-DD',
            ),
            requestWorker: formik.values.cutoff_application_com_request_worker,
            onChangeRequestWorker: (event, newValue) => {
                formik.setFieldValue(
                    'cutoff_application_com_request_worker',
                    newValue,
                );
            },
            viewRequestWorker:
                formik.values.cutoff_application_com_request_worker?.label ||
                '',
        },
    ];

    const fetchData = async () => {
        try {
            setFetchLoading(true);
            const data = await sugorokuService.getSugorokuEventDetail(
                data_id,
                Number(id),
            );
            if (data && data.sugoroku_event) {
                const sugorokuData = data.sugoroku_event;

                formik.setValues({
                    // 'has_deal_price': sugorokuData.has_deal_price,
                    contruction_recycle_document_submitted_at:
                        sugorokuData.contruction_recycle_document_submitted_at
                            ? new Date(
                                  sugorokuData.contruction_recycle_document_submitted_at,
                              )
                            : null,
                    construction_recycle_document_submitted_worker:
                        sugorokuData.construction_recycle_document_submitted_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .construction_recycle_document_submitted_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .construction_recycle_document_submitted_worker
                                      .name,
                                  name: sugorokuData
                                      .construction_recycle_document_submitted_worker
                                      .name,
                              }
                            : null,

                    construction_notice_document_submitted_at:
                        sugorokuData.construction_notice_document_submitted_at
                            ? new Date(
                                  sugorokuData.construction_notice_document_submitted_at,
                              )
                            : null,
                    construction_notice_document_submitted_worker:
                        sugorokuData.construction_notice_document_submitted_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .construction_notice_document_submitted_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .construction_notice_document_submitted_worker
                                      .name,
                                  name: sugorokuData
                                      .construction_notice_document_submitted_worker
                                      .name,
                              }
                            : null,

                    plan_submitted_at: sugorokuData.plan_submitted_at
                        ? new Date(sugorokuData.plan_submitted_at)
                        : null,
                    plan_submitted_worker: sugorokuData.plan_submitted_worker
                        ? {
                              value: String(
                                  sugorokuData.plan_submitted_worker.id,
                              ),
                              label: sugorokuData.plan_submitted_worker.name,
                              name: sugorokuData.plan_submitted_worker.name,
                          }
                        : null,

                    road_usage_application_is_necessary:
                        sugorokuData.road_usage_application_is_necessary,
                    road_usage_application_submitted_at:
                        sugorokuData.road_usage_application_submitted_at
                            ? new Date(
                                  sugorokuData.road_usage_application_submitted_at,
                              )
                            : null,
                    road_usage_application_submitted_worker:
                        sugorokuData.road_usage_application_submitted_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .road_usage_application_submitted_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .road_usage_application_submitted_worker
                                      .name,
                                  name: sugorokuData
                                      .road_usage_application_submitted_worker
                                      .name,
                              }
                            : null,

                    road_occupation_application_is_necessary:
                        sugorokuData.road_occupation_application_is_necessary,
                    road_occupation_application_submitted_at:
                        sugorokuData.road_occupation_application_submitted_at
                            ? new Date(
                                  sugorokuData.road_occupation_application_submitted_at,
                              )
                            : null,
                    road_occupation_application_submitted_worker:
                        sugorokuData.road_occupation_application_submitted_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .road_occupation_application_submitted_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .road_occupation_application_submitted_worker
                                      .name,
                                  name: sugorokuData
                                      .road_occupation_application_submitted_worker
                                      .name,
                              }
                            : null,

                    cutoff_application_water_is_necessary:
                        sugorokuData.cutoff_application_water_is_necessary,
                    cutoff_application_water_business_worker:
                        sugorokuData.cutoff_application_water_business_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .cutoff_application_water_business_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .cutoff_application_water_business_worker
                                      .name,
                                  name: sugorokuData
                                      .cutoff_application_water_business_worker
                                      .name,
                              }
                            : null,
                    cutoff_application_water_at:
                        sugorokuData.cutoff_application_water_at
                            ? new Date(sugorokuData.cutoff_application_water_at)
                            : null,
                    cutoff_application_water_request_worker:
                        sugorokuData.cutoff_application_water_request_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .cutoff_application_water_request_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .cutoff_application_water_request_worker
                                      .name,
                                  name: sugorokuData
                                      .cutoff_application_water_request_worker
                                      .name,
                              }
                            : null,

                    cutoff_application_gas_is_necessary:
                        sugorokuData.cutoff_application_gas_is_necessary,
                    cutoff_application_gas_business_worker:
                        sugorokuData.cutoff_application_gas_business_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .cutoff_application_gas_business_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .cutoff_application_gas_business_worker
                                      .name,
                                  name: sugorokuData
                                      .cutoff_application_gas_business_worker
                                      .name,
                              }
                            : null,
                    cutoff_application_gas_at:
                        sugorokuData.cutoff_application_gas_at
                            ? new Date(sugorokuData.cutoff_application_gas_at)
                            : null,
                    cutoff_application_gas_request_worker:
                        sugorokuData.cutoff_application_gas_request_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .cutoff_application_gas_request_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .cutoff_application_gas_request_worker
                                      .name,
                                  name: sugorokuData
                                      .cutoff_application_gas_request_worker
                                      .name,
                              }
                            : null,

                    cutoff_application_ele_is_necessary:
                        sugorokuData.cutoff_application_ele_is_necessary,
                    cutoff_application_ele_business_worker:
                        sugorokuData.cutoff_application_ele_business_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .cutoff_application_ele_business_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .cutoff_application_ele_business_worker
                                      .name,
                                  name: sugorokuData
                                      .cutoff_application_ele_business_worker
                                      .name,
                              }
                            : null,
                    cutoff_application_ele_at:
                        sugorokuData.cutoff_application_ele_at
                            ? new Date(sugorokuData.cutoff_application_ele_at)
                            : null,
                    cutoff_application_ele_request_worker:
                        sugorokuData.cutoff_application_ele_request_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .cutoff_application_ele_request_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .cutoff_application_ele_request_worker
                                      .name,
                                  name: sugorokuData
                                      .cutoff_application_ele_request_worker
                                      .name,
                              }
                            : null,

                    cutoff_application_com_is_necessary:
                        sugorokuData.cutoff_application_com_is_necessary,
                    cutoff_application_com_business_worker:
                        sugorokuData.cutoff_application_com_business_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .cutoff_application_com_business_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .cutoff_application_com_business_worker
                                      .name,
                                  name: sugorokuData
                                      .cutoff_application_com_business_worker
                                      .name,
                              }
                            : null,
                    cutoff_application_com_at:
                        sugorokuData.cutoff_application_com_at
                            ? new Date(sugorokuData.cutoff_application_com_at)
                            : null,
                    cutoff_application_com_request_worker:
                        sugorokuData.cutoff_application_com_request_worker
                            ? {
                                  value: String(
                                      sugorokuData
                                          .cutoff_application_com_request_worker
                                          .id,
                                  ),
                                  label: sugorokuData
                                      .cutoff_application_com_request_worker
                                      .name,
                                  name: sugorokuData
                                      .cutoff_application_com_request_worker
                                      .name,
                              }
                            : null,

                    plan_from: sugorokuData.plan_from
                        ? new Date(sugorokuData.plan_from)
                        : null,
                    plan_to: sugorokuData.plan_to
                        ? new Date(sugorokuData.plan_to)
                        : null,
                    business_worker: sugorokuData.business_worker
                        ? {
                              value: String(sugorokuData.business_worker.id),
                              label: sugorokuData.business_worker.name,
                              name: sugorokuData.business_worker.name,
                          }
                        : null,
                    agent_worker: sugorokuData.agent_worker
                        ? {
                              value: String(sugorokuData.agent_worker.id),
                              label: sugorokuData.agent_worker.name,
                              name: sugorokuData.agent_worker.name,
                          }
                        : null,

                    completed_at: sugorokuData.completed_at
                        ? new Date(sugorokuData.completed_at)
                        : null,
                });
                setIsEmpty(false);
            } else {
                setIsEmpty(true);
            }
        } catch (error) {
            // error
        } finally {
            setFetchLoading(false);
        }
    };

    const createSugoroku = async (data: IBreakingGroundPayload) => {
        try {
            const responseData = await sugorokuService.createSugorokuEvent(
                data,
            );
            if (responseData) {
                setSubmitLoading(false);
                onFinish();
            }
        } catch (error) {
            setSubmitLoading(false);
            // empty
        }
    };

    const updateSugoroku = async (data: IBreakingGroundPayload) => {
        try {
            const responseData = await sugorokuService.updateSugorokuEvent(
                data_id,
                data,
            );
            if (responseData) {
                setSubmitLoading(false);
                onFinish();
            }
        } catch (error) {
            setSubmitLoading(false);
            // epmty
        }
    };

    // const onChangeHasDealPrice = (e) => {
    //   if (e.target.value === formik.values.has_deal_price) {
    //     formik.setFieldValue('has_deal_price', null);
    //   } else {
    //     formik.setFieldValue('has_deal_price', e.target.value);
    //   }
    // }

    useEffect(() => {
        if (data_id && id) {
            fetchData();
        }
    }, []);

    return (
        <StyledDialogWrapper
            style={{
                width:
                    (!fetchLoading && !isEmpty) || mode === EDIT_SUGOROKU_MODE
                        ? '900px'
                        : '380px',
            }}
        >
            <DialogHeader
                step={step}
                title={SugorokuIndexEnum.BREAKING_GROUND.title}
                onClose={onClose}
                mode={mode}
                setMode={setMode}
                completed={!!formik.values.completed_at}
                disabled={props.disabled}
            />

            {fetchLoading && (
                <div className="d-flex justify-center">
                    <CircularProgress />
                </div>
            )}

            {((!fetchLoading && !isEmpty) || mode === EDIT_SUGOROKU_MODE) && (
                <form onSubmit={formik.handleSubmit}>
                    <section className="construction-recycling-law">
                        <div className="step-section-title">
                            建設リサイクル法
                        </div>
                        <div className="dialog-field-row">
                            <div className="field-label">日付</div>
                            {mode === EDIT_SUGOROKU_MODE ? (
                                <div className="datepicker-wrapper">
                                    <DatePicker
                                        id="datepicker-wrapper"
                                        selected={
                                            formik.values
                                                .contruction_recycle_document_submitted_at
                                        }
                                        onChange={(date) => {
                                            formik.setFieldValue(
                                                'contruction_recycle_document_submitted_at',
                                                date,
                                            );
                                        }}
                                        dateFormat="yyyy-MM-dd"
                                        autoComplete="off"
                                        locale="ja"
                                    />
                                    <label htmlFor="datepicker-wrapper">
                                        <img
                                            className="calendar-datepicker-icon"
                                            src={calendarIcon}
                                        ></img>
                                    </label>
                                </div>
                            ) : (
                                <div className="value">
                                    {formatDateTime(
                                        formik.values
                                            .contruction_recycle_document_submitted_at,
                                        'YYYY-MM-DD',
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="dialog-field-row">
                            <div className="field-label">提出者</div>
                            {mode === EDIT_SUGOROKU_MODE ? (
                                <div className="autocomplete-wrapper">
                                    <AutocompleteCustom
                                        disablePortal
                                        id="combo-box-demo"
                                        options={workerOptions.map((item) => ({
                                            label: item.label,
                                            value: String(item.value),
                                            name: item.name,
                                        }))}
                                        value={
                                            formik.values
                                                .construction_recycle_document_submitted_worker
                                        }
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                'construction_recycle_document_submitted_worker',
                                                newValue,
                                            );
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        classes={{
                                            root: 'autocomplete-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="" />
                                        )}
                                        noOptionsText="該当なし"
                                    />
                                </div>
                            ) : (
                                <div className="value">
                                    {formik.values
                                        .construction_recycle_document_submitted_worker
                                        ?.name || ''}
                                </div>
                            )}
                        </div>
                    </section>

                    <section className="work-implement-notification">
                        <div className="step-section-title">
                            特定建設作業実施届書
                        </div>
                        <div className="dialog-field-row">
                            <div className="field-label">日付</div>
                            {mode === EDIT_SUGOROKU_MODE ? (
                                <div className="datepicker-wrapper">
                                    <DatePicker
                                        id="construction_notice_document_submitted_at"
                                        selected={
                                            formik.values
                                                .construction_notice_document_submitted_at
                                        }
                                        onChange={(date) => {
                                            formik.setFieldValue(
                                                'construction_notice_document_submitted_at',
                                                date,
                                            );
                                        }}
                                        dateFormat="yyyy-MM-dd"
                                        autoComplete="off"
                                        locale="ja"
                                    />
                                    <label htmlFor="construction_notice_document_submitted_at">
                                        <img
                                            className="calendar-datepicker-icon"
                                            src={calendarIcon}
                                        ></img>
                                    </label>
                                </div>
                            ) : (
                                <div className="value">
                                    {formatDateTime(
                                        formik.values
                                            .construction_notice_document_submitted_at,
                                        'YYYY-MM-DD',
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="dialog-field-row">
                            <div className="field-label">提出者</div>
                            {mode === EDIT_SUGOROKU_MODE ? (
                                <div className="autocomplete-wrapper">
                                    <AutocompleteCustom
                                        disablePortal
                                        id="combo-box-demo"
                                        options={workerOptions.map((item) => ({
                                            label: item.label,
                                            value: String(item.value),
                                            name: item.name,
                                        }))}
                                        value={
                                            formik.values
                                                .construction_notice_document_submitted_worker
                                        }
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                'construction_notice_document_submitted_worker',
                                                newValue,
                                            );
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        classes={{
                                            root: 'autocomplete-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="" />
                                        )}
                                        noOptionsText="該当なし"
                                    />
                                </div>
                            ) : (
                                <div className="value">
                                    {formik.values
                                        .construction_notice_document_submitted_worker
                                        ?.name || ''}
                                </div>
                            )}
                        </div>
                    </section>

                    <section className="engineering-table">
                        <div className="step-section-title">工程表</div>
                        <div className="dialog-field-row">
                            <div className="field-label">日付</div>
                            {mode === EDIT_SUGOROKU_MODE ? (
                                <div className="datepicker-wrapper">
                                    <DatePicker
                                        id="plan_submitted_at"
                                        selected={
                                            formik.values.plan_submitted_at
                                        }
                                        onChange={(date) => {
                                            formik.setFieldValue(
                                                'plan_submitted_at',
                                                date,
                                            );
                                        }}
                                        dateFormat="yyyy-MM-dd"
                                        autoComplete="off"
                                        locale="ja"
                                    />
                                    <label htmlFor="plan_submitted_at">
                                        <img
                                            className="calendar-datepicker-icon"
                                            src={calendarIcon}
                                        ></img>
                                    </label>
                                </div>
                            ) : (
                                <div className="value">
                                    {formatDateTime(
                                        formik.values.plan_submitted_at,
                                        'YYYY-MM-DD',
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="dialog-field-row">
                            <div className="field-label">提出者</div>
                            {mode === EDIT_SUGOROKU_MODE ? (
                                <div className="autocomplete-wrapper">
                                    <AutocompleteCustom
                                        disablePortal
                                        id="combo-box-demo"
                                        options={workerOptions.map((item) => ({
                                            label: item.label,
                                            value: String(item.value),
                                            name: item.name,
                                        }))}
                                        value={
                                            formik.values.plan_submitted_worker
                                        }
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                'plan_submitted_worker',
                                                newValue,
                                            );
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        classes={{
                                            root: 'autocomplete-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="" />
                                        )}
                                        noOptionsText="該当なし"
                                    />
                                </div>
                            ) : (
                                <div className="value">
                                    {formik.values.plan_submitted_worker
                                        ?.name || ''}
                                </div>
                            )}
                        </div>
                    </section>

                    <section className="application">
                        <div className="step-section-title">申請書</div>

                        <Grid container spacing={1}>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                                <div>提出者</div>
                            </Grid>
                            <Grid item xs={3}>
                                <div>提出者</div>
                            </Grid>

                            {roadApplications &&
                                roadApplications.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <Grid item xs={3}>
                                            <div className="step-title">
                                                {item.title}
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {mode === EDIT_SUGOROKU_MODE ? (
                                                <RadioGroup
                                                    aria-label="委託契約書"
                                                    value={item.isNecessary}
                                                    className="radio-group"
                                                >
                                                    <FormControlLabel
                                                        value={true}
                                                        control={
                                                            <Radio
                                                                onClick={
                                                                    item.onChangeIsNecessary
                                                                }
                                                            />
                                                        }
                                                        label="要"
                                                    />
                                                    <FormControlLabel
                                                        value={false}
                                                        control={
                                                            <Radio
                                                                onClick={
                                                                    item.onChangeIsNecessary
                                                                }
                                                            />
                                                        }
                                                        label="不要"
                                                    />
                                                </RadioGroup>
                                            ) : (
                                                <div className="value">
                                                    {item.viewIsNecessary}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {mode === EDIT_SUGOROKU_MODE ? (
                                                <div className="datepicker-wrapper">
                                                    <DatePicker
                                                        id="submitted_at"
                                                        selected={
                                                            item.submittedAt
                                                        }
                                                        onChange={
                                                            item.onChangeSubmittedAt
                                                        }
                                                        dateFormat="yyyy-MM-dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                    />
                                                    <label htmlFor="submitted_at">
                                                        <img
                                                            className="calendar-datepicker-icon"
                                                            src={calendarIcon}
                                                        ></img>
                                                    </label>
                                                </div>
                                            ) : (
                                                <div className="value">
                                                    {item.viewSubmittedAt}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {mode === EDIT_SUGOROKU_MODE ? (
                                                <div className="autocomplete-wrapper">
                                                    <AutocompleteCustom
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={workerOptions.map(
                                                            (item) => ({
                                                                label: item.label,
                                                                value: String(
                                                                    item.value,
                                                                ),
                                                                name: item.name,
                                                            }),
                                                        )}
                                                        value={
                                                            item.submittedWorker
                                                        }
                                                        onChange={
                                                            item.onChangeSubmittedWorker
                                                        }
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value,
                                                        ) =>
                                                            option.value ===
                                                            value.value
                                                        }
                                                        classes={{
                                                            root: 'autocomplete-select-style',
                                                        }}
                                                        renderInput={(
                                                            params,
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label=""
                                                            />
                                                        )}
                                                        noOptionsText="該当なし"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="value">
                                                    {item.viewSubmittedWorker}
                                                </div>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                ))}
                        </Grid>
                    </section>

                    <section className="application-removal-lifeline">
                        <div className="step-section-title">
                            ライフラインの撤去申請
                        </div>

                        <Grid container spacing={1}>
                            <Grid item xs={4.5}></Grid>
                            <Grid item xs={2.5}>
                                <div>営業者</div>
                            </Grid>
                            <Grid item xs={2.5}>
                                <div>作業予定日</div>
                            </Grid>
                            <Grid item xs={2.5}>
                                <div>依頼者</div>
                            </Grid>

                            {cutoffApplications &&
                                cutoffApplications.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <Grid item xs={2}>
                                            <div className="step-title">
                                                {item.title}
                                            </div>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            {mode === EDIT_SUGOROKU_MODE ? (
                                                <RadioGroup
                                                    aria-label="ガス"
                                                    value={item.isNecessary}
                                                    className="radio-group"
                                                >
                                                    <FormControlLabel
                                                        value={true}
                                                        control={
                                                            <Radio
                                                                onClick={
                                                                    item.onChangeIsNecessary
                                                                }
                                                            />
                                                        }
                                                        label="要"
                                                    />
                                                    <FormControlLabel
                                                        value={false}
                                                        control={
                                                            <Radio
                                                                onClick={
                                                                    item.onChangeIsNecessary
                                                                }
                                                            />
                                                        }
                                                        label="不要"
                                                    />
                                                </RadioGroup>
                                            ) : (
                                                <div className="value">
                                                    {item.viewIsNecessary}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            {mode === EDIT_SUGOROKU_MODE ? (
                                                <div className="autocomplete-wrapper">
                                                    <AutocompleteCustom
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={workerOptions.map(
                                                            (item) => ({
                                                                label: item.label,
                                                                value: String(
                                                                    item.value,
                                                                ),
                                                                name: item.name,
                                                            }),
                                                        )}
                                                        value={
                                                            item.businessWorker
                                                        }
                                                        onChange={
                                                            item.onChangeBusinessWorker
                                                        }
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value,
                                                        ) =>
                                                            option.value ===
                                                            value.value
                                                        }
                                                        classes={{
                                                            root: 'autocomplete-select-style',
                                                        }}
                                                        renderInput={(
                                                            params,
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label=""
                                                            />
                                                        )}
                                                        noOptionsText="該当なし"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="value">
                                                    {item.viewBusinessWorker}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            {mode === EDIT_SUGOROKU_MODE ? (
                                                <div className="datepicker-wrapper">
                                                    <DatePicker
                                                        id="view_at"
                                                        selected={item.at}
                                                        onChange={
                                                            item.onChangeAt
                                                        }
                                                        dateFormat="yyyy-MM-dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                    />
                                                    <label htmlFor="view_at">
                                                        <img
                                                            className="calendar-datepicker-icon"
                                                            src={calendarIcon}
                                                        ></img>
                                                    </label>
                                                </div>
                                            ) : (
                                                <div className="value">
                                                    {item.viewAt}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            {mode === EDIT_SUGOROKU_MODE ? (
                                                <div className="autocomplete-wrapper">
                                                    <AutocompleteCustom
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={workerOptions.map(
                                                            (item) => ({
                                                                label: item.label,
                                                                value: String(
                                                                    item.value,
                                                                ),
                                                                name: item.name,
                                                            }),
                                                        )}
                                                        value={
                                                            item.requestWorker
                                                        }
                                                        onChange={
                                                            item.onChangeRequestWorker
                                                        }
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value,
                                                        ) =>
                                                            option.value ===
                                                            value.value
                                                        }
                                                        classes={{
                                                            root: 'autocomplete-select-style',
                                                        }}
                                                        renderInput={(
                                                            params,
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label=""
                                                            />
                                                        )}
                                                        noOptionsText="該当なし"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="value">
                                                    {item.viewRequestWorker}
                                                </div>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                ))}
                        </Grid>
                    </section>

                    <section className="start-of-construction">
                        <div className="step-section-title">着工</div>

                        <div className="dialog-field-row">
                            <div className="field-label">工期</div>

                            {mode === EDIT_SUGOROKU_MODE ? (
                                <div className="datepicker-wrapper">
                                    <DatePicker
                                        id="plan_from"
                                        selected={formik.values.plan_from}
                                        onChange={(date) => {
                                            formik.setFieldValue(
                                                'plan_from',
                                                date,
                                            );
                                        }}
                                        dateFormat="yyyy-MM-dd"
                                        autoComplete="off"
                                        locale="ja"
                                    />
                                    <label htmlFor="plan_from">
                                        <img
                                            className="calendar-datepicker-icon"
                                            src={calendarIcon}
                                        ></img>
                                    </label>
                                </div>
                            ) : (
                                <div className="value">
                                    {formatDateTime(
                                        formik.values.plan_from,
                                        'YYYY-MM-DD',
                                    )}
                                </div>
                            )}
                            <div className="mx-4">{'  ~  '}</div>
                            {mode === EDIT_SUGOROKU_MODE ? (
                                <div className="datepicker-wrapper">
                                    <DatePicker
                                        id="plan_to"
                                        selected={formik.values.plan_to}
                                        onChange={(date) => {
                                            formik.setFieldValue(
                                                'plan_to',
                                                date,
                                            );
                                        }}
                                        dateFormat="yyyy-MM-dd"
                                        autoComplete="off"
                                        locale="ja"
                                    />
                                    <label htmlFor="plan_to">
                                        <img
                                            className="calendar-datepicker-icon"
                                            src={calendarIcon}
                                        ></img>
                                    </label>
                                </div>
                            ) : (
                                <div className="value">
                                    {formatDateTime(
                                        formik.values.plan_to,
                                        'YYYY-MM-DD',
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="dialog-field-row">
                            <div className="field-label">営業担当者</div>
                            {mode === EDIT_SUGOROKU_MODE ? (
                                <div className="autocomplete-wrapper">
                                    <AutocompleteCustom
                                        disablePortal
                                        id="combo-box-demo"
                                        options={workerOptions.map((item) => ({
                                            label: item.label,
                                            value: String(item.value),
                                            name: item.name,
                                        }))}
                                        value={formik.values.business_worker}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                'business_worker',
                                                newValue,
                                            );
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        classes={{
                                            root: 'autocomplete-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="" />
                                        )}
                                        noOptionsText="該当なし"
                                    />
                                </div>
                            ) : (
                                <div className="value">
                                    {formik.values.business_worker?.name || ''}
                                </div>
                            )}
                        </div>
                        <div className="dialog-field-row">
                            <div className="field-label">現場代理人</div>
                            {mode === EDIT_SUGOROKU_MODE ? (
                                <div className="autocomplete-wrapper">
                                    <AutocompleteCustom
                                        disablePortal
                                        id="combo-box-demo"
                                        options={workerOptions.map((item) => ({
                                            label: item.label,
                                            value: String(item.value),
                                            name: item.name,
                                        }))}
                                        value={formik.values.agent_worker}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                'agent_worker',
                                                newValue,
                                            );
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        classes={{
                                            root: 'autocomplete-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="" />
                                        )}
                                        noOptionsText="該当なし"
                                    />
                                </div>
                            ) : (
                                <div className="value">
                                    {formik.values.agent_worker?.name || ''}
                                </div>
                            )}
                        </div>
                        <div className="dialog-field-row">
                            <div className="field-label">完了日付</div>
                            {mode === EDIT_SUGOROKU_MODE ? (
                                <div className="datepicker-wrapper">
                                    <DatePicker
                                        id="completed_at"
                                        selected={formik.values.completed_at}
                                        onChange={(date) => {
                                            formik.setFieldValue(
                                                'completed_at',
                                                date,
                                            );
                                        }}
                                        dateFormat="yyyy-MM-dd"
                                        autoComplete="off"
                                        locale="ja"
                                    />
                                    <label htmlFor="completed_at">
                                        <img
                                            className="calendar-datepicker-icon"
                                            src={calendarIcon}
                                        ></img>
                                    </label>
                                </div>
                            ) : (
                                <div className="value">
                                    {formatDateTime(
                                        formik.values.completed_at,
                                        'YYYY-MM-DD',
                                    )}
                                </div>
                            )}
                        </div>
                    </section>

                    {mode === EDIT_SUGOROKU_MODE && (
                        <div className="action-section">
                            <button
                                type="button"
                                className="back-step-edit-button"
                                onClick={onClose}
                            >
                                キャンセル
                            </button>
                            <button
                                type="submit"
                                className="submit-step-edit-button"
                                disabled={submitLoading}
                            >
                                {submitLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={18}
                                    />
                                ) : (
                                    '登録'
                                )}
                            </button>
                        </div>
                    )}
                </form>
            )}
        </StyledDialogWrapper>
    );
};

export default BreakingGroundModal;
