import { FC } from 'react';

type IProps = {
    strokeColor?: string;
};

const FolderPlus: FC<IProps> = ({ strokeColor = '#CDD1D5' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 11v6m-3-3h6m7 5a2 2 0 01-2 2H4a2 2 0 01-2-2V5a2 2 0 012-2h5l2 3h9a2 2 0 012 2v11z"
            ></path>
        </svg>
    );
};

export default FolderPlus;
