import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import calendarIcon from '../../../../../assets/icons/calendar.png';
import { Controller, useForm } from 'react-hook-form';

import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);
import { useTranslation } from 'react-i18next';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import workersService from 'services/wokersService';
import { convertErrorMessagesToObject } from 'utils/validation/validatorHelper';
import isEmpty from 'lodash/isEmpty';
import plansService from 'services/plansService';
import updatePlanValidation from 'utils/validation/registers/plans/updatePlanValidation';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import userService from 'services/userService';
import { formatDateTime } from 'utils/helper/dateHelper';
import { handleFilter } from 'utils/helper/filterDropdown';
import SelectSearch from 'react-select-search';
import StyledPlanUpdateWrapper from './styles';

const PlanUpdate = (props) => {
    const {
        onClose,
        onCancel,
        id,
        project_id,
        originPlan,
        setChangeProject,
        isChangeProject,
    } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [currentPlan, setCurrentPlan] = useState<any>(null);
    const planStartAtActual = originPlan.start_at_actual
        ? new Date(originPlan.start_at_actual)
        : null;
    const planEndAtActual = originPlan.end_at_actual
        ? new Date(originPlan.end_at_actual)
        : null;

    const [startDate, setStartDate] = useState<any>(
        new Date(originPlan.start_at),
    );
    const [endDate, setEndDate] = useState<any>(new Date(originPlan.end_at));
    const [startDateActual, setStartDateActual] =
        useState<any>(planStartAtActual);
    const [endDateActual, setEndDateActual] = useState<any>(planEndAtActual);

    // Configurations
    const [statuses, setStatuses] = useState<any>([]);
    const [listWorkers, setListWorkers] = useState<any>([]);
    const [isErrorName, setIsErrorName] = useState(false);
    const [isInvalidNote, setIsInvalidNote] = useState(false);
    const [isInvalidName, setIsInvalidName] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: useMemo(() => {
            const plan = currentPlan || originPlan;
            // return plan
            return {
                ...plan,
                ...{
                    start_at: formatDateTime(plan.start_at, 'YYYY/MM/DD'),
                    end_at: formatDateTime(plan.end_at, 'YYYY/MM/DD'),
                    start_at_actual: formatDateTime(
                        plan.start_at_actual,
                        'YYYY/MM/DD',
                    ),
                    end_at_actual: formatDateTime(
                        plan.end_at_actual,
                        'YYYY/MM/DD',
                    ),
                },
            };
        }, [originPlan, currentPlan]),
    });

    const getListConfiguration = async () => {
        try {
            const response = await userService.getListConfiguration();
            if (response !== null && response !== undefined) {
                setStatuses(
                    convertUserConfigToDropdownOptions(
                        response.configurations?.plan?.status_types,
                    ),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getDetailPlan = async () => {
        if (!id || !project_id) return;
        await plansService
            .getPlan(id, project_id)
            .then((response) => {
                setCurrentPlan(response.plan);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getListWorkers = async () => {
        try {
            const response = await workersService.getListWorkers({
                per_page: 999,
            });
            if (response !== null && response !== undefined) {
                setListWorkers(
                    convertObjectToDropdownOptions(response.workers),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        getListWorkers();
        getListConfiguration();
    }, []);

    useEffect(() => {
        if (id && project_id) getDetailPlan();
    }, [id, project_id]);

    useEffect(() => {
        if ((currentPlan || originPlan) && statuses.length) {
            setValue(
                'status',
                statuses.find(
                    (status) =>
                        status.label ===
                        (currentPlan || originPlan).status_type,
                ),
            );
        }
    }, [originPlan, currentPlan, statuses, listWorkers]);

    const onUpdatePlan = (data) => {
        if(data?.name?.trim()?.length == 0 || !data.name ) {
            setIsErrorName(true)
            return;
        }
        if(isInvalidNote || isInvalidName) return;
        return new Promise(async (resolve, reject) => {
            data.project_id =
                (currentPlan || originPlan).project_id || project_id;
            await plansService
                .updatePlan(id, {...data, name: data?.name?.trim()})
                .then((data) => {
                    onClose();
                    setChangeProject(!isChangeProject);
                })
                .catch((err) => {
                    const response = err?.response;
                    if (!response || typeof response === 'undefined') {
                        window.location.href = '/login';
                        reject(err);
                    }
                    switch (response.status) {
                        case 403:
                            const messages = convertErrorMessagesToObject(
                                {
                                    name: 'name',
                                    start_at: 'start_at',
                                    start_at_actual: 'start_at_actual',
                                    end_at: 'end_at',
                                    end_at_actual: 'end_at_actual',
                                    assigned_worker_id: 'assigned_worker_id',
                                    note: 'note',
                                    status: 'status',
                                },
                                response.data?.error,
                            );

                            if (!isEmpty(messages)) {
                                Object.keys(messages).map((key: any) =>
                                    setError(key, { message: messages[key] }),
                                );
                            } else {
                                setError('name', {
                                    message: response.data?.error,
                                });
                            }
                            break;
                        default:
                    }
                    reject(err);
                });
            resolve(true);
        });
    };
    
    const convertErrorMessage = (err) => {
        if(!err) return '';
        if(err?.includes('0以上の')) {
            return '値は 0 以上でなければなりません。';
        } else if(err?.includes('100より大')) {
            return '値は 100 以下でなければなりません。';
        } else {
            return err;
        }
    }
    return (
        <StyledPlanUpdateWrapper onSubmit={handleSubmit(onUpdatePlan)}>
            <div className="planTitle">{originPlan?.name}</div>
            {location.pathname.includes('/calendar') && (
                <div className="fieldWrapper">
                    <p>案件名</p>
                    <input
                        autoComplete="off"
                        defaultValue={originPlan?.projectName}
                        disabled={true}
                    />
                </div>
            )}
            <div className="fieldWrapper">
                <p>工程名</p>
                <input
                    autoComplete="off"
                    type="name"
                    {...register('name', { required: '必須です。' })}
                    onChange={(e) => {setIsErrorName(!e.target.value.trim() ? true : false); setIsInvalidName(e.target.value?.length > 255 ? true : false)}}
                />
                {errors.name && !isErrorName && !isInvalidName && (
                    <span className="error">{errors.name.message}</span>
                )}
                {isErrorName && (
                        <span className="error">必須です。</span>
                )}
                {isInvalidName && (
                    <span className="error">255文字以内で入力してください。</span>
                )}
            </div>
            <div className="fieldWrapper">
                <p>期間</p>
                <div className="datepickerGroup">
                    <div className="datepickerWrapper">
                        <Controller
                            name="start_at"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    id="start_at"
                                    selected={startDate}
                                    {...register('start_at', {
                                        required: '必須です。',
                                    })}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                        setStartDate(date);
                                    }}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsStart
                                    maxDate={endDate}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                            )}
                        />
                        <label htmlFor="start_at">
                            <img
                                className="calendarIcon"
                                src={calendarIcon}
                            ></img>
                        </label>
                    </div>
                    <div className="tilde">~</div>
                    <div className="datepickerWrapper">
                        <Controller
                            name="end_at"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    id="end_at"
                                    selected={endDate}
                                    {...register('end_at', {
                                        required: '必須です。',
                                    })}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                        setEndDate(date);
                                    }}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsEnd
                                    minDate={startDate}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                            )}
                        />
                        <label htmlFor="end_at">
                            <img
                                className="calendarIcon"
                                src={calendarIcon}
                            ></img>
                        </label>
                    </div>
                </div>
                {(errors.start_at || errors.end_at) && (
                    <span className="error">
                        {errors.start_at?.message || errors.end_at?.message}
                    </span>
                )}
            </div>
            <div className="fieldWrapper">
                <p>期間実績</p>
                <div className="datepickerGroup">
                    <div className="datepickerWrapper">
                        <Controller
                            name="start_at_actual"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    id="start_at_actual"
                                    selected={startDateActual}
                                    {...register('start_at_actual')}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                        setStartDateActual(date);
                                    }}
                                    startDate={startDateActual}
                                    endDate={endDateActual}
                                    selectsStart
                                    maxDate={endDateActual}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                            )}
                        />
                        <label htmlFor="start_at_actual">
                            <img
                                className="calendarIcon"
                                src={calendarIcon}
                            ></img>
                        </label>
                    </div>
                    <div className="tilde">~</div>
                    <div className="datepickerWrapper">
                        <Controller
                            name="end_at_actual"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    id="end_at_actual"
                                    selected={endDateActual}
                                    {...register('end_at_actual')}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                        setEndDateActual(date);
                                    }}
                                    startDate={startDateActual}
                                    endDate={endDateActual}
                                    selectsEnd
                                    // required={true}
                                    minDate={startDateActual}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                            )}
                        />
                        <label htmlFor="end_at_actual">
                            <img
                                className="calendarIcon"
                                src={calendarIcon}
                            ></img>
                        </label>
                    </div>
                </div>
                {/* {(errors.start_at_actual || errors.end_at_actual) && <span className="error">{errors.start_at_actual?.message || errors.end_at_actual?.message}</span>} */}
            </div>
            <div className="fieldWrapper">
                <p>担当</p>
                <Controller
                    name="assigned_worker_id"
                    control={control}
                    render={({ field }) => (
                        <SelectSearch
                            options={listWorkers}
                            search
                            filterOptions={handleFilter}
                            {...register('assigned_worker_id', {
                                required: '必須です。',
                            })}
                            {...field}
                            placeholder=""
                        />
                    )}
                />
                {errors.assigned_worker_id && (
                    <span className="error">
                        {errors.assigned_worker_id.message}
                    </span>
                )}
            </div>
            <div className="fieldWrapper">
                <p>状態</p>
                <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                        <Dropdown
                            className="planStatusDropdown"
                            controlClassName="statusDropdownControl"
                            options={statuses}
                            {...register(
                                'status',
                                updatePlanValidation(t).status(),
                            )}
                            {...field}
                            placeholder=""
                        />
                    )}
                />
                {errors.status && (
                    <span className="error">{errors.status.message}</span>
                )}
            </div>
            <div className="fieldWrapper">
                <p>進捗</p>
                <input
                    autoComplete="off"
                    type="number"
                    {...register(
                        'progress_percentage',
                        updatePlanValidation(t).progress_percentage(),
                    )}
                    onKeyDown={(e) => e.key === '.' || e.key === ',' ? e.preventDefault() : {}}
                />
                {errors.progress_percentage && (
                    <span className="error">
                        {convertErrorMessage(errors?.progress_percentage?.message)}
                    </span>
                )}
            </div>
            <div className="fieldWrapper">
                <p>備考</p>
                <textarea
                    autoComplete="off"
                    className="dropdown-update-plan2"
                    {...register('note')}
                    onChange={(e) => setIsInvalidNote(e.target.value?.length > 500 ? true : false) }
                ></textarea>
                {isInvalidNote && (
                    <span className="error">500 文字以内で入力してください。</span>
                )}
            </div>

            <div className="buttonGroup">
                <button className="returnBtn" onClick={onCancel}>
                    戻る
                </button>
                <button className="registerBtn" type="submit">
                    登録
                </button>
            </div>
        </StyledPlanUpdateWrapper>
    );
};

export default PlanUpdate;
