import { FC } from 'react';

type IProps = {
    strokeColor?: string;
};

const Note: FC<IProps> = ({ strokeColor = '#CDD1D5' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 9h6M9 12h6M9 15h3M14.69 20.25H4.5a.75.75 0 01-.75-.75v-15a.75.75 0 01.75-.75h15a.75.75 0 01.75.75v10.19a.742.742 0 01-.216.526l-4.818 4.818a.74.74 0 01-.525.216v0z"
            ></path>
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.184 15H15v5.184"
            ></path>
        </svg>
    );
};

export default Note;
