import styled from 'styled-components';

export const PastImagesContainer = styled.div`
  .imagesContainer {
    display: flex;
    column-gap: 8px;
    row-gap: 8px;
    flex-wrap: wrap;
  }
  .imageWrapper {
    width: 158px;
    height: 158px;
    position: relative;
    
    img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
    .deleteIcon {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 0px;
        right: 0px;
        cursor: pointer;
    }
  }
  .addFileBtn {
    position: relative;
    width: 158px;
    height: 158px;
    background: #FAFAFA;
    border-radius: 8px;
    margin-right: 8px;
    border: 0.96px dashed #D9D9D9;
  }
  .addFileBtn-label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .btn-back {
    font-size: 14px;
    line-heigth: 16.41px;
    font-weight: 500;
    color: #3A3B3C;
    border: 1px solid #3A3B3C;
    padding: 8px 25px;
    width: 120px;
    border-radius: 3px;
  }
  .btn-submit {
    font-size: 14px;
    line-heigth: 16.41px;
    font-weight: 500;
    color: #FFFFFF;
    background: #215493;
    padding: 8px 25px;
    border-radius: 3px;
  }
`

