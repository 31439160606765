import { useState, useMemo, useEffect } from 'react';
import Dropdown from 'react-select';
import moment from 'moment';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import estimateService from 'services/estimationService';
import calendarIcon from 'assets/icons/calendar.png';

import { useLogicEstimate } from '../hooks';
import { validate } from './validation';
// import './styles.css';
import { Main } from './style';
import uploadSimple from 'assets/icons/UploadSimple.svg';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
interface IFormData {
    project_id: number;
    name: string;
    status: {value: string; label: string};
    assigned_worker: {value: number; label: string};
    approver_worker: {value: number; label: string};
    approve_expired_at: string;
    isGotoEdit: boolean;
    document?: File;
}
const EstimationCreate = (props) => {
    const navigate = useNavigate();
    const { control } = useForm();
    const [openAssignedWorker, setOpenAssignedWorker] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [openApproveWorker, setOpenApproveWorker] = useState(false);
    const [fileData, setFileData] = useState<string | null>();

    const initialValues: IFormData = {
        project_id: 0,
        name: '',
        status: {
            value: '',
            label: '',
        },
        assigned_worker: {
            value: 0,
            label: '',
        },
        approver_worker: {
            value: 0,
            label: '',
        },
        approve_expired_at: '',
        isGotoEdit: false,
        document: undefined,
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validate,
        onSubmit: (values) => {
            submitForm(values);
        },
    });

    const {
        id,
        project = {},
        workers = [],
        loading = false,
        statusOptions = [],
        getProjectDetail,
        getListWorkers,
        setLoading,
    } = useLogicEstimate();

    const renderContentPreview = () => {
        if (!fileData) return;
            return <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={fileData}
                    defaultScale={SpecialZoomLevel.PageWidth}
                />
            </Worker>
    }

    const handleFileUpload = (e) => {
        formik.setFieldValue('document', e.target.files[0]);
    };

    const handleViewFile = async (values) => {
        try {
            const response = await fetch(values?.image_path);
            const data = await response.blob();
            const file = window.URL.createObjectURL(data);
            values?.image_path ? setFileData(file) : setFileData(URL.createObjectURL(values));
        } catch (error) {}
    };

    useEffect(() => {
        if (id) {
            getProjectDetail(Number(id));
            formik.setValues({
                ...formik.values,
                project_id: Number(id),
            });
        }
        getListWorkers();
    }, []);

    const submitForm = async (data) => {
        setLoading(true);
        let formData = new FormData();
        formData.append('project_id', data.project_id);
        formData.append('status', data.status.value);
        formData.append('assigned_worker_id', data.assigned_worker.value);
        formData.append('approver_worker_id', data.approver_worker.value);
        // formData.append("approve_expired_at", moment(data.approve_expired_at).startOf('day').utc().format('YYYY-MM-DD'));
        formData.append(
            'approve_expired_at',
            moment(data.approve_expired_at).format('YYYY-MM-DD'),
        );
        if(data.document !== undefined) {
            formData.append('document', data.document);
        }
        return await estimateService
            .createEstimate(formData)
            .then((res) => {
                if (res?.estimate?.id && !data.isGotoEdit) {
                    return navigate(`/projects/detail/${id}/plan?tab=1`);
                }

                if (res?.estimate?.id && data.isGotoEdit) {
                    return navigate(
                        `/projects/estimation/${id}/edit/${res?.estimate?.id}`,
                    );
                }
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
    };

    return (
        <Main>
            <div className="container-form-estimation">
            {fileData && (
                <Dialog
                    onClose={() => setFileData(null)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: "100%",
                            height: "100%",
                            margin: '0px',
                        },
                       
                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!fileData}
                >
                    <DialogContent>
                        {
                            renderContentPreview()
                        }
                    </DialogContent>
                </Dialog>
            )}
                <form
                    onSubmit={formik.handleSubmit}
                    className="form-create-estimation d-flex flex-column"
                >
                    <div className="title">見積新規作成</div>
                    <div className="form-create-estimation-form d-flex flex-column">
                        <div className="input-add-estimation d-flex">
                            <p className="d-flex">案件名</p>
                            <div className="d-flex content-right">
                                {project?.name}
                            </div>
                        </div>
                        <div className="input-add-estimation d-flex">
                            <p className="d-flex">見積担当</p>
                            <div className="d-flex content-right">
                                <Controller
                                    control={control}
                                    name="assigned_worker"
                                    render={() => (
                                        <Dropdown
                                            isDisabled={loading}
                                            className="modal-dropdown-create-estimation height36"
                                            //controlClassName="dropdown-control-plan height36"
                                            options={workers}
                                            //value={String(
                                            //    formik.values.assigned_worker
                                            //        .label,
                                            //)}
                                            value={
                                                formik.values.assigned_worker
                                            }
                                            onChange={(value: any) =>
                                                formik.setFieldValue(
                                                    'assigned_worker',
                                                    { ...value },
                                                )
                                            }
                                            placeholder=""
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            menuIsOpen={openAssignedWorker}
                                            onMenuOpen={() => setOpenAssignedWorker(true)}
                                            onMenuClose={() => setOpenAssignedWorker(false)}
                                            onFocus={() => setOpenAssignedWorker(true)}
                                        />
                                    )}
                                />
                                {formik.touched.assigned_worker &&
                                    formik.errors.assigned_worker && (
                                        <span className="error ml-3">
                                            {formik.errors.assigned_worker}
                                        </span>
                                    )}
                            </div>
                        </div>
                        <div className="input-add-estimation d-flex">
                            <p className="d-flex">状態</p>
                            <div className="d-flex content-right">
                                <Controller
                                    control={control}
                                    name="status"
                                    render={() => (
                                        <Dropdown
                                            isDisabled={loading}
                                            className="modal-dropdown-create-estimation height36 w-full"
                                            //controlClassName="dropdown-control-plan height36"
                                            options={statusOptions}
                                            value={formik.values.status}
                                            onChange={(value: any) =>
                                                formik.setFieldValue('status', {
                                                    ...value,
                                                })
                                            }
                                            placeholder=""
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            menuIsOpen={openStatus}
                                            onMenuOpen={() => setOpenStatus(true)}
                                            onMenuClose={() => setOpenStatus(false)}
                                            onFocus={() => setOpenStatus(true)}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="input-add-estimation d-flex">
                            <p className="d-flex">承認担当</p>
                            <div className="d-flex content-right">
                                <Controller
                                    control={control}
                                    name="approver_worker"
                                    render={() => (
                                        <Dropdown
                                            isDisabled={loading}
                                            className="modal-dropdown-create-estimation height36 w-full"
                                            //controlClassName="dropdown-control-plan height36"
                                            options={workers}
                                            //value={String(
                                            //    formik.values.approver_worker
                                            //        .label,
                                            //)}
                                            value={formik.values.approver_worker}
                                            onChange={(value: any) =>
                                                formik.setFieldValue(
                                                    'approver_worker',
                                                    { ...value },
                                                )
                                            }
                                            placeholder=""
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            menuIsOpen={openApproveWorker}
                                            onMenuOpen={() => setOpenApproveWorker(true)}
                                            onMenuClose={() => setOpenApproveWorker(false)}
                                            onFocus={() => setOpenApproveWorker(true)}
                                        />
                                    )}
                                />
                                {formik.touched.approver_worker &&
                                    formik.errors.approver_worker && (
                                        <span className="error ml-3">
                                            {formik.errors.approver_worker}
                                        </span>
                                    )}
                            </div>
                        </div>
                        <div className="input-add-estimation d-flex">
                            <p className="d-flex">承認期限</p>
                            <div className="content-right d-flex">
                                <div className="datepicker-wrap">
                                    <div className="target-datepicker-wrap d-flex flex-row">
                                        <div
                                            className="input-date"
                                            style={{ position: 'relative' }}
                                        >
                                            <Controller
                                                control={control}
                                                name="approve_expired_at"
                                                render={() => (
                                                    <DatePicker
                                                        id="approve_expired_at"
                                                        selectsStart
                                                        dateFormat="yyyy-MM-dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                        selected={
                                                            formik.values
                                                                .approve_expired_at
                                                        }
                                                        startDate={
                                                            formik.values
                                                                .approve_expired_at
                                                        }
                                                        onChange={(date) => {
                                                            formik.setFieldValue(
                                                                'approve_expired_at',
                                                                date,
                                                            );
                                                        }}
                                                        disabled={loading}
                                                    />
                                                )}
                                            />
                                            <label htmlFor="approve_expired_at">
                                                <img
                                                    className="input-date-img"
                                                    width={'16px'}
                                                    height={'16px'}
                                                    src={calendarIcon}
                                                ></img>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {formik.touched.approve_expired_at &&
                                    formik.errors.approve_expired_at && (
                                        <span className="error ml-3">
                                            {formik.errors.approve_expired_at}
                                        </span>
                                    )}
                            </div>
                        </div>
                        <div className="input-add-estimation d-flex">
                            <p className="d-flex">ファイル</p>
                            <div className='content-right flex items-center gap-x-[24px]'>
                                <Button
                                    component="label"
                                    className="uploadImageFileBtn"
                                    style={{
                                        width: 'fit-content',
                                        backgroundColor: '#215493',
                                        color: '#FFFFFF',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        padding: '10px 18px',
                                        height: '36px',
                                        borderRadius: '6px',
                                    }}
                                >
                                    <img
                                        src={uploadSimple}
                                        alt="upload icon"
                                        style={{ marginRight: '7px' }}
                                    />
                                    <span>ファイルアップロード</span>
                                    <input
                                        type="file"
                                        name="document"
                                        hidden
                                        style={{
                                            display: 'none',
                                        }}
                                        onChange={handleFileUpload}
                                        accept="application/pdf"
                                    />
                                </Button>
                                <div className='pt-2 flex items-center gap-x-[12px]'>
                                    <div className="mb-0">
                                        {formik?.values?.document?.name || ''}
                                    </div>
                                    {
                                        formik?.values?.document && <div className="text-[#195192] text-[14px] font-[500] cursor-pointer underline mb-0" onClick={() => { handleViewFile(formik?.values?.document) }}>プレビュー</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="group-buttons d-flex flex-row">
                        <Link
                            className={`bnt bnt-cancel d-flex flex-row ${loading && 'disable-link'
                                }`}
                            to={`/projects/detail/${id}/plan?tab=1`}
                        >
                            戻る
                        </Link>
                        <Button
                            className="bnt bnt-submit d-flex flex-row"
                            type="submit"
                            disabled={loading}
                        >
                            登録
                        </Button>
                        <Button
                            className="bnt bnt-submit d-flex flex-row"
                            onClick={() => {
                                formik.setFieldValue('isGotoEdit', true);
                                formik.handleSubmit();
                            }}
                            disabled={loading}
                        >
                            登録して見積編集
                        </Button>
                    </div>
                </form>
            </div>
        </Main>
    );
};

export default EstimationCreate;
