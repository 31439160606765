import { useEffect, useState } from 'react';
import './WorkingReport.css';
import 'react-step-progress/dist/index.css';
import ReportServiceSP from 'services/reportServiceSP';
import { formatDateTime } from 'utils/helper/dateHelper';
import WorkingReportCreateSP from './WorkingReportCreate';
import { RootState, useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import {
    setAddSuccess,
    setEditSuccess,
    setDeleteSuccess,
    setFetching,
    setReports,
    setSelectedReport,
    setShowModalDelete,
    setShowModalDetail,
    setShowModalUpdate,
    setTempFilterReset,
    setShowModalCreate,
} from 'store/workingReport/workingReport';
import WorkingReportDeleteSP from './WorkingReportDelete';
import WorkingReportFilterSP from './WorkingReportFilter';
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import styled from 'styled-components';
import {
    Paper,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableRow,
    Table,
    TableHead,
    TableBody,
    Grid,
    Box,
    Button,
} from '@mui/material';
import { IPageQueries } from 'models';
import CustomPagination from 'components/CustomPagination';
import TrashIcon from 'assets/icons/delete_ic.svg';
import ViewIcon from 'assets/icons/view_ic.svg';
import EditIcon from 'assets/icons/edit_ic.svg';

const StyledTable = styled(Table)(({ theme }) => ({
    tableLayout: 'fixed',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        height: '56px',
        backgroundColor: '#C7DCF5',
        color: '#000000',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 400,
    },
    [`&.${tableCellClasses.body}`]: {
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '17px',
        color: '#000000',
        height: '56px',
        padding: '8px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const WorkingReportListSP = ({}) => {
    const navigate = useNavigate();
    const [Modal, open, close, isOpen] = useModal('root', {
        // preventScroll: true,
        closeOnOverlayClick: false,
    });
    const [
        ModalDelete,
        openDeleteModal,
        closeDeleteModal,
        isOpenDelete,
    ] = useModal('root', {
        // preventScroll: true,
        closeOnOverlayClick: false,
    });
    const {
        showModalDetail = false,
        showModalCreate = false,
        showModalUpdate = false,
        showModalDelete = false,
        addSuccess = false,
        editSuccess = false,
        deleteSuccess = false,
    } = useSelector((state: RootState) => state.workingReport);
    let { id } = useParams();
    const dispatch = useAppDispatch();
    const {
        reports = [] as any[],
        filter = {},
        fetching = false,
    } = useSelector((state: RootState) => state.workingReport);

    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });

    // const getDetailProject = async (id: number) => {
    //     if (!id) return;
    //     try {
    //         const response = await projectsService.getProject(id);
    //         setCurrentProject(response.project);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // useEffect(() => {
    //     if (id) {
    //         getDetailProject(Number(id));
    //     }
    // }, [id]);

    const getListReports = async ({ page = 1, per_page = 0, data = {} }) => {
        try {
            dispatch(setFetching(true));
            const response: any = await ReportServiceSP.getListReports({
                project_id: id,
                page,
                per_page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                dispatch(setReports(response.working_reports));
                setTotalCount(response.meta.total_count);
            }
        } catch (err) {
            console.log('err', err);
        } finally {
            dispatch(setFetching(false));
        }
    };

    const openShow = async (record) => {
        try {
            const response: any = await ReportServiceSP.getReportDetail(
                record?.id,
                {
                    project_id: id,
                },
            );
            if (response) {
                navigate(`/sp/project/${id}/weekly-report/${record?.id}`);
                dispatch(setSelectedReport(response.working_report));
                dispatch(setShowModalDetail(true));
                dispatch(setShowModalCreate(false));
                dispatch(setShowModalUpdate(false));
            }
        } catch (e) {
            console.error(e);
        }
    };

    const openEdit = async (record) => {
        try {
            const response: any = await ReportServiceSP.getReportDetail(
                record?.id,
                {
                    project_id: id,
                },
            );
            if (response) {
                navigate(`/sp/project/${id}/weekly-report/${record?.id}`);
                dispatch(setSelectedReport(response.working_report));
                dispatch(setShowModalUpdate(true));
                dispatch(setShowModalDetail(false));
                dispatch(setShowModalCreate(false));
            }
        } catch (e) {
            console.error(e);
        }
    };

    const openDelete = (record) => {
        dispatch(setSelectedReport(record));
        dispatch(setShowModalDelete(true));
    };

    useEffect(() => {
        if (id) {
            getListReports({
                page: pageQueries.page,
                per_page: pageQueries.per_page,
            });
        }
    }, [id]);

    useEffect(() => {
        if (filter) {
            if (pageQueries.page !== 1) {
                setPageQueries((prevState) => ({ ...prevState, page: 1 }));
            }
            getListReports({
                page: 1,
                per_page: pageQueries.per_page,
                data: { ...filter },
            });
        }
    }, [filter]);

    useEffect(() => {
        if (showModalCreate || showModalDetail || showModalUpdate) {
            open();
        } else {
            dispatch(setSelectedReport({}));
            close();
        }
    }, [showModalDetail, showModalCreate, showModalUpdate]);

    useEffect(() => {
        if (showModalDelete) {
            openDeleteModal();
        } else {
            closeDeleteModal();
        }
    }, [showModalDelete]);

    useEffect(() => {
        if (addSuccess) {
            dispatch(setAddSuccess(false));
            if (pageQueries.page !== 1) {
                setPageQueries((prevState) => ({ ...prevState, page: 1 }));
            }
            // getListReports({
            //   page: 1,
            //   per_page: pageQueries.per_page,
            //   data: { ...filter }
            // });

            dispatch(setTempFilterReset(true));
        }
    }, [addSuccess]);

    useEffect(() => {
        if (editSuccess) {
            dispatch(setEditSuccess(false));
            if (pageQueries.page !== 1) {
                setPageQueries((prevState) => ({ ...prevState, page: 1 }));
            }
            // getListReports({
            //   page: 1,
            //   per_page: pageQueries.per_page,
            //   data: { ...filter }
            // });
            dispatch(setTempFilterReset(true));
        }
    }, [editSuccess]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(setDeleteSuccess(false));
            if (pageQueries.page !== 1) {
                setPageQueries((prevState) => ({ ...prevState, page: 1 }));
            }
            getListReports({
                page: 1,
                per_page: pageQueries.per_page,
                data: { ...filter },
            });
        }
    }, [deleteSuccess]);

    return (
        <div className='h-[100vh]'>
            <WorkingReportFilterSP />
            <div className="table-workers d-flex flex-column mt-[30px] px-[16px]">
                <div className="table-content d-flex flex-column">
                    <TableContainer
                        component={Paper}
                        // table-layout={'fixed'}
                    >
                        <StyledTable
                            sx={{ minWidth: 345 }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        className="text-overflow"
                                        align="center"
                                        style={{ width: '30%' }}
                                    >
                                        作業日
                                    </StyledTableCell>

                                    <StyledTableCell
                                        className="text-overflow"
                                        align="center"
                                    >
                                        作業内容
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow"
                                        align="center"
                                        style={{ width: '30%' }}
                                    >
                                        <div>操作</div>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reports !== null &&
                                    reports.map((record, ind) => (
                                        <StyledTableRow key={ind}>
                                            <StyledTableCell
                                                className="text-overflow"
                                                align="left"
                                                scope="row"
                                            >
                                                {formatDateTime(
                                                    record?.from_at,
                                                    'YYYY/MM/DD',
                                                )}{' '}
                                                ~
                                                <br />
                                                {`${formatDateTime(
                                                    record?.to_at,
                                                    'YYYY/MM/DD',
                                                )}`}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="whitespace-nowrap text-ellipsis overflow-hidden"
                                                align="center"
                                            >
                                                {record?.working_logs?.reduce(
                                                    (
                                                        accu,
                                                        log,
                                                        idx,
                                                        workingLogsArray,
                                                    ) => {
                                                        if (
                                                            idx ===
                                                            workingLogsArray.length -
                                                                1
                                                        ) {
                                                            accu =
                                                                accu +
                                                                log?.name;
                                                        } else {
                                                            accu =
                                                                accu +
                                                                log?.name +
                                                                ', ';
                                                        }
                                                        return accu;
                                                    },
                                                    '',
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow"
                                                align="center"
                                            >
                                                <Grid
                                                    className="list-icon"
                                                    xs={3}
                                                    item
                                                >
                                                    <Box
                                                        className="view-ic"
                                                        onClick={() => {
                                                            openShow(record);
                                                        }}
                                                    >
                                                        <img src={ViewIcon} />
                                                    </Box>
                                                    <Box
                                                        className="edit-ic"
                                                        onClick={() => {
                                                            openEdit(record);
                                                        }}
                                                    >
                                                        <img src={EditIcon} />
                                                    </Box>
                                                    <Box
                                                        className="delete-ic"
                                                        onClick={() => {
                                                            openDelete(record);
                                                        }}
                                                    >
                                                        <img src={TrashIcon} />
                                                    </Box>
                                                </Grid>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                {/* {reports?.length === 0 && (
                                    <StyledTableRow>
                                        <StyledTableCell
                                            className="text-overflow"
                                            align="center"
                                            colSpan={5}
                                        >
                                            データなし
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )} */}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                </div>
            </div>
            <div>
                <Button
                    onClick={() => navigate(`/sp/project/${id}`)}
                    sx={{
                        color: '#215493',
                        margin: '28px 16px',
                        width: '166px',
                        fontWeight: 600,
                    }}
                    variant="outlined"
                >
                    戻る
                </Button>
            </div>

            {/* {reports && reports.length > 0 && (
                <CustomPagination
                    totalCount={totalCount}
                    pageQueries={pageQueries}
                    setPageQueries={setPageQueries}
                    fetchList={(page) =>
                        getListReports({
                            page,
                            per_page: pageQueries.per_page,
                            data: { ...filter },
                        })
                    }
                />
            )} */}
            {/* <Modal>
                <WorkingReportCreateSP />
            </Modal> */}
            <ModalDelete>
                <WorkingReportDeleteSP />
            </ModalDelete>
        </div>
    );
};

export default WorkingReportListSP;
