import { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import plus from 'assets/icons/Plus.png';
import { formatMoney } from 'utils/helper/helpers';

import { Main, StyledTableRow, StyledTableCell } from './styles';
import { useSearchParams } from 'react-router-dom';

const ListDetail = ({
    item,
    data,
    onAddLineNew,
    openDelete,
    onGetDetail,
    onBack,
    showDataEstimateItem,
    totalPrice,
    classNameMain = '',
    isShowDeleteBtn = true,
    classNameBtn = '',
}) => {
    const [dataList, setDataList] = useState<any[]>([]);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setDataList(data);
    }, [data]);

    return (
        <Main className={classNameMain}>
            {/* button show for children estimate */}
            {showDataEstimateItem && (
                <>
                    <Button className="bnt bnt-line bnt-back" onClick={onBack}>
                        戻る
                    </Button>
                    <span className="ml-3">{item.name}</span>
                </>
            )}

            <TableContainer component={Paper} table-layout={'auto'}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                                style={{ width: '10%' }}
                            >
                                <div
                                    className="text-overflow1"
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    符号
                                </div>
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                                style={{ width: '10%' }}
                            >
                                <div
                                    className="text-overflow1"
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    名称
                                </div>
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                                style={{ width: '10%' }}
                            >
                                <div className="text-overflow1">摘要</div>
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                                style={{ width: '10%' }}
                            >
                                <div className="text-overflow1">数量</div>
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                                style={{ width: '10%' }}
                            >
                                <div className="text-overflow1">単位</div>
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                                style={{ width: '10%' }}
                            >
                                <div className="text-overflow1">単価</div>
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                                style={{ width: '10%' }}
                            >
                                <div className="text-overflow1">金額</div>
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                                style={{ width: '10%' }}
                            >
                                <div className="text-overflow1">備考</div>
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                                style={{ width: '10%' }}
                            >
                                <div className="text-overflow1">操作</div>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.isArray(dataList) &&
                            dataList.map((row, ind) => {
                                return (
                                    <StyledTableRow key={ind}>
                                        <StyledTableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            style={{ width: '10%' }}
                                        >
                                            {row?.id}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            style={{ width: '10%' }}
                                        >
                                            <div className="text-overflow1">
                                                {row?.name}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div className="text-overflow1">
                                                {row?.summary}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div className="text-overflow1">
                                                {row?.amount || ''}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div className="text-overflow1">
                                                {row?.unit || ''}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div className="text-overflow1">
                                                {formatMoney(
                                                    String(
                                                        row?.unit_price || '',
                                                    ),
                                                )}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div className="text-overflow1">
                                                {formatMoney(
                                                    String(
                                                        row?.total_price || 0,
                                                    ),
                                                )}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div className="text-overflow1">
                                                {row?.note}
                                            </div>
                                        </StyledTableCell>

                                        <StyledTableCell
                                            align="center"
                                            style={{
                                                width: '255px',
                                                minWidth: '255px',
                                            }}
                                        >
                                            {!showDataEstimateItem && (
                                                <button
                                                    className={`button-tb ${classNameBtn}`}
                                                    onClick={() =>
                                                        onGetDetail(row)
                                                    }
                                                >
                                                    詳細
                                                </button>
                                            )}

                                            {isShowDeleteBtn && (
                                                <button
                                                    className={`button-tb ${classNameBtn}`}
                                                    onClick={() => openDelete(row)}
                                                    style={{
                                                        width: '72px',
                                                        margin: '5px',
                                                        background: 'rgb(255, 80, 69)'
                                                    }}
                                                >
                                                    削除
                                                </button>
                                            )}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {searchParams.get('type') != 'detail' && (
                <Button
                    className="button-tb bnt-add d-flex"
                    onClick={onAddLineNew}
                >
                    <img width={'16px'} height={'16px'} src={plus}></img>
                    行追加
                </Button>
            )}

            <p className="total_price">
                合計金額 : ¥{formatMoney(String(totalPrice || 0))}
            </p>
        </Main>
    );
};

export default ListDetail;
