import { useState, useEffect } from 'react';
import './worker.css';
import { useNavigate } from 'react-router-dom';
import plus from '../../assets/icons/Plus.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useModal } from 'react-hooks-use-modal';
import workersService from '../../services/wokersService';
import positionService from '../../services/positionService';
import departmentsService from '../../services/departmentsService';
import CooperativeCompanyService from '../../services/cooperativeCompaniesService';
import userService from 'services/userService';
import { handleFilter } from 'utils/helper/filterDropdown';
import { RootState, AppDispatch, useAppDispatch } from '../../store/index';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Controller, useForm } from 'react-hook-form';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import { convertClientCompaniesToDropdownOptionsCreateProject } from 'utils/helper/clientCompanyHelper';
import SelectSearch from 'react-select-search';
import CustomPagination from 'components/CustomPagination';
import WorkerErrorPopup from './workerErrorPopup';
import { TextField, InputAdornment, Box } from '@mui/material';
import { Search } from '@mui/icons-material';
import {StylesWorkerContainer} from './styled';
import EmptyImage from 'components/EmptyImage';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Worker = (props) => {
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const { positionsList } = useSelector(
        (state: RootState) => state.positions,
    );
    const { departmentList } = useSelector(
        (state: RootState) => state.deparments,
    );

    const [workersList, setWorkersList] = useState<any>(null);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState({
        page: 1,
        per_page: 20,
    });
    const [searchedState, setSearchedState] = useState({
        cooperative_company_id: '',
        department_id: undefined,
        free_word: '',
        gender_type: undefined,
        position_id: undefined,
    });
    const [cooperativeListCompanies, setCooperativeListCompanies] =
        useState<any>(null);

    const [positionOption, setPositionOption] = useState<any>([]);
    const [companyOption, setCompanyOption] = useState<any>([]);
    const [departmentOption, setDepartmentOption] = useState<any>([]);

    const [workersDelete, setWorkersDelete] = useState<any>(null);

    const [listGender, setListGender] = useState<any>(null);
    const [listGenderValue, setListGenderValue] = useState<any>(null);
    const [deleteError, setDeleteError] = useState<any>(null);

    const onSearch = (data) => {
        setSearchedState({ ...data });
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        getListWorkers({ page: 1, per_page: pageQueries.per_page, data: data });
    };

    const getListWorkers = async ({ page = 1, per_page = 5, data = {} }) => {
        try {
            const response = await workersService.getListWorkers({
                page,
                per_page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                setWorkersList(response.workers);
                setTotalCount(response.meta.total_count);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListConfiguration = async () => {
        try {
            const response = await userService.getListConfiguration();
            if (response !== null && response !== undefined) {
                setListGender(response.configurations.worker.gender_types);
                setListGenderValue(
                    convertUserConfigToDropdownOptions(
                        response.configurations.worker.gender_types,
                    ),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListDemartment = async () => {
        try {
            const response = await departmentsService.getListDepartments();
            if (response !== null && response !== undefined) {
                setDepartmentOption(
                    convertObjectToDropdownOptions(response.departments),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListPositions = async () => {
        try {
            const response = await positionService.getListPositions();
            // console.log(response)
            if (response !== null && response !== undefined) {
                setPositionOption(
                    convertObjectToDropdownOptions(response.positions),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListCompanies = async () => {
        try {
            const response =
                await CooperativeCompanyService.getListCooperativeCompanies();
            // console.log(response)
            if (response !== null && response !== undefined) {
                setCooperativeListCompanies(response.cooperative_companies);
                setCompanyOption(
                    convertClientCompaniesToDropdownOptionsCreateProject(
                        response.cooperative_companies,
                    ),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const deleteWorker = async () => {
        try {
            const response = await workersService.deleteWorker(
                workersDelete.id,
            );
            if (response !== null && response !== undefined) {
                if (pageQueries.page !== 1) {
                    setPageQueries((prevState) => ({ ...prevState, page: 1 }));
                }
                getListWorkers({
                    page: 1,
                    per_page: pageQueries.per_page,
                    data: { ...searchedState },
                });
                setWorkersDelete(null);
                closeDelete();
            }
            if (response?.error) {
                setDeleteError(response?.error);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const [winReady, setwinReady] = useState(false);
    useEffect(() => {
        setwinReady(true);
        getListConfiguration();
        reset({
            cooperative_company_id: '',
        });
    }, []);

    useEffect(() => {
        getListWorkers({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, []);

    useEffect(() => {
        getListCompanies();
        if (positionsList !== undefined && positionsList.length == 0)
            getListPositions();
        else {
            setPositionOption(convertObjectToDropdownOptions(positionsList));
        }
        if (departmentList !== undefined && departmentList.length == 0)
            getListDemartment();
        else {
            setDepartmentOption(convertObjectToDropdownOptions(departmentList));
        }
    }, []);

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: false,
        },
    );

    return (
        <StylesWorkerContainer className="container-worker d-flex flex-row">
            <div className="worker-content d-flex flex-column">
                <div
                    className="create-button d-flex flex-row"
                    style={{ height: '53px' }}
                >
                    <button
                        className="create-user-button d-flex flex-row"
                        onClick={() => navigate('/workers/create')}
                    >
                        <img width={'16px'} height={'16px'} src={plus}></img>
                        新規作成
                    </button>
                </div>
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className="search-workers d-flex flex-column">
                        <div className="search-workers-row d-flex flex-row">
                            <div className="search-workers-row-dropdown d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>部署</p>
                                    <Controller
                                        name="department_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={departmentOption}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>役職</p>
                                    <Controller
                                        name="position_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={positionOption}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>性別</p>
                                    <Controller
                                        name="gender_type"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={listGenderValue}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="search-workers-row d-flex flex-row">
                            <div className="search-workers-row-dropdown d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>協力会社</p>
                                    <Controller
                                        name="cooperative_company_id"
                                        control={control}
                                        render={({ field }) => (
                                            // <Dropdown
                                            //   className='dropdown-create-user1 height36'
                                            //   controlClassName='dropdown-control-user height36'
                                            //   options={companyOption}
                                            //   {...field}
                                            //   placeholder=""
                                            // />
                                            <SelectSearch
                                                options={companyOption}
                                                search
                                                emptyMessage={'該当なし'}
                                                filterOptions={handleFilter}
                                                // {...register('cooperative_company_id')}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <Box
                                    sx={{
                                        width: '100%',
                                        '.input-search': {
                                            border: '1px solid #cdd1d5',
                                            borderRadius: '8px',
                                            marginBottom: '14.5px',
                                            paddingLeft: '14px',
                                        },
                                        '.MuiTextField-root': {
                                            border: '1px solid #cdd1d5',
                                            width: '100%',
                                            borderRadius: '8px',
                                            marginRight: '12.5px',
                                        },
                                        '.MuiInputBase-input': {
                                            height: '35px',
                                            padding: '0px !important',
                                        },
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: 0,
                                            marginBottom: '4px',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#344054',
                                        }}
                                    >
                                        キーワード
                                    </p>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        sx={{ color: '#000' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        autoComplete="off"
                                        id="input-search"
                                        type="text"
                                        name="free_word"
                                        onChange={(e) => {
                                            setValue(
                                                'free_word',
                                                e.target.value,
                                            );
                                        }}
                                    />
                                </Box>
                            </div>
                            <div className="search-workers-row-button margin-left d-flex flex-column">
                                <button
                                    className="search-user-button d-flex flex-column"
                                    style={{
                                        width: '150px',
                                        height: '36px',
                                        marginBottom: '12px',
                                    }}
                                >
                                    検索
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                {/* <div className='table-workers d-flex flex-column'> */}
                <div className="table-content d-flex flex-column">
                    {winReady ? (
                        <TableContainer component={Paper} table-layout={'auto'}>
                            <Table
                                sx={{ minWidth: 1200 }}
                                aria-label="customized table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div>名前</div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '15%' }}
                                        >
                                            <div
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                フリガナ
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '5%' }}
                                        >
                                            <div
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                性別
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div>部署</div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div>役職</div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div>Eメール</div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '15%' }}
                                        >
                                            <div>協力会社</div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{
                                                width: '25%',
                                                minWidth: '220px',
                                            }}
                                        >
                                            <div>操作</div>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {workersList !== null &&
                                        workersList.map((row, ind) => (
                                            <StyledTableRow key={ind}>
                                                <StyledTableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {row.name}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '15%' }}
                                                >
                                                    {/* {row.kana} */}
                                                    <div className="text-overflow1">
                                                        {row.kana}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '5%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {row?.gender_type}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div className="text-overflow1">
                                                        {row.department !==
                                                            null &&
                                                            row.department.name}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {row.position !==
                                                            null &&
                                                            row.position.name}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '10%'}}
                                                >
                                                    <div className="text-overflow1">
                                                        {row.email}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '15%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {
                                                            row.cooperative_company_name
                                                        }
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '25%' }}
                                                >
                                                    <button
                                                        className="button-tb"
                                                        style={{
                                                            width: '72px',
                                                            margin: '5px',
                                                        }}
                                                        onClick={() =>
                                                            navigate(
                                                                `/workers/detail/${row.id}`,
                                                            )
                                                        }
                                                    >
                                                        詳細
                                                    </button>
                                                    <button
                                                        className="button-tb"
                                                        style={{
                                                            width: '72px',
                                                            margin: '5px',
                                                        }}
                                                        onClick={() => {
                                                            navigate(
                                                                `/workers/edit/${row.id}`,
                                                            );
                                                        }}
                                                    >
                                                        編集
                                                    </button>
                                                    <button
                                                        className={`button-tb ${row?.has_working_times ? 'opacity-70' : ''}`}
                                                        style={{
                                                            width: '72px',
                                                            margin: '5px',
                                                            background:
                                                                '#FF5045',
                                                        }}
                                                        onClick={() => {
                                                            openDelete();
                                                            setWorkersDelete(
                                                                row,
                                                            );
                                                        }}
                                                        disabled={row?.has_working_times}
                                                    >
                                                        削除
                                                    </button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    {workersList?.length <= 0 && (
                                        <tr>
                                            <StyledTableCell colSpan={8}>
                                                <div className="text-center py-[40px]">
                                                    <EmptyImage classNameCustom='inline-block' />
                                                    <div className='text-[#00000040] text-sm mt-[6px]'>データがありません</div>
                                                </div>
                                            </StyledTableCell>
                                        </tr>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : null}
                    <ModalDelete>
                        <div
                            className="modal-create-user d-flex flex-column"
                            style={{ height: '152px' }}
                        >
                            <div className="modal-create-user-title d-flex flex-row">
                                本当に削除しますか?
                            </div>
                            <div
                                className="modal-create-user-buttons d-flex flex-row"
                                style={{ marginTop: '50px' }}
                            >
                                <button
                                    className="modal-create-user-button1 d-flex flex-row"
                                    onClick={closeDelete}
                                >
                                    キャンセル
                                </button>
                                <button
                                    onClick={deleteWorker}
                                    className="modal-create-user-button2 d-flex flex-row"
                                >
                                    はい
                                </button>
                            </div>
                        </div>
                    </ModalDelete>
                    <WorkerErrorPopup
                        isOpen={!!deleteError}
                        message={deleteError}
                        onClose={() => setDeleteError(null)}
                    />
                </div>
                {/* </div> */}
                {workersList && workersList.length > 0 && (
                    <CustomPagination
                        totalCount={totalCount}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) =>
                            getListWorkers({
                                page,
                                per_page: pageQueries.per_page,
                                data: { ...searchedState },
                            })
                        }
                    />
                )}
            </div>
        </StylesWorkerContainer>
    );
};

export default Worker;
