import { StylesCreateStockNotes } from './styled';
import { ReceivedProps } from '../type';
import useCreateInternalOJT, { Props } from './hook';
import { Button } from '@mui/material';
import uploadSimple from 'assets/icons/UploadSimple.svg';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledDialog } from '../styled';

const CreateStockNotesLayout = ({
    fileUpload,
    navigate,
    handleFileUpload,
    handleCreateStockNotes,
    errorMsg,
    openPreview,
    setOpenPreview,
}: Props) => {
    const renderContentPreview = () => {
        if (!fileUpload) return;
        return <Worker workerUrl={`${process.env.REACT_APP_WORKER_URL}`}>
            <Viewer
                fileUrl={URL.createObjectURL(fileUpload)}
            />
        </Worker>
    };
    return (
        <StylesCreateStockNotes>
            {openPreview && (
                <StyledDialog
                    onClose={() => setOpenPreview(false)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: "100%",
                            height: "100%",
                            margin: '0px',
                        },

                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!openPreview}
                >
                    <DialogTitle className='flex justify-end'>
                        <span className='cursor-pointer' onClick={() => setOpenPreview(false)}><CloseIcon /></span>
                    </DialogTitle>
                    <DialogContent>
                        {
                            renderContentPreview()
                        }
                    </DialogContent>
                </StyledDialog>
            )}
            <p className="title">納品書一覧新規登録</p>
            <p className='label'>ファイル</p>
            <Button
                component="label"
                className="uploadImageFileBtn"
                style={{
                    backgroundColor: '#215493',
                    color: '#FFFFFF',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '24px',
                    padding: '10px 18px',
                    height: '36px',
                    borderRadius: '6px',
                }}
            >
                <img
                    src={uploadSimple}
                    alt="upload icon"
                    style={{ marginRight: '7px' }}
                />
                <span>ファイルアップロード</span>
                <input
                    type="file"
                    hidden
                    style={{
                        display: 'none',
                    }}
                    accept="application/pdf"
                    onChange={(e) => handleFileUpload(e.target.files)}
                />
            </Button>
            {fileUpload?.name && <p className="pt-2 mb-0">{fileUpload?.name}<span className="text-[#195192] text-[14px] font-[500] cursor-pointer underline mb-0 ml-[4px]" onClick={() => setOpenPreview(true)}>プレビュー</span></p>}
            {errorMsg && <p className="pt-2 mb-0 text-red">{errorMsg}</p>}
            <div className="mt-10">
                <Button
                    onClick={() => navigate('/stock_notes')}
                    sx={{
                        padding: '6px 18px',
                        height: '44.5px',
                        width: '166.5px',
                        borderColor: 'rgba(33, 84, 147, 0.30)',
                        color: '#215493',
                        borderRadius: '8px',
                        marginRight: '4px',
                    }}
                    variant="outlined"
                >
                    戻る
                </Button>
                <Button
                    onClick={handleCreateStockNotes}
                    sx={{
                        padding: '6px 18px',
                        width: '166.5px',
                        marginLeft: '4px',
                        backgroundColor: '#215493',
                        borderColor: '#215493',
                        color: '#fff',
                        height: '44.5px',
                        borderRadius: '8px',
                    }}
                    variant="contained"
                >
                    登録
                </Button>
            </div>
        </StylesCreateStockNotes>
    );
};

const CreateStockNotes = (props: ReceivedProps) => {
    return <CreateStockNotesLayout {...useCreateInternalOJT(props)} />;
};

export default CreateStockNotes;
