import { useState, Dispatch, SetStateAction, FC, useEffect } from 'react';
import './createWorkingLog.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);
import { useTranslation } from 'react-i18next';
import { convertErrorMessagesToObject } from 'utils/validation/validatorHelper';
import isEmpty from 'lodash/isEmpty';
import workingLogService from 'services/workingLogService';
import createWorkingLogValidation from 'utils/validation/registers/workingLogs/createWorkingLogValidation';
import { Grid, Autocomplete, TextField, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
// import moment from 'moment';
import calendarIcon from 'assets/icons/calendar.png';
import deleteIcon from 'assets/icons/deleteIcon.svg';
import StyledWorkingLogForm from './style';
import { getFilePreview } from 'utils/helper/file';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import CloseIcon from '@mui/icons-material/Close';
import ImageService from 'services/imageService';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
interface IProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    plans: any[];
    workers: any[];
    onFinishCreate: () => void;
    project_id: any;
    currentPlan: any;
}
interface IFile {
    image_path?: string;
    signed_id?: string;
    file?: File;
}
const Input = styled('input')({
    display: 'none !important',
});

const CreateWorkingLogDialog: FC<IProps> = (props) => {
    const {
        open,
        setOpen,
        onFinishCreate,
        project_id,
        plans,
        workers,
        currentPlan,
    } = props;
    const { userData } = useSelector((state: RootState) => state.users);

    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        setError,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    const [fileUpload, setFileUpload] = useState<any[]>([]);
    const [errorSize, setErrorSize] = useState(false);
    const [errorQuantityFiles, setErrorQuantityFiles] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [selectedImage, setSelectedImage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [errorTypeFile, setErrorTypeFile] = useState(false);
    const [listFileConvert, setListFileConvert] = useState<string[]>([]);
    const [loadingConvert, setLoadingConvert] = useState(false);
    const [errorName, setErrorname] = useState(false);

    const handleUploadFile = (files) => {
        let current = fileUpload ? [...fileUpload] : [];
        let currentUpload = fileUpload ? [...fileUpload] : [];
        if (files.length > 0) {
            const fileArr = [...files];
            fileArr?.map((item) => {
                current.push({ file: item, type: 'upload', size: item?.size });
            })
            let total = current?.reduce((sum, item) => {
                return sum + item?.size
            }, 0)
            
            let currentFilterWrongType = current?.filter(item => !(item?.file?.type === 'image/jpeg' || item?.file?.type === 'image/jpg' || item?.file?.type === 'image/svg+xml' || item?.file?.type === 'image/png' || item?.file?.type === 'image/gif' || item?.file?.type === 'image/tiff' || (item?.file?.name?.includes('.heic') && item?.file?.type === '') || (item?.file?.name?.includes('.heif') && item?.file?.type === '')) && !item?.signed_id)
            
            let currentFilterValidType = current?.filter(item => (item?.file?.type === 'image/jpeg' || item?.file?.type === 'image/jpg' || item?.file?.type === 'image/svg+xml' || item?.file?.type === 'image/png' || item?.file?.type === 'image/gif' || item?.file?.type === 'image/tiff' || (item?.file?.name?.includes('.heic') && item?.file?.type === '') || (item?.file?.name?.includes('.heif') && item?.file?.type === '')) || item?.signed_id)

            if(currentFilterWrongType?.length > 0) {
                setErrorTypeFile(true)
            }else{
                setErrorTypeFile(false)
            }
            if(total > 104857600 || current?.length > 100) {
                setErrorSize(total > 104857600 ? true : false)
                setErrorQuantityFiles(current?.length > 100 ? true : false)
                const arrRemoveFileUpload = current?.filter(item => item?.type != 'upload').concat(currentUpload?.filter(item => item?.type == 'upload'))
                setFileUpload(arrRemoveFileUpload);
                convertImage(arrRemoveFileUpload)
            } else {
                setFileUpload(currentFilterValidType);
                setErrorQuantityFiles(false)
                setErrorSize(false)
                convertImage(currentFilterValidType)
            }
            
        }
    };
    
    const handleShowError = (arrFiles) => {
        if(!arrFiles) return;
        let total = 0;
        arrFiles.filter(
            (item) => item?.type != 'destroy',
        )?.map(it => {
            total += it?.size
        })
        setTotalSize(total)                              
    }

    const removeImage = (idx) => {
        // remove for send file to BE
        let current = [...fileUpload];
        current = current.filter((item, index) => index !== idx);
        setFileUpload(current);
        setErrorQuantityFiles(false);
        setErrorTypeFile(false);
        
        // remove for preview file convert in FE
        let currentConvert = [...listFileConvert];
        currentConvert = currentConvert.filter((item, index) => index !== idx);
        setListFileConvert(currentConvert)
     
    };

    const onCreate = (data) => {
        if(!data?.name.trim()) {
            return;
        }
        if(totalSize > 104857600 || errorQuantityFiles) return;
        let formData = new FormData();
        formData.append('project_id', project_id);
        formData.append('plan_id', data?.plan_id?.value || currentPlan?.id);
        formData.append('worker_id', data?.worker?.value);
        formData.append('name', data?.name.trim());
        formData.append('note', data?.note);
        formData.append('worked_at', data?.worked_at);
        //for (var x = 0; x < fileUpload.length; x++) {
        //    formData.append('working_log_images[]', fileUpload[x]!);
        //}
        fileUpload?.forEach((file) => {
            formData.append('working_log_images[]', file.file);
        });
        return new Promise(async (resolve, reject) => {
            await workingLogService
                .createWorkingLog(formData)
                .then((data) => {
                    onFinishCreate();
                })
                .catch((err) => {
                    const response = err?.response;
                    if (!response || typeof response === 'undefined') {
                        // window.location.href = '/login';
                        reject(err);
                    }
                    switch (response.status) {
                        case 403:
                            const messages = convertErrorMessagesToObject(
                                {
                                    name: 'name',
                                    worked_at: 'start_at',
                                    plan_id: 'assigned_worker_id',
                                    note: 'note',
                                },
                                response.data?.error,
                            );

                            if (!isEmpty(messages)) {
                                Object.keys(messages).map((key: any) =>
                                    setError(key, {
                                        message: messages[key],
                                    }),
                                );
                            } else {
                                setError('name', {
                                    message: response.data?.error,
                                });
                            }
                            break;
                        default:
                    }
                    reject(err);
                });
            resolve(true);
        });
    };
    
    const convertImage = async(listFile) => {
        setLoadingConvert(true)
        try {
            let formData = new FormData();
            listFile?.forEach((file) => {
                formData.append('images[]', file?.file);
            });
            const res = await ImageService.convertImage(formData)
            if(!(res as {converted_image_urls: string[]})?.converted_image_urls) return;
            const converted_image_urls = (res as {converted_image_urls: string[]})?.converted_image_urls;
            let listFileAfterConvert = converted_image_urls?.map(item => `${process.env.REACT_APP_DOMAIN_IMAGE}${item}`)
            setListFileConvert(listFileAfterConvert)
            setLoadingConvert(false)
        } catch (error) {
            console.log("convert image error: ", error)
            setLoadingConvert(false)
        }
    }
    useEffect(() => {
        handleShowError(fileUpload)
    }, [fileUpload])

    return (
        <Dialog open={open} onClose={() => {setOpen(false); setFileUpload([]);
            reset({
                name: "",
                worked_at: "",
                note: ""
            });
            setValue('worker', null); setValue("plan_id", null)}} disableEscapeKeyDown>
            <Dialog
                onClose={() => { setSelectedImage(''); setOpenDialog(false) }}
                sx={{
                    '.MuiPaper-root': {
                        width: "unset",
                        height: "unset",
                        margin: '0px',
                    },

                    '.rpv-core__inner-container': {
                        paddingBottom: '30px !important',
                    },
                }}
                open={openDialog}
            >
                <DialogTitle className='flex justify-end'>
                    <span onClick={() => { setSelectedImage(''); setOpenDialog(false) }}><CloseIcon /></span>
                </DialogTitle>
                <DialogContent>
                    <img
                        src={
                            selectedImage
                        }
                    />
                </DialogContent>
            </Dialog>
            <StyledWorkingLogForm onSubmit={handleSubmit(onCreate)}>
                <div className="formTitle">作業記録</div>
                <div className="formFieldRow">
                    <p>工程</p>
                    <Controller
                        name="plan_id"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                sx={{
                                    border: '1px solid #CDD1D5',
                                    borderRadius: '8px',
                                }}
                                disablePortal
                                id="combo-box-demo"
                                options={plans.map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                }))}
                                {...register(
                                    'plan_id',
                                    createWorkingLogValidation(t).plan_id()
                                )}
                                onChange={(event, newValue) => {
                                    setValue('plan_id', newValue);
                                    field?.onChange(newValue);
                                }}
                                classes={{
                                    root: 'autocomplete-select-style',
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" />
                                )}
                                noOptionsText="該当なし"
                                defaultValue={currentPlan?.name}
                                clearIcon={null}
                            />
                        )}
                    />
                    {errors.plan_id && (
                        <span className="error">{errors.plan_id.message}</span>
                    )}
                </div>
                <div className="formFieldRow">
                    <p>作業名</p>
                    <input
                        autoComplete="off"
                        type="name"
                        {...register(
                            'name',
                            createWorkingLogValidation(t).name(),
                        )}
                        className="textfield"
                        onChange={(e) => setErrorname(!e.target.value.trim() ? true : false)}
                    />
                    {errors.name && !errorName && (
                        <span className="error">{errors.name.message}</span>
                    )}
                    {errorName && <span className="error">必須です。</span>}
                </div>
                <div className="formFieldRow">
                    <p>作業日</p>
                    <div className="datepicker-wrapper">
                        <Controller
                            name="worked_at"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    id="worked_at"
                                    selected={field.value}
                                    {...register(
                                        'worked_at',
                                        createWorkingLogValidation(
                                            t,
                                        ).worked_at(),
                                    )}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                            )}
                        />
                        <label htmlFor="worked_at">
                            <img
                                className="calendar-datepicker-icon"
                                src={calendarIcon}
                            ></img>
                        </label>
                    </div>
                    {errors.worked_at && (
                        <span className="error">
                            {errors.worked_at?.message}
                        </span>
                    )}
                </div>
                <div className="formFieldRow">
                    <p>担当</p>
                    <Controller
                        name="worker"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                sx={{
                                    border: '1px solid #CDD1D5',
                                    borderRadius: '8px',
                                }}
                                disablePortal
                                id="combo-box-demo"
                                options={workers.map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                }))}
                                {...register(
                                    'worker',
                                    createWorkingLogValidation(t).worker(),
                                )}
                                onChange={(event, newValue) => {
                                    setValue('worker', newValue);
                                    field?.onChange(newValue);
                                }}
                                classes={{
                                    root: 'autocomplete-select-style',
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" />
                                )}
                                noOptionsText="該当なし"
                            />
                        )}
                    />
                    {errors.worker && (
                        <span className="error">{errors.worker.message}</span>
                    )}
                </div>
                <div className="formFieldRow">
                    <p>備考</p>
                    <textarea
                        autoComplete="off"
                        className="textarea"
                        {...register(
                            'note',
                        )}
                    ></textarea>
                    {errors.note && (
                        <span className="error">{errors.note.message}</span>
                    )}
                </div>
                <div className="formFieldRow">
                    <p>画像</p>
                    <Grid container>
                        <Grid item xs={9}>
                            <div className="imagesContainer">
                                {listFileConvert &&
                                    listFileConvert.map((item, idx) => {
                                        return (
                                            <div
                                                className="imageWrapper"
                                                key={`image-preview-${idx}`}
                                            >
                                                <img
                                                    src={item}
                                                    onClick={() => {setSelectedImage(item); setOpenDialog(true);}}
                                                    className='cursor-pointer'
                                                />
                                                <img
                                                    className="deleteIcon"
                                                    src={deleteIcon}
                                                    onClick={() =>
                                                        removeImage(idx)
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                {loadingConvert && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <label
                                htmlFor="contained-button-file"
                                className="file-uploader-wrapper"
                            >
                                <Input
                                    accept="image/*, .heic, .heif"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={(e) =>
                                        {
                                            if(!e.target.files) return;
                                            handleUploadFile(e.target.files)
                                        }
                                    }
                                    onClick={(e) => (e.target as HTMLInputElement).value = ""}
                                />
                                <div className="addFileBtn">追加</div>
                            </label>
                        </Grid>
                        <span className="error">
                            {totalSize > 104857600 || errorSize ? "アップロードできるサイズは100MBまでです。" : ""}
                        </span>
                        {errorQuantityFiles && <span className="error">100枚を超える画像のアップロードは許可されていません。</span>}
                        {errorTypeFile && <span className="error">ファイル形式が正しくありません。</span>}
                    </Grid>
                </div>

                <div className="formButtonGroup">
                    <button
                        type="button"
                        className="cancelBtn"
                        onClick={() => {
                            setOpen(false);
                            setFileUpload([]);
                            reset({
                                plan_id: "",
                                name: "",
                                worked_at: "",
                                note: ""
                            });
                            setValue('worker', null);
                        }}
                    >
                        戻る
                    </button>
                    <button className="registerBtn" type="submit">
                        登録
                    </button>
                </div>
            </StyledWorkingLogForm>
        </Dialog>
    );
};

export default CreateWorkingLogDialog;
