import { Autocomplete, TextField } from '@mui/material';
const AutocompleteCustom = ({ disablePortal = true, id = "", options, renderInput = (params) => (<TextField {...params} label="" />), ...props }) => {
    return (
        <Autocomplete
            {...props}
            disablePortal={disablePortal}
            id={id}
            options={options}
            renderInput={renderInput}
            style={{border: "1px solid #CDD1D5", borderRadius: "8px"}}
        />
    )
}
export default AutocompleteCustom;
