import { FC } from 'react';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import CustomPagination from 'components/CustomPagination';
import useEstimate, { ReceivedProps, Props } from './hook';
import { StylesEstimateSp } from './styled';
import EmptyImage from 'components/EmptyImage';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#C7DCF5',
    color: '#1C1E21',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontWeight: 600,
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: 'Zen Kaku Gothic Antique',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: '#1C1E21',
    padding: '4px 8px',
  },  
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const EstimateSpLayout: FC<Props> = ({
  estimatesList,
  navigate,
  renderStatusItem,
  getListEstimates,
  totalCount,
  pageQueries,
  setPageQueries,
  isUserRole,
  loading,
}) => {

  return (
    <StylesEstimateSp>
      <p className="estimateSp--title">見積書一覧</p>
      {loading && <div className='h-[90vh] flex justify-center'><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div>}
      <div className="table-content d-flex flex-column">
        <TableContainer component={Paper} table-layout={'auto'}>
          <Table
            //sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  className="text-overflow1"
                  align="center"
                  style={{ width: '10%' }}
                >
                  <div>作成日</div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-overflow1"
                  align="center"
                  style={{ width: '10%' }}
                >
                  <div style={{ whiteSpace: 'nowrap' }}>
                    更新日
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-overflow1"
                  align="center"
                  style={{ width: '10%' }}
                >
                  <div style={{ whiteSpace: 'nowrap' }}>
                    案件名
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-overflow1 min-w-[136px]"
                  align="center"
                  style={{ width: '10%' }}
                >
                  <div>見積担当者</div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-overflow1 min-w-[136px]"
                  align="center"
                  style={{ width: '15%' }}
                >
                  <div>承認担当者</div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-overflow1"
                  align="center"
                  style={{ width: '10%' }}
                >
                  <div>状態</div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-overflow1"
                  align="center"
                  style={{
                    width: '10%',
                  }}
                >
                  <div>操作</div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estimatesList !== null && !loading &&
                estimatesList.map((row, ind) => {
                  return (
                    <StyledTableRow key={ind}>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ width: '10%' }}
                      >
                        <div
                          className="text-overflow1"
                          style={{
                            whiteSpace:
                              'nowrap',
                          }}
                        >
                          {row.created_at
                            ? moment(
                              row.created_at,
                            ).format(
                              'YYYY/MM/DD',
                            )
                            : ''}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ width: '10%' }}
                      >
                        <div
                          className="text-overflow1"
                          style={{
                            whiteSpace:
                              'nowrap',
                          }}
                        >
                          {row.updated_at
                            ? moment(
                              row.updated_at,
                            ).format(
                              'YYYY/MM/DD',
                            )
                            : ''}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ width: '10%' }}
                      >
                        <div
                          className="text-overflow1"
                          style={{
                            whiteSpace:
                              'nowrap',
                          }}
                        >
                          {row?.project?.name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ width: '10%' }}
                      >
                        <div
                          className="text-overflow1"
                          style={{
                            whiteSpace:
                              'nowrap',
                          }}
                        >
                          {
                            row?.assigned_worker
                              ?.name
                          }
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ width: '15%' }}
                      >
                        <div
                          className="text-overflow1"
                          style={{
                            whiteSpace:
                              'nowrap',
                          }}
                        >
                          {
                            row?.approver_worker
                              ?.name
                          }
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ width: '10%' }}
                      >
                        <div
                          className="text-overflow1"
                          style={{
                            whiteSpace:
                              'nowrap',
                          }}
                        >
                          {renderStatusItem(
                            row?.status,
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ width: '10%' }}
                      >
                        <button
                          className={`button-tb !w-[52px] !m-[5px] !text-[14px] !px-[12px] !py-[0px] !h-[25px] ${isUserRole ? 'opacity-60' : ''}`}
                          disabled={isUserRole}
                          onClick={() =>
                            navigate(
                              `/sp/projects/estimation/${row?.project?.id}/edit/${row?.id}?type=detail&from=list`,
                            )
                          }
                        >
                          詳細
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}

              {estimatesList?.length <= 0 && (
                <tr>
                  <StyledTableCell colSpan={7}>
                    <div className="text-center py-[40px]">
                      <EmptyImage classNameCustom='inline-block' />
                      <div className='text-[#00000040] text-sm mt-[6px]'>データがありません</div>
                    </div>
                  </StyledTableCell>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {estimatesList && estimatesList.length > 0 && (
        <div className='flex justify-center'>
          <CustomPagination
            totalCount={totalCount}
            pageQueries={pageQueries}
            setPageQueries={setPageQueries}
            fetchList={(page) =>
              getListEstimates({
                page,
                per_page: pageQueries.per_page,
              })
            }
          />
        </div>
      )}
    </StylesEstimateSp>
  );
};

const EstimateSp: FC<ReceivedProps> = (props) => {
  return <EstimateSpLayout {...useEstimate(props)} />;
};

export default EstimateSp;
