import { useEffect, useState } from "react";
import Papa from "papaparse";
import {StyledTable} from './styled';

const TableCSV = ({fileCSV}) => {
  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);

  const changeHandler = () => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(fileCSV, {
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray: any = [];
        const valuesArray: any = [];

        // Iterating data to get column name and their values
        results?.data?.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        console.log("results: ", results)
        // Parsed Data Response in array format
        setParsedData(results.data);

        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        setValues(valuesArray);
      },
    });
  };
  useEffect(() => {
    if(!fileCSV) return;
    changeHandler();
  }, [])
  return <StyledTable>
    <thead>
      <tr>
        {tableRows?.map((rows, index) => {
          return <th key={index}>{rows}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      {values?.map((value: any, index) => {
        return (
          <tr key={index}>
            {value?.map((val, i) => {
              return <td key={i}>{val}</td>;
            })}
          </tr>
        );
      })}
    </tbody>
  </StyledTable>
}

export default TableCSV;
