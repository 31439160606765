import styled from 'styled-components';

export const StylesPlansContainer = styled.div`
    background-color: #fff;
    padding: 16px 16px;
    border-radius: 8px;
    .plan-title {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .ant-table-thead > tr > th {
        background-color: rgb(199, 220, 245);
        color: rgb(28, 30, 33);
        padding-top: 8px;
        padding-bottom: 8px;
        font-weight: 600;
    }
    .btn-tb {
        background: #215493;
        border: none;
        border-radius: 6px;
        box-shadow: 0 1px 2px rgba(16,24,40,.05);
        color: #fff;
        font-size: 14px;
        font-weight: 100;
        gap: 8px;
        height: 24px;
        line-height: 12px;
        padding: 4px 12px;
    }
    .btn-delete {
        background-color: rgb(255, 80, 69);
    }
`;
