import { useNavigate } from 'react-router-dom';
import { ReceivedProps } from './type';
import { useModal } from 'react-hooks-use-modal';
import { useEffect, useState } from 'react';
import chatService from 'services/chatService';
import PrivateMessageService from 'services/privateMessageService';
import workersService from 'services/wokersService';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import messageService from 'services/messageService';
import { orderBy } from 'lodash';

const usePrivateMessage = (props: ReceivedProps) => {
    const navigate = useNavigate();
    const [activeMessage, setActiveMesssage] = useState<any>(null);
    const [histories, setHistories] = useState<any>([]);
    const [workersOptions, setWorkersOptions] = useState<any>([]);
    const [listUnread, setListUnread] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false);
    const [listMessage, setListMessage] = useState<any>([]);
    const [checkSocket, setChecksocket] = useState<any>(false);
    const [isInHistories, setIsInHistories] = useState(true);
    const [
        ModalPrivateMessage,
        openPrivateMessage,
        closePrivateMessage,
        isOpenPrivateMessage,
    ] = useModal('root', {
        closeOnOverlayClick: true,
    });
    const { userData }: any = useSelector((state: RootState) => state.users);

    const fetchHasUnreadChat = async () => {
        try {
            const { data }: any =
                await PrivateMessageService.checkHasUnreadMessage();
            setListUnread(data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchMessages = async (params) => {
        if (!activeMessage?.id) return;
        if (!isOpenPrivateMessage) return;
        setLoading(true);
        try {
            setListMessage([]);
            const { direct_chats }: any =
                await PrivateMessageService.getDirectMessages(
                    activeMessage?.id || params.id,
                    {
                        page: 1,
                        per_page: 99999,
                    },
                );
            setListMessage(orderBy(direct_chats, 'id', 'asc'));
            setLoading(false);
            if (direct_chats) {
                fetchHasUnreadChat();
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchChats = async () => {
        if (!userData?.id) return;
        try {
            const { direct_chats, meta }: any =
                await PrivateMessageService.getDirectChats({
                    page: 1,
                    per_page: 0,
                });
            const chats = direct_chats
                ?.filter((chat) =>
                    chat?.workers?.find(
                        (item) => item.id === userData?.worker.id,
                    ),
                )
                ?.sort((a, b) =>
                    b.last_message_published_at.localeCompare(
                        a.last_message_published_at,
                    ),
                );
            setHistories(chats);
            if (!activeMessage?.id) {
                setActiveMesssage(chats[0]);
            }
        } catch {}
    };

    const fetchData = async () => {
        try {
            const { workers } = await workersService.getListWorkers({
                page: 1,
                per_page: 0,
            });
            setWorkersOptions(
                workers.map((item) => ({
                    value: item.id,
                    label: item.name,
                })),
            );
        } catch (error) {
            //
        }
    };

    const handleReadChat = async () => {
        try {
            await PrivateMessageService.getDirectMessageDetail(
                activeMessage?.id,
            );
            fetchHasUnreadChat();
        } catch (error) {}
    };

    function removeDuplicatesById(arr) {
        const uniqueMap: any = new Map();
        const uniqueArray: any = [];

        for (const obj of arr) {
            if (!uniqueMap.has(obj.id)) {
                uniqueMap.set(obj.id, obj);
                uniqueArray.push(obj);
            }
        }
        return uniqueArray;
    }

    const handleReceived = async (response) => {
        try {
            const { data } = response;
            if (!!data?.is_owner) return;
            if (
                activeMessage?.workers?.find(
                    (worker) => worker.id === data.sender.id,
                )
            ) {
                setListMessage((preState) =>
                    removeDuplicatesById([
                        ...preState,
                        { ...data, images: data?.image_urls },
                    ]),
                );
            }
            const receivedChat = histories.find((item) =>
                item.workers.find((x) => x.id === data.sender.id),
            );
            setHistories((preState) => {
                const newArray = [...preState];
                const index = newArray.findIndex(
                    (item) => item.id === receivedChat.id,
                );
                if (index !== -1) {
                    newArray[index] = {
                        ...receivedChat,
                        last_message: data.body,
                        last_message_published_at: data.publish_at,
                    };
                }
                return newArray;
            });
            setListUnread((preState) => {
                const newArray = [...preState];
                const index = newArray.findIndex(
                    (item) => item.direct_chat_id === receivedChat.id,
                );
                if (index !== -1) {
                    newArray[index] = {
                        ...preState[index],
                        has_unread_chat:
                            receivedChat?.id === activeMessage?.id && isOpenPrivateMessage
                                ? false
                                : true,
                    };
                }
                return newArray;
            });
        } catch (error) {}
    };

    useEffect(() => {
        fetchData();
        if (!isOpenPrivateMessage) {
            fetchHasUnreadChat();
        }
        const close = (e) => {
            if(e.keyCode === 27){
              closePrivateMessage()
            }
          }
          window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, []);

    useEffect(() => {
        fetchChats();
    }, [userData]);

    useEffect(() => {
        fetchMessages({});
    }, [activeMessage, isOpenPrivateMessage]);
    return {
        ...props,
        navigate,
        ModalPrivateMessage,
        openPrivateMessage,
        closePrivateMessage,
        isOpenPrivateMessage,
        histories,
        listUnread,
        activeMessage,
        setActiveMesssage,
        workersOptions,
        fetchChats,
        userData,
        fetchHasUnreadChat,
        listMessage,
        loading,
        handleReadChat,
        handleReceived,
        setListMessage,
        setHistories,
        isInHistories,
        setIsInHistories,
        fetchMessages
    };
};
export type Props = ReturnType<typeof usePrivateMessage>;

export default usePrivateMessage;
