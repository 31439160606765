import styled from 'styled-components';

export const StylesEstimateDetailSp = styled.div`
    padding: 12px 16px;
    .estimateSp--title {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 10px;
        line-height: normal;
    }
    .form-create-estimation-form {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid #CDD1D5;
      border-radius: 4px;
      border-left: none;
    }
    .input-add-estimation {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      background: #C7DCF5;
    }
    .input-add-estimation>p {
      width: 98px;
      //background: #C7DCF5;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding-left: 12px;
      align-items: center;
    }
  
    .input-add-estimation:first-child>p {
      border-top-left-radius: 8px;
    }
  
    .input-add-estimation:last-child>p {
      border-bottom-left-radius: 8px;
    }
  
    .input-add-estimation>.content-right {
      width: 100%;
      align-items: center;
      padding-left: 6px;
      min-height: 56px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #1C1E21;
      border-bottom: 1px solid #F5F5F5;
      background: #ffffff;
    }
  
    .input-add-estimation:last-child>.content-right {
      border: none;
    }
    .MuiTableCell-sizeMedium {
      font-size: 14px !important;
    }
`;
