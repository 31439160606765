import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { ReceivedProps, DataType } from './type';
import { useNavigate } from 'react-router-dom';
import VehicleInspectionService from 'services/vehicleInspectionService';
import * as yup from 'yup';

export const validation = yup.object().shape({
    name: yup.string().required('必須項目です'),
});


const useVehicleInspection = (props: ReceivedProps) => {
    const [deleteData, setDeleteData] = useState<DataType>({
        id: 1,
        name: '',
        created_at: '',
        file_url: '',
        updated_at: '',
    });
    const [vehicleInspectionLists, setVehicleInspectionLists] = useState<
        DataType[]
    >([]);
    const [refreshList, setRefreshList] = useState<boolean>(false);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: (values) => fetchListVerhicleInspection(values),
    });

    const updateFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
        },
        validationSchema: validation,
        onSubmit: (values) => handleUpdate(values),
    });

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: true,
        },
    );

    const [ModalUpdate, openUpdate, closeUpdate, isOpenUpdate] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: true,
        },
    );

    const handleUpdate = async (values) => {
        const formData = new FormData();
        formData.append('name', `${values.name}.pdf`);
        try {
            await VehicleInspectionService.updateVehicleInspection(
                deleteData.id,
                formData,
            );
            setRefreshList((preState) => !preState);
            closeUpdate();
        } catch (error) {}
    };
    const fetchListVerhicleInspection = async (params = {}) => {
        try {
            const { checklists }: any =
                await VehicleInspectionService.getListVehicleInspection({
                    page: 1,
                    per_page: 0,
                    ...params,
                });
            setVehicleInspectionLists(checklists);
        } catch (error) {
            //
        }
    };

    const handleOpenDeleleModal = (data) => {
        setDeleteData(data);
        openDelete();
    };

    const handleOpenUpdateModal = (data) => {
        setDeleteData(data);
        updateFormik.setFieldValue('name', data?.name?.replace('.pdf', ''));
        openUpdate();
    };

    const handleDelete = async () => {
        try {
            await VehicleInspectionService.deleteVehicleInspection(
                deleteData.id,
            );
            closeDelete();
            setRefreshList((preState) => !preState);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchListVerhicleInspection();
    }, [refreshList]);

    return {
        ...props,
        formik,
        ModalDelete,
        isOpenDelete,
        vehicleInspectionLists,
        openDelete,
        handleOpenUpdateModal,
        closeDelete,
        handleOpenDeleleModal,
        navigate,
        handleDelete,
        ModalUpdate,
        openUpdate,
        closeUpdate,
        isOpenUpdate,
        updateFormik,
    };
};
export type Props = ReturnType<typeof useVehicleInspection>;

export default useVehicleInspection;
