import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import scheduleService from 'services/scheduleService';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import moment from 'moment';
import { ListItems } from 'utils/constants';
import initStatusIcon from 'assets/icons/status/ic_init_fill.svg';
import initStatusEmptyIcon from 'assets/icons/status/ic_init_empty2.svg';
import progressStatusIcon from 'assets/icons/status/ic_progress_fill2.svg';
import progressStatusEmptyIcon from 'assets/icons/status/ic_progress_empty2.svg';
import finishStatusIcon from 'assets/icons/status/ic_finish_fill.svg';
import finishStatusEmptyIcon from 'assets/icons/status/ic_finish_empty2.svg';
import { formatDateTime } from 'utils/helper/dateHelper';
import NewsService from 'services/newsService';
import { isAdmin } from 'utils/helper/workerHelper';
import { StyledTopSPv2, StyledItemComp } from "./styled";

export const NewsItem = ({ newItem, openNew, setSelectedNew, isNews }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  return (
    <Box marginBottom="12px">
      <div className='flex justify-between items-center'>
        <Typography
          lineHeight="14.46px"
          color="#1C1E21"
          fontSize="12px"
          marginBottom="8px"
          fontWeight={700}
          minWidth="100px"
        >
          {moment(newItem?.publish_at).format('YYYY年M月D日')}
        </Typography>
        <Typography
          fontSize={12}
          lineHeight="14.46px"
          color="#ffffff"
          marginBottom="8px"
          style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
        >
          {newItem?.title}
        </Typography>
      </div>

      <Typography
        sx={
          !isShowMore
            ? {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }
            : {}
        }
        fontSize={12}
        fontWeight={400}
        lineHeight="24px"
        color="#rgba(0, 0, 0, 0.7)"
      >
        {newItem?.content}
      </Typography>
      {newItem?.content.length > 50 && (
        <Typography
          onClick={() => {
            setSelectedNew(newItem);
            openNew();
          }}
          color="#0A67D3"
          fontSize={12}
          fontWeight={500}
          lineHeight="14.46px"
          className='flex justify-end mt-[9px]'
        >
          もっと見る
        </Typography>
      )}
      {!isNews && newItem?.content.length > 25 && (
        <Typography
          onClick={() => {
            setSelectedNew(newItem);
            openNew();
          }}
          color="#0A67D3"
          fontSize={12}
          fontWeight={500}
          lineHeight="14.46px"
          className='flex justify-end mt-[9px]'
        >
          もっと見る
        </Typography>
      )}
    </Box>
  );
};

export const CardWrapper = ({ children, title, classCustom = '', classTitle = '', bg, onClick }) => {
  return (
    <Box
      marginBottom="16px"
    >
      <Box
        className={`card-container ${classCustom}`}
        marginTop="16px"
        border="1px solid #E9E9E9"
        borderRadius="12px"
        padding="25px 16px 13px 16px"
        color="#1A1A1A"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + `${bg}`
            })`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        onClick={onClick}
      >
        <Typography className={`card-title ${classTitle}`} lineHeight="30px" color="#1A1A1A" fontWeight={700}>
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};

interface ITodo {
  title: string;
}
interface ITarget {
  title: string;
  status: string;
}
interface IPurchaseRequest {
  content: string;
  custom_data: { status: string, maker_worker_name: string, name: string },
  id: number;
  publish_at: string;
  resource_id: number;
  resource_type: string;
  title: string;
}
interface INews {
  id: number;
  content: string;
  resource_type: string | null;
  title: string;
}
interface ISelect {
  title: string;
  content: string;
  publish_at: string;
}
const TopSPv2 = () => {
  const [todos, setTodos] = useState<ITodo[]>([]);
  const [targets, setTargets] = useState<ITarget[]>([]);
  const [newsList, setNewsList] = useState<INews[]>([]);
  const [purchaseRequests, setPurchaseRequests] = useState<IPurchaseRequest[]>([]);
  const [selectedNew, setSelectedNew] = useState<ISelect>();
  const [ModalNew, openNew, closeNew, isOpenNew] = useModal('root', {
    // preventScroll: true,
    closeOnOverlayClick: true,
  });

  const { userData } = useSelector((state: RootState) => state.users);
  const isAllow = isAdmin(userData);
  const today = formatDateTime(new Date(), 'yyyy-MM-DD');
  const navigate = useNavigate();

  const fetchTodos = async () => {
    try {
      const { schedules } = await scheduleService.getTodolist({
        page: 1,
        per_page: 9999,
        worker_id: userData?.worker?.id,
      });
      setTodos(
        schedules
          ?.filter((schedule) => schedule.is_show_on_todo_list)
          ?.filter((todo) =>
            moment().isBetween(
              todo?.start_date,
              todo.end_date,
              'days',
              '[]',
            ),
          ),
      );
    } catch (error) { }
  };

  const fetchTargets = async () => {
    try {
      const { schedules } = await scheduleService.getTarget({
        page: 1,
        per_page: 0,
        worker_id: userData?.worker?.id,
        targeted: true,
        start_date_lteq: moment(
          moment().format('YYYY-MM-DD'),
          'YYYY-MM-DD',
        )
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        start_date_gteq: moment().format('YYYY-MM-DD'),
      });
      setTargets(schedules);
    } catch (error) { }
  };

  const getListNews = async () => {
    try {
      const response = await NewsService.getListNewsSP({
        page: 1,
        per_page: 0,
      });
      if (response !== null && response !== undefined) {
        const todayNews = response.news.filter(
          (item) => item.publish_at === today && item.resource_type !== "PurchaseRequest",
        );
        const otherDayNews = response.news.filter(
          (item) => item.publish_at !== today && item.resource_type !== "PurchaseRequest",
        );
        setNewsList([...todayNews, ...otherDayNews]);
        const listPrNews = response?.news?.filter((item: { resource_type: string; }) => item?.resource_type === "PurchaseRequest");
        const listPrNewsChangeDt = listPrNews?.map(item => {
          return { ...item, title: item?.custom_data?.name, content: item.title }
        })
        setPurchaseRequests(isAllow ? [] : listPrNewsChangeDt);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    fetchTodos();
    fetchTargets();
    getListNews();
  }, []);

  return (
    <StyledTopSPv2>
      <ModalNew>
        <Box
          sx={{
            backgroundColor: '#fff',
            margin: '0px 16px',
            borderRadius: '4px',
            border: '1px solid rgb(204, 204, 204)',
            padding: '16px',
            maxHeight: '80vh',
            overflow: 'auto',
            overscrollBehavior: 'contain',
          }}
        >
          <p className="text-[14px] font-[500]">
            {moment(selectedNew?.publish_at)?.format('YYYY年M月D日')}
          </p>
          <p className="text-[24px] text-[#448CDF] leading-[32px] font-bold">
            {selectedNew?.title}
          </p>
          <p className="text-[14px] leading-[24px] text-[rgba(0,0,0,.7)]">
            {selectedNew?.content}
          </p>
          <button onClick={() => closeNew()} className="modal-cancel-news d-flex flex-row">
            キャンセル
          </button>
        </Box>
      </ModalNew>
      <div className="banner-group h-[410px]">
        <img className="banner-img" alt="top-sp-banner-shoei" src="/img/topSP/top_banner.png" />
        <img className="banner-img-trasparent" alt="top-sp-banner-shoei" src="/img/topSP/bg-transparent.png" />
        <div className="items">
          {ListItems.map((value, index) => (
            <Link key={index} className="item" to={value.route}>
              <img
                className="icon"
                src={process.env.PUBLIC_URL + value.icon}
              />
              <div dangerouslySetInnerHTML={{ __html: value.name }} className="label"></div>
            </Link>
          ))}
        </div>
      </div>

      {todos?.length > 0 && (
        <div className='px-[16px] mt-[22.6px] md:mt-[112px]'>
          <CardWrapper onClick={() => navigate('/sp/todo')} title="今日のTODO" classCustom='min-h-[220px]' bg='/img/topSP/bg-todo2.png'>
            {
              todos?.slice(0, 3)?.map((item, index) => (
                <div className={`todo-item ${index === todos?.slice(0, 3)?.length - 1 ? "delete-border" : ""}`} key={index}>
                  <p className='item-title'>{item?.title}</p>
                </div>
              ))
            }
          </CardWrapper>
        </div>
      )}

      {
        targets?.length > 0 && (
          <div className='px-[16px]'>
            <CardWrapper onClick={() => navigate('/sp/target')} classTitle='!text-[#ffffff]' title="目標" classCustom='min-h-[220px]' bg='/img/topSP/bg-target.png'>
              {targets?.slice(0, 3)?.map((target, index) => (
                <div key={index} className={`flex items-center justify-between todo-item !border-[#ffffff] ${index === targets?.slice(0, 3)?.length - 1 ? "delete-border" : ""}`}>
                  <p className="w-[80%] truncate mb-0 text-xs text-[#ffffff]">
                    {target?.title}
                  </p>
                  <div className="flex items-center gap-[8px]">
                    <div className='icon-target'>
                      <img
                        style={{ width: "10px", height: "10.12px" }}
                        src={
                          target?.status === 'initial'
                            ? initStatusIcon
                            : initStatusEmptyIcon
                        }
                      />
                    </div>
                    <div className='icon-target'>
                      <img
                        style={{ width: "11.19px", height: "9.81px" }}
                        src={
                          target?.status === 'in_progress'
                            ? progressStatusIcon
                            : progressStatusEmptyIcon
                        }
                      /></div>
                    <div className='icon-target'>
                      <img
                        style={{ width: "10.08px", height: "10.15px" }}
                        src={
                          target?.status === 'completed'
                            ? finishStatusIcon
                            : finishStatusEmptyIcon
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </CardWrapper>
          </div>
        )
      }

      {
        newsList?.length > 0 && (
          <div
            className='px-[16px]'
          >
            <CardWrapper onClick={() => {}} classTitle='!mb-[22px]' title="お知らせ" bg='/img/topSP/bg-news2.svg'>
              {newsList?.slice(0, 3).map((newItem, index) =>
                <NewsItem
                  setSelectedNew={setSelectedNew}
                  openNew={openNew}
                  key={newItem?.id}
                  newItem={newItem}
                  isNews={true}
                />
              )}
              <div className='mt-[18px] mb-[14px]'>
                {
                  purchaseRequests?.length > 0 && purchaseRequests?.map((item, index) => (
                    <NewsItem
                      setSelectedNew={setSelectedNew}
                      openNew={openNew}
                      key={index}
                      newItem={item}
                      isNews={false}
                    />
                  ))
                }
                <div className='text-[#0A67D3] w-fit py-[4px] px-[16px] border border-solid border-[#0A67D3] rounded-[6px] mx-auto' onClick={() => navigate('/sp/news')}>もっと見る</div>
              </div>
            </CardWrapper>
          </div>
        )
      }
    </StyledTopSPv2>
  )
}
export default TopSPv2;
