import CloseIcon from '@mui/icons-material/Close';
import { EDIT_SUGOROKU_MODE, VIEW_SUGOROKU_MODE } from 'utils/constants';
import { divideByTen, roundDown } from 'utils/helper/numberHelper';
import StyledStepDialogHeader from './styles';
import { Popover } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
interface IProps {
  step: number;
  title?: string;
  onClose?: any;
  mode: string;
  setMode: any;
  completed?: boolean;
  disabled?: boolean;
}

const DialogHeader = (props: IProps) => {
  const { step, title, onClose, mode, setMode, completed, disabled = false } = props;
  const { userData } = useSelector((state: RootState) => state.users);
  return (
    <StyledStepDialogHeader>
        <div
            className={
                completed
                    ? 'step-number-icon complete-theme'
                    : 
                    'step-number-icon incomplete-theme'
            }
        >
            {roundDown(divideByTen(step), 1)}
        </div>
        <p>{title}</p>
        <div>
        {
          mode === VIEW_SUGOROKU_MODE && (
            <Popover zIndex={2000} title='' content={disabled && userData?.role === 'member' ? <p style={{color: '#f21f31'}}>前のステップを完了してください。</p> : ''}>
              <button className={`step-edit-button ${disabled && userData?.role === 'member' ? 'step-edit-button-disabled' : ''}`} onClick={() => setMode(EDIT_SUGOROKU_MODE)} disabled={disabled && userData?.role === 'member'}>
                編集
              </button>
            </Popover>
          )
        }
          <CloseIcon
              fontSize="small"
              onClick={onClose}
              style={{ cursor: 'pointer' }}
          />
        </div>
    </StyledStepDialogHeader>
  )
}

export default DialogHeader;
