import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import WorkingLogAdditionalsService from 'services/workinglogAdditionals';
import { useModal } from 'react-hooks-use-modal';
import NewsService from 'services/newsService';

export type ReceivedProps = Record<string, never>;

const useNews = (props: ReceivedProps) => {
    const navigate = useNavigate();
    const [data, setData] = useState<any>([]);
    const [selectedNew, setSelectedNew] = useState<any>({});
    const [ModalNew, openNew, closeNew, isOpenNew] = useModal('root', {
        closeOnOverlayClick: true,
    });

    const getListNews = async (free_word = '') => {
        try {
            const response = await NewsService.getListNewsSP({
                page: 1,
                per_page: 0,
                free_word,
            });
            if (response !== null && response !== undefined) {
                const listNewsNorPr = response?.news?.filter((item: { resource_type: string; }) => item.resource_type !== "PurchaseRequest")
                setData(listNewsNorPr);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        getListNews();
    }, []);

    return {
        ...props,
        data,
        navigate,
        setSelectedNew,
        selectedNew,
        ModalNew,
        openNew,
        closeNew,
        isOpenNew,
    };
};

export type Props = ReturnType<typeof useNews>;

export default useNews;
