import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useModal } from 'react-hooks-use-modal';
import WorkingTimeService from 'services/workingTimesService';
import { alertError } from 'utils/helper/appHelper';
import departmentsService from 'services/departmentsService';
import PositionsService from 'services/positionService';
import workersService from 'services/wokersService';
interface ITotal {
    total_working_days: number;
    total_working_times: number;
}
interface IDataSendToBE {
    worker_id?: number | undefined;
    month: number;
    year: number;
}

interface IDataSendToBE6Months {
    worker_id: number;
    start_month: string | Date;
}
interface IItemWorkingTimePair {
    worker_name?: string;
    submit_date?: string;
    check_in_time?: string;
    check_out_time?: string;
    total_time?: number;
    checkin_location?: string;
    checkout_location?: string;
    checkin_item_id?: number;
    checkout_item_id?: number;
    uid?: number;
    worker_id?: number;
    is_manual_checkin?: boolean;
    is_manual_checkout?: boolean;
}
const useWorkingTimesManagements = (props: any) => {
    const [workingTimeLists, setWorkingTimeLists] = useState<IItemWorkingTimePair[]>([]);
    const [departmentOptions, setDepartmentOptions] = useState<any>([]);
    const [deleteData, setDeleteData] = useState<any>({});
    const [positionOptions, setPositionOptions] = useState<any>([]);
    const [workerOptions, setWorkerOptions] = useState<any>([]);
    const [pageQueries, setPageQueries] = useState<any>({
        page: 1,
        per_page: 0,
    });
    const [isOpenDepartment, setIsOpentDepartment] = useState(false);
    const [isOpenPosition, setIsOpenPosition] = useState(false);
    const [isOpenWorker, setIsOpenWorker] = useState(false);
    const [total, setTotal] = useState<ITotal>();
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [month, setMonth] = useState<Date | string>('');
    const [idWorker, setIdWorker] = useState<number>(0);
    const [srcPDF, setSrcPdf] = useState<string | null>();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isErrorDate, setIsErrorDate] = useState(true);
    const [isShowPopup6Months, setIsShowPopup6Months] = useState(false);
    const [startMonth, setStartMonth] = useState<Date | string>('');
    const [srcPDF6Months, setSrcPdf6Months] = useState<string | null>();
    const [idWorker6Months, setIdWorker6Months] = useState<number>(0);
    const [disabledBtnSubmit6Months, setDisabledBtnSubmit6Month] = useState(false);
    const [isErrorStartMonth, setIsErrorStartMonth] = useState(true);
    const [isErrorWorker6Months, setIsErrorWorker6Month] = useState(true);
    const [isErrorWorker, setIsErrorWorker] = useState(true);
    const [disabledBtnSubmit1Month, setDisabledBtnSubmit1Month] = useState(false);

    const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            department_id: [],
            position_id: [],
            free_word: '',
            start_date: '',
            end_date: '',
        },
        onSubmit: (values) => fetchWorkingTimes(values),
    });
    
    const isValidDate = (startDate, endDate) => {
        if(!startDate || !endDate) return true;
        if(new Date(startDate).getTime() > new Date(endDate).getTime()) {
            return false;
        } else {
            return true;
        }
    }

    const fetchWorkingTimes = async (params: any = {}) => {
        const isValidDateFilter = isValidDate(params?.start_date, params?.end_date);
        if(!isValidDateFilter) return;
        try {
            const { working_time_managements, meta }: any =
                await WorkingTimeService.getWorkingTime({
                    ...pageQueries,
                    attribute_department_ids:
                        params?.department_id?.map(
                            (department) => department.value,
                        ) || [],
                    attribute_position_ids:
                        params?.position_id?.map(
                            (department) => department.value,
                        ) || [],
                    worker_id: params?.worker_id || '',
                    free_word: params?.free_word || '',
                    start_date: params?.start_date ? moment(params?.start_date).format("YYYY-MM-DD") : '',
                    end_date: params?.end_date ? moment(params?.end_date).format("YYYY-MM-DD") : '',
                });
            let arrItemPairs: IItemWorkingTimePair[] = [];
            if(working_time_managements?.length > 0) {
                working_time_managements?.map(item => {
                    if(item?.working_time_items_pair?.length > 0) {
                        item?.working_time_items_pair?.map((itPair, idx) => {
                            arrItemPairs.push({...itPair, uid: item?.id, worker_id: item?.worker?.id})
                        })
                    }
                })
            }
            setWorkingTimeLists(arrItemPairs?.sort((a, b) => {
                let bCheckoutId = b?.checkout_item_id ? b?.checkout_item_id : 0;
                let aCheckoutId = a?.checkout_item_id ? a?.checkout_item_id : 0;
                return (b?.checkin_item_id! > bCheckoutId ? b?.checkin_item_id! : bCheckoutId) - (a?.checkin_item_id! > aCheckoutId ? a?.checkin_item_id! : aCheckoutId)
            }));
            setTotal(meta?.totals);
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchDepartments = async () => {
        try {
            const { departments } =
                await departmentsService.getListDepartments();
            setDepartmentOptions(
                departments?.map((department) => ({
                    name: department?.name,
                    value: department?.id,
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchPositions = async () => {
        try {
            const { positions } = await PositionsService.getListPositions();
            setPositionOptions(
                positions.map((item) => ({
                    value: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchWorkerOptions = async () => {
        try {
            const { workers } = await workersService.getListWorkers({
                per_page: 0,
            });
            setWorkerOptions(
                workers.map((item) => ({
                    value: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: true,
        },
    );

    const handleOpenDelete = (data) => {
        setDeleteData(data);
        openDelete();
    };

    const handleDelete = async () => {
        try {
            await WorkingTimeService.deleteWorkingTimeItemPair({
                ids: `${deleteData?.checkin_item_id},${deleteData?.checkout_item_id}`,
            });
            closeDelete();
            fetchWorkingTimes();
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const getPdfWorkingTime = async(workerId: number, month: number, year: number) => {
        if(!month || !year || isErrorDate) {
            return;
        }
        let data: IDataSendToBE = {
            worker_id: workerId,
            month: month,
            year: year
        }
        if(!workerId || workerId === 0) {
            delete data.worker_id
        }
        setDisabledBtnSubmit1Month(true);
        try {
            const res = await WorkingTimeService.getWorkingTimePdf(data)
            const src = window.URL.createObjectURL(new Blob([res as ArrayBuffer], {type: 'application/pdf'}));
            setSrcPdf(src)
            setIsShowPopup(false)
            setIdWorker(0)
            setIsErrorWorker(true)
            setDisabledBtnSubmit1Month(false)
            setMonth('')
            setIsErrorDate(true)
        } catch (error) {
            console.log(error);
            setIsErrorWorker(true);
            setDisabledBtnSubmit1Month(false);
            setIsErrorDate(true);
        }
    }

    const getPdfWorkingTime6Months = async() => {
        if(!idWorker6Months || idWorker6Months === 0 || !startMonth) return;

        let data: IDataSendToBE6Months = {
            worker_id: idWorker6Months,
            start_month: moment(startMonth).format('YYYY-MM-DD'),
        }
        setDisabledBtnSubmit6Month(true);
        try {
            const res = await WorkingTimeService.getWorkingTimePdf6Months(data);
            const src = window.URL.createObjectURL(new Blob([res as ArrayBuffer], {type: 'application/pdf'}));
            setSrcPdf6Months(src);
            setIsShowPopup6Months(false);
            setStartMonth('');
            setIdWorker6Months(0);
            setDisabledBtnSubmit6Month(false);
            setIsErrorStartMonth(true);
            setIsErrorWorker6Month(true);
        } catch (error) {
            console.log(error);
            setDisabledBtnSubmit6Month(false);
            setIsErrorStartMonth(true);
            setIsErrorWorker6Month(true);
        }
    }

    useEffect(() => {
        fetchWorkingTimes();
    }, []);

    useEffect(() => {
        fetchDepartments();
        fetchPositions();
        fetchWorkerOptions();
    }, []);

    return {
        ...props,
        formik,
        navigate,
        workingTimeLists,
        ModalDelete,
        openDelete,
        closeDelete,
        isOpenDelete,
        departmentOptions,
        workerOptions,
        positionOptions,
        setPageQueries,
        pageQueries,
        fetchWorkingTimes,
        handleOpenDelete,
        handleDelete,
        isOpenDepartment,
        setIsOpentDepartment,
        isOpenPosition,
        setIsOpenPosition,
        isOpenWorker,
        setIsOpenWorker,
        total,
        isShowPopup,
        setIsShowPopup,
        month,
        setMonth,
        getPdfWorkingTime,
        idWorker,
        setIdWorker,
        srcPDF,
        setSrcPdf,
        isValidDate,
        isErrorDate,
        setIsErrorDate,
        isShowPopup6Months,
        setIsShowPopup6Months,
        startMonth,
        setStartMonth,
        setIdWorker6Months,
        getPdfWorkingTime6Months,
        srcPDF6Months,
        setSrcPdf6Months,
        disabledBtnSubmit6Months,
        setDisabledBtnSubmit6Month,
        isErrorStartMonth,
        setIsErrorStartMonth,
        isErrorWorker6Months,
        setIsErrorWorker6Month,
        isErrorWorker,
        setIsErrorWorker,
        disabledBtnSubmit1Month,
        setDisabledBtnSubmit1Month,
    };
};

export type Props = ReturnType<typeof useWorkingTimesManagements>;

export default useWorkingTimesManagements;
