import {
    FC,
    memo,
    useMemo,
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react';
import { isArray, isEmpty } from 'lodash';
import {
    Widget,
    renderCustomComponent,
    toggleWidget,
    dropMessages,
} from 'react-chat-widget';
import moment from 'moment';
import 'react-chat-widget/lib/styles.css';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { BiImageAdd, BiSend } from 'react-icons/bi';
import { TiCancel } from 'react-icons/ti';
import { Main } from './style';
import useChatBox, { Props, ReceivedProps } from './hook';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
} from '@chatscope/chat-ui-kit-react';
import Dialog from '@mui/material/Dialog';
import { Formik, Form, Field } from 'formik';
import { Box } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { saveAs } from 'file-saver';
import { values } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type TVideo = {
    signed_id: string;
    video_path: string;
}
type TImage = {
    signed_id: string;
    image_path: string;
}

interface ISender {
    id: number;
    name: string;
}
interface IMessage {
    body: string | undefined;
    id: number;
    images: TImage[] | null;
    is_owner: boolean | null;
    is_read: boolean | null;
    is_system: boolean | null;
    publish_at: string | undefined;
    sender: ISender;
    videos: TVideo[] | File[] | null;
}
const ChatBoxLayout: FC<Props> = ({
    workerId,
    listMessage,
    onSubmit,
    handleFileChange,
    //listImageMessage,
    setListMessage,
    refInput,
    removeImage,
    message,
    setUpdateChat,
    setMessage,
    handleReceived,
    chatRoomId,
    typePreview,
    setTypeReivew,
    loading
}) => {
    const messageInputRef: any = useRef(null);
    const [limit, setLimit] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    
    const onYReachStart = () => {
        if (loadingMore === true) {
            return;
        }
        setLoadingMore(true);
        setTimeout(() => {
            setLimit(limit+5);
            setLoadingMore(false);
        }, 1500);
    };
    useEffect(() => {
        const element = document.getElementsByClassName('cs-button--send');
        element[0]?.removeAttribute('disabled');
    }, []);

    const handleButtonClick = () => {
        const enterKeyEvent = new KeyboardEvent('keydown', { key: 'Enter' });
        messageInputRef?.current?.inputRef?.current.dispatchEvent(
            enterKeyEvent,
        );
    };
    const { userData }: any = useSelector((state: RootState) => state.users);

    const [open, setOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState();

    const downloadImage = () => {
        typePreview === "image" ? saveAs(imagePreview, 'image.jpeg') : saveAs(imagePreview, 'video.mp4')
    };
    const listMessageItem = useMemo(() => {
        return listMessage?.slice(-limit)?.map((message: IMessage) => (
            <>
                {!message?.is_system ? (
                    <>
                        <Message
                            key={message.id}
                            model={{
                                message: message.body,
                                sender: message?.sender?.name,
                                sentTime: message?.publish_at,
                                direction:
                                    message?.sender?.id === userData?.worker?.id
                                        ? 'outgoing'
                                        : 'incoming',
                                position: 'first',
                                type: 'custom',
                            }}
                        >
                            <Message.CustomContent>
                                <div className="message">
                                    <p>{message?.body || ''}</p>

                                    {message?.images &&
                                        Object.values(message?.images).map(
                                            (img: any, id) => (
                                                <img
                                                    src={img?.image_path}
                                                    style={{
                                                        marginTop: '5px',
                                                        width: '324px',
                                                    }}
                                                    key={id}
                                                    onClick={() => {
                                                        setOpen(true);
                                                        setImagePreview(
                                                            img?.image_path,
                                                        );
                                                        setTypeReivew('image');
                                                    }}
                                                />
                                            ),
                                        )}
                                    {message?.videos &&
                                        Object.values(message?.videos).map(
                                            (video, id) => (
                                                <video
                                                    controls
                                                    style={{
                                                        marginTop: '5px',
                                                        width: '324px',
                                                        cursor: 'pointer',
                                                    }}
                                                    key={id}
                                                    onClick={() => {
                                                        setOpen(true);
                                                        setImagePreview(
                                                            video?.video_path || URL.createObjectURL(video),
                                                        );
                                                        setTypeReivew('video');
                                                    }}
                                                >
                                                    <source
                                                        src={video?.video_path || URL.createObjectURL(video)}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            ),
                                        )}
                                </div>
                            </Message.CustomContent>
                        </Message>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent:
                                    message?.sender?.id === userData?.worker?.id
                                        ? 'end'
                                        : 'start',
                            }}
                            className="message-footer"
                        >
                            <p className="mr-[5px]">{message?.sender?.name || ''}</p>
                            <p>
                                {message.publish_at &&
                                    moment(message.publish_at)
                                        .zone('+09:00')
                                        .format('YYYY/MM/DD HH:mm:ss')}
                            </p>
                        </div>
                    </>
                ) : (
                    <div className="flex justify-center mb-2">
                        <div className="message bg-[#f4f4f4] py-1 px-2 text-[#666666] rounded-md flex text-xs justify-center flex-col items-center">
                            <p className="mb-0">
                                {message.publish_at &&
                                    moment(message.publish_at)
                                        .zone('+09:00')
                                        .format('YYYY/MM/DD HH:mm:ss')}
                            </p>
                            <p className="mb-0">{message?.body || ''}</p>
                        </div>
                    </div>
                )}
            </>
        ));
    }, [listMessage, limit]);

    const renderError = (values: File[]) => {
        if(!values) return;
        let listError = values?.filter(f => f.size > 10 * 1024 * 1024);
        if (listError?.length > 0) {
            return <p className='text-[#ff3333] mt-[1em]'>10MB以上がアップロードできません。</p>
        } else {
            return '';
        }
    }
  
    return (
        <Main className="chat-box" style={{ position: 'relative' }}>
             <ActionCableConsumer
                channel={{
                    channel: 'ChatroomChannel',
                    chat_id: chatRoomId,
                }}
                onReceived={handleReceived}
            ></ActionCableConsumer>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box>
                    <div className="flex justify-end px-2 py-1">
                        <FileDownloadOutlinedIcon
                            className="cursor-pointer"
                            onClick={() => downloadImage()}
                        />
                    </div>
                    {
                        typePreview === "image" ? <img
                            src={imagePreview}
                            className="w-[600px] cursor-pointer"
                            alt="preview"
                        /> : (
                            <video
                                className="w-[600px] cursor-pointer"
                                controls
                            >
                                <source
                                    src={imagePreview}
                                    type="video/mp4"
                                    className="image-ads-edit"
                                />
                            </video>
                        )
                    }
                </Box>
            </Dialog>
            {/*<ActionCableConsumer
                channel={{
                    channel: 'ChatroomChannel',
                    chat_id: chatRoomId,
                }}
                onReceived={handleReceived}
            >*/}
                <div style={{ position: 'relative', height: '500px' }}>
                    <MainContainer>
                        <ChatContainer id="chat-container">
                            <MessageList autoScrollToBottomOnMount={true} loadingMore={loadingMore} onYReachStart={onYReachStart}>{listMessageItem}</MessageList>
                        </ChatContainer>
                    </MainContainer>
                    <div className="action-container">
                        <Formik
                            onSubmit={(values, { resetForm }) => {
                                onSubmit(values);
                                resetForm();
                            }}
                            initialValues={{
                                message: '',
                                file: [],
                            }}
                        >
                            {({values, handleSubmit, setFieldValue }) => (
                                <Form className="w-full justify-between flex items-center ml-[20px]">
                                    <label
                                        className="upload-image-chat"
                                        htmlFor="fileInput1"
                                    >
                                        <input
                                            onChange={(e) =>
                                                handleFileChange(
                                                    e,
                                                    values,
                                                    setFieldValue,
                                                )
                                            }
                                            id="fileInput1"
                                            name="file"
                                            type="File"
                                            multiple
                                            //ref={refInput}
                                            accept="image/*, video/*"
                                            style={{ display: 'none' }}
                                            onClick={(e) => (e.target as HTMLInputElement).value = ""}
                                        ></input>
                                        <BiImageAdd size={20} />
                                    </label>

                                    <Field
                                        onKeyPress={(e) => {
                                            if (e.code === 'Enter') {
                                                e.preventDefault();
                                                if(values?.file?.filter((f: {size: number}) => f.size > 10 * 1024 * 1024)?.length > 0) return
                                                handleSubmit();
                                            }
                                        }}
                                        className="input-custom-chat"
                                        type="text"
                                        placeholder="メッセージを入力..."
                                        autoFocus
                                        name="message"
                                    />
                                    <button
                                        disabled={loading || values?.file?.filter((f: {size: number}) => f.size > 10 * 1024 * 1024)?.length > 0}
                                        style={{
                                            bottom: '16px',
                                            zIndex: 100000,
                                            right: '16px',
                                        }}
                                        className='btnSend'
                                        type="button"
                                        onClick={() => handleSubmit()}
                                    >
                                        <BiSend size={25} />
                                    </button>
                                    <div className="preview-img-upload">
                                        {values?.file?.length > 0 &&
                                            values?.file.map((item: File, id) => {
                                                return (
                                                    <div className="img-upl-ct">
                                                        {
                                                            item?.type?.includes('image/')
                                                                ?
                                                                <img
                                                                    src={URL.createObjectURL(
                                                                        item,
                                                                    )}
                                                                    key={id}
                                                                />
                                                                :
                                                                <video
                                                                    className="img-container-edit"
                                                                    controls
                                                                >
                                                                    <source
                                                                        src={URL.createObjectURL(item)}
                                                                        type="video/mp4"
                                                                        className="image-ads-edit"
                                                                    />
                                                                </video>
                                                        }
                                                        <TiCancel
                                                            onClick={() =>
                                                                removeImage(id, values, setFieldValue)
                                                            }
                                                            size={15}
                                                            className="delete-image-upl"
                                                        />
                                                    </div>
                                                );
                                            })}
                                        {
                                            renderError(values?.file)
                                        }
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            {/*</ActionCableConsumer>*/}
        </Main>
    );
};

const ChatBox: FC<ReceivedProps> = (props) => (
    <ChatBoxLayout {...useChatBox(props)} />
);

export default memo(ChatBox);
