import { convertNestedObjToSearchParamsArray } from 'utils/helper/helpers';
import fetch from './api/config';
interface IParamItemPair {
    //submit_date: Date | string;
    //worker_id: number | string;
    checkin_item_id: number;
    checkout_item_id?: number;
}
const API = {
    WORKING_TIMES: () => `/working_time_managements`,
    WORKING_TIMES_WORKERS: () => `/working_time_managements/workers`,
    WORKING_TIME_DETAIL: (id) => `/working_time_managements/${id}`,
    WORKING_TIME_CHECKIN: () => `/sp/working_time_managements/check_in`,
    WORKING_TIME_CHECKOUT: () => `/sp/working_time_managements/check_out`,
    WORKING_TIME_TODAY: () => `/sp/working_time_managements/today`,
    WORKING_TIME_EXPORT_PDF: () => `/working_time_managements/export_pdf`,
    WORKING_ITEMS_DETAIL_SP: (id) => `/sp/working_time_items/${id}`,
    WORKING_TIME_DETAIL_ITEM_PAIR: () => `/working_time_managements/find_pair_by_ids`,
    WORKING_ITEMS_PC: () => `/working_time_items`,
    WORKING_ITEMS_PC_DETAIL: (id) => `/working_time_items/${id}`,
    WORKING_ITEMS_SP: () => `/sp/working_time_items`,
    WORKING_TIME_EXPORT_PDF_SIX_MONTHS: () => `/working_time_managements/export_pdf_within_six_months`,
};
export default class WorkingTimeService {
    static createWorkingTimes = (params: any) =>
        fetch.post(API.WORKING_ITEMS_PC(), params);

    static updateWorkingTimes = (id, params: any) =>
        fetch.put(API.WORKING_TIME_DETAIL(id), params);

    static deleteWorkingTimes = (id, params: any) =>
        fetch.delete(API.WORKING_TIME_DETAIL(id), params);

    static checkin = (params: any) =>
        fetch.post(API.WORKING_TIME_CHECKIN(), params);

    static checkout = (params: any) =>
        fetch.post(API.WORKING_TIME_CHECKOUT(), params);

    static getWorkingTimeToday = (params: any) =>
        fetch.get(API.WORKING_TIME_TODAY(), params);

    static getWorkingTime = (params: any) => {
        const url = `/working_time_managements`;
        return fetch.get(
            `${url}?${convertNestedObjToSearchParamsArray(params)}`,
            {},
        );
    }
    static getWorkingTimeDetail = (id, params) =>
        fetch.get(API.WORKING_TIME_DETAIL(id), params);

    static getWorkingTimeWorker = (params) => {
        const url = `/working_time_managements/workers`
        return fetch.get(`${url}?${convertNestedObjToSearchParamsArray(params)}`, {});
    }
    
    static getWorkingTimePdf = (params: {worker_id?: number, month: number, year: number}) =>
        fetch.getBlob(API.WORKING_TIME_EXPORT_PDF(), params);
    
    static updateWorkingTimeItem = (id, params: {end_time?: string | Date | null | undefined, start_time?: string | Date | null | undefined, worker_id?: number}) =>
        fetch.put(API.WORKING_ITEMS_DETAIL_SP(id), params);
        
    static getWorkingTimeItemPair = (params: IParamItemPair) =>
        fetch.get(API.WORKING_TIME_DETAIL_ITEM_PAIR(), params);
    
    static updateWorkingTimeItemPC = (id, params) =>
        fetch.put(API.WORKING_ITEMS_PC_DETAIL(id), params);

    static deleteWorkingTimeItemPair = (params) =>
        fetch.delete(API.WORKING_ITEMS_PC(), params);
    
    static createWorkingTimesSP = (params) =>
        fetch.post(API.WORKING_ITEMS_SP(), params);

    static getWorkingTimePdf6Months = (params: { worker_id: number, start_month: string | Date }) =>
        fetch.getBlob(API.WORKING_TIME_EXPORT_PDF_SIX_MONTHS(), params);
}
