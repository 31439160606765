import useSPOJTInspection, { Props } from './hook';
import { ReceivedProps, DataType } from './type';
import { StylesOJTSP } from './styled';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Box, Button } from '@mui/material';
import ViewIcon from 'assets/icons/view_ic.svg';
import Share from 'assets/icons/share.svg';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Worker, Viewer, OpenFile } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import { saveAs } from 'file-saver';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
const OJTSPLayout = ({
    navigate,
    handleShare,
    OJTLists,
    fileData,
    currentData,
    handleViewFile,
    setFileData,
}: Props) => {

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            const fileName = file.name.substring(
                file.name.lastIndexOf('/') + 1,
            );
            return currentData?.name!;
        },
    });
    const { Download } = getFilePluginInstance;
    const downloadImage = () => {
        saveAs(currentData?.file_url, `${currentData?.name}`)
    };

    const columns: ColumnsType<DataType> = [
        {
            title: 'ファイル名',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <span className="vehicle-name">
                {record?.files?.map(item => <div className='flex items-center justify-between mb-[6px]'>
                    <div className='vehicle-name-text'>{item?.file_name}</div>
                    <div className='flex items-center gap-x-[8px]'>
                        <Box
                            onClick={() => handleViewFile(item)}
                            sx={{
                                backgroundColor: '#215493',
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={ViewIcon} />
                        </Box>
                        <Box
                            onClick={() => handleShare(item)}
                            sx={{
                                backgroundColor: '#215493',
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={Share} />
                        </Box>
                    </div>
                </div>
                )}
            </span>,
            ellipsis: true,
        },
    ];

    const renderContentDialog = () => {
        if (!fileData) return <></>;
        if (currentData?.file_type?.includes("application/pdf")) {
            return (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                        plugins={[getFilePluginInstance]}
                        fileUrl={fileData}
                        defaultScale={SpecialZoomLevel.PageWidth}
                    />
                </Worker>
            )
        } else {
            return (
                <img className='mx-auto' src={fileData} alt='preview-image-ojt'></img>
            )
        }
    }
    return (
        <StylesOJTSP>
            {fileData && (
                <Dialog
                    onClose={() => setFileData(null)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: '100%',
                            height: '100%',
                            margin: '0px',
                            maxHeight: 'unset'
                        },
                        '.MuiDialogContent-root': {
                            padding: '0px 0px 20px 0px',
                        },
                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!fileData}
                >
                    <DialogTitle className='!py-[16px] !px-[9px]'>
                        <button
                            type="button"
                            className="border-main border-[1px] text-sm px-[10px] py-[5px] rounded-[6px] whitespace-nowrap"
                            style={{ color: '#215493' }}
                            onClick={() => setFileData(null)}
                        >
                            閉じる
                        </button>
                        <Download>
                            {(props: RenderDownloadProps) => (
                                <button
                                    type="button"
                                    className="border-main ml-2 bg-main border-[1px] text-white text-sm px-[10px] py-[5px] rounded-[6px] whitespace-nowrap"
                                    onClick={currentData?.file_type?.includes("application/pdf") ? props.onClick : downloadImage}
                                >
                                    ダウンロード
                                </button>
                            )}
                        </Download>
                    </DialogTitle>
                    <DialogContent>
                        {
                            renderContentDialog()
                        }
                    </DialogContent>
                </Dialog>
            )}

            <p className="vehicle-inspection-title">各種マニュアル</p>
            <Table
                rowKey="id"
                columns={columns}
                pagination={false}
                dataSource={OJTLists}
            />
            <Button
                onClick={() => navigate('/')}
                sx={{
                    padding: '6px 18px',
                    height: '44.5px',
                    width: '166.5px',
                    marginTop: '43px',
                    borderColor: 'rgba(33, 84, 147, 0.30)',
                    color: '#215493',
                    borderRadius: '8px',
                    marginRight: '4px',
                }}
                variant="outlined"
            >
                戻る
            </Button>
        </StylesOJTSP>
    );
};

const OJTSP = (props: ReceivedProps) => {
    return <OJTSPLayout {...useSPOJTInspection(props)} />;
};

export default OJTSP;
