import styled from "styled-components";

export const SchedulerBoxWrapper = styled.div`
  width: 100%;
  font-family: "Zen Kaku Gothic Antique";

  &.not-show {
    .schedulerWrapper .MainLayout-container {
      display: none;
    }
  }

  .schedule-day, 
  .schedule-week {
    .e-toolbar-items.e-tbar-pos {
      position: relative;
    }

    .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
        position: absolute !important;
        left: 75px !important;

        button {
          display: none;
        }
    }
    .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
      .e-toolbar-item:last-child {
        display: none;
      }

      .e-toolbar .e-toolbar-item .e-tbar-btn {
        background: transparent !important;
      }
    }
  }

  .e-appointment.css-allDay-appointment {
    .e-time {
        visibility: hidden;
    }
    .e-time:after {
        visibility: visible;
        content: '一日中';
        position: absolute;
        left: 5px;
    }
  }

  .e-appointment.css-muitiple-slash-lines {
    background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 4px,
        transparent 1px,
        gray 7px
      ),
      linear-gradient(
        to bottom,
        transparent,
        transparent
      )
  }

  //.e-today:before {
  //  width: 58px;
  //  height: 28px;
  //  padding: 4px 0;
  //  content: '今日';
  //  text-align: center;
  //  font-size: 14px;
  //  border: 1px solid rgba(28,30,33,0.2);
  //  border-radius: 4px;
  //}
  
  .e-schedule .e-timeline-view .e-work-cells {
    background: #fff;
  }

  .e-resource-left-td {
    width: 120px !important;
    .worker-wrapper {
      text-align: center;
    }
  }

  .e-resource-cells.e-child-node {
    padding-left: 0;
    text-align: center;
  }

  .e-header-cells {
    text-align: center;
  }

  .schedulerWrapper {
    position: relative;
    margin-top: 20px;

    &.hideMainTable {
      .MainLayout-container {
        display: none !important;
      }
    }

    .customViewSwitcher {
      display: flex;
      align-items: center;
      position: absolute;
      top: -45px;
      right: 0;
      z-index: 2;

      button {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #215493;
        border: 1px solid rgba(33, 84, 147, 0.4);
        transition: all 0.3s ease 0s;
        border-radius: 6px;
        background: none;
        padding: 4px 22px;

        &.active,
        &:hover {
          color: #ffffff;
          background: #215493;
          box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
        }

        & ~ button {
          margin: 0 0 0 10px;
        }
      }
    }
    .customViewSwitcher2 {
      top: -85px;
    }
    .MainLayout-container {
      border: 2px solid #bfbfbf;
      border-radius: 5px;

      /* TODO */
      .MainLayout-dayScaleEmptyCell {
        width: 88.75px;
        min-width: 88.75px;
      }

      .Label-label {
        height: 48px;
        line-height: 48px;
        &.Label-emptyLabel {
          height: 8px;
          &:last-child {
            height: calc(10px - 1px);
          }
        }
      }

      .TitleCell-container {
        width: 100%;
        border-bottom: 4px solid rgba(224, 224, 224, 1);

        .TitleCell-content {
          border-top: 2px solid rgba(224, 224, 224, 1);
          height: 45.5px;
          width: 100%;
          justify-content: center;

          .TitleCell-title {
            padding-right: 0;
          }
        }
      }

      .MuiTableRow-root {
        &.allDayRow {
          .MuiTableCell-root {
            border-top: 1px solid rgba(224, 224, 224, 1) !important;
            border-bottom: 4px solid rgba(224, 224, 224, 1) !important;
          }
        }
      }

      .MuiTableCell-root {
        &.dayViewTH {
          text-align: center;
          .Cell-dayOfWeek {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #1c1e21;
            &:after {
              content: "曜日";
            }
          }
          .Cell-dayOfMonth {
            display: none;
          }
        }
        &.today {
          background: #fefae5;
        }

        &.saturday {
          background: #f5f9ff;
          .Cell-dayOfWeek,
          .Cell-dayOfMonth,
          .Cell-text {
            color: #195192;
          }
        }
        &.sunday {
          background: #fff5f8;
          .Cell-dayOfWeek,
          .Cell-dayOfMonth,
          .Cell-text {
            color: #fe3571;
          }
        }
      }

      .MainLayout-relativeContainer {
        .MuiTableCell-root {
          padding: 0;
        }
      }

      .MuiTable-root {
        border-radius: 0;
        .MuiTableRow-root {
          &:first-child {
            .MuiTableCell-root {
              border-top: 0;
            }
          }
          &:last-child {
            .MuiTableCell-root {
              border-left: 1px solid #dddddd;
            }
          }
        }
        .MuiTableCell-root {
          border: 1px solid #dddddd;

          &:first-child {
            border-left: 0 !important;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }

      .Cell-dayOfWeek {
        padding-top: 0;
        color: #1c1e21;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .Container-container {
      .Appointment-appointment {
        background: #195192;
        font-size: 12px;
        line-height: 1.2;
        &:hover {
          background: #195192;
        }
      }

      .VerticalAppointment-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }

      .VerticalAppointment-time {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
      }

      .Cell-highlightedText {
        color: #195192;
      }

      .Cell-today {
        background: #195192;
      }
    }

    .MuiToolbar-root.Toolbar-toolbar {
      border: 0;
      padding: 0;
      .TodayButton-button.MuiButtonBase-root {
        color: #1c1e21;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 3px 10px;
        min-width: 58px;
        background: #e7e7e7;
        border: 1px solid rgba(28, 30, 33, 0.2);
        margin: 0 0 0 15px;
      }

      .Root-root {
        pointer-events: none;
        > * {
          pointer-events: initial;
        }
        .NavigationButton-button {
          padding: 10px 2px;
          &:first-child {
            padding-left: 0;
          }
        }

        .MuiButton-text {
          position: absolute;
          top: 14px;
          left: 25px;
          right: 0;
          margin: 0 auto;
          max-width: 300px;
          text-align: center;
          z-index: 2;
          font-weight: 600;
        }
      }

      .OpenButton-textButton {
        pointer-events: none;
        color: #1c1e21;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        padding-right: 18px;
      }

      .NavigationButton-button {
        color: #1c1e21;
        background: none;
        &:hover {
          color: #195192;
        }
      }

      .Toolbar-toolbar {
        padding: 0;
      }
    }

    .MuiTableCell-root {
      padding: 14px 20px;
    }
  }

  .dayListView {
    border: 1px solid #dddddd;
    border-radius: 5px;
    overflow: hidden;
    min-height: 500px;

    > div {
      &:first-child {
        margin-top: -1px;
      }
    }

    .viewTitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      margin: 0;
      background: #f3f3f3;
      border-top: 1px solid #dddddd;
      padding: 6px 12px;
    }

    .viewItem {
      padding: 0;
      border: 0;
      padding: 6px 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      border-top: 1px solid #dddddd;
      width: 100%;
      text-align: left;
      background: none;
      display: flex;
      flex-wrap: wrap;

      .itemLabel {
        width: 150px;
      }

      .itemTitle {
        color: #1c1e21;
        width: calc(100% - 150px);
        position: relative;
        padding: 0 0 0 10px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 9px;
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #1c1e21;
        }
      }
    }
  }
  .btnTodayCustom {
    position: absolute;
    top: 20px;
    left: 50px;
    
    color: rgb(28, 30, 33);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 3px 10px;
    min-width: 58px;
    background: rgb(231, 231, 231);
    border: 1px solid rgba(28, 30, 33, 0.2);
    margin: 0px 0px 0px 15px;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    z-index: 1;
  }
  .btnToDayViewWeek {
      position: absolute;
      top: 7px;
      left: 112px;
      width: 58px;
      height: 28px;
      padding: 4px 0;
      text-align: center;
      font-size: 14px;
      border: 1px solid rgba(28,30,33,0.2);
      border-radius: 4px;
      cursor: pointer;
  }
  @media (max-width: 600px) {
    .btnTodayCustom {
      top: 11px;
    }
  }
  .e-new-event {
    display: none;
  }
  .rbc-btn-group:last-child {
    display: none;
  }
  .rbc-off-range-bg {
    background: #f0f0f0;
  }

  .rbc-row-content {
    min-height: 319px !important;
  }
  
  .rbc-month-row {
    overflow: visible !important;
  }
  
  .calendar-container {
    min-height: 100vh;
  }  `;
