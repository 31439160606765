import { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ProjectService from 'services/projectServiceSP';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

interface IProjectSP {
    workingLogs?: boolean;
    isPlans?: boolean;
}

const ProjectsSP = ({ workingLogs = false, isPlans = false }: IProjectSP) => {
    const [projectsList, setProjectsList] = useState<any>(null);
    const navigate = useNavigate();
    const getListProjects = async ({ page = 1, per_page = 0, data = {} }) => {
        try {
            const response: any = await ProjectService.getProjectLists({
                page,
                per_page,
                ...data,
            });
            setProjectsList(response?.projects);
        } catch (err) {}
    };
    useEffect(() => {
        getListProjects({ page: 1, per_page: 0 });
    }, []);
    
    const handleClickToProject = (project) => {
        if(workingLogs) {
            navigate(`/sp/working_logs/${project?.id}`)
        } else if (isPlans) {
            navigate(`/sp/plans/${project?.id}`)
        } else {
            navigate(`/sp/project/${project?.id}`)
        }
    }
    
    return (
        <Box
            sx={{
                '.MuiTypography-root': {
                    fontFamily: 'Zen Kaku Gothic Antique',
                    color: '#1C1E21',
                },
            }}
            padding="16px"
        >
            <Typography
                lineHeight="26px"
                textAlign="center"
                fontWeight="700"
                fontSize="18px"
                sx={{ marginBottom: '24px' }}
            >
                案件一覧
            </Typography>
            <Grid container spacing={2}>
                {projectsList?.map((project, index) => (
                    <Grid item xs={6} sm={6} md={4} lg={3} key={project?.id}>
                        <div
                            //to={
                            //    workingLogs
                            //        ? `/sp/working_logs/${project?.id}`
                            //        : `/sp/project/${project?.id}`
                            //}
                            onClick={() => handleClickToProject(project)}
                        >
                            <div className="image">
                                <img
                                    style={{
                                        borderRadius: '4px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        marginBottom: '8px',
                                        height: '164px',
                                    }}
                                    src={
                                        project?.image?.image_path ||
                                        process.env.PUBLIC_URL +
                                            '/img/login-background.png'
                                    }
                                />
                                <div
                                    className={`tag ${
                                        project?.business_type === '解体'
                                            ? 'dismantling'
                                            : 'industrial-waste'
                                    }`}
                                >
                                    {project?.business_type}
                                </div>
                            </div>
                            <Typography
                                sx={{ marginBottom: '4px' }}
                                fontSize="12px"
                                lineHeight="16px"
                            >
                                {project?.start_at && moment(project?.start_at).format(
                                    'YYYY年M月D日',
                                )}
                            </Typography>
                            <Typography
                                fontSize="14px"
                                lineHeight="20px"
                                fontWeight="500"
                            >
                                {project?.name}
                            </Typography>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
export default ProjectsSP;
