import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReceivedProps, DataType } from './type';
import InternalOJTService from 'services/internalOJTService';

interface IFile {
    file_name: string;
    file_type: string;
    image_path: string;
    signed_id: string;
}

interface IOJT {
    created_at: string;
    files: IFile[];
    id: number;
    updated_at: string;
    file_type?: string;
}

const useSPOJTInspection = (props: ReceivedProps) => {
    const [OJTLists, setOJTLists] = useState<
        DataType[]
    >([]);
    const [currentData, setCurrenData] = useState<DataType>();
    const [fileData, setFileData] = useState<string | null>();

    const navigate = useNavigate();

    const handleShare = async (values) => {
        const response = await fetch(values?.file_url);
        const data = await response.blob();
        const metadata = {
            type: 'application/pdf',
        };
        const file = new File([data], values.file_name, metadata);
        const files = [file];
        if (navigator.share) await navigator.share({ files });
    };

    const fetchListOJT = async () => {
        try {
            const res =
                await InternalOJTService.getInternalOJTListsSP({
                    page: 1,
                    per_page: 0,
                });
                if(!(res as {internal_ojts: IOJT[]})?.internal_ojts) return;
                const internal_ojts = (res as {internal_ojts: IOJT[]})?.internal_ojts;
                const listOJTs = internal_ojts?.map(item => {
                    const file = item?.files[0];    
                    const filesArr = item?.files?.map(item => {
                        return {...item, file_url: item?.image_path}
                    })
                    return {id: item?.id, created_at: item?.created_at, updated_at: item?.updated_at, name: file?.file_name,file_url: file?.image_path, file_type: file?.file_type, files: filesArr}
                });
                setOJTLists(listOJTs);
        } catch (error) {
            console.log(error);
        }
    };

    const handleViewFile = async (values) => {
        try {
            setCurrenData({...values, name: values?.file_name});
            const response = await fetch(values?.file_url);
            const data = await response.blob();
            const file = window.URL.createObjectURL(data);
            setFileData(file);
        } catch (error) {}
    };

    useEffect(() => {
        fetchListOJT();
    }, []);
    return {
        ...props,
        OJTLists,
        fileData,
        currentData,
        navigate,
        handleShare,
        handleViewFile,
        setFileData,
    };
};
export type Props = ReturnType<typeof useSPOJTInspection>;

export default useSPOJTInspection;
