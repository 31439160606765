import { useState, useEffect } from 'react';
import './companyCreate.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import calendarIcon from '../../../assets/icons/calendar.png';
import { Checkbox } from 'antd';
import CooperativeCompanyService from '../../../services/cooperativeCompaniesService';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);
import createClientCompanyValidation from 'utils/validation/registers/clientCompanies/createClientCompany';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CompanyService from '../../../services/clientCompanyService';
import {
    convertClientCompaniesToDropdownOptions,
    removeUndefinedCompany,
} from 'utils/helper/clientCompanyHelper';

const CooperativeCompanyCreate = (props) => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(null);
    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        formState: { errors },
        setValue,
    } = useForm();

    const [companyOption, setCompanyOption] = useState<any>([]);

    const [checkClient, setCheckClient] = useState(false);
    const [checkCooperative, setCheckCooperative] = useState(true);
    const [errorName, setErrorName] = useState(false);
    const [isErrorLengthText, setIsErrorLengthText] = useState(false);

    const handleStartAtChange = (date: any) => {
        setStartDate(date);
    };

    const getListClientCompany = async () => {
        try {
            const response = await CompanyService.getListCompanies();
            if (response !== null && response !== undefined) {
                let tempListClient = convertClientCompaniesToDropdownOptions(
                    response.client_companies,
                );
                const finalClients = removeUndefinedCompany(tempListClient);
                setCompanyOption(finalClients);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListCooperativeCompanies = async () => {
        try {
            const response =
                await CooperativeCompanyService.getListCooperativeCompanies();
            if (response !== null && response !== undefined) {
                let tempListClient = convertClientCompaniesToDropdownOptions(
                    response.cooperative_companies,
                );
                const finalClients = removeUndefinedCompany(tempListClient);
                setCompanyOption(finalClients);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListCompany = async () => {
        try {
            let listClient: any = [];
            let listCooperative: any = [];
            const response = await CompanyService.getListCompanies();
            if (response !== null && response !== undefined) {
                listClient = convertClientCompaniesToDropdownOptions(
                    response.client_companies,
                );
            }
            const response2 =
                await CooperativeCompanyService.getListCooperativeCompanies();
            if (response2 !== null && response2 !== undefined) {
                listCooperative = convertClientCompaniesToDropdownOptions(
                    response2.cooperative_companies,
                );
            }
            const finalClients = removeUndefinedCompany(
                listClient.concat(listCooperative),
            );
            setCompanyOption(finalClients);
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        checkClient && !checkCooperative && getListClientCompany();
        !checkClient && checkCooperative && getListCooperativeCompanies();
        checkClient && checkCooperative && getListCompany();
    }, [checkClient, checkCooperative]);

    useEffect(() => {
        if (checkClient) {
            setValue('is_client_company', true);
        } else {
            setValue('is_client_company', false);
        }
    }, [checkClient]);

    useEffect(() => {
        if (checkCooperative) {
            setValue('is_cooperative_company', true);
        } else {
            setValue('is_cooperative_company', false);
        }
    }, [checkCooperative]);

    const onCreate = (data) => {
        if(data.name.trim().length == 0 || !data.name) {
            setErrorName(true)
            return;
        }
        if(!data?.is_cooperative_company && !data.is_client_company) {
            data.is_cooperative_company = true;
        }
        return new Promise(async (resolve, reject) => {
            await CooperativeCompanyService.createCooperativeCompany({...data, date_of_establishment: data?.date_of_establishment || null, name: data.name.trim()})
                .then(() => {
                    navigate('/cooperative-companies');
                })
                .catch((err) => {
                    const response = err?.response;
                    if (!response || typeof response === 'undefined') {
                        // window.location.href = '/login';
                        reject(err);
                    }
                    reject(err);
                });
            resolve(true);
        });
    };

    return (
        <form
            onSubmit={handleSubmit(onCreate)}
            className="container-company-create-detail d-flex flex-row"
        >
            <div className="company-create-content d-flex flex-column">
                <div
                    className="worker-create-title d-flex flex-column"
                    style={{ alignItems: 'flex-start' }}
                >
                    協力会社 新規作成
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>会社名</p>
                    <input
                        autoComplete="off"
                        type="text"
                        // required
                        className="create-company-input"
                        {...register(
                            'name',
                        )}
                        onChange={(e) => {setErrorName(!e.target.value.trim() ? true : false), setIsErrorLengthText(e.target.value?.length > 255 ? true : false)}}
                    ></input>
                    {/*{errors.name && (
                        <span className="error">{errors.name.message}</span>
                    )}*/}
                    {errorName && <span className="error">会社名は必須です。</span>}
                    {isErrorLengthText && <span className="error">255文字以内で入力してください</span>}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p style={{ marginTop: 0 }}>親会社</p>
                    <Controller
                        name="parent_company_id"
                        control={control}
                        render={({ field: { value } }) => (
                            // <Dropdown
                            // className='dropdown-create-user1 create-company-input'
                            // controlClassName='dropdown-control-user'
                            // options={companyOption}
                            // {...register('parent_company_id', createClientCompanyValidation(t).parent_company_id())}
                            // {...field}
                            // placeholder="&#xf002;"/>
                            <Autocomplete
                                disablePortal
                                value={{
                                    label: value?.label || '',
                                    value: value?.value || '',
                                }}
                                options={companyOption.map((item) => ({
                                    label: item.label,
                                    value: String(item.value),
                                }))}
                                getOptionLabel={(option: any) =>
                                    option.label || option.value
                                }
                                onChange={(event, newValue) => {
                                    setValue('parent_company_id', newValue);
                                }}
                                classes={{
                                    root: 'cooperative-company-create-search-select-style',
                                    input: 'cooperative-company-create-search-select-style',
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label=""
                                        className="autocomplete-custom-textfield"
                                    />
                                )}
                                noOptionsText="該当なし"
                            />
                        )}
                    />
                    {errors.parent_company && (
                        <span className="error">
                            {errors.parent_company.message}
                        </span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>会社属性</p>
                    <div className="checkbox checkbox-create-company d-flex flex-row">
                        <Checkbox
                            checked={checkClient}
                            onChange={() => setCheckClient(!checkClient)}
                        />
                        <div className="checkbox-text" style={{marginLeft: '6px'}}>顧客会社</div>
                    </div>

                    <div
                        className="checkbox checkbox-create-company d-flex flex-row"
                        style={{ marginTop: '8px' }}
                    >
                        <Checkbox
                            checked={checkCooperative}
                            //onChange={() =>
                            //    setCheckCooperative(!checkCooperative)
                            //}
                        />
                        <div className="checkbox-text" style={{marginLeft: '6px'}}>協力会社</div>
                    </div>
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>Eメール</p>
                    <input
                        className="create-company-input"
                        autoComplete="off"
                        type="text"
                        {...register(
                            'email'
                        )}
                    ></input>
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>電話番号</p>
                    <input
                        className="create-company-input"
                        autoComplete="off"
                        type="text"
                        {...register(
                            'phone'
                        )}
                    ></input>
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>FAX</p>
                    <input
                        className="create-company-input"
                        autoComplete="off"
                        type="text"
                        {...register(
                            'fax'
                        )}
                    ></input>
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>郵便番号</p>
                    <input
                        className="create-company-input"
                        autoComplete="off"
                        type="text"
                        {...register(
                            'zipcode'
                        )}
                    ></input>
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>住所</p>
                    <input
                        className="create-company-input"
                        autoComplete="off"
                        type="text"
                        {...register(
                            'address'
                        )}
                    ></input>
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>設立日</p>
                    <div
                        className="input-date create-company-input"
                        style={{ position: 'relative' }}
                    >
                        <Controller
                            name="date_of_establishment"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    id="date_of_establishment"
                                    selected={startDate}
                                    {...register(
                                        'date_of_establishment'
                                    )}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                        handleStartAtChange(date);
                                    }}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                            )}
                        />
                        <label htmlFor="date_of_establishment">
                            <img
                                className="input-date-img"
                                width={'16px'}
                                height={'16px'}
                                src={calendarIcon}
                            ></img>
                        </label>
                    </div>
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>資本金</p>
                    <input
                        className="create-company-input"
                        autoComplete="off"
                        type="text"
                        {...register(
                            'capital'
                        )}
                    ></input>
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>従業員数</p>
                    <input
                        className="create-company-input"
                        autoComplete="off"
                        type="number"
                        {...register(
                            'number_of_employees'
                        )}
                    ></input>
                </div>

                <div className="input-add-user d-flex flex-column">
                    <p style={{ marginTop: 0 }}>事業内容</p>
                    <textarea
                        autoComplete="off"
                        className="dropdown-create-user2"
                        {...register(
                            'business_content'
                        )}
                    ></textarea>
                </div>
                <div
                    className="input-add-user d-flex flex-column"
                    style={{ marginTop: '8px' }}
                >
                    <p style={{ marginTop: 0 }}>備考</p>
                    <textarea
                        autoComplete="off"
                        className="dropdown-create-user2"
                        {...register('note')}
                    ></textarea>
                </div>
                <div
                    className="modal-create-user-buttons create-button-company"
                    style={{ marginTop: '14px' }}
                >
                    <button
                        className="modal-create-user-button1"
                        onClick={() => navigate('/cooperative-companies')}
                    >
                        戻る
                    </button>
                    <button
                        className="modal-create-user-button2"
                        style={{ marginLeft: '5px' }}
                        // disabled={(newUser.email === "" || newUser.password === "" || newUser.password_confirmation === "" || newUser.worker_id === -1) ? true : false}
                        // onClick={handleCreateUser}
                        type="submit"
                        disabled={isErrorLengthText}
                    >
                        登録
                    </button>
                </div>
            </div>
        </form>
    );
};
const checkBoxStyles = {
    checkmark: {
        background: '#3DDD74',
        color: 'white',
    },
    checkbox: {
        background: '#3DDD74',
        borderColor: '#3DDD74',
        width: '15px',
        margin: '5px 15px 0 0',
        height: '15px',
    },
    // text: {
    //   fontWeight: "bold",
    //   color:'brown'
    // }
};

const checkBoxStyles2 = {
    checkmark: {
        background: '#3DDD74',
        color: 'white',
    },
    checkbox: {
        background: '#FFF',
        borderColor: '#CDD1D5',
        width: '15px',
        margin: '5px 15px 0 0',
        height: '15px',
    },
};
export default CooperativeCompanyCreate;
