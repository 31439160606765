import { Box, Grid, Typography, Button } from '@mui/material';
import TrashIcon from 'assets/icons/delete_ic.svg';
import ViewIcon from 'assets/icons/view_ic.svg';
import { FC } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useNavigate } from 'react-router-dom';
import useChat, { Props, ReceivedProps } from './hook';
import { Styles } from './styled';
import plus from 'assets/icons/Plus.png';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const ChatListLayout: FC<Props> = ({
    rowData,
    onDeleteChat,
    setChatId,
    id,
    loading
}) => {
    const navigate = useNavigate();
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: false,
        },
    );
    if(loading) return <div className='h-[100vh] flex justify-center mt-[40px]'><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div>
    return (
        <Styles>
            <Button
                onClick={() => navigate(`/sp/project/${id}/chats/create`)}
                sx={{
                    color: '#215493',
                    mt: '42px',
                    width: '123px',
                    fontWeight: 600,
                    mb: '25px',
                }}
                variant="outlined"
            >
                <img
                    className="mr-2"
                    width={'16px'}
                    height={'16px'}
                    src={plus}
                />
                新規作成
            </Button>
            <Box
                sx={{
                    border: '1px solid #CDD1D5 ',
                    '.MuiGrid-container': {
                        borderBottom: '1px solid #F5F5F5',
                    },
                }}
            >
                <Box sx={{ backgroundColor: '#C7DCF5', padding: '14px 17px' }}>
                    チャットタイトル
                </Box>
                {rowData?.map((chat) => (
                    <Grid sx={{ padding: '14px 17px' }} container>
                        <Grid sx={{ display: 'flex', alignItems: 'center' }} xs={9} item>
                            {chat?.title}
                            {chat?.total_unread > 0 && (
                                <Box
                                    sx={{
                                        backgroundColor: '#215493',
                                        color: 'white',
                                        padding: '2px 4px',
                                        borderRadius: '4px',
                                        marginLeft: '8px',
                                        fontSize: '12px',
                                        lineHeight: '12px',
                                        height: 'max-content'
                                    }}
                                >
                                    {`${chat?.total_unread}件`}
                                </Box>
                            )}
                        </Grid>
                        <Grid
                            display="flex"
                            justifyContent="end"
                            alignItems="center"
                            xs={3}
                            item
                        >
                            <Box
                                onClick={() =>
                                    {
                                        navigate(
                                            `/sp/project/${id}/chats/chat/${chat.id}`,
                                        );
                                        localStorage.setItem("createChatFromProject", "no");
                                    }
                                }
                                sx={{
                                    backgroundColor: '#215493',
                                    width: 24,
                                    height: 24,
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={ViewIcon} />
                            </Box>
                            <Box
                                onClick={() => {
                                    setChatId(chat.id);
                                    openDelete();
                                }}
                                sx={{
                                    backgroundColor: '#FF5045',
                                    width: 24,
                                    height: 24,
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    ml: '6px',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={TrashIcon} />
                            </Box>
                        </Grid>
                    </Grid>
                ))}
            </Box>
            <Button
                onClick={() => navigate(`/sp/project/${id}`)}
                sx={{
                    color: '#215493',
                    mt: '42px',
                    width: '166px',
                    fontWeight: 600,
                }}
                variant="outlined"
            >
                戻る
            </Button>
            <ModalDelete>
                <div
                    className="modal-create-user d-flex flex-column"
                    style={{ height: '152px' }}
                >
                    <div className="modal-create-user-title d-flex flex-row">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            onClick={() => {
                                closeDelete();
                                setChatId(null);
                            }}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            style={{ background: '#215493' }}
                            onClick={() => onDeleteChat(closeDelete)}
                        >
                            はい
                        </button>
                    </div>
                </div>
            </ModalDelete>
        </Styles>
    );
};

const ChatList: FC<ReceivedProps> = (props) => (
    <ChatListLayout {...useChat(props)} />
);

export default ChatList;
