import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Input } from 'antd';
import { StyledDialog } from './styled';
import { useState } from 'react';
import projectsService from 'services/projectsService';

const EditPdfDocumentDialog = ({ onClose = () => { }, open = false, fileName = '', projectId = '', signedId = '', onSucess = () => {} }) => {
  const [name, setName] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const handleEditFileName = async () => {
    if (!name.trim() || !projectId || !signedId) {
      onClose();
      return;
    }
    try {
      setDisabledSubmit(true);
      const res = await projectsService.updatePdfDocumentFilename(projectId, signedId, { new_name: name.trim() })
      setDisabledSubmit(false);
      onClose();
      onSucess();
    } catch (error) {
      setDisabledSubmit(false);
      console.log(error);
    }
  }
  return <>
    {open && (
      <StyledDialog
        onClose={() => {onClose(), setDisabledSubmit(false)}}
        sx={{
          width: '100%',
          '.MuiPaper-root': {
            width: "100%",
            height: "100%",
            margin: '0px',
          },

          '.rpv-core__inner-container': {
            paddingBottom: '30px !important',
          },
        }}
        open={open}
      >
        <DialogTitle className='flex justify-between'>
          <p className='!mb-[0] font-semibold'>アップロード</p>
          <span className='cursor-pointer' onClick={() => {onClose(), setDisabledSubmit(false)}}><CloseIcon /></span>
        </DialogTitle>
        <DialogContent>
          <p className='!mb-[0] font-semibold'>名前変更</p>
          <Input className='mt-[12px]'
            defaultValue={fileName}
            onChange={(e) => {
              if (e.target.value.trim()) {
                setName(e.target.value)
                setDisabledSubmit(false)
              } else {
                setDisabledSubmit(true)
              }
            }} />
          <div
            className="modal-create-user-buttons d-flex flex-row gap-x-[10px] !mt-[30px]"
            style={{
              marginTop: '14px',
              justifyContent: 'flex-start',
            }}
          >
            <button
              className="modal-create-user-button1 d-flex flex-row"
              onClick={onClose}
            >
              キャンセル
            </button>
            <button
              disabled={disabledSubmit}
              className={`modal-create-user-button2 d-flex flex-row ${disabledSubmit ? 'opacity-70' : ''}`}
              onClick={handleEditFileName}
            >
              保存
            </button>
          </div>
        </DialogContent>
      </StyledDialog>
    )}
  </>
}

export default EditPdfDocumentDialog;
