import { Dialog, DialogContent, DialogTitle, styled } from "@mui/material";
import { StyledTextField } from "components/StyledTextField";
import { Dispatch, FC, SetStateAction, useState } from "react";
import StyledDetailWrapper from "./style";
import { getFilePreview } from "utils/helper/file";
import CloseIcon from '@mui/icons-material/Close';
interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  workingLog: any;
}
interface IFile {
  image_path?: string;
  signed_id?: string;
  file?: File;
}
const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    transition: 'none !important',
  },
  '& .MuiDialog-container': {
    transition: 'none !important',
  },
  '& .MuiPaper-root': {
    transition: 'none !important',
  },
}));

const WorkingLogDetailDialog: FC<IProps> = (props) => {
  const {open, setOpen, workingLog} = props;
  const [selectedImage, setSelectedImage] = useState<IFile>();
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <BootstrapDialog open={open} onClose={() => setOpen(false)} disableEscapeKeyDown>
      <Dialog
        onClose={() => { setSelectedImage({}); setOpenDialog(false) }}
        sx={{
          '.MuiPaper-root': {
            width: "unset",
            height: "unset",
            margin: '0px',
          },

          '.rpv-core__inner-container': {
            paddingBottom: '30px !important',
          },
        }}
        open={openDialog}
      >
        <DialogTitle className='flex justify-end'>
          <span onClick={() => { setSelectedImage({}); setOpenDialog(false) }}><CloseIcon /></span>
        </DialogTitle>
        <DialogContent>
          <img
            src={
              selectedImage?.image_path
                ? selectedImage.image_path
                : getFilePreview(
                  selectedImage?.file!,
                )
            }
          />
        </DialogContent>
      </Dialog>
      <StyledDetailWrapper>
        <div className="dialogTitle">作業記録</div>

        <div className="detailRow">
          <p>工程</p>
          <StyledTextField 
            value={workingLog?.plan?.name || ""} 
            className="textfield-style" 
            disabled 
          />
        </div>

        <div className="detailRow">
          <p>作業名</p>
          <StyledTextField 
            value={workingLog?.name || ""}
            className="textfield-style" 
            disabled 
          />
        </div>

        <div className="detailRow">
          <p>作業日</p>
          <StyledTextField 
            value={workingLog?.worked_at || ""}
            className="textfield-style" 
            disabled 
          />
        </div>

        <div className="detailRow">
          <p>担当</p>
          <StyledTextField 
            value={workingLog?.worker?.name || ""}
            className="textfield-style" 
            disabled 
          />
        </div>

        <div className="detailRow">
          <p>備考</p>
          <textarea 
            value={workingLog?.note || ""}
            className="textarea" 
            disabled
          ></textarea>
        </div>

        <div className="detailRow">
          <p>画像</p>
          <div className="imagesContainer">
            {
              workingLog 
              && workingLog.working_log_images
              && workingLog.working_log_images.length > 0
              && workingLog.working_log_images[0].images
              && workingLog.working_log_images[0].images.length > 0
              && workingLog.working_log_images[0].images.map((item) => (
                <div className="imageWrapper" key={item.signed_id}>
                  <img src={item.image_path || ""} alt="working log images"  onClick={() => {setSelectedImage(item); setOpenDialog(true)}} className='cursor-pointer' />
                </div>
              ))
            }
          </div>
        </div>

        <div className="footer">
          <button className="cancelBtn" onClick={() => setOpen(false)}>戻る</button>
        </div>
      </StyledDetailWrapper>
    </BootstrapDialog>
  )
}

export default WorkingLogDetailDialog;
