import { useFormik } from 'formik';
import { Dispatch, SetStateAction } from 'react';

type IOptions = {
    id: any;
    name: string;
}[];
export type IDeparment2 = {
    id: number;
    name: string;
}
export interface IWorkersOptions {
    id: number;
    name: string;
    departments: number[] | null;
    position: number | null;
    departments2: IDeparment2[];
};

export type ReceivedProps = {
    positionOptions: IOptions;
    workerOptions: IWorkersOptions[];
    departmentOptions: IOptions;
    setSearchParam: Dispatch<SetStateAction<any>>;
};

const useFilterBox = (props: ReceivedProps) => {
    const getListWorkers = (departmentIds: number[], positionIds: number[], workerIds: number[]) => {
        const listId: number[] = [];
        props.workerOptions.map((item: IWorkersOptions) => {
            let check = true;
            item?.departments && item?.departments?.length > 0 && item?.departments.map((departmentId: number) => {
                departmentIds?.length > 0 && departmentIds.map((id: number) => {
                    if (id === departmentId) {
                        check = false;
                        !listId.includes(item.id) && listId.push(item.id);
                    }
                })
            })

            check && item?.position && positionIds?.length > 0 && positionIds.map((id: number) => {
                if (id === item?.position) {
                    check = true;
                    !listId.includes(item.id) && listId.push(item.id);
                }
            })

            check && workerIds?.length > 0 && workerIds.map((workerId: number) => {
                !listId.includes(workerId) && listId.push(workerId);
            })
        })
        return listId
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            department_id: [],
            position_id: [],
            worker_id: [],
            free_word: null,
        },
        onSubmit: (values: any) => {
            const listWorkers = getListWorkers(values?.department_id?.map(v => v.id), values?.position_id?.map(v => v.id), values?.worker_id?.map(v => v.id))
            props.setSearchParam({
                ...values,
                department_id: values?.department_id?.map(v => v.id),
                position_id: values?.position_id?.map(v => v.id),
                worker_id: values?.worker_id?.map(v => v.id),
                worker_list: listWorkers
            });
        },
    });

    return {
        ...props,
        formik,
    };
};

export type Props = ReturnType<typeof useFilterBox>;

export default useFilterBox;
