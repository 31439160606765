import { DialogTitle, DialogContent } from '@mui/material';
import InfoIcon from 'assets/icons/info.svg';
import PdfIcon from 'assets/icons/pdf-icon.svg';
import { StyledDialog } from './styled';
import { useState } from 'react';
import projectsService from 'services/projectsService';
import DocIcon from 'assets/icons/docx.svg';
import ExcelIcon from 'assets/icons/xlsx.svg';

const DeletePdfDocumentDialog = ({ onClose = () => { }, open = false, fileName = '', projectId = '', signedId = '', onSucess = () => { }, size = 0, convertByteToMB = (num) => {}, type = "" }) => {
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const handleDeletePdf = async () => {
    if (!projectId || !signedId) return;
    try {
      setDisabledSubmit(true);
      await projectsService.deletePdfDocument(projectId, signedId)
      setDisabledSubmit(false);
      onClose();
      onSucess();
    } catch (error) {
      setDisabledSubmit(false);
      console.log(error);
    }
  }

  const renderIcon = (type = "") => {
    if (type?.includes("/pdf")) {
      return <img src={PdfIcon} alt='pdf icon' />
    } else if (type?.includes(".sheet") || type?.includes(".ms-excel")) {
      return <img src={ExcelIcon} alt='excel icon' />
    } else {
      return <img src={DocIcon} alt='word icon' />
    }
  }

  return <>
    {open && (
      <StyledDialog
        onClose={onClose}
        sx={{
          width: '100%',
          '.MuiPaper-root': {
            width: "100%",
            height: "100%",
            margin: '0px',
          },

          '.rpv-core__inner-container': {
            paddingBottom: '30px !important',
          },
        }}
        open={open}
      >
        <DialogContent>
          <img className='cursor-pointer icon-info' src={InfoIcon} alt='info icon' />
          <p className='!mb-[0] mt-[18px] font-bold text-[#d83535] text-[20px] text-center'>このファイルを削除しても<br/>よろしいですか？</p>
          <div className='flex items-center gap-x-[10px] justify-center mt-[18px]'>
            {renderIcon(type)}
            <div>
              <p className='!mb-0'>{fileName}</p>
              <p className='!mb-0'>{convertByteToMB(size)}MB</p>
            </div>
          </div>
          <div
            className="modal-create-user-buttons d-flex flex-row gap-x-[10px] !mt-[24px] pb-[12px]"
            style={{
              marginTop: '14px',
              justifyContent: 'flex-start',
            }}
          >
            <button
              className="modal-create-user-button1 d-flex flex-row"
              onClick={onClose}
            >
              キャンセル
            </button>
            <button
              disabled={disabledSubmit}
              className={`modal-create-user-button2 d-flex flex-row !bg-[#d83535] ${disabledSubmit ? 'opacity-70' : ''}`}
              onClick={handleDeletePdf}
            >
              はい
            </button>
          </div>
        </DialogContent>
      </StyledDialog>
    )}
  </>
}

export default DeletePdfDocumentDialog;
