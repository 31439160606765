import { useState, useEffect } from 'react';
import './clientCompany.css';
import { useNavigate } from 'react-router-dom';
import plus from '../../assets/icons/Plus.png';
import Table from '@mui/material/Table';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useModal } from 'react-hooks-use-modal';
import CooperativeCompanyService from '../../services/cooperativeCompaniesService';
import { TextField, InputAdornment, Box } from '@mui/material';
import { Search } from '@mui/icons-material';
import 'react-dropdown/style.css';
import { IPageQueries } from 'models';
import CustomPagination from 'components/CustomPagination';
import EmptyImage from 'components/EmptyImage';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const TableRowCustom = (props) => {
    const { row, openDelete, setCompanyDelete } = props;
    const navigate = useNavigate();

    return (
        <StyledTableRow>
            <StyledTableCell
                align="left"
                component="th"
                scope="row"
                style={{ width: '15%' }}
            >
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {row.company?.name}
                </div>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '15%' }}>
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {row.company?.parent_company?.name}
                </div>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '15%' }}>
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {row.company?.phone}
                </div>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '15%' }}>
                <div className="text-overflow1">{row.company?.email}</div>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '15%' }}>
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {row.company?.business_content}
                </div>
            </StyledTableCell>

            <StyledTableCell align="center" style={{ width: '25%' }}>
                <button
                    className="button-tb"
                    style={{ width: '72px', margin: '5px' }}
                    onClick={() => {
                        navigate(`/cooperative-companies/detail/${row.id}`);
                    }}
                >
                    詳細
                </button>
                <button
                    className="button-tb"
                    style={{ width: '72px', margin: '5px' }}
                    onClick={() => {
                        navigate(`/cooperative-companies/edit/${row.id}`);
                    }}
                >
                    編集
                </button>
                <button
                    className="button-tb"
                    style={{
                        width: '72px',
                        margin: '5px',
                        background: '#FF5045',
                    }}
                    onClick={() => {
                        openDelete();
                        setCompanyDelete(row);
                    }}
                >
                    削除
                </button>
            </StyledTableCell>
        </StyledTableRow>
    );
};

const CooperativeCompany = (props) => {
    const navigate = useNavigate();

    const [companiesList, setCompanyList] = useState<any[]>([]);

    const [searchingText, setSearchingText] = useState<any>('');
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchedState, setSearchedState] = useState<string>('');
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });

    const [companyDelete, setCompanyDelete] = useState<any>(null);

    const getListCompany = async ({
        page = 1,
        per_page = 5,
        free_word = '',
    }) => {
        try {
            const response =
                await CooperativeCompanyService.getListCooperativeCompanies({
                    page,
                    per_page,
                    free_word,
                });
            if (response !== null && response !== undefined) {
                setCompanyList(response.cooperative_companies);
                setTotalCount(response.meta.total_count);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const deleteCompany = async () => {
        try {
            const response =
                await CooperativeCompanyService.deleteCooperativeCompany(
                    companyDelete.id,
                );
            if (response !== null && response !== undefined) {
                if (pageQueries.page !== 1) {
                    setPageQueries((prevState) => ({ ...prevState, page: 1 }));
                }
                getListCompany({
                    page: 1,
                    per_page: pageQueries.per_page,
                    free_word: searchedState,
                });
                setCompanyDelete(null);
                closeDelete();
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const handleSearch = () => {
        setSearchedState(searchingText);
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        getListCompany({
            page: 1,
            per_page: pageQueries.per_page,
            free_word: searchingText,
        });
    };

    const [winReady, setwinReady] = useState(false);
    useEffect(() => {
        setwinReady(true);
    }, []);

    useEffect(() => {
        getListCompany({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, []);

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: false,
        },
    );

    return (
        <div className="container-worker d-flex flex-row">
            <div className="worker-content d-flex flex-column">
                <div
                    className="create-button d-flex flex-row"
                    style={{ height: '53px' }}
                >
                    <button
                        className="create-user-button d-flex flex-row"
                        onClick={() =>
                            navigate('/cooperative-companies/create')
                        }
                    >
                        <img width={'16px'} height={'16px'} src={plus}></img>
                        新規作成
                    </button>
                </div>
                <div className="search-client-company-button d-flex flex-row">
                    <Box
                        sx={{
                            width: '100%',
                            '.input-search': {
                                border: '1px solid #cdd1d5',
                                borderRadius: '8px',
                                marginBottom: '14.5px',
                                paddingLeft: '14px',
                            },
                            '.MuiTextField-root': {
                                border: '1px solid #cdd1d5',
                                width: '100%',
                                borderRadius: '8px',
                                marginRight: '12.5px',
                            },
                            '.MuiInputBase-input': {
                                height: '35px',
                                padding: '0px !important',
                            },
                        }}
                        style={{ width: '343px' }}
                    >
                        <p className='mb-[4px] text-[14px]'>キーワード</p>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search sx={{ color: '#000' }} />
                                    </InputAdornment>
                                ),
                            }}
                            autoComplete="off"
                            id="input-search"
                            type="text"
                            name="free_word"
                            onChange={(e) => {
                                setSearchingText(e.target.value);
                            }}
                        />
                    </Box>
                    <div className="search-client-company-row-button margin-left d-flex flex-column">
                        <button
                            className="search-user-button d-flex flex-column"
                            onClick={handleSearch}
                            style={{
                                width: '150px',
                                height: '36px',
                                marginBottom: '3px',
                            }}
                        >
                            検索
                        </button>
                    </div>
                </div>
                <div
                    className="table-workers table-company d-flex flex-column"
                    style={{ marginTop: '10px' }}
                >
                    <div className="table-content d-flex flex-column">
                        {winReady ? (
                            <TableContainer
                                component={Paper}
                                table-layout={'auto'}
                            >
                                <Table
                                    sx={{ minWidth: 1200 }}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '15%' }}
                                            >
                                                <div>会社名</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '15%' }}
                                            >
                                                <div>親会社名</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '15%' }}
                                            >
                                                <div>電話番号</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '15%' }}
                                            >
                                                <div>Eメール</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '15%' }}
                                            >
                                                <div
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    事業内容
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{
                                                    width: '25%',
                                                    minWidth: '200px',
                                                }}
                                            >
                                                <div>操作</div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {companiesList !== null &&
                                            companiesList.map((row, ind) => (
                                                <TableRowCustom
                                                    key={ind}
                                                    row={row}
                                                    openDelete={openDelete}
                                                    setCompanyDelete={
                                                        setCompanyDelete
                                                    }
                                                ></TableRowCustom>
                                            ))}
                                        {companiesList?.length <= 0 && (
                                            <tr>
                                                <StyledTableCell colSpan={6}>
                                                    <div className="text-center py-[40px]">
                                                        <EmptyImage classNameCustom='inline-block' />
                                                        <div className='text-[#00000040] text-sm mt-[6px]'>データがありません</div>
                                                    </div>
                                                </StyledTableCell>
                                            </tr>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : null}
                        <ModalDelete>
                            <div
                                className="modal-create-user d-flex flex-column"
                                style={{ height: '152px' }}
                            >
                                <div className="modal-create-user-title d-flex flex-row">
                                    本当に削除しますか?
                                </div>
                                <div
                                    className="modal-create-user-buttons d-flex flex-row"
                                    style={{ marginTop: '50px' }}
                                >
                                    <button
                                        className="modal-create-user-button1 d-flex flex-row"
                                        onClick={closeDelete}
                                    >
                                        キャンセル
                                    </button>
                                    <button
                                        onClick={deleteCompany}
                                        className="modal-create-user-button2 d-flex flex-row"
                                    >
                                        はい
                                    </button>
                                </div>
                            </div>
                        </ModalDelete>
                    </div>
                </div>
                {companiesList && companiesList.length > 0 && (
                    <CustomPagination
                        totalCount={totalCount}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) =>
                            getListCompany({
                                page,
                                per_page: pageQueries.per_page,
                                free_word: searchedState,
                            })
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default CooperativeCompany;
