import { FC } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import useFilterBox, { Props, ReceivedProps } from './hook';
import FilterBoxWrapper from './style';

const FilterBoxLayout: FC<Props> = ({
    formik,
    workerOptions,
    positionOptions,
    departmentOptions,
}) => {
    return (
        <FilterBoxWrapper>
            <form onSubmit={formik.handleSubmit} className="filterBox">
                <div className="filterBoxBottom">
                    <div className="filterBoxCol">
                        <span className="fieldLabel">部署</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                multiple
                                value={formik.values.department_id}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'department_id',
                                        newValue,
                                    );
                                }}
                                noOptionsText="該当なし"
                                options={departmentOptions || []}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </div>

                    <div className="filterBoxCol">
                        <span className="fieldLabel">役職</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                multiple
                                value={formik.values.position_id}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'position_id',
                                        newValue,
                                    );
                                }}
                                noOptionsText="該当なし"
                                options={positionOptions || []}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </div>

                    <div className="filterBoxCol">
                        <span className="fieldLabel">社員</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                multiple
                                value={formik.values.worker_id}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue('worker_id', newValue);
                                }}
                                noOptionsText="該当なし"
                                options={workerOptions || []}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </div>

                    <div className="filterBoxCol">
                        <span className="fieldLabel">キーワード</span>
                        <div className="wrapTextField haveIcon">
                            <TextField
                                fullWidth
                                hiddenLabel
                                name="free_word"
                                type="text"
                                variant="outlined"
                                margin="dense"
                                onChange={formik.handleChange}
                                value={formik.values.free_word}
                            />
                        </div>
                    </div>

                    <div className="filterBoxCol justify-end flex">
                        <button
                            type="submit"
                            className="modal-create-plan-button2 d-flex flex-row"
                            style={{
                                width: 100,
                                marginLeft: 0,
                                marginTop: 7,
                                height: 30,
                            }}
                        >
                            検索
                        </button>
                    </div>
                </div>
            </form>
        </FilterBoxWrapper>
    );
};

const FilterBox: FC<ReceivedProps> = (props) => (
    <FilterBoxLayout {...useFilterBox(props)} />
);

export default FilterBox;
