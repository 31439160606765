import { useState, useEffect } from 'react';
import './position.css';
import plus from '../../assets/icons/Plus.png';
import frame from '../../assets/icons/Frame.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useModal } from 'react-hooks-use-modal';
import positionService from '../../services/positionService';
import {
    DragDropContext,
    Droppable,
    Draggable,
    resetServerContext,
} from 'react-beautiful-dnd';
import { alertError } from 'utils/helper/appHelper';
import CreatePositionDialog from './create';
import EditPositionDialog from './edit';
import DeletePositionDialog from './delete';
import EmptyImage from 'components/EmptyImage';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Positions = (props) => {
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [positionsList, setPositionsList] = useState<any>([]);
    const [selectedPosition, setSelectedPosition] = useState<any>(null);
    const [deletePosition, setDeletePosition] = useState<any>(null);

    const [isChangePosition, setChangePosition] = useState(false);

    const getListPositions = async (per_page = 0) => {
        try {
            const response = await positionService.getListPositions({
                per_page,
            });
            // console.log(response)
            if (response !== null && response !== undefined) {
                setPositionsList(response.positions);
                resetServerContext();
            }
        } catch (err) {
            console.log('err', err);
        }
    };
    // const handleDeletePosition = async() => {
    //   try {
    //     const response = await positionService.deletePositions(deletePosition.id)
    //     if(response !== null && response !== undefined) {
    //       setChangePosition((prevState) => !prevState)
    //     }
    //   } catch (err) {
    //     const response = err?.response;
    //     alertError(response.data?.error)
    //   } finally {
    //     closeDelete()
    //   }
    // }

    const handleDrop = async (droppedItem) => {
        if (!droppedItem.destination) return;
        var updatedList = [...positionsList];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // console.log(reorderedItem)
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        setPositionsList(updatedList);
        let body = [
            {
                id: reorderedItem.id,
                sort_index:
                    positionsList[updatedList.indexOf(reorderedItem)]
                        .sort_index,
            },
            {
                id: positionsList[updatedList.indexOf(reorderedItem)].id,
                sort_index: reorderedItem.sort_index,
            },
        ];
        try {
            const response = await positionService.reorderPositions({
                positions: body,
            });
            if (response !== null && response !== undefined) {
                setChangePosition((prevState) => !prevState);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        getListPositions();
    }, [isChangePosition]);

    useEffect(() => {
        if (!openEdit) {
            setSelectedPosition(null);
        }
    }, [openEdit]);

    useEffect(() => {
        if (!openDelete) {
            setSelectedPosition(null);
        }
    }, [openDelete]);

    return (
        <>
            {openCreate && (
                <CreatePositionDialog
                    open={openCreate}
                    setOpen={setOpenCreate}
                    onFinish={() => {
                        getListPositions();
                        setOpenCreate(false);
                    }}
                />
            )}
            {openEdit && selectedPosition && (
                <EditPositionDialog
                    open={openEdit}
                    setOpen={setOpenEdit}
                    onFinish={() => {
                        getListPositions();
                        setOpenEdit(false);
                    }}
                    selectedPosition={selectedPosition}
                />
            )}
            {openDelete && selectedPosition && (
                <DeletePositionDialog
                    open={openDelete}
                    setOpen={setOpenDelete}
                    onFinish={() => {
                        getListPositions();
                        setOpenDelete(false);
                    }}
                    selectedPosition={selectedPosition}
                />
            )}
            <div className="container-department d-flex flex-row">
                <div className="department-content d-flex flex-column">
                    <div
                        className="create-button d-flex flex-row"
                        style={{ height: '73px' }}
                    >
                        <button
                            className="create-user-button d-flex flex-row"
                            onClick={() => setOpenCreate(true)}
                        >
                            <img
                                width={'16px'}
                                height={'16px'}
                                src={plus}
                            ></img>
                            新規作成
                        </button>
                    </div>
                    <div className="table-departments d-flex flex-column">
                        <div className="table-content d-flex flex-column">
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 700 }}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow"
                                                align="center"
                                                style={{ width: '5%' }}
                                            ></StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow"
                                                align="center"
                                                style={{ width: '70%' }}
                                            >
                                                役職名
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow"
                                                align="center"
                                                style={{ width: '25%' }}
                                            >
                                                操作
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <DragDropContext onDragEnd={handleDrop}>
                                        <Droppable droppableId="list-container">
                                            {(provided) => (
                                                <TableBody
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {positionsList !== null &&
                                                        positionsList.map(
                                                            (row, ind) => (
                                                                <Draggable
                                                                    key={row.id}
                                                                    draggableId={
                                                                        row.name
                                                                    }
                                                                    index={ind}
                                                                >
                                                                    {(
                                                                        provided,
                                                                    ) => (
                                                                        <StyledTableRow
                                                                            ref={
                                                                                provided.innerRef
                                                                            }
                                                                            {...provided.dragHandleProps}
                                                                            {...provided.draggableProps}
                                                                        >
                                                                            <StyledTableCell
                                                                                className="text-overflow"
                                                                                align="left"
                                                                                component="th"
                                                                                scope="row"
                                                                            >
                                                                                <img
                                                                                    width={
                                                                                        '20px'
                                                                                    }
                                                                                    height={
                                                                                        '20px'
                                                                                    }
                                                                                    src={
                                                                                        frame
                                                                                    }
                                                                                ></img>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell
                                                                                className="text-overflow"
                                                                                align="left"
                                                                                component="th"
                                                                                scope="row"
                                                                            >
                                                                                <div className="department-row">
                                                                                    {
                                                                                        row.name
                                                                                    }
                                                                                </div>
                                                                            </StyledTableCell>

                                                                            <StyledTableCell
                                                                                className="text-overflow"
                                                                                align="center"
                                                                            >
                                                                                <button
                                                                                    className="button-tb"
                                                                                    style={{
                                                                                        width: '72px',
                                                                                        margin: '5px',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setOpenEdit(
                                                                                            true,
                                                                                        );
                                                                                        setSelectedPosition(
                                                                                            row,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    編集
                                                                                </button>
                                                                                <button
                                                                                    className="button-tb"
                                                                                    style={{
                                                                                        width: '72px',
                                                                                        margin: '5px',
                                                                                        background:
                                                                                            '#FF5045',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setOpenDelete(
                                                                                            true,
                                                                                        );
                                                                                        setSelectedPosition(
                                                                                            row,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    削除
                                                                                </button>
                                                                            </StyledTableCell>
                                                                        </StyledTableRow>
                                                                    )}
                                                                </Draggable>
                                                            ),
                                                        )}
                                                    {positionsList?.length <= 0 && (
                                                        <tr>
                                                            <StyledTableCell colSpan={6}>
                                                                <div className="text-center py-[40px]">
                                                                    <EmptyImage classNameCustom='inline-block' />
                                                                    <div className='text-[#00000040] text-sm mt-[6px]'>データがありません</div>
                                                                </div>
                                                            </StyledTableCell>
                                                        </tr>
                                                    )}
                                                </TableBody>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Positions;
