import styled from 'styled-components';

export const StylesWorkerContainer = styled.div`
    .select-search__option {
        max-width: 460px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .select-search__input {
        padding: 10px 22px 10px 12px;
    }
    .select-search__value::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(50% - 6px);
        right: 14px;
        width: 8px;
        height: 8px;
    }
    .select-search__option.is-selected {
        background: #f2f9fc;
        color: #333
    }
    .select-search:not(.select-search--multiple) .select-search__input:hover {
        border-color: #CDD1D5;
    }
    .select-search__option.is-highlighted, .select-search__option:not(.is-selected):hover {
        background: #f2f9fc;
    }
`;
