import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import workersService from 'services/wokersService';
import WorkingTimeService from 'services/workingTimesService';
import { alertError } from 'utils/helper/appHelper';
import { ObjectToFormData } from 'utils/helper/helpers';
interface IParamItemPair {
    submit_date: Date | string;
    worker_id: number | string;
    checkin_item_id: number;
    checkout_item_id?: number;
}

interface IItemPair {
    check_in_time?: string | Date;
    check_out_time?: string | Date;
    checkin_item_id?: number;
    checkin_location?: string;
    checkout_item_id?: number;
    checkout_location?: string;
    submit_date?: string | Date;
    worker_name?: string;
}
const useWorkingTimesManagementsCreate = (props: any) => {
    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => createWorkingTime(values),
    });
   
    const [workerOptions, setWorkerOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startTimeDetail, setStartTimeDetail] = useState<string | Date | undefined>();
    const [endTimeDetail, setEndTimeDetail] = useState<string | Date | undefined>();
    const [isErrorWorker, setIsErrorWorker] = useState(false); 
    const [isErrorSubmitDate, setIsErrorSubmitDate] = useState(false);
    const [isErrorStartDate, setIsErrorStartDate] = useState(false);
    const [isErrorEndDate, setIsErrorEndDate] = useState(false);
    const [itemPairDetail, setItemPairDetail] = useState<IItemPair>();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const location = useLocation();
    const locationState = (location.state as IParamItemPair);

    const fetchWorkers = async () => {
        try {
            const { workers } = await workersService.getListWorkers();
            if (workers) {
                setWorkerOptions(
                    workers?.map((worker) => ({
                        label: worker.name,
                        value: worker.id,
                    })),
                );
            }
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

   
   
    const createWorkingTime = async (params) => {
        if(!params?.worker_id) {
            setIsErrorWorker(true);
        }
        if(!params?.submit_date) {
            setIsErrorSubmitDate(true)
        }
        if(!params?.start_time) {
            setIsErrorStartDate(true)
        }
        if(!params?.end_time) {
            setIsErrorEndDate(true)
        }
        if(!params?.worker_id || !params?.start_time || !params?.end_time) return;
        if(new Date(params?.start_time) > new Date(params?.end_time)) return;
        const today = new Date().setHours(0, 0, 0, 0)
        if(new Date(params?.submit_date)?.setHours(0, 0, 0, 0) > today || new Date(params?.start_time)?.setHours(0, 0, 0, 0) > today || new Date(params?.end_time)?.setHours(0, 0, 0, 0) > today) return;
        try {
            if(!id) {
                const formData = ObjectToFormData({ ...params, worker_id: params?.worker_id?.value });
                await WorkingTimeService.createWorkingTimes(formData);
            } else {
                let newParams = {...params, submit_date: moment(params?.start_time).format('YYYY-MM-DD')}
                if(startTimeDetail && new Date(params?.start_time).getTime() == new Date(startTimeDetail).getTime()) {
                    delete newParams?.start_time
                } else if(endTimeDetail && new Date(params?.end_time).getTime() == new Date(endTimeDetail).getTime()) {
                    delete newParams?.end_time
                }
                
                const formData = ObjectToFormData({ ...newParams, worker_id: params?.worker_id?.value, id: id, set_checkout: !itemPairDetail?.check_out_time ? true : false});
              
                const cloneParamsCheckin = {...newParams}
                delete cloneParamsCheckin?.end_time
                const cloneParamsCheckout = {...newParams, set_checkout: !itemPairDetail?.check_out_time ? true : false}
                delete cloneParamsCheckout?.start_time

                if ((endTimeDetail && new Date(params?.end_time).getTime() !== new Date(endTimeDetail).getTime() && startTimeDetail && new Date(params?.start_time).getTime() !== new Date(startTimeDetail).getTime()) || (startTimeDetail && !endTimeDetail && new Date(params?.start_time).getTime() !== new Date(startTimeDetail).getTime() && params?.end_time)) {
                    await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkin_item_id, ObjectToFormData({ ...cloneParamsCheckin, worker_id: params?.worker_id?.value, id: id }));
                    await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkout_item_id, ObjectToFormData({ ...cloneParamsCheckout, worker_id: params?.worker_id?.value, id: id }));
                } else if (startTimeDetail && new Date(params?.start_time).getTime() !== new Date(startTimeDetail).getTime()) {
                    await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkin_item_id, formData);
                } else if((endTimeDetail && new Date(params?.end_time).getTime() !== new Date(endTimeDetail).getTime()) || (startTimeDetail && !endTimeDetail && new Date(params?.start_time).getTime() === new Date(startTimeDetail).getTime())) {
                    await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkout_item_id, formData);
                } else {
                    await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkin_item_id, ObjectToFormData({ ...cloneParamsCheckin, worker_id: params?.worker_id?.value, id: id }));
                    await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkout_item_id, ObjectToFormData({ ...cloneParamsCheckout, worker_id: params?.worker_id?.value, id: id }));
                }
            }
            navigate('/working_times_management');
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchWorkingTimeDetail = async () => {
        if (!id || !locationState?.checkin_item_id) return;
        try {
            setLoading(true)
            let params = {
                //worker_id: locationState?.worker_id,
                //submit_date: locationState?.submit_date,
                checkin_item_id: locationState?.checkin_item_id,
                checkout_item_id: locationState?.checkout_item_id
            }
            if(!locationState?.checkout_item_id) delete params.checkout_item_id;
            const res = await WorkingTimeService.getWorkingTimeItemPair(params);
            if(!(res as {result: IItemPair[]})?.result) return;
            const working_time_management = (res as {result: IItemPair[]})?.result[0];
            formik.resetForm({
                values: {
                    checkin_location: working_time_management?.checkin_location || '',
                    checkout_location: working_time_management?.checkout_location || '',
                    start_time: working_time_management?.check_in_time,
                    end_time: working_time_management?.check_out_time,
                    submit_date: working_time_management?.submit_date,
                    worker_id: {
                        label: working_time_management?.worker_name,
                        value: locationState?.worker_id
                    }
                }
            });
            setStartTimeDetail(working_time_management?.check_in_time)
            setEndTimeDetail(working_time_management?.check_out_time)
            setItemPairDetail(working_time_management)
            setLoading(false)
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    useEffect(() => {
        fetchWorkers();
    }, []);

    useEffect(() => {
        fetchWorkingTimeDetail();
    }, []);

    return {
        ...props,
        formik,
        navigate,
        workerOptions,
        loading,
        isErrorSubmitDate,
        isErrorWorker,
        isErrorStartDate,
        isErrorEndDate
    };
};

export type Props = ReturnType<typeof useWorkingTimesManagementsCreate>;

export default useWorkingTimesManagementsCreate;
