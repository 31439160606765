import { useMemo, useState } from 'react';
import useChatBoxPrivateMessage, { Props } from './hook';
import { Box, Button, Typography, Stack } from '@mui/material';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { BiImageAdd, BiSend } from 'react-icons/bi';
import { TiCancel } from 'react-icons/ti';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
} from '@chatscope/chat-ui-kit-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';

type TVideo = {
    signed_id: string;
    video_path: string;
}
type TImage = {
    signed_id: string;
    image_path: string;
}

interface ISender {
    id: number;
    name: string;
}
interface IMessage {
    body: string | undefined;
    id: number;
    images: TImage[] | null;
    is_owner: boolean | null;
    is_read: boolean | null;
    is_system: boolean | null;
    publish_at: string | undefined;
    sender: ISender;
    videos: TVideo[] | File[] | null;
}

const ChatBoxPrivateMessageLayout = ({
    listMessage,
    userData,
    activeMessage,
    unreadFlag,
    handleFileChange,
    handleReadChat,
    setActiveMesssage,
    removeImage,
    onSubmit,
    loading,
    isLoading,
    getLanguage,
    listErrorFile
}: Props) => {
    const { t } = useTranslation();
    const [limit, setLimit] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    const onYReachStart = () => {
        if (loadingMore === true) {
            return;
        }
        setLoadingMore(true);
        setTimeout(() => {
            setLimit(limit+5);
            setLoadingMore(false);
        }, 1500);
    };

    const listMessageItem = useMemo(() => {
        return listMessage?.slice(-limit)?.map((message: IMessage) => (
            <>
                {!message?.is_system ? (
                    <>
                        <Message
                            key={message.id}
                            model={{
                                message: message.body,
                                sender: message?.sender?.name,
                                sentTime: message?.publish_at,
                                direction:
                                    message?.sender?.id === userData?.worker?.id
                                        ? 'outgoing'
                                        : 'incoming',
                                position: 'first',
                                type: 'custom',
                            }}
                        >
                            <Message.CustomContent>
                                <div className="message">
                                    <p className="mb-0">
                                        {message?.body || ''}
                                    </p>

                                    {message?.images &&
                                        Object.values(message?.images).map(
                                            (img: any, id) => (
                                                <img
                                                    src={img?.image_path || URL.createObjectURL(img)}
                                                    style={{
                                                        marginTop: '5px',
                                                        width: '324px',
                                                    }}
                                                    key={id}
                                                />
                                            ),
                                        )}
                                    {message?.videos &&
                                        Object.values(message?.videos).map(
                                            (video, id) => (
                                                <video
                                                    controls
                                                    style={{
                                                        marginTop: '5px',
                                                        width: '324px',
                                                        cursor: 'pointer',
                                                    }}
                                                    key={id}
                                                >
                                                    <source
                                                        src={video?.video_path || URL.createObjectURL(video)}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            ),
                                        )}
                                </div>
                            </Message.CustomContent>
                        </Message>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent:
                                    message?.sender?.id === userData?.worker?.id
                                        ? 'end'
                                        : 'start',
                            }}
                            className="message-footer"
                        >
                            <p className="text-xs text-[#666666] mr-1">
                                {message?.sender?.name || ''}
                            </p>
                            <p className="text-xs text-[#666666]">
                                {message.publish_at &&
                                    moment(message.publish_at)
                                        .zone('+09:00')
                                        .format('YYYY/MM/DD HH:mm:ss')}
                            </p>
                        </div>
                    </>
                ) : (
                    <div className="flex justify-center mt-2">
                        <div className="message bg-[#f4f4f4] py-1 px-2 text-[#666666] rounded-md flex text-xs justify-center flex-col items-center">
                            <p className="mb-0">
                                {message.publish_at &&
                                    moment(message.publish_at)
                                        .zone('+09:00')
                                        .format('YYYY/MM/DD HH:mm:ss')}
                            </p>
                            <p className="mb-0">{message?.body || ''}</p>
                        </div>
                    </div>
                )}
            </>
        ));
    }, [listMessage]);
    return (
        <Box
            className="flex flex-col justify-between h-full"
            borderRadius="8px"
        >
            <Box padding="15px">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '16px',
                    }}
                >
                    <Button
                        onClick={() => {
                            handleReadChat();
                            setActiveMesssage(null);
                        }}
                        sx={{
                            color: '#215493',
                            width: '100px',
                            fontWeight: 600,
                        }}
                        variant="outlined"
                    >
                        戻る
                    </Button>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#F3F3F3',
                        padding: '14px',
                        borderRadius: '10px',
                    }}
                >
                    <Typography fontWeight="500" fontSize={16}>
                        {
                            activeMessage?.workers?.find(
                                (worker) => worker.id !== userData.worker.id,
                            )?.name
                        }
                    </Typography>
                </Box>
                {/* <WhoCanJoin chat={activeMessage} /> */}
            </Box>
            <Box
                border="1px solid #e3e5e8"
                sx={{
                    borderRadius: '8px',
                    position: 'relative',
                }}
            >
                {/* <ActionCableConsumer
                    channel={{
                        channel: 'ChatroomChannel',
                        chat_id: activeMessage.id,
                    }}
                    onReceived={handleReceived}
                > */}
                <Box
                    marginTop="15px"
                    height="350px"
                    sx={{
                        '.cs-main-container': {
                            border: 'none;',
                            '.cs-message-list__scroll-wrapper': {
                                padding: '0px 16px !important',
                            },

                            borderTopRightRadius: '8px',
                            borderTopLeftRadius: '8px',
                        },
                        '.cs-message__content': {
                            maxWidth: '274.5px',
                        },
                    }}
                >
                    <MainContainer>
                        <ChatContainer id="chat-container">
                            <MessageList autoScrollToBottomOnMount={true} loadingMore={loadingMore} onYReachStart={onYReachStart}>
                                {loading ? (
                                    <div className="h-full flex items-center justify-center">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    listMessageItem
                                )}
                            </MessageList>
                        </ChatContainer>
                    </MainContainer>
                </Box>
                <Box
                    sx={{
                        form: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            bgcolor: 'rgb(244,247,249)',
                            position: 'relative',
                            padding: '0px 20px 10px 20px',
                            borderBottomRightRadius: '8px',
                            borderBottomLeftRadius: '8px',
                        },
                        input: {
                            outline: 'none',
                            paddingLeft: '12px',
                        },
                        '.preview-img-upload': {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            position: 'absolute',
                            bottom: '88px',
                            left: '50px',
                            zIndex: '1000',
                            top: '-40px',
                            img: {
                                width: '40px',
                                height: '40px',
                                borderRadius: '6px',
                                marginRight: '5px',
                            },
                            video: {
                                width: '40px',
                                height: '40px',
                                borderRadius: '6px',
                                marginRight: '5px',
                            },
                        },
                        '.img-upl-ct': {
                            position: 'relative',
                        },
                        '.delete-image-upl': {
                            position: 'absolute',
                            top: '-7px',
                            right: 0,
                            color: 'red',
                            cursor: 'pointer',
                        },
                        '.btnSend[disabled]': {
                            opacity: 0.5,
                        },
                        '.btnSend:disabled': {
                            opacity: 0.5,
                        }
                    }}
                >
                    <Formik
                        initialValues={{
                            message: '',
                            file: [],
                        }}
                        onSubmit={(values) => onSubmit(values)}
                    >
                        {({ values, handleSubmit, setFieldValue }) => (
                            <>
                                <Stack
                                    sx={{
                                        padding: '10px 10px 15px 10px',
                                        width: '100%',
                                        maxWidth: '768px',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: '10px',
                                        overflowX: 'scroll',
                                        backgroundColor: 'rgb(244,247,249)',
                                        overflowY: 'hidden',
                                        '.item': {
                                            padding: '4px 8px',
                                            width: 'max-content',
                                            minWidth: 'max-content',
                                            backgroundColor: 'white',
                                            borderRadius: '4px',
                                            fontSize: '14px',
                                        },
                                    }}
                                    direction="row"
                                    className="options"
                                >
                                    {[...Array(7).keys()].map((_, i) => (
                                        <span
                                            key={i}
                                            className="item"
                                            onClick={() => {
                                                setFieldValue(
                                                    'message',
                                                    t(`chat.ja.text${i + 1}`),
                                                );
                                            }}
                                        >
                                            {t(
                                                `chat.${getLanguage(
                                                    navigator.language,
                                                )}.text${i + 1}`,
                                            )}
                                        </span>
                                    ))}
                                </Stack>
                                <Form>
                                    <label
                                        className="upload-image-chat cursor-pointer"
                                        htmlFor="fileInput"
                                    >
                                        <input
                                            onChange={(e) =>
                                                handleFileChange(
                                                    e,
                                                    values,
                                                    setFieldValue,
                                                )
                                            }
                                            id="fileInput"
                                            name="file"
                                            type="File"
                                            multiple
                                            accept="image/*, video/*"
                                            style={{ display: 'none' }}
                                            onClick={(e) => (e.target as HTMLInputElement).value = ""}
                                        ></input>

                                        <BiImageAdd size={20} />
                                    </label>
                                    <Field
                                        onKeyPress={(e) => {
                                            if (e.code === 'Enter') {
                                                e.preventDefault();
                                                if(listErrorFile?.length > 0) return;
                                                handleSubmit();
                                            }
                                        }}
                                        value={values.message}
                                        className="input-custom-chat w-[80%] h-[40px]"
                                        type="text"
                                        placeholder="メッセージを入力..."
                                        autoFocus
                                        name="message"
                                    />
                                    <button
                                        disabled={isLoading || listErrorFile?.length > 0}
                                        style={{
                                            bottom: '16px',
                                            zIndex: 100000,
                                            right: '16px',
                                        }}
                                        type="submit"
                                        className='btnSend'
                                    >
                                        <BiSend size={25} />
                                    </button>
                                    <div className="preview-img-upload !top-[-91px]">
                                        {values?.file?.length > 0 &&
                                            values?.file.map((item: File, id) => {
                                                return (
                                                    <div className="img-upl-ct">
                                                        {
                                                            item?.type?.includes('image/')
                                                                ?
                                                                <img
                                                                    src={URL.createObjectURL(
                                                                        item,
                                                                    )}
                                                                    key={id}
                                                                />
                                                                :
                                                                <video
                                                                    className="img-container-edit"
                                                                    controls
                                                                >
                                                                    <source
                                                                        src={URL.createObjectURL(item)}
                                                                        type="video/mp4"
                                                                        className="image-ads-edit"
                                                                    />
                                                                </video>
                                                        }
                                                        <TiCancel
                                                            onClick={() =>
                                                                removeImage(
                                                                    id,
                                                                    values,
                                                                    setFieldValue,
                                                                )
                                                            }
                                                            size={15}
                                                            className="delete-image-upl"
                                                        />
                                                    </div>
                                                );
                                            })}
                                        {listErrorFile?.length > 0 && <p className='text-[#ff3333] mt-[2.5em] text-[10px]'>10MB以上がアップロードできません。</p>}
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Box>
                {/* </ActionCableConsumer> */}
            </Box>
        </Box>
    );
};
const ChatBoxPrivateMessage = (props: any) => {
    return <ChatBoxPrivateMessageLayout {...useChatBoxPrivateMessage(props)} />;
};

export default ChatBoxPrivateMessage;
