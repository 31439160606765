import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import estimateService from 'services/estimationService';
import projectsService from 'services/projectsService';
import userService from 'services/userService';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import estimateItemService from 'services/estimateItemService';
import _ from 'lodash';
import { useModal } from 'react-hooks-use-modal';

export type ReceivedProps = Record<string, never>;

const useEstimateDetail = (props: ReceivedProps) => {
    const { id = null, estimationId = null } = useParams();
    const [project, setProject] = useState<any>({});
    const [estimate, setEstimate] = useState<any>({});
    const [statusOptions, setStatusOptions] = useState<any>([]);
    const [fileData, setFileData] = useState<string | null>();
    const [dataEstimateItem, setDataEstimateItem] = useState<any>([]);
    const [rowData, setRowData] = useState<any>({});
    const [detailEstimateItem, setDetailEstimateItem] = useState<any>({});
    const [ModalCreateEstimateItem, openCreateEstimateItem, closeCreateEstimateItem, isOpen] = useModal('root', {
        preventScroll: true,
        closeOnOverlayClick: false,
    });

    const getProjectDetail = async () => {
        if (!id) return;
        try {
            const response = await projectsService.getProject(Number(id))
            if (response !== null && response !== undefined) {
                setProject(response.project)
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    const getDetailEstimate = async (estimationId) => {
        if (!estimationId || !id) return;
        await estimateService.getDetailEstimate(Number(estimationId), Number(id))
            .then(response => {
                setEstimate(response.estimate)
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    const getListConfiguration = async () => {
        try {
            const response = await userService.getListConfiguration()
            if (response !== null && response !== undefined) {
                setStatusOptions(
                    convertUserConfigToDropdownOptions(
                        response.configurations?.estimate?.statuses
                    )
                )
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    const handleViewFile = async (values) => {
        try {
            const response = await fetch(values?.url);
            const data = await response.blob();
            const file = window.URL.createObjectURL(data);
            values?.url ? setFileData(file) : setFileData(URL.createObjectURL(values));
        } catch (error) { }
    };

    const getDetailEstimateItem = async (input) => {
        await estimateItemService.getDetailEstimateItem(input)
            .then(response => {
                setDetailEstimateItem(_.get(response, 'estimate_item', {}))
            })
            .catch(error => {
                setDetailEstimateItem({})

            })
    }

    const getListEstimateChildItem = (child_estimate_items_id: string) => {
        getDetailEstimateItem({
            project_id: String(id),
            estimate_id: estimationId,
            id: child_estimate_items_id
        });
    }

    const goBackDetailParentEstimate = () => {
        // update array estimate
        dataEstimateItem.pop();
        setDataEstimateItem(dataEstimateItem);

        // update object children estimate item
        const row = _.size(dataEstimateItem) ? dataEstimateItem[_.size(dataEstimateItem) - 1] : {};
        setRowData(row);

        // call api detail estimate
        if (row?.id) getListEstimateChildItem(row?.id);
        else getDetailEstimate(Number(estimationId));
    }

    const onGetDataChildrenEstimateItem = (row) => {
        setRowData(row)
        setDataEstimateItem([...dataEstimateItem, { ...row }]);
        getListEstimateChildItem(row?.id)
    }

    const convertTZ = (date, tzString) => {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("ja-JP", { timeZone: tzString }));
    }

    useEffect(() => {
        if (!id) return;
        getProjectDetail();
    }, [id])

    useEffect(() => {
        if (!estimationId || !id) return;
        getDetailEstimate(estimationId)
    }, [estimationId, id]);

    useEffect(() => {
        getListConfiguration();
    }, [])
    return {
        ...props,
        id,
        estimationId,
        project,
        estimate,
        getProjectDetail,
        handleViewFile,
        fileData,
        setFileData,
        statusOptions,
        rowData,
        dataEstimateItem,
        detailEstimateItem,
        openCreateEstimateItem,
        goBackDetailParentEstimate,
        onGetDataChildrenEstimateItem,
        convertTZ,
    };
};

export type Props = ReturnType<typeof useEstimateDetail>;

export default useEstimateDetail;
