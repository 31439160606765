import {useState, useEffect} from 'react'
import './workerDetail.css'
import { useNavigate, useParams } from "react-router-dom";
import workersService from "../../../services/wokersService";

const WorkerDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [worker, setWorker] = useState<any>(null)

    const getWorkerInf = async (worker_id) => {
        try {
            const response = await workersService.getWorker(worker_id)
            // console.log(response)
            if(response !== null && response !== undefined) {
              setWorker(response.worker)
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    useEffect(() => {
      if (id) {
        getWorkerInf(id);
      }
    }, [id]);

    
    return(
        <div className='container-worker-detail-f d-flex flex-row'>
            <div className='worker-detail-content-f d-flex flex-column'>
                <div className="worker-detail-name-title d-flex flex-column">{worker?.name}</div>
                <div className="worker-detail-inf-f d-flex flex-column">
                    <div className="worker-detail-inf-row-f d-flex flex-row">
                        <div className="worker-detail-inf-row-f-title" style={{borderTopLeftRadius: 5}}>名前</div>
                        <div className="worker-detail-inf-row-f-content">{worker?.name}</div>
                    </div>
                    <div className="worker-detail-inf-row-f d-flex flex-row">
                        <div className="worker-detail-inf-row-f-title">フリガナ</div>
                        <div className="worker-detail-inf-row-f-content">{worker?.kana}</div>
                    </div>
                    <div className="worker-detail-inf-row-f d-flex flex-row">
                        <div className="worker-detail-inf-row-f-title">性別</div>
                        <div className="worker-detail-inf-row-f-content">{worker?.gender_type}</div>
                    </div>
                    <div className="worker-detail-inf-row-f d-flex flex-row">
                        <div className="worker-detail-inf-row-f-title">協力会社</div>
                        <div className="worker-detail-inf-row-f-content">{worker?.cooperative_company_name}</div>
                    </div>
                    <div className="worker-detail-inf-row-f d-flex flex-row">
                        <div className="worker-detail-inf-row-f-title">部署</div>
                        <div className="worker-detail-inf-row-f-content">{worker?.department?.name}</div>
                    </div>
                    <div className="worker-detail-inf-row-f d-flex flex-row">
                        <div className="worker-detail-inf-row-f-title">役職</div>
                        <div className="worker-detail-inf-row-f-content">{ worker?.position?.name}</div>
                    </div>
                    <div className="worker-detail-inf-row-f d-flex flex-row">
                        <div className="worker-detail-inf-row-f-title">携帯電話 </div>
                        <div className="worker-detail-inf-row-f-content">{worker?.celphone}</div>
                    </div>
                    <div className="worker-detail-inf-row-f d-flex flex-row">
                        <div className="worker-detail-inf-row-f-title">FAX</div>
                        <div className="worker-detail-inf-row-f-content">{worker?.fax}</div>
                    </div>
                    <div className="worker-detail-inf-row-f d-flex flex-row">
                        <div className="worker-detail-inf-row-f-title">Eメール</div>
                        <div className="worker-detail-inf-row-f-content">{worker?.email}</div>
                    </div>

                    <div className="worker-detail-inf-row-f-3 d-flex flex-row">
                        <div className="worker-detail-inf-row-f-title" style={{height: '168px'}}>住所</div>
                        <div className='worker-detail-inf-row-f-content-all d-flex flex-column' style={{height: '168px'}}>
                            <div className="worker-detail-inf-row-f d-flex flex-row">
                                <div className="worker-detail-inf-row-f-title bg-w">電話番号</div>
                                <div className="worker-detail-inf-row-f-content">{worker?.phone}</div>
                            </div>

                            <div className="worker-detail-inf-row-f d-flex flex-row">
                                <div className="worker-detail-inf-row-f-title bg-w">郵便番号</div>
                                <div className="worker-detail-inf-row-f-content">{worker?.zipcode}</div>
                            </div>

                            <div className="worker-detail-inf-row-f d-flex flex-row">
                                <div className="worker-detail-inf-row-f-title bg-w">住所</div>
                                <div className="worker-detail-inf-row-f-content">{worker?.address}</div>
                            </div>
                        </div>
                    </div>
                    <div className="worker-detail-inf-row-f d-flex flex-row">
                        <div className="worker-detail-inf-row-f-title" style={{borderBottomLeftRadius: 5, borderBottom: 'none'}}>誕生日</div>
                        <div className="worker-detail-inf-row-f-content">{worker?.birthday}</div>
                    </div>
                </div>
                <div className="worker-detail-footer-f d-flex flex-row">
                    <button className='back-button d-flex flex-row' onClick={() => navigate('/workers')}>
                    戻る
                    </button>
                </div>
            </div>
        </div>
        );
}

export default WorkerDetail;