import { useEffect, useState } from 'react';
import { Input, Popover } from 'antd';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import uploadSimple from 'assets/icons/UploadSimple.svg';
import { StyledPdfDocoments, StyledDialog } from './styled';
import projectsService from 'services/projectsService';
import DeleteIcon from 'assets/icons/Delete.svg';
import DownloadIcon from 'assets/icons/Dowload.svg';
import EditIcon from 'assets/icons/Edit.svg';
import ViewIcon from 'assets/icons/View.svg';
import PdfIcon from 'assets/icons/pdf-icon.svg';
import EditPdfDocumentDialog from './components/EditPdfDialog';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import DeletePdfDocumentDialog from './components/DeletePdfDialog';
import TableCSV from './components/TableCSV';
import DocIcon from 'assets/icons/docx.svg';
import ExcelIcon from 'assets/icons/xlsx.svg';

const { Search } = Input;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#C7DCF5',
    color: '#1C1E21',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontWeight: 600,
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: 'Zen Kaku Gothic Antique',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: '#1C1E21',
    padding: '8px 8px',
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IPdfInfo {
  filename: string;
  signed_id: string;
  url: string;
  size: number;
  upload_date: Date | string;
  fileNameConvert?: string;
  type?: string;
}

const PdfDocuments = ({ projectID, className = '' }) => {
  const [showErrorFileSize, setShowErrorFileSize] = useState(false);
  const [errorTypeFile, setErrorTypeFile] = useState(false);
  const [errorTotalFiles, setErrorTotalFiles] = useState(false);
  const [listPdfs, setListPdfs] = useState<IPdfInfo[]>([]);
  const [fileSelected, setFileSelected] = useState<IPdfInfo>();
  const [fileEdit, setFileEdit] = useState<IPdfInfo>();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [disabledUpload, setDisabledUpload] = useState(false);
  const [fileDelete, setFileDelete] = useState<IPdfInfo>();

  const LIMIT_MB = 20 * 1024 * 1024;
  const QUANTITY_LIMIT_FILES = 50;

  const handleGetPdfDocuments = async (text = '') => {
    const res = await projectsService.getPdfDocuments(projectID, text);
    if (res?.data?.pdf_documents?.length > 0) {
      const result = res?.data?.pdf_documents?.map(item => {
        let fileText = '';
        if (item?.filename?.includes('.xlsx') || item?.filename?.includes('.docx')) {
          fileText = item?.filename?.slice(0, -5);
        } else if (item?.filename?.includes('.pdf') || item?.filename?.includes('.doc') || item?.filename?.includes('.xls') || item?.filename?.includes('.csv')) {
          fileText = item?.filename?.slice(0, -4);
        } else {
          fileText = item?.filename;
        }
        return { ...item, fileNameConvert: fileText }
      })
      setListPdfs(result);
    } else {
      setListPdfs([]);
    }
  }

  const handleFileUpload = async (e) => {
    let arrFiles: FileList | File[] = []
    arrFiles = Array.from(e.target.files);
   
    const findFilesBigger20MB = arrFiles?.filter((item) => item?.size > LIMIT_MB);
    const findFilesValid = arrFiles?.filter((item) => ((item?.type?.includes("pdf") || item?.type == "application/msword" || item?.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || item?.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || item?.type == "application/vnd.ms-excel" || item?.type == "text/csv") && item.size <= LIMIT_MB));
    setShowErrorFileSize(findFilesBigger20MB?.length > 0 ? true : false);

    const findFileWrongType = arrFiles?.filter((item) => !(item?.type?.includes("pdf") || item?.type == "application/msword" || item?.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || item?.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || item?.type == "application/vnd.ms-excel" || item?.type == "text/csv"));
    setErrorTypeFile(findFileWrongType?.length > 0 ? true : false);

    setErrorTotalFiles(arrFiles?.length + listPdfs?.length > QUANTITY_LIMIT_FILES ? true : false);

    if (listPdfs?.length >= QUANTITY_LIMIT_FILES || arrFiles?.length + listPdfs?.length > QUANTITY_LIMIT_FILES || findFileWrongType?.length > 0 || findFilesBigger20MB?.length > 0) return;

    if (findFilesValid?.length > 0 && projectID) {
      let formData = new FormData();
      findFilesValid?.forEach((file) => {
        formData.append('pdf_documents[]', file);
      })
      setDisabledUpload(true);
      const res = await projectsService.uploadPdfDocuments(projectID, formData);
      if (res) {
        handleGetPdfDocuments();
        setDisabledUpload(false);
        setShowErrorFileSize(false);
        setErrorTypeFile(false);
      }
      setDisabledUpload(false);
    }
  };

  const renderContentPreview = () => {
    if (!fileSelected?.url) return;
  
    if(fileSelected?.type == 'application/pdf') {
      return <Worker workerUrl={`${process.env.REACT_APP_WORKER_URL}`}>
      <Viewer
        fileUrl={fileSelected?.url}
        //defaultScale={SpecialZoomLevel.PageWidth}
      />
    </Worker>
    }
    if (fileSelected?.type == 'text/csv') {
      return <TableCSV fileCSV={fileSelected?.url} />
    }
    return <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileSelected?.url}`} width='100%' height='100%' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
  }

  const convertByteToMB = (size: number) => {
    if (!size) return 0;
    return (size / 1024 / 1024).toFixed(2);
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
  }

  const handleOpenEdit = (fileSelect: IPdfInfo) => {
    setOpenEdit(true);
    setFileEdit(fileSelect);
  }

  const handleOpenDelete = (fileSelect: IPdfInfo) => {
    setOpenDelete(true);
    setFileDelete(fileSelect);
  }

  const handleDownloadFile = (file: IPdfInfo) => {
    if (!file) return;
    saveAs(file?.url, `${file?.filename}`)
  }

  const renderIconWithTypeFile = (type) => {
    if (type == 'text/csv' || type == 'application/vnd.ms-excel' || type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return <img src={ExcelIcon} alt='excel icon' />
    } else if (type == 'application/pdf') {
      return <img src={PdfIcon} alt='pdf icon' />
    } else {
      return <img src={DocIcon} alt='docx icon' />
    }
  }

  useEffect(() => {
    if (projectID) {
      handleGetPdfDocuments();
    }
  }, [projectID])
  
  return <StyledPdfDocoments className={className}>
    {fileSelected && (
      <StyledDialog
        onClose={() => setFileSelected(undefined)}
        sx={{
          width: '100%',
          '.MuiPaper-root': {
            width: "100%",
            height: "100%",
            margin: '0px',
          },

          '.rpv-core__inner-container': {
            paddingBottom: '30px !important',
          },
        }}
        open={!!fileSelected}
      >
        <DialogTitle className='flex justify-end'>
          <span className='cursor-pointer' onClick={() => setFileSelected(undefined)}><CloseIcon /></span>
        </DialogTitle>
        <DialogContent>
          <table id='csv-data'></table>
          {
            renderContentPreview()
          }
        </DialogContent>
      </StyledDialog>
    )}

    <EditPdfDocumentDialog
      open={openEdit}
      onClose={handleCloseEdit}
      fileName={fileEdit?.fileNameConvert}
      projectId={projectID}
      signedId={fileEdit?.signed_id}
      onSucess={handleGetPdfDocuments}
    />

    <DeletePdfDocumentDialog
      open={openDelete}
      onClose={() => setOpenDelete(false)}
      projectId={projectID}
      signedId={fileDelete?.signed_id}
      onSucess={handleGetPdfDocuments}
      fileName={fileDelete?.fileNameConvert}
      size={fileDelete?.size}
      convertByteToMB={convertByteToMB}
      type={fileDelete?.type}
    />

    <div className='flex justify-between items-center'>
      <Search className='!w-[300px]' onSearch={(value) => handleGetPdfDocuments(value?.trim())} />
      <Popover zIndex={2000} title='' content={listPdfs?.length >= QUANTITY_LIMIT_FILES && !errorTotalFiles ? <p style={{ color: '#f21f31' }}>ファイル数が50を超えています</p> : ''}>
        <Button
          component="label"
          className={`uploadImageFileBtn ${disabledUpload || listPdfs?.length >= QUANTITY_LIMIT_FILES ? 'opacity-70 !cursor-not-allowed' : ''}`}
          style={{
            backgroundColor: '#215493',
            color: '#FFFFFF',
            fontFamily: 'Zen Kaku Gothic Antique',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            padding: '10px 18px',
            width: '166px',
            height: '32px',
            borderRadius: '2px',
          }}
          disabled={disabledUpload}
        >
          {disabledUpload ? <Spin className='flex items-center' indicator={<LoadingOutlined style={{ fontSize: 20, marginRight: '7px', color: '#ffffff' }} spin />} /> : <img
            src={uploadSimple}
            alt="upload icon"
            style={{ marginRight: '7px' }}
          />}

          <span>アップロード</span>
          <input
            type="file"
            hidden
            style={{
              display: 'none',
            }}
            accept="application/pdf, .doc, .docx,.xls,.xlsx, .csv"
            multiple
            onClick={(e) => (e.target as HTMLInputElement).value = ""}
            onChange={(e) =>
              handleFileUpload(e)
            }
            disabled={disabledUpload || listPdfs?.length >= QUANTITY_LIMIT_FILES}
          />
        </Button>
      </Popover>
    </div>
    {showErrorFileSize && <div className='text-[#f44336] flex justify-end mt-[3px]'>ファイルサイズが20mbを超えています</div>}
    {errorTypeFile && <div className='text-[#f44336] flex justify-end mt-[3px]'>PDF 、ワード、エクセルファイルをアップロードしてください</div>}
    {errorTotalFiles && <div className='text-[#f44336] flex justify-end mt-[3px]'>ファイル数が50を超えています</div>}

    <div className='mt-[18px]'>
      <TableContainer component={Paper} table-layout={'auto'}>
        <Table
          //sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell
                className="text-overflow1"
                align="left"
                style={{ width: '60%' }}
              >
                <div>ファイル名</div>
              </StyledTableCell>
              <StyledTableCell
                className="text-overflow1"
                align="center"
                style={{ width: '10%' }}
              >
                <div style={{ whiteSpace: 'nowrap' }}>
                  作成日付
                </div>
              </StyledTableCell>
              <StyledTableCell
                className="text-overflow1"
                align="center"
                style={{ width: '30%' }}
              >
                <div style={{ whiteSpace: 'nowrap' }}>
                  アクション
                </div>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listPdfs &&
              listPdfs.map((row, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      align="left"
                      component="th"
                      scope="row"
                      style={{ width: '60%' }}
                    >
                      <div className='flex items-center gap-x-[10px]'>
                        {renderIconWithTypeFile(row?.type)}
                        <div>
                          <p className='!mb-0'>{row?.fileNameConvert}</p>
                          <p className='!mb-0'>{convertByteToMB(row?.size)}MB</p>
                        </div>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      component="th"
                      scope="row"
                      style={{ width: '10%' }}
                    >
                      <div
                        className="text-overflow1"
                        style={{
                          whiteSpace:
                            'nowrap',
                        }}
                      >
                        {row?.upload_date ? moment(row?.upload_date).format('YYYY/MM/DD') : ''}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      component="th"
                      scope="row"
                      style={{ width: '30%' }}
                    >
                      <div className='flex items-center justify-center gap-x-[8px]'>
                        <img className='cursor-pointer' src={ViewIcon} alt='view icon' onClick={() => setFileSelected(row)} />
                        <img className='cursor-pointer' src={EditIcon} alt='edit icon' onClick={() => handleOpenEdit(row)} />
                        <img className='cursor-pointer' src={DownloadIcon} alt='download icon' onClick={() => handleDownloadFile(row)} />
                        <img className='cursor-pointer' src={DeleteIcon} alt='delete icon' onClick={() => handleOpenDelete(row)} />
                      </div>
                    </StyledTableCell>

                  </StyledTableRow>
                );
              })}

            {/*{estimatesList?.length <= 0 && (
            <tr>
              <StyledTableCell colSpan={7}>
                <div className="text-center py-[40px]">
                  <EmptyImage classNameCustom='inline-block' />
                  <div className='text-[#00000040] text-sm mt-[6px]'>データがありません</div>
                </div>
              </StyledTableCell>
            </tr>
          )}*/}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </StyledPdfDocoments>
}

export default PdfDocuments;
