import StyledUserSearchArea from "./styles";

interface IProps {
  onSearchName: any;
  onSearchEmail: any;
  onClick: any;
}

const UserSearch = (props: IProps) => {
  const { onSearchEmail, onSearchName, onClick } = props;

  return (
    <StyledUserSearchArea>
        <div className="search-fields-area">
            <div className='search-field-control'>
                <p>社員名</p>
                <input type="text" onChange={onSearchName}></input>
            </div>

            <div className='search-field-control'>
                <p>Eメール</p>
                <input type="text" onChange={onSearchEmail}></input>
            </div>
        </div>

        <div className='search-button-wrapper'>
            <button onClick={onClick}>
              検索
            </button>
        </div>
    </StyledUserSearchArea>
  )
}

export default UserSearch;