import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import moment from 'moment';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store/index';
import scheduleService from 'services/scheduleService';
import { getDateTimeUtc } from 'utils/helper/dateHelper';
import { uniqBy } from "utils/helper/helpers";
import { DataManager } from '@syncfusion/ej2-data';

export type ReceivedProps = {
    setOpenScheduleFormDialog: Dispatch<SetStateAction<boolean>>;
    setOpenDeleteConfirmationDialog: Dispatch<SetStateAction<boolean>>;
    setOpenPlanUpdateModal: Dispatch<SetStateAction<boolean>>;
    searchParam: any;
    openDeleteConfirmationDialog: boolean;
    openScheduleFormDialog: boolean;
    openPlanUpdateModal: boolean;
    workerOptions: {
        id: any;
        name: string;
    }[];
    activeTab: number;
};

export const SWITCHER_LIST = [
    {
        id: 'Day',
        name: '日',
    },
    {
        id: 'Week',
        name: '週',
    },
    {
        id: 'Month',
        name: '月',
    },
];

export interface CustomizedState {
    editingId: number;
}

const useSchedulerBox = (props: ReceivedProps) => {
    const { userData } = useSelector((state: RootState) => state.users);

    const [showDayList, setShowDayList] = useState<boolean>(false);
    const [currentViewName, setCurrentViewName] = useState<string>('Month');
    const [currentDate, setCurrentDate] = useState<any>();
    const [localScheduleDate, setLocalScheduleData] = useState<any[]>();
    const [currentItemId, setCurrentItemId] = useState<any>(null);
    const [scheduleList, setScheduleList] = useState<any>({
        data: [],
    });
    const [loading, setLoading] = useState<boolean>(true);
    const [isShowData, setIsShowData] = useState((localStorage.getItem("showDataSP") === "false" ? false : true));
    const [dataManager, setDataManager] = useState<DataManager>();
    const [filterWorker, setFilterWorker] = useState<any[]>([]);

    const commitChanges = () => {};

    const fetchSchedules = useCallback(async () => {
        // if (!userData.worker) return;

        const queryFilter = {
            ...props.searchParam,
            worker_id:
                props.activeTab === 0
                    ? [userData.worker?.id]
                    : props.searchParam.worker_id,
        };

        Object.keys(queryFilter).forEach((key) => {
            if ([undefined, null, ''].includes(queryFilter[key])) {
                delete queryFilter[key];
            }
        });

        try {
            setLoading(true);
            const { schedules, meta } = await scheduleService.getSchedulesSP({
                page: 1,
                per_page: 9999,
                ...queryFilter,
            });

            const plansData = meta.plans.map((item) => ({
                ...item,
                color: '#009F08',
                startDate: item.start_at,
                endDate: moment(item.end_at).add(1, 'day').format('YYYY-MM-DD'),
                allDay: true,
                projectName:
                    meta.projects.find((obj) => obj.id === item.project_id)
                        ?.name || '',
                title: meta.projects.find((obj) => obj.id === item.project_id)
                    ?.name || '',
            }));

            const projectWithBothStartAndEndDates = meta?.projects?.filter(item => item.start_at && item.end_at)
            const projectsData = projectWithBothStartAndEndDates?.map((item) => ({
                ...item,
                id: item?.id + 1,
                project_id: item?.id,
                color: '#195192',
                startDate: item?.start_at,
                endDate: item?.end_at,
                allDay: true,
                projectName: item?.name,
                isProject: true,
                title: item?.name,
            }));
            
            const schedulesData = schedules.map((item) => ({
                ...omit(item, ['start_date', 'end_date']),
                startDate: getDateTimeUtc(item.start_date),
                endDate: currentViewName === 'Month' ? moment(item.end_date).add(30, 'm').utc().format() : getDateTimeUtc(item.end_date),
                endDate2: getDateTimeUtc(item.end_date),
                allDay: item.is_all_day,
                title2: item?.title
            }));

            const newArr = [...schedulesData, ...plansData, ...projectsData];

            setScheduleList({
                data: newArr.sort(
                    (d1, d2) =>
                        new Date(d1.startDate).getTime() -
                        new Date(d2.startDate).getTime(),
                ),
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, [userData, props.searchParam, props.activeTab, currentDate, currentViewName]);

    const deleteSchedule = async () => {
        try {
            await scheduleService.deleteScheduleSP(currentItemId);
            props.setOpenDeleteConfirmationDialog(false);
            props.setOpenScheduleFormDialog(false);
            fetchSchedules();
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        if (currentViewName === 'Month') {
            let counting = 0;
            let prevItemDate: any = null;

            let schedulerDataForMonth: any = [];
            let scheduleWorker: any = [];

            scheduleList.data.forEach((item) => {
                const currentItemDate = moment(item.startDate).format(
                    'YYYY-MM-DD',
                );

                if (currentItemDate === prevItemDate && counting < 2) {
                    counting += 1;
                    schedulerDataForMonth = [
                        ...schedulerDataForMonth,
                        {
                            ...item,
                            title: item.project_id || item.allDay
                                ? `${moment(item.startDate).format(
                                    'DD',
                                )}日 ~ ${moment(item.endDate).format(
                                    'DD',
                                )}日 ${item.projectName ? `${item.projectName} ~ ${item.name}` : `${item.title}`}`
                                : `${moment(item.startDate).format('DD')}日${moment(item.startDate).format('HH:mm')} - ${moment(item.endDate).format('DD')}日${moment(item.endDate2).format('HH:mm')} ${item.title
                                }`,
                        },
                    ];
                    scheduleWorker = [
                        ...scheduleWorker,
                        {
                            worker: item.worker && item.worker.id,
                        }
                    ]
                } else if (currentItemDate === prevItemDate && counting === 2) {
                    counting += 1;

                    const getCurrentDateLength = scheduleList.data.filter(
                        (childItem) =>
                            moment(childItem.startDate).format('YYYY-MM-DD') ===
                            currentItemDate,
                    );
                    schedulerDataForMonth = [
                        ...schedulerDataForMonth,
                        {
                            ...item,
                            title: `他の${getCurrentDateLength.length - 3}件`,
                            showWeekView: true,
                        },
                    ];
                } else if (currentItemDate !== prevItemDate) {
                    counting = 0;
                    prevItemDate = moment(item.startDate).format('YYYY-MM-DD');
                    schedulerDataForMonth = [
                        ...schedulerDataForMonth,
                        {
                            ...item,
                            title: item.project_id || item.allDay
                                ? `${moment(item.startDate).format(
                                    'DD',
                                )}日 ~ ${moment(item.endDate).format(
                                    'DD',
                                )}日 ${item.projectName ? `${item.projectName} ~ ${item.name}` : `${item.title}`}`
                                : `${moment(item.startDate).format('DD')}日${moment(item.startDate).format('HH:mm')} - ${moment(item.endDate).format('DD')}日${moment(item.endDate2).format('HH:mm')} ${item.title
                                }`,
                        },
                    ];
                    scheduleWorker = [
                        ...scheduleWorker,
                        {
                            worker: item.worker && item.worker.id,
                        }
                    ]
                }
            });

            setLocalScheduleData(schedulerDataForMonth);
            setFilterWorker(uniqBy(scheduleWorker, 'worker').filter(v => v.worker));
            const formattedEj2ScheduleList = schedulerDataForMonth.map((item) => {
                const arrWorkers: string[] = [];
                if (item?.estimate_assigned_worker_id) {
                    arrWorkers.push(item?.estimate_assigned_worker_id)
                }
                if (item?.manager_worker_id) {
                    arrWorkers.push(item?.manager_worker_id)
                }
                if(item?.assigned_worker_id) {
                    arrWorkers.push(item?.assigned_worker_id)
                }
                if (item.project_id) {
                    return {
                        ...item,
                        WorkerId: item.worker && item.worker.id,
                        Id: item.id,
                        Subject: item.projectName,
                        StartTime: item.startDate,
                        EndTime: item.endDate,
                        color: item.color || "#195192",
                        ConferenceId:
                            item?.worker_schedule_attributes?.length > 0
                                ? (item?.worker_schedule_attributes
                                    .map((v) => v.worker_id)
                                    .includes(item.worker && item.worker.id || -1)
                                    ? item?.worker_schedule_attributes
                                    : [
                                        ...item?.worker_schedule_attributes,
                                        ...[{
                                            id: item?.worker_schedule_attributes?.length + 1,
                                            name: item.worker && item.worker.name,
                                            worker_id: item.worker && item.worker.id
                                        }]]
                                )?.map((v) => v.worker_id)
                                : [item.worker && item.worker.id || -1].concat(arrWorkers),
                    };
                } else {
                    return {
                        ...item,
                        WorkerId: item.worker && item.worker.id,
                        Id: item.id,
                        Subject: item.title2,
                        StartTime: item.startDate,
                        EndTime: item.endDate,
                        color: item.color || "#195192",
                        ConferenceId:
                            item?.worker_schedule_attributes?.length > 0
                                ? (item?.worker_schedule_attributes
                                    .map((v) => v.worker_id)
                                    .includes(item.worker && item.worker.id || -1)
                                    ? item?.worker_schedule_attributes
                                    : [
                                        ...item?.worker_schedule_attributes,
                                        ...[{
                                            id: item?.worker_schedule_attributes?.length + 1,
                                            name: item.worker && item.worker.name,
                                            worker_id: item.worker && item.worker.id
                                        }]]
                                )?.map((v) => v.worker_id)
                                : [item.worker && item.worker.id || -1].concat(arrWorkers),
                    };
                }
            })
            const manager = new DataManager(formattedEj2ScheduleList);
            setDataManager(manager);
        } else {
            //setLocalScheduleData(scheduleList.data);
            let scheduleWorker: any = [];

            setLocalScheduleData(scheduleList.data);
            const formattedEj2ScheduleList = scheduleList.data.map((item) => {
                scheduleWorker = [
                    ...scheduleWorker,
                    {
                        worker: item.worker && item.worker.id,
                    }
                ]
                const arrWorkers: string[] = [];
                if (item?.estimate_assigned_worker_id) {
                    arrWorkers.push(item?.estimate_assigned_worker_id)
                }
                if (item?.manager_worker_id) {
                    arrWorkers.push(item?.manager_worker_id)
                }
                if(item?.assigned_worker_id) {
                    arrWorkers.push(item?.assigned_worker_id)
                }
                if (item.project_id) {
                    return {
                        ...item,
                        WorkerId: item.worker && item.worker.id,
                        Id: item.id,
                        Subject: item.projectName,
                        StartTime: item.startDate,
                        EndTime: item.endDate,
                        color: item.color || "#195192",
                        ConferenceId:
                            item?.worker_schedule_attributes?.length > 0
                                ? (item?.worker_schedule_attributes
                                    .map((v) => v.worker_id)
                                    .includes(item.worker && item.worker.id || -1)
                                    ? item?.worker_schedule_attributes
                                    : [
                                        ...item?.worker_schedule_attributes,
                                        ...[{
                                            id: item?.worker_schedule_attributes?.length + 1,
                                            name: item.worker && item.worker.name,
                                            worker_id: item.worker && item.worker.id
                                        }]]
                                )?.map((v) => v.worker_id)
                                : [item.worker && item.worker.id || -1].concat(arrWorkers),
                    };
                } else {
                    return {
                        ...item,
                        WorkerId: item.worker && item.worker.id,
                        Id: item.id,
                        Subject: item.title2,
                        StartTime: item.startDate,
                        EndTime: item.endDate,
                        color: item.color || "#195192",
                        ConferenceId:
                            item?.worker_schedule_attributes?.length > 0
                                ? (item?.worker_schedule_attributes
                                    .map((v) => v.worker_id)
                                    .includes(item.worker && item.worker.id || -1)
                                    ? item?.worker_schedule_attributes
                                    : [
                                        ...item?.worker_schedule_attributes,
                                        ...[{
                                            id: item?.worker_schedule_attributes?.length + 1,
                                            name: item.worker && item.worker.name,
                                            worker_id: item.worker && item.worker.id
                                        }]]
                                )?.map((v) => v.worker_id)
                                : [item.worker && item.worker.id || -1].concat(arrWorkers),
                    };
                }
            })
            const manager = new DataManager(formattedEj2ScheduleList);
            setDataManager(manager);
            setFilterWorker(uniqBy(scheduleWorker, 'worker').filter(v => v.worker));
        }
    }, [scheduleList.data, currentViewName]);

    useEffect(() => {
        fetchSchedules();
    }, [fetchSchedules]);

    return {
        ...props,
        loading,
        showDayList,
        currentViewName,
        currentDate,
        localScheduleDate,
        scheduleList,
        deleteSchedule,
        setCurrentItemId,
        fetchSchedules,
        commitChanges,
        setLocalScheduleData,
        setCurrentDate,
        setShowDayList,
        setCurrentViewName,
        isShowData,
        setIsShowData,
        dataManager,
        userData,
    };
};

export type Props = ReturnType<typeof useSchedulerBox>;

export default useSchedulerBox;
