import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Add } from '@mui/icons-material';
import { formatMoney } from 'utils/helper/helpers';
import { DefaultButton, DeleteButton } from '../../components/button';
import CreateOrderPopup from './createPopup';
import IndustriesWatesService from 'services/industriesWatesService';
import { useParams } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import ConfirmPopup from 'components/ComfirmPopup';
import userService from 'services/userService';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';

const ProjectOrderItemsTab = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [initValues, setInitValues] = useState(null);
    const [orderItems, setOrderItems] = useState<any>(null);
    const [deleteId, setDeleteId] = useState<any>(null);
    const [wasteType, setWasteType] = useState<any>([]);
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: true,
        },
    );
    const { id } = useParams();
    const handleEditOrder = (order) => {
        setInitValues({
            name: order?.name,
            amount: order?.amount,
            collect_amount_per_car: order?.collect_amount_per_car,
            collect_receipt_fee: order?.collect_receipt_fee,
            disposal_amount_per_unit: order?.disposal_amount_per_unit,
            disposal_receipt_fee: order?.disposal_receipt_fee,
            num_of_car: order?.num_of_car,
            collect_company_id: order?.collect_company?.id,
            disposal_company_id: order?.disposal_company?.id,
            waste_type: order?.waste_type,
            disposal_unit: order?.disposal_unit,
            unit: order?.unit,
            id: order?.id,
        } as any);
        setIsOpen(true);
    };

    const getListConfiguration = async () => {
        try {
            const response = await userService.getListConfiguration();
            setWasteType(
                convertUserConfigToDropdownOptions(
                    response.configurations.order_item.waste_type,
                ),
            );
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListOrderItems = async () => {
        try {
            const response: any = await IndustriesWatesService.getListOrderItem(
                {
                    page: 1,
                    per_page: 0,
                    project_id: id,
                },
            );

            setOrderItems(response?.order_items);
        } catch (error) {}
    };

    const handleDeleteOrder = async () => {
        try {
            await IndustriesWatesService.deleteOrderItem(deleteId, {
                project_id: id,
            });
            closeDelete();
            getListOrderItems();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getListOrderItems();
        getListConfiguration();
    }, []);

    return (
        <Box marginTop="24px">
            <ModalDelete>
                <ConfirmPopup
                    closeDelete={closeDelete}
                    handleSubmit={handleDeleteOrder}
                />
            </ModalDelete>
            {isOpen && (
                <CreateOrderPopup
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    initValues={initValues}
                    setInitValues={setInitValues}
                    getListOrderItems={getListOrderItems}
                    wasteType={wasteType}
                />
            )}
            <Box display="flex" justifyContent="flex-end">
                <Button
                    onClick={() => setIsOpen(true)}
                    sx={{
                        mb: '20px',
                        fontWeight: 'bold',
                        padding: '6px 18px',
                        color: '#215493',
                    }}
                    variant="outlined"
                    tabIndex={6}
                >
                    <Add /> 新規作成
                </Button>
            </Box>
            <TableContainer
                table-layout={'auto'}
                sx={{
                    padding: '0px',
                    boxShadow: 'none',
                    border: '1px solid #CDD1D5',
                    borderRadius: '8px',
                }}
                component={Paper}
            >
                <Table
                    sx={{
                        width: '100%',
                        minWidth: 1400,
                        'th, td': {
                            fontFamily: 'Zen Kaku Gothic Antique',
                        },
                    }}
                >
                    <TableHead
                        sx={{
                            tr: {
                                backgroundColor: '#C7DCF5',
                            },
                            th: {
                                color: '#1C1E21',
                                fontWeight: '600',
                                fontSize: '15px',
                                textAlign: 'center',
                                borderRight: '1px solid #F5F5F5',
                                borderBottom: '1px solid #F5F5F5',
                                paddingY: '6px',
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell rowSpan={3}>廃材種類</TableCell>
                            <TableCell rowSpan={3}>数量</TableCell>
                            <TableCell rowSpan={3}>単位</TableCell>
                            <TableCell colSpan={4}>契約金額</TableCell>
                            <TableCell rowSpan={3}>収集運搬</TableCell>
                            <TableCell rowSpan={3}>処分先</TableCell>
                            <TableCell colSpan={2}>印紙額</TableCell>
                            <TableCell rowSpan={3}>操作</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>収集</TableCell>
                            <TableCell colSpan={2}>処分</TableCell>
                            <TableCell rowSpan={2}>収集</TableCell>
                            <TableCell rowSpan={2}> 処分</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                th: {
                                    borderBottom: 'none !important',
                                },
                            }}
                        >
                            <TableCell>台数単価</TableCell>
                            <TableCell>台数</TableCell>
                            <TableCell>単価</TableCell>
                            <TableCell>単位</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            'th, td': {
                                borderRight: '1px solid #F5F5F5',
                                padding: '18px 13.5px',
                                borderBottom: '1px solid #F5F5F5 !important',
                            },
                        }}
                    >
                        {orderItems?.map((order, index) => (
                            <TableRow
                                sx={{
                                    td: {
                                        textAlign: 'center',
                                        padding: '8px 10px',
                                    },
                                    'td:first-child': {
                                        textAlign: 'left',
                                    },
                                }}
                                key={index}
                            >
                                <TableCell>
                                    {
                                        wasteType?.find(
                                            (item) =>
                                                item.value == order?.waste_type,
                                        ).label
                                    }
                                </TableCell>
                                <TableCell>{order?.amount}</TableCell>
                                <TableCell>{order?.unit}</TableCell>
                                <TableCell>
                                    {formatMoney(order?.collect_amount_per_car)}
                                </TableCell>
                                <TableCell>{order?.num_of_car}</TableCell>
                                <TableCell>
                                    {formatMoney(
                                        order?.disposal_amount_per_unit,
                                    )}
                                </TableCell>
                                <TableCell>{order?.disposal_unit}</TableCell>
                                <TableCell>
                                    {order?.collect_company?.name}
                                </TableCell>
                                <TableCell>
                                    {order?.disposal_company?.name}
                                </TableCell>
                                <TableCell>
                                    {order?.collect_receipt_fee}
                                </TableCell>
                                <TableCell>
                                    {order?.disposal_receipt_fee}
                                </TableCell>

                                <TableCell>
                                    {order?.amount && (
                                        <>
                                            <DefaultButton
                                                text="編集"
                                                onClick={() => {
                                                    handleEditOrder(order);
                                                    setDeleteId(null);
                                                }}
                                            />
                                            <DeleteButton
                                                onClick={() => {
                                                    setDeleteId(order.id);
                                                    openDelete();
                                                }}
                                            />
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
export default ProjectOrderItemsTab;
