import styled from 'styled-components';

const StyledPlanUpdateWrapper = styled.form`
  width: 100%;
  background: #FFFFFF;
  padding: 20px;
  .planTitle {
    font-family: 'Zen Kaku Gothic Antique';
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #1C1E21;
  }

  .fieldWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      font-family: 'Zen Kaku Gothic Antique';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
      margin: 0;
      margin-bottom: 4px;
      margin-top: 12px;
    }

    input {
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 12px;
      gap: 8px;
      width: 100%;
      height: 36px;
      background: #FFFFFF;
      border: 1px solid #CDD1D5;
      border-radius: 8px;
    }

    textarea {
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 12px;
      gap: 8px;
      width: 100%;
      height: 84px;
      background: #FFFFFF;
      border: 1px solid #CDD1D5;
      border-radius: 8px;
      resize:vertical;
    }

    .datepickerGroup {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .tilde {
        margin-left: 5px;
        margin-right: 5px;
      }

      .datepickerWrapper {
        position: relative;
        width: 100%;
        max-width: 180px;
        
        .calendarIcon {
          position: absolute;
          right: 15px;
          top: 10px;
        }
      }
    }

    .planStatusDropdown {
      width: calc(100%);
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 8px;
      height: 36px;
      background: #FFFFFF;
      font-family: "FontAwesome";

      .statusDropdownControl {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #CDD1D5 !important;
        border-radius: 8px !important;
      }
    }
  }

  .buttonGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .returnBtn {
      width: 49%;
      height: 44px;
      border-radius: 8px;
      border: 1px solid #215493;
      color:  #215493;
    }

    .registerBtn {
      width: 49%;
      height: 44px;
      background: #215493;
      border-radius: 8px;
      color: #FFFFFF;
    }
  }
  .select-search__option.is-selected {
    background: #f2f9fc;
    color: #000;
  }
  .select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #CDD1D5;
  }
`;

export default StyledPlanUpdateWrapper;
