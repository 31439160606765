import styled from 'styled-components';
import { Dialog } from '@mui/material';

export const StyledPdfDocoments = styled.div`
  .MuiPaper-root {
    border-radius: 0px !important;
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 850px !important;
  }
`;
