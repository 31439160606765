import Api from './api/workersApi';
import pick from 'lodash/pick';
import moment from 'moment';
// import workerLists from '../shared/mocks/workerLists.json';

const getListWorkers = async (input: any = {}) => {
    // return workerLists; // TODO remove debug
    let data = pick(input, ['page', 'per_page', 'free_word']);
    if (input.department_id) data.department_id = input.department_id?.value;
    if (input.position_id) data.position_id = input.position_id?.value;
    if (input.cooperative_company_id)
        data.cooperative_company_id = input.cooperative_company_id;
    if (input.gender_type) data.gender_type = input.gender_type?.value;
    if(input.include_workers_without_user) data.include_workers_without_user = input.include_workers_without_user;
    if(input.project_id) data.project_id = input.project_id
    const params = new URLSearchParams(data);
    try {
        return await Api()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (err) {
        // window.location.href = '/login';
        return null;
    }
};

const getWorker = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = {
        id: id,
    };
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .get(`${id}`, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const createWorker = async (input) => {
    let data = pick(input, [
        'name',
        'kana',
        'celphone',
        'fax',
        'email',
        'phone',
        'zipcode',
        'address',
    ]);
    data.gender_type = input.gender_type?.value || null;
    data.cooperative_company_id = input.cooperative_company_id?.value || null;
    data.department_id = input.department_id?.value || null;
    data.position_id = input.position_id?.value || null;
    if (input?.birthday) {
        data.birthday = moment(input.birthday).format('YYYY-MM-DD');
    } else {
      data.birthday = null;
    }
    return await Api()
        .post('', data, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateWorker = async (id, input) => {
    let data = pick(input, [
        'name',
        'kana',
        'celphone',
        'fax',
        'email',
        'phone',
        'zipcode',
        'address',
    ]);
    data.gender_type = input.gender_type?.value || null;
    data.cooperative_company_id = input.cooperative_company_id?.value || null;
    data.department_id = input.department_id?.value || null;
    data.position_id = input.position_id?.value || null;
    if (input?.birthday) {
        data.birthday = moment(input.birthday).format('YYYY-MM-DD');
    } else {
      data.birthday = null
    }
    return await Api()
        .put(`${id}`, data, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteWorker = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = {
        id: id,
    };
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .delete(`${id}`, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            return err?.response?.data
        }
    } else {
        window.location.href = '/login';
    }
};

const workersService = {
    getListWorkers,
    createWorker,
    updateWorker,
    deleteWorker,
    getWorker,
};

export default workersService;
