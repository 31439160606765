import fetch from './api/config';

const API = {
    GET_LIST_WORKING_LOG_ADDITIONALS: () => `/sp/working_log_additionals`,
    DELETE_WORKING_LOG_ADDITIONALS: (id: number) => `/sp/working_log_additionals/${id}`,
    UPLOAD_WORKING_LOG_IMAGE: (id) => `/sp/working_logs/${id}/add_images`,
    DELETE_WORKING_LOG_IMAGE: (id) => `/sp/working_logs/${id}/delete_image`,
    GET_LIST_WORKING_LOG_ADDITIONALS_DETAIL: (id) => `/sp/working_log_additionals/${id}`,
    GET_WORKING_LOG_ADDITIONALS_PDF_DETAIL: (id) => `/sp/working_log_additionals/${id}/pdf`,
};

export default class WorkingLogAdditionalsService {
    static getListWorkingLogsAdditionals = (params: any) =>
        fetch.get(API.GET_LIST_WORKING_LOG_ADDITIONALS(), params);

    static deleteWorkingLog = (id: number, project_id: number) =>
        fetch.delete(API.DELETE_WORKING_LOG_ADDITIONALS(id), { project_id: project_id });

    // static uploadImage = (id: number, params: any) =>
    //     fetch.put(API.UPLOAD_WORKING_LOG_IMAGE(id), params);

    // static deleteImage = (id: number, params: any) =>
    //     fetch.delete(API.DELETE_WORKING_LOG_IMAGE(id), params);

    static createWorkingLogAdditionnal = (params: any) =>
        fetch.post(API.GET_LIST_WORKING_LOG_ADDITIONALS(), params);

    static getWorkingLogAdditionnalDetail = (id: number, params: any) =>
        fetch.get(API.GET_LIST_WORKING_LOG_ADDITIONALS_DETAIL(id), params);
        
    static getWorkingLogAdditionalsPdfDetail = (id: number | string, params) =>
        fetch.getBlob(API.GET_WORKING_LOG_ADDITIONALS_PDF_DETAIL(id), params);
}
