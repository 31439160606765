import styled from 'styled-components';
import vector from '../../../assets/icons/Vector.png';

const FilterBoxWrapper = styled.div`
    width: 100%;
    margin: 10px 0;
    font-family: 'Zen Kaku Gothic Antique';

    .filterBox {
        padding: 0px 15px;
        .fieldLabel {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #1c1e21;
            display: block;
            margin: 0 0 4px;
        }

        .filterBoxBottom {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
        }

        .filterBoxCol {
            width: 100%;
        }
    }

    .wrapTextField {
        width: 100%;
        .MuiFormControl-root {
            margin: 0 0 14px 0;
            .MuiFormHelperText-root {
                margin-left: 0;
                color: #d32f2f !important;
            }
            .MuiOutlinedInput-root.MuiInputBase-formControl {
                input {
                    padding: 5px 15px;
                    color: #1c1e21;
                    font-weight: 400;
                    font-size: 14px;
                    height: 23px;
                }

                fieldset {
                    border: none;
                }
            }

            &.haveIcon {
                position: relative;

                img {
                    width: 15px;
                    position: absolute;
                    z-index: 99;
                    right: 10px;
                    top: 9px;
                    pointer-events: none;
                }
            }

            .MuiOutlinedInput-input {
                border: 0;
                background: #f3f3f3;
                border-radius: 4px;
            }
        }
    }

    .wrapSelectField {
        margin: 0 0 15px;
        width: 100%;
        max-width: 100%;
        .MuiFormControl-root {
            .MuiFormHelperText-root {
                margin-left: 0;
                color: #d32f2f !important;
            }
        }

        &.multipleSelect {
            .MuiOutlinedInput-root.MuiInputBase-root {
                height: auto;
            }
        }

        .MuiSelect-select {
            font-weight: 400;
            color: #1c1e21;
            font-weight: 400;
            font-size: 14px;
        }

        .MuiOutlinedInput-root.MuiInputBase-root {
            min-height: 30px;
            background: #f3f3f3;
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                right: 10px;
                top: 8px;
                width: 13px;
                height: 13px;
                background: url('${vector}') no-repeat center;
                transform: rotate(90deg);
                background-size: contain;
                pointer-events: none;
            }

            fieldset {
                border: none;
            }

            .MuiSvgIcon-root {
                //display: none;
            }
            .MuiAutocomplete-clearIndicator,
            .MuiAutocomplete-popupIndicator {
                display: none; 
            }
        }

        .MuiAutocomplete-root {
            .MuiInputBase-root {
                padding: 5px 15px;
            }
        }

        .MuiAutocomplete-input {
            color: #1c1e21;
            font-weight: 400;
            font-size: 14px;
        }
        .MuiOutlinedInput-root {
            .MuiAutocomplete-input {
                padding: 0;
            }
        }
    }
`;

export default FilterBoxWrapper;
