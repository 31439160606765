import { useState, Dispatch, SetStateAction, FC, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import billService from 'services/billService';
import {
    Autocomplete,
    TextField,
    Dialog,
    Box,
    CircularProgress,
} from '@mui/material';
import calendarIcon from 'assets/icons/calendar.png';
import StyledWorkingLogForm from '../BillingCreate/style';
import updateBillValidation from 'utils/validation/registers/bills/updateBillValidation';

interface IProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    companies: any[];
    workers: any[];
    onFinishEdit: () => void;
    project_id: any;
    selectedBill: any;
}

const EditBillingDialog: FC<IProps> = (props) => {
    const {
        open,
        setOpen,
        onFinishEdit,
        project_id,
        companies,
        workers,
        selectedBill,
    } = props;
    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        setError,
        watch,
        getValues,
        setValue,
        formState: { errors },
    } = useForm();
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [deletePaymentIds, setDeletePaymentIds] = useState<any[]>([]);
    const [totalPayAmount, setTotalPayAmount] = useState<number>(0);

    const fetchBillDetail = async () => {
        setFetchLoading(true);
        try {
            const data = await billService.getDetailBill(
                selectedBill.id,
                project_id,
            );
            if (data) {
                console.log('bill detail data', data);

                const billData = data.bill;
                const clientCompany = companies.find(
                    (item) => item.id === billData?.client_company?.id,
                );
                const authorWorker = workers.find(
                    (item) => item.id === billData?.author_worker?.id,
                );

                setValue('amount', billData.amount);
                setValue(
                    'submitted_at',
                    new Date(billData.submitted_at) || null,
                );
                setValue(
                    'payment_deadline',
                    new Date(billData.payment_deadline) || null,
                );
                setValue(
                    'submitted_at',
                    new Date(billData.submitted_at) || null,
                );
                setTotalPayAmount(billData.total_amount_paid);

                if (clientCompany) {
                    setValue('client_company_id', {
                        value: clientCompany.id,
                        label: clientCompany.company?.name,
                    });
                } else {
                    setValue('client_company_id', null);
                }
                if (authorWorker) {
                    setValue('author_worker_id', {
                        value: authorWorker.id,
                        label: authorWorker.name,
                    });
                } else {
                    setValue('author_worker_id', null);
                }
                if (billData.payments) {
                    setValue(
                        'payment_attributes',
                        billData.payments.map((item: any) => ({
                            ...item,
                            id: item.id,
                            amount: item.amount,
                            paid_at: item.paid_at
                                ? new Date(item.paid_at)
                                : null,
                        })),
                    );
                } else {
                    setValue('payment_attributes', null);
                }
            }
        } catch (error) {
            // empty
        } finally {
            setFetchLoading(false);
        }
    };

    const handleDeletePayments = () => {
        const promisesArray: Promise<unknown>[] = [];
        deletePaymentIds.forEach((item) => {
            promisesArray.push(
                new Promise(async (resolve, reject) => {
                    await billService
                        .deletePayment(selectedBill.id, project_id, item)
                        .then(() => resolve(true))
                        .catch((err) => reject(err));
                }),
            );
        });
        return Promise.all(promisesArray);
    };

    const onEdit = async (data) => {
        setSubmitLoading(true);
        if (deletePaymentIds.length > 0) {
            await handleDeletePayments();
        }
        let submitData = { ...data };
        submitData.project_id = project_id;
        submitData.author_worker_id = data.author_worker_id.value;
        submitData.client_company_id = data.client_company_id.value;
        if (data.submitted_at) {
            submitData.submitted_at = moment(data.submitted_at).format(
                'YYYY-MM-DD',
            );
        }
        if (data.payment_deadline) {
            submitData.payment_deadline = moment(data.payment_deadline).format(
                'YYYY-MM-DD',
            );
        }
        if (data.payment_attributes.length > 0) {
            data.payment_attributes.forEach((p) => {
                if (typeof p.id === 'string' && p.id.includes('new')) {
                    delete p.id;
                }
                if (p.paid_at) {
                    p.paid_at = moment(p.paid_at).format('YYYY-MM-DD');
                }
            });
            submitData.payment_attributes = data.payment_attributes;
        }
        try {
            const data = await billService.updateBill(
                selectedBill.id,
                submitData,
            );
            if (data) {
                setSubmitLoading(false);
                onFinishEdit();
            }
        } catch (error) {
            setSubmitLoading(false);
        }
    };

    const addPayment = () => {
        let paymentAttributes = [...getValues('payment_attributes')];
        paymentAttributes.push({
            paid_at: '',
            amount: '',
            id: `new_${paymentAttributes.length + 1}`,
        });
        setValue('payment_attributes', paymentAttributes);
    };

    const removePayment = (id: any) => {
        let paymentAttributes = [...getValues('payment_attributes')];
        paymentAttributes = paymentAttributes.filter((p) => p.id !== id);
        if (typeof id === 'number') {
            setDeletePaymentIds((prev) => [...prev, id]);
        }
        paymentAttributes.forEach((p, index) => {
            if (typeof p.id === 'string' && p.id.includes('new')) {
                p.id = `new_${index + 1}`;
            }
        });
        setValue('payment_attributes', paymentAttributes);
        handleUpdateTotalPay();
    };

    const handleUpdateTotalPay = () => {
        const total = getValues('payment_attributes').reduce(
            (accu, current) => {
                return accu + +current.amount;
            },
            0,
        );
        setTotalPayAmount(total);
    };

    useEffect(() => {
        fetchBillDetail();
    }, []);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} disableEscapeKeyDown>
            <StyledWorkingLogForm onSubmit={handleSubmit(onEdit)}>
                <div className="formTitle">請求編集</div>

                {fetchLoading && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        marginTop="1rem"
                    >
                        <CircularProgress />
                    </Box>
                )}

                {!fetchLoading && (
                    <>
                        <div className="formFieldRow">
                            <p>宛先</p>
                            <Controller
                                name="client_company_id"
                                control={control}
                                render={({ field: { value } }) => (
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={
                                            companies &&
                                            companies.map((item) => ({
                                                value: Number(item.id),
                                                label: item.company.name,
                                            }))
                                        }
                                        {...register(
                                            'client_company_id',
                                            updateBillValidation(
                                                t,
                                            ).client_company_id(),
                                        )}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        onChange={(event, newValue) => {
                                            setValue(
                                                'client_company_id',
                                                newValue,
                                            );
                                        }}
                                        classes={{
                                            root: 'autocomplete-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="" />
                                        )}
                                        noOptionsText="該当なし"
                                        value={value}
                                    />
                                )}
                            />
                            {errors.client_company_id && (
                                <span className="error">
                                    {errors.client_company_id.message}
                                </span>
                            )}
                        </div>

                        <div className="formFieldRow">
                            <p>担当</p>
                            <Controller
                                name="author_worker_id"
                                control={control}
                                render={({ field: { value } }) => (
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={
                                            workers &&
                                            workers.map((item) => ({
                                                value: item.id,
                                                label: item.name,
                                            }))
                                        }
                                        {...register(
                                            'author_worker_id',
                                            updateBillValidation(
                                                t,
                                            ).author_worker_id(),
                                        )}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        onChange={(event, newValue) => {
                                            setValue(
                                                'author_worker_id',
                                                newValue,
                                            );
                                        }}
                                        classes={{
                                            root: 'autocomplete-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="" />
                                        )}
                                        noOptionsText="該当なし"
                                        value={value}
                                    />
                                )}
                            />
                            {errors.author_worker_id && (
                                <span className="error">
                                    {errors.author_worker_id.message}
                                </span>
                            )}
                        </div>

                        <div className="formFieldRow">
                            <p>請求日</p>
                            <div className="datepicker-wrapper">
                                <Controller
                                    name="submitted_at"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            id="submitted_at"
                                            selected={field.value}
                                            {...register(
                                                'submitted_at',
                                                updateBillValidation(
                                                    t,
                                                ).submitted_at(),
                                            )}
                                            {...field}
                                            onChange={(date) => {
                                                field?.onChange(date);
                                            }}
                                            dateFormat="yyyy-MM-dd"
                                            autoComplete="off"
                                            locale="ja"
                                        />
                                    )}
                                />
                                <label htmlFor="submitted_at">
                                    <img
                                        className="calendar-datepicker-icon"
                                        src={calendarIcon}
                                    ></img>
                                </label>
                            </div>
                            {errors.submitted_at && (
                                <span className="error">
                                    {errors.submitted_at.message}
                                </span>
                            )}
                        </div>
                        <div className="formFieldRow">
                            <p>振込期限</p>
                            <div className="datepicker-wrapper">
                                <Controller
                                    name="payment_deadline"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            id="payment_deadline"
                                            selected={field.value}
                                            {...register(
                                                'payment_deadline',
                                                updateBillValidation(
                                                    t,
                                                ).payment_deadline(),
                                            )}
                                            {...field}
                                            onChange={(date) => {
                                                field?.onChange(date);
                                            }}
                                            dateFormat="yyyy-MM-dd"
                                            autoComplete="off"
                                            locale="ja"
                                        />
                                    )}
                                />
                                <label htmlFor="payment_deadline">
                                    <img
                                        className="calendar-datepicker-icon"
                                        src={calendarIcon}
                                    ></img>
                                </label>
                            </div>
                            {errors.payment_deadline && (
                                <span className="error">
                                    {errors.payment_deadline?.message}
                                </span>
                            )}
                        </div>
                        <div className="formFieldRow">
                            <p>金額</p>
                            <input
                                autoComplete="off"
                                type="number"
                                step="any"
                                {...register(
                                    'amount',
                                    updateBillValidation(t).amount(),
                                )}
                                className="textfield"
                            />
                            {errors.amount && (
                                <span className="error">
                                    {errors.amount.message}
                                </span>
                            )}
                        </div>
                        {selectedBill && (
                            <div className="formFieldRow">
                                <p>入金金額の合計</p>
                                <input
                                    value={totalPayAmount}
                                    className="textfield"
                                    disabled
                                />
                            </div>
                        )}
                        <button
                            type="button"
                            onClick={() => addPayment()}
                            className="cancelBtn btnAddpayment"
                        >
                            入金追加
                        </button>
                        {watch('payment_attributes') &&
                            watch('payment_attributes').length > 0 &&
                            watch('payment_attributes').map((pay, index) => {
                                return (
                                    <div className="formRow" key={pay.id}>
                                        <div className="formFieldRow">
                                            <p>金額</p>
                                            <input
                                                autoComplete="off"
                                                type="number"
                                                step="any"
                                                {...register(
                                                    `payment_attributes[${index}].amount`,
                                                    updateBillValidation(
                                                        t,
                                                    ).payment_amount(),
                                                )}
                                                value={watch(
                                                    `payment_attributes[${index}].amount`,
                                                )}
                                                onChange={(e) => {
                                                    setValue(
                                                        `payment_attributes[${index}].amount`,
                                                        e.target.value,
                                                    );
                                                    handleUpdateTotalPay();
                                                }}
                                                className="textFieldArray"
                                            />
                                            {errors.payment_attributes && (
                                                    <span className="error h-[22px]">
                                                        {
                                                            errors
                                                                .payment_attributes[
                                                                index
                                                            ]?.amount?.message
                                                        }
                                                    </span>
                                                )}
                                        </div>
                                        <div className="formFieldRow">
                                            <p>振込期限</p>
                                            <div className="datepicker-wrapper">
                                                <DatePicker
                                                    id={`payment_attributes[${index}].paid_at`}
                                                    selected={watch(
                                                        `payment_attributes[${index}].paid_at`,
                                                    )}
                                                    {...register(
                                                        `payment_attributes[${index}].paid_at`,
                                                        updateBillValidation(
                                                            t,
                                                        ).payment_paid_at(),
                                                    )}
                                                    onChange={(date) => {
                                                        setValue(
                                                            `payment_attributes[${index}].paid_at`,
                                                            date,
                                                        );
                                                    }}
                                                    dateFormat="yyyy-MM-dd"
                                                    autoComplete="off"
                                                    locale="ja"
                                                />
                                                <label
                                                    htmlFor={`payment_attributes[${index}].paid_at`}
                                                >
                                                    <img
                                                        className="calendar-datepicker-icon"
                                                        src={calendarIcon}
                                                    ></img>
                                                </label>
                                            </div>
                                            {errors.payment_attributes && (
                                                    <span className="error h-[22px]">
                                                        {
                                                            errors
                                                                .payment_attributes[
                                                                index
                                                            ]?.paid_at?.message
                                                        }
                                                    </span>
                                                )}
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                removePayment(pay.id)
                                            }
                                            className={`cancelBtn btnRemovepayment ${errors?.payment_attributes || errors?.amount ? 'btnRemovepayment2' : ''}`}
                                        >
                                            削除
                                        </button>
                                    </div>
                                );
                            })}

                        <div className="formButtonGroup">
                            <button
                                type="button"
                                className="cancelBtn"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                戻る
                            </button>
                            <button className="registerBtn" type="submit">
                                {submitLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={18}
                                    />
                                ) : (
                                    '登録'
                                )}
                            </button>
                        </div>
                    </>
                )}
            </StyledWorkingLogForm>
        </Dialog>
    );
};

export default EditBillingDialog;
