import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { SugorokuIndexEnum } from 'utils/enums';
import { convertSugorokuEnumObjectToArray } from 'utils/helper/sugorokuHelper';

interface IProps {
    setStep: Dispatch<SetStateAction<number>>;
    setOpenStep: Dispatch<SetStateAction<boolean>>;
    sugorokuList: any;
    readOnly?: boolean;
}
export interface ISugorukuItem {
    isCompleted?: boolean;
    name: string;
    number: number;
    title?: string;
    value: number;
}
const StepDiagram = (props: IProps) => {
    const { setStep, setOpenStep, sugorokuList, readOnly } = props;
    const handleOpen = (step) => {
        if(readOnly) return
        setStep(step);
        setOpenStep(true);
    };
    const { userData } = useSelector((state: RootState) => state.users);
    const isUserRole = userData?.role === 'member';

    const sugorokuIndexEnumList = convertSugorokuEnumObjectToArray(SugorokuIndexEnum);
    const arrOrigin = sugorokuIndexEnumList?.map((item, index) => {
        return {...item, number: index + 1}
    });
    let arrSugorokuIndexEnumList: ISugorukuItem[] = [];
    let arrCompleteds: ISugorukuItem[] = [];
    for(let i = arrOrigin.length - 1; i >= 0; i--) {
        if(sugorokuList[arrOrigin[i].name]?.completed_at) {
            arrCompleteds?.push(arrOrigin[i])
        }
        
        if(arrOrigin[i].number < arrCompleteds[0]?.number && !sugorokuList[arrOrigin[i].name]?.completed_at) {
            arrSugorokuIndexEnumList.push({...arrOrigin[i], isCompleted: false})
        } else {
            arrSugorokuIndexEnumList.push({...arrOrigin[i], isCompleted: true})
        }
    }
    arrSugorokuIndexEnumList = arrSugorokuIndexEnumList?.sort((a, b) => a.number - b.number);
    let objectSugoruku: any = {}
    arrSugorokuIndexEnumList?.map((item, i) => {
        objectSugoruku[item?.name?.toUpperCase()] = arrSugorokuIndexEnumList[i]
    })
    
    return (
        <React.Fragment>
            <div className="step-progress-container-top d-f fr">
                <div className="step-item" style={{ marginLeft: 40 }}>
                    <div
                        onClick={() => {
                            handleOpen(
                                SugorokuIndexEnum.REQUEST_FOR_QUOTATION.value,
                            );
                        }}
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name
                            ]?.completed_at
                                ? 'step-circle d-f fc completeStep'
                                : `step-circle d-f fc unCompleteStep ${!objectSugoruku?.REQUEST_FOR_QUOTATION?.isCompleted ? 'classRed' : ''}`
                        }
                    >
                        1
                    </div>
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name
                            ]?.completed_at
                                ? 'completeText step-text d-f fc'
                                : 'unCompleteText step-text d-f fc'
                        }
                    >
                        <span>
                            {SugorokuIndexEnum.REQUEST_FOR_QUOTATION.title}
                        </span>
                        {sugorokuList[
                            SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name
                        ]?.completed_at && (
                            <span>
                                {
                                    sugorokuList[
                                        SugorokuIndexEnum.REQUEST_FOR_QUOTATION
                                            .name
                                    ]?.completed_at
                                }
                            </span>
                        )}
                    </div>
                </div>
                <div className="step-item ">
                    <div
                        className={
                            sugorokuList[SugorokuIndexEnum.QUOTATION.name]
                                ?.completed_at
                                ? 'step-circle d-f fc completeStep'
                                : `step-circle d-f fc unCompleteStep ${!objectSugoruku?.QUOTATION?.isCompleted ? 'classRed' : ''}`
                        }
                        onClick={() => {
                            handleOpen(SugorokuIndexEnum.QUOTATION.value);
                        }}
                    >
                        2
                    </div>
                    <div
                        className={
                            sugorokuList[SugorokuIndexEnum.QUOTATION.name]
                                ?.completed_at
                                ? 'completeText step-text d-f fc'
                                : 'unCompleteText step-text d-f fc'
                        }
                    >
                        <span>{SugorokuIndexEnum.QUOTATION.title}</span>
                        <span>
                            {
                                sugorokuList[SugorokuIndexEnum.QUOTATION.name]
                                    ?.completed_at
                            }
                        </span>
                    </div>
                </div>
                <div className="step-item">
                    <div
                        className={
                            sugorokuList[SugorokuIndexEnum.SEND_QUOTATION.name]
                                ?.completed_at
                                ? 'step-circle d-f fc completeStep'
                                : `step-circle d-f fc unCompleteStep ${!objectSugoruku?.SEND_QUOTATION?.isCompleted ? 'classRed' : ''}`
                        }
                        onClick={() => {
                            handleOpen(SugorokuIndexEnum.SEND_QUOTATION.value);
                        }}
                    >
                        3
                    </div>
                    <div
                        className={
                            sugorokuList[SugorokuIndexEnum.SEND_QUOTATION.name]
                                ?.completed_at
                                ? 'completeText step-text d-f fc'
                                : 'unCompleteText step-text d-f fc'
                        }
                    >
                        <span>{SugorokuIndexEnum.SEND_QUOTATION.title}</span>
                        <span>
                            {
                                sugorokuList[
                                    SugorokuIndexEnum.SEND_QUOTATION.name
                                ]?.completed_at
                            }
                        </span>
                    </div>
                </div>
                <div className="step-item">
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.PRICE_NEGOTIATION.name
                            ]?.completed_at
                                ? 'step-circle d-f fc completeStep'
                                : `step-circle d-f fc unCompleteStep ${!objectSugoruku?.PRICE_NEGOTIATION?.isCompleted ? 'classRed' : ''}`
                        }
                        onClick={() => {
                            handleOpen(
                                SugorokuIndexEnum.PRICE_NEGOTIATION.value,
                            );
                        }}
                    >
                        4
                    </div>
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.PRICE_NEGOTIATION.name
                            ]?.completed_at
                                ? 'completeText step-text d-f fc'
                                : 'unCompleteText step-text d-f fc'
                        }
                    >
                        <span>{SugorokuIndexEnum.PRICE_NEGOTIATION.title}</span>
                        <span>
                            {
                                sugorokuList[
                                    SugorokuIndexEnum.PRICE_NEGOTIATION.name
                                ]?.completed_at
                            }
                        </span>
                    </div>
                </div>
                <div className="step-item">
                    <div
                        onClick={() => {
                            handleOpen(
                                SugorokuIndexEnum.CONTRACT_WRITING.value,
                            );
                        }}
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.CONTRACT_WRITING.name
                            ]?.completed_at
                                ? 'step-circle d-f fc completeStep'
                                : `step-circle d-f fc unCompleteStep ${!objectSugoruku?.CONTRACT_WRITING?.isCompleted ? 'classRed' : ''}`
                        }
                    >
                        5
                    </div>
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.CONTRACT_WRITING.name
                            ]?.completed_at
                                ? 'completeText step-text d-f fc'
                                : 'unCompleteText step-text d-f fc'
                        }
                    >
                        <span>{SugorokuIndexEnum.CONTRACT_WRITING.title}</span>
                        <span>
                            {
                                sugorokuList[
                                    SugorokuIndexEnum.CONTRACT_WRITING.name
                                ]?.completed_at
                            }
                        </span>
                    </div>
                </div>
                <div className="step-item">
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum
                                    .PREPARATION_OF_SAFETY_DOCUMENTS.name
                            ]?.completed_at
                                ? 'step-circle d-f fc step-circle-6 completeStep'
                                : `step-circle d-f fc step-circle-6 unCompleteStep ${!objectSugoruku?.PREPARATION_OF_SAFETY_DOCUMENTS?.isCompleted ? 'classRed' : ''}`
                        }
                        style={{ zIndex: 10 }}
                        onClick={() => {
                            handleOpen(
                                SugorokuIndexEnum
                                    .PREPARATION_OF_SAFETY_DOCUMENTS.value,
                            );
                        }}
                    >
                        6
                    </div>
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum
                                    .PREPARATION_OF_SAFETY_DOCUMENTS.name
                            ]?.completed_at
                                ? 'completeText step-text d-f fc'
                                : 'unCompleteText step-text d-f fc'
                        }
                        style={{ zIndex: 10 }}
                    >
                        <span>
                            {
                                SugorokuIndexEnum
                                    .PREPARATION_OF_SAFETY_DOCUMENTS.title
                            }
                        </span>
                        <span>
                            {
                                sugorokuList[
                                    SugorokuIndexEnum
                                        .PREPARATION_OF_SAFETY_DOCUMENTS.name
                                ]?.completed_at
                            }
                        </span>
                    </div>
                </div>

                <div className="sub-step-item">
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name
                            ]?.completed_at
                                ? 'sub-step-circle d-f fc step-circle-6 completeStep'
                                : `sub-step-circle d-f fc step-circle-6 unCompleteStep ${!objectSugoruku?.WASTE_REMOVAL_PLAN?.isCompleted ? 'classRed' : ''}`
                        }
                        onClick={() => {
                            handleOpen(
                                SugorokuIndexEnum.WASTE_REMOVAL_PLAN.value,
                            );
                        }}
                    >
                        6
                    </div>
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name
                            ]?.completed_at
                                ? 'completeText sub-step-text d-f fc'
                                : 'unCompleteText sub-step-text d-f fc'
                        }
                    >
                        <span>
                            {SugorokuIndexEnum.WASTE_REMOVAL_PLAN.title}
                        </span>
                        <span>
                            {
                                sugorokuList[
                                    SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name
                                ]?.completed_at
                            }
                        </span>
                    </div>
                </div>
            </div>
            <div className={`step-progress-container-bottom d-f fr ${isUserRole ? 'justify-between' : ''}`}>
                <div className="step-item" style={{ marginLeft: 40 }}>
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum
                                    .PRE_CONSTRUCTION_REVIEW_MEETING.name
                            ]?.completed_at
                                ? 'step-circle-bt d-f fc completeStep'
                                : `step-circle-bt d-f fc unCompleteStep ${!objectSugoruku?.PRE_CONSTRUCTION_REVIEW_MEETING?.isCompleted ? 'classRed' : ''}`
                        }
                        onClick={() => {
                            handleOpen(
                                SugorokuIndexEnum
                                    .PRE_CONSTRUCTION_REVIEW_MEETING.value,
                            );
                        }}
                    >
                        7
                    </div>
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum
                                    .PRE_CONSTRUCTION_REVIEW_MEETING.name
                            ]?.completed_at
                                ? 'completeText step-text-bt d-f fc'
                                : 'unCompleteText step-text-bt d-f fc'
                        }
                    >
                        <div>
                            {
                                SugorokuIndexEnum
                                    .PRE_CONSTRUCTION_REVIEW_MEETING.title
                            }
                        </div>
                        <div>
                            {
                                sugorokuList[
                                    SugorokuIndexEnum
                                        .PRE_CONSTRUCTION_REVIEW_MEETING.name
                                ]?.completed_at
                            }
                        </div>
                    </div>
                </div>

                <div className="step-item ">
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING.name
                            ]?.completed_at
                                ? 'step-circle-bt d-f fc completeStep'
                                : `step-circle-bt d-f fc unCompleteStep ${!objectSugoruku?.PRE_CONSTRUCTION_MEETING?.isCompleted ? 'classRed' : ''}`
                        }
                        onClick={() => {
                            handleOpen(
                                SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING
                                    .value,
                            );
                        }}
                        style={{ zIndex: 10 }}
                    >
                        8
                    </div>
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING.name
                            ]?.completed_at
                                ? 'completeText step-text-bt d-f fc'
                                : 'unCompleteText step-text-bt d-f fc'
                        }
                        style={{ zIndex: 10 }}
                    >
                        <span>
                            {SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING.title}
                        </span>
                        <span>
                            {
                                sugorokuList[
                                    SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING
                                        .name
                                ]?.completed_at
                            }
                        </span>
                    </div>
                </div>
                <div className="step-item">
                    <div
                        className={
                            sugorokuList[SugorokuIndexEnum.BREAKING_GROUND.name]
                                ?.completed_at
                                ? 'step-circle-bt d-f fc completeStep'
                                : `step-circle-bt d-f fc unCompleteStep ${!objectSugoruku?.BREAKING_GROUND?.isCompleted ? 'classRed' : ''}`
                        }
                        onClick={() => {
                            handleOpen(SugorokuIndexEnum.BREAKING_GROUND.value);
                        }}
                    >
                        9
                    </div>
                    <div
                        className={
                            sugorokuList[SugorokuIndexEnum.BREAKING_GROUND.name]
                                ?.completed_at
                                ? 'completeText step-text-bt d-f fc'
                                : 'unCompleteText step-text-bt d-f fc'
                        }
                    >
                        <span>{SugorokuIndexEnum.BREAKING_GROUND.title}</span>
                        <span>
                            {
                                sugorokuList[
                                    SugorokuIndexEnum.BREAKING_GROUND.name
                                ]?.completed_at
                            }
                        </span>
                    </div>
                </div>
                <div className="step-item">
                    <div
                        className={
                            sugorokuList[SugorokuIndexEnum.WEEKLY_REPORT.name]
                                ?.completed_at
                                ? 'step-circle-bt d-f fc completeStep'
                                : `step-circle-bt d-f fc unCompleteStep ${!objectSugoruku?.WEEKLY_REPORT?.isCompleted ? 'classRed' : ''}`
                        }
                        style={{ zIndex: 10 }}
                        onClick={() => {
                            handleOpen(SugorokuIndexEnum.WEEKLY_REPORT.value);
                        }}
                    >
                        10
                    </div>
                    <div
                        className={
                            sugorokuList[SugorokuIndexEnum.WEEKLY_REPORT.name]
                                ?.completed_at
                                ? 'completeText step-text-bt d-f fc'
                                : 'unCompleteText step-text-bt d-f fc'
                        }
                        style={{ zIndex: 10 }}
                    >
                        <span>{SugorokuIndexEnum.WEEKLY_REPORT.title}</span>
                        <span>
                            {
                                sugorokuList[
                                    SugorokuIndexEnum.WEEKLY_REPORT.name
                                ]?.completed_at
                            }
                        </span>
                    </div>
                </div>
                {!isUserRole &&
                    <div className="step-item">
                        <div
                            className={
                                sugorokuList[SugorokuIndexEnum.INVOICING.name]
                                    ?.completed_at
                                    ? 'step-circle-bt d-f fc completeStep'
                                    : `step-circle-bt d-f fc unCompleteStep ${!objectSugoruku?.INVOICING?.isCompleted ? 'classRed' : ''}`
                            }
                            onClick={() => {
                                handleOpen(SugorokuIndexEnum.INVOICING.value);
                            }}
                        >
                            11
                        </div>
                        <div
                            className={
                                sugorokuList[SugorokuIndexEnum.INVOICING.name]
                                    ?.completed_at
                                    ? 'completeText step-text-bt d-f fc'
                                    : 'unCompleteText step-text-bt d-f fc'
                            }
                        >
                            <span>{SugorokuIndexEnum.INVOICING.title}</span>
                            <span>
                                {
                                    sugorokuList[SugorokuIndexEnum.INVOICING.name]
                                        ?.completed_at
                                }
                            </span>
                        </div>
                    </div>
                }
                <div className={`step-item ${isUserRole ? '!w-[0px]' : ''}`}>
                    {!isUserRole && <>
                        <div
                            className={
                                sugorokuList[SugorokuIndexEnum.CONFIRM_PAYMENT.name]
                                    ?.completed_at
                                    ? 'step-circle-bt d-f fc completeStep'
                                    : `step-circle-bt d-f fc unCompleteStep ${!objectSugoruku?.CONFIRM_PAYMENT?.isCompleted ? 'classRed' : ''}`
                            }
                            onClick={() => {
                                handleOpen(SugorokuIndexEnum.CONFIRM_PAYMENT.value);
                            }}
                        >
                            12
                        </div>
                        <div
                            className={
                                sugorokuList[SugorokuIndexEnum.CONFIRM_PAYMENT.name]
                                    ?.completed_at
                                    ? 'completeText step-text-bt d-f fc'
                                    : 'unCompleteText step-text-bt d-f fc'
                            }
                        >
                            <span>{SugorokuIndexEnum.CONFIRM_PAYMENT.title}</span>
                            <span>
                                {
                                    sugorokuList[
                                        SugorokuIndexEnum.CONFIRM_PAYMENT.name
                                    ]?.completed_at
                                }
                            </span>
                        </div>
                    </>}
                    <div
                        className={
                            sugorokuList[SugorokuIndexEnum.DONE.name]
                                ?.completed_at
                                ? 'step-circle-bt-13 d-f fc step-circle-13 completeStep'
                                : `step-circle-bt-13 d-f fc step-circle-13 unCompleteStep ${!objectSugoruku?.DONE?.isCompleted ? 'classRed' : ''}`
                        }
                        onClick={() => {
                            handleOpen(SugorokuIndexEnum.DONE.value);
                        }}
                    >
                        {!isUserRole ? 13 : 11}
                    </div>
                    <div
                        className={
                            sugorokuList[SugorokuIndexEnum.DONE.name]
                                ?.completed_at
                                ? 'completeText step-text-bt-13 d-f fc'
                                : 'unCompleteText step-text-bt-13 d-f fc'
                        }
                    >
                        <span>完了</span>
                        <span>
                            {
                                sugorokuList[SugorokuIndexEnum.DONE.name]
                                    ?.completed_at
                            }
                        </span>
                    </div>
                </div>

                {/*<div className="sub-step-item8">
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.OVERALL_PROCESS_MEETING.name
                            ]?.completed_at
                                ? 'sub-step-circle8 d-f fc step-circle-6 completeStep'
                                : 'sub-step-circle8 d-f fc step-circle-6 unCompleteStep'
                        }
                        onClick={() => {
                            handleOpen(
                                SugorokuIndexEnum.OVERALL_PROCESS_MEETING.value,
                            );
                        }}
                    >
                        8
                    </div>
                    <div
                        className={
                            sugorokuList[
                                SugorokuIndexEnum.OVERALL_PROCESS_MEETING.name
                            ]?.completed_at
                                ? 'completeText sub-step-text d-f fc'
                                : 'unCompleteText sub-step-text d-f fc'
                        }
                        style={{
                            transform: 'translate(-50%, 130px)',
                        }}
                    >
                        <span>
                            {SugorokuIndexEnum.OVERALL_PROCESS_MEETING.title}
                        </span>
                        <span>
                            {
                                sugorokuList[
                                    SugorokuIndexEnum.OVERALL_PROCESS_MEETING
                                        .name
                                ]?.completed_at
                            }
                        </span>
                    </div>
                </div>*/}
                {!isUserRole &&
                    <div className="sub-step-item10">
                        <div
                            className={
                                sugorokuList[
                                    SugorokuIndexEnum.VOLUME_ADJUSTMENT_BOOK.name
                                ]?.completed_at
                                    ? 'sub-step-circle10 d-f fc step-circle-6 completeStep'
                                    : `sub-step-circle10 d-f fc step-circle-6 unCompleteStep ${!objectSugoruku?.VOLUME_ADJUSTMENT_BOOK?.isCompleted ? 'classRed' : ''}`
                            }
                            onClick={() => {
                                handleOpen(
                                    SugorokuIndexEnum.VOLUME_ADJUSTMENT_BOOK.value,
                                );
                            }}
                        >
                            10
                        </div>
                        <div
                            className={
                                sugorokuList[
                                    SugorokuIndexEnum.VOLUME_ADJUSTMENT_BOOK.name
                                ]?.completed_at
                                    ? 'completeText sub-step-text d-f fc'
                                    : 'unCompleteText sub-step-text d-f fc'
                            }
                            style={{
                                transform: 'translate(-50%, 130px)',
                            }}
                        >
                            <span>
                                {SugorokuIndexEnum.VOLUME_ADJUSTMENT_BOOK.title}
                            </span>
                            <span>
                                {
                                    sugorokuList[
                                        SugorokuIndexEnum.VOLUME_ADJUSTMENT_BOOK
                                            .name
                                    ]?.completed_at
                                }
                            </span>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

export default StepDiagram;
