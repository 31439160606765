import fetch from './api/config';

const API = {
    IMAGE_CONVERSION: () => `/image_conversion/convert_images`,
};

export default class ImageService {
    static convertImage = (params: any) =>
        fetch.post(API.IMAGE_CONVERSION(), params);
}
