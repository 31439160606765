import { StylesWorkingTimesManagements, StylesDialog6Months } from './styled';
import useWorkingTimesManagements, { Props } from './hook';
import {
    Button,
    TextField,
    Autocomplete,
    Grid,
    Box,
    InputAdornment,
    Dialog,
    DialogContent,
} from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import type { ColumnsType } from 'antd/es/table';
import { Table, Pagination } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import calendarIcon from '../../assets/icons/calendar.png';
import { Worker, Viewer, LocalizationMap } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import jp_JP from '@react-pdf-viewer/locales/lib/jp_JP.json';
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
import { ConfigProvider } from 'antd';
import ja_JPP from 'antd/es/locale/ja_JP';

const WorkingTimesManagementLayout = ({
    navigate,
    formik,
    workingTimeLists,
    ModalDelete,
    openDelete,
    closeDelete,
    isOpenDelete,
    positionOptions,
    departmentOptions,
    workerOptions,
    setPageQueries,
    pageQueries,
    handleOpenDelete,
    handleDelete,
    isOpenDepartment,
    setIsOpentDepartment,
    isOpenPosition,
    setIsOpenPosition,
    isOpenWorker,
    setIsOpenWorker,
    total,
    isShowPopup,
    setIsShowPopup,
    month,
    setMonth,
    idWorker,
    setIdWorker,
    getPdfWorkingTime,
    srcPDF,
    setSrcPdf,
    isValidDate,
    isErrorDate,
    setIsErrorDate,
    isShowPopup6Months,
    setIsShowPopup6Months,
    startMonth,
    setStartMonth,
    idWorker6Months,
    setIdWorker6Months,
    getPdfWorkingTime6Months,
    srcPDF6Months,
    setSrcPdf6Months,
    disabledBtnSubmit6Months,
    isErrorStartMonth,
    setIsErrorStartMonth,
    isErrorWorker6Months,
    setIsErrorWorker6Month,
    isErrorWorker,
    setIsErrorWorker,
    disabledBtnSubmit1Month,
}: Props) => {
    const columns: ColumnsType<any> = [
        {
            title: '名前',
            dataIndex: 'worker_name',
            key: 'worker_name',
            render: (text, record) => (
                <span className="vehicle-name">{text}</span>
            ),
            ellipsis: true,
        },
        {
            title: '日付',
            dataIndex: 'submit_date',
            key: 'submit_date',
            render: (text) => <span className="vehicle-name">{moment(text)?.format('YYYY/MM/DD')}</span>,
            ellipsis: true,
        },
        {
            title: '出勤時間',
            dataIndex: 'check_in_time',
            key: 'check_in_time',
            render: (text, record) =>
                text ? (
                    <span className={`vehicle-name ${record?.is_manual_checkin ? 'text-[#f44336]' : ''}`}>
                        {moment(text)?.format('YYYY/MM/DD h:mm a')}
                    </span>
                ) : (
                    ''
                ),
            ellipsis: true,
        },
        {
            title: '退勤時間',
            dataIndex: 'check_out_time',
            key: 'check_out_time',
            render: (text, record) =>
                text ? (
                    <span className={`vehicle-name ${record?.is_manual_checkout
                        ? 'text-[#f44336]' : ''}`}>
                        {moment(text)?.format('YYYY/MM/DD h:mm a')}
                    </span>
                ) : (
                    ''
                ),
            ellipsis: true,
        },
        {
            title: '合計労働時間',
            dataIndex: 'total_time',
            key: 'total_time',
            render: (text, record) => (
                <span className="vehicle-name">{text || '0'}</span>
            ),
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'action',
            width: 260,
            align: 'center',
            render: (_, record) => (
                <div className="flex items-center gap-[4px]">
                    <button
                        onClick={() =>
                            navigate(
                                `detail/${record?.uid}?worker_id=${record?.worker_id}`,
                                {
                                    state: {
                                        submit_date: record?.submit_date,
                                        checkin_item_id: record?.checkin_item_id,
                                        checkout_item_id: record?.checkout_item_id,
                                        worker_id: record?.worker_id
                                    }
                                }
                            )
                        }
                        className="search-user-button !w-[72px] d-flex flex-column height36"
                        type="button"
                    >
                        詳細
                    </button>
                    <button
                        onClick={() =>
                            navigate(
                                `edit/${record?.uid}?worker_id=${record?.worker_id}`,
                                {
                                    state: {
                                        submit_date: record?.submit_date,
                                        checkin_item_id: record?.checkin_item_id,
                                        checkout_item_id: record?.checkout_item_id,
                                        worker_id: record?.worker_id
                                    }
                                }
                            )
                        }
                        className="search-user-button !w-[72px] d-flex flex-column height36"
                        type="button"
                    >
                        編集
                    </button>
                    <button
                        onClick={() => handleOpenDelete(record)}
                        className="search-user-button !bg-[#FF5045] !w-[72px] d-flex flex-column height36"
                        type="button"
                    >
                        削除
                    </button>
                </div>
            ),
        },
    ];
    const isValidDateFilter = isValidDate(formik?.values?.start_date, formik?.values?.end_date);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const renderContentPreview = (src) => {
        if (!src) return;
        return <Worker workerUrl={`${process.env.REACT_APP_WORKER_URL}`}>
            <Viewer
                fileUrl={src}
                plugins={[
                    defaultLayoutPluginInstance,
                ]}
                localization={jp_JP as unknown as LocalizationMap}
                defaultScale={SpecialZoomLevel.PageWidth}
            />
        </Worker>
    }
    return (
        <StylesWorkingTimesManagements>
            <ModalDelete>
                <div
                    className="modal-create-user d-flex flex-column"
                    style={{ height: '152px' }}
                >
                    <div className="modal-create-user-title d-flex flex-row">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            onClick={closeDelete}
                        >
                            キャンセル
                        </button>
                        <button
                            onClick={handleDelete}
                            className="modal-create-user-button2 d-flex flex-row"
                        >
                            はい
                        </button>
                    </div>
                </div>
            </ModalDelete>
            <StylesDialog6Months
                onClose={() => { setIsShowPopup(false), setIsErrorDate(true), setMonth(''), setIdWorker(0), setIsErrorWorker(true)}}
                sx={{
                    width: '100%',
                    '.MuiPaper-root': {
                        width: "unset",
                        height: "unset",
                        margin: '0px',
                        minHeight: '250px',
                        minWidth: '350px'
                    },

                    '.rpv-core__inner-container': {
                        paddingBottom: '30px !important',
                    },
                }}
                open={isShowPopup}
            >
                <DialogContent>
                    <div className='filterBoxTop'>
                        <span>社員<span className='text-[#f44336]'>*</span></span>
                        <div className='wrapSelectField'>
                            <Autocomplete
                                className="w-full border-[1px] border-[#f3f3f3] rounded-[6px] bg-[#f3f3f3]"
                                noOptionsText="該当なし"
                                onChange={(event, newValue: any) => {
                                    if (newValue?.value) {
                                        setIdWorker(newValue?.value);
                                        setIsErrorWorker(false)
                                    } else {
                                        setIsErrorWorker(0);
                                        setIsErrorWorker(true)
                                    }

                                }}
                                options={workerOptions}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                                //clearIcon={<CloseIcon fontSize="small" />}
                            />
                        </div>
                    </div>
                    <div>
                        <span>期間<span className='text-[#f44336]'>*</span></span>
                        <DatePicker
                            className="w-full border-[1px] border-[#f3f3f3] rounded-[6px] px-[12px] py-[4px] bg-[#f3f3f3]"
                            name="start_date"
                            selected={month}
                            onChange={(val) => {
                                if (val) {
                                    setMonth(val)
                                    setIsErrorDate(false)
                                } else {
                                    setMonth(null)
                                    setIsErrorDate(true)
                                }
                            }}
                            dateFormat="yyyy/MM"
                            autoComplete="off"
                            showMonthYearPicker
                            locale="ja"
                        />
                    </div>

                    {/*{isErrorDate && <p className='flex justify-end text-[#f44336] mb-0'>必須です。</p>}*/}
                    <div className='flex items-center justify-end mt-[8px]'>
                        <button
                            type="submit"
                            className="bg-main px-[30px] py-[4px] rounded-[6px] text-white h-[31px] mt-[5px]"
                            onClick={() => { getPdfWorkingTime(idWorker, new Date(month)?.getMonth() + 1, new Date(month)?.getFullYear()) }}
                            disabled={isErrorWorker || isErrorDate || disabledBtnSubmit1Month}
                        >
                            OK
                        </button>
                    </div>
                </DialogContent>
            </StylesDialog6Months>
            <StylesDialog6Months
                onClose={() => {setIsShowPopup6Months(false), setStartMonth(''), setIdWorker6Months(0), setIsErrorStartMonth(true), setIsErrorWorker6Month(true)}}
                sx={{
                    width: '100%',
                    '.MuiPaper-root': {
                        width: "unset",
                        margin: '0px',
                        minWidth: '350px'
                    },

                    '.rpv-core__inner-container': {
                        paddingBottom: '30px !important',
                    },
                }}
            open={isShowPopup6Months}
            >
                <DialogContent>
                    <div className='filterBoxTop'>
                        <span>社員<span className='text-[#f44336]'>*</span></span>
                        <div className='wrapSelectField'>
                            <Autocomplete
                                className="w-full border-[1px] border-[#f3f3f3] rounded-[6px] bg-[#f3f3f3]"
                                noOptionsText="該当なし"
                                onChange={(event, newValue: any) => {
                                    if(newValue?.value) {
                                        setIdWorker6Months(newValue?.value);
                                        setIsErrorWorker6Month(false)
                                    } else {
                                        setIdWorker6Months(0);
                                        setIsErrorWorker6Month(true)
                                    }
                                    
                                }}
                                options={workerOptions}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <span>期間<span className='text-[#f44336]'>*</span></span>
                        <DatePicker
                            className="w-full border-[1px] border-[#f3f3f3] rounded-[6px] px-[12px] py-[4px] bg-[#f3f3f3]"
                            name="start_date"
                            selected={startMonth}
                            onChange={(val) => {
                                if (val) {
                                    setStartMonth(val);
                                    setIsErrorStartMonth(false)
                                } else {
                                    setStartMonth('');
                                    setIsErrorStartMonth(true)
                                }
                            }}
                            dateFormat="yyyy/MM"
                            autoComplete="off"
                            showMonthYearPicker
                            locale="ja"
                        />
                    </div>
                    <div className='flex items-center justify-end mt-[12px]'>
                        <button
                            type="submit"
                            className="bg-main px-[30px] py-[4px] rounded-[6px] text-white h-[31px] mt-[5px]"
                            onClick={getPdfWorkingTime6Months}
                            disabled={disabledBtnSubmit6Months || isErrorStartMonth || isErrorWorker6Months}
                        >
                            OK
                        </button>
                    </div>
                </DialogContent>
            </StylesDialog6Months>
            {srcPDF && (
                <Dialog
                    onClose={() => setSrcPdf(null)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: "100%",
                            height: "100%",
                            margin: '0px',
                            minWidth: '75vw'
                        },
                       
                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!srcPDF}
                >
                    <DialogContent>
                        {
                            renderContentPreview(srcPDF)
                        }
                    </DialogContent>
                </Dialog>
            )}
            {srcPDF6Months && (
                <Dialog
                    onClose={() => setSrcPdf6Months(null)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: "100%",
                            height: "100%",
                            margin: '0px',
                            minWidth: '75vw'
                        },
                       
                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!srcPDF6Months}
                >
                    <DialogContent>
                        {
                            renderContentPreview(srcPDF6Months)
                        }
                    </DialogContent>
                </Dialog>
            )}
            <div className="flex items-center justify-between">
                <p className="working_times--title mb-0">勤怠管理</p>
                <div>
                    <Button
                        onClick={() => { setIsShowPopup6Months(true) }}
                        sx={{ mb: '20px', fontWeight: 'bold', padding: '6px 18px', marginRight: '10px' }}
                        variant="outlined"
                    >
                        出勤簿 PDF
                    </Button>
                    <Button
                        onClick={() => {setIsShowPopup(true)}}
                        sx={{ mb: '20px', fontWeight: 'bold', padding: '6px 18px', marginRight: '10px' }}
                        variant="outlined"
                    >
                        PDF
                    </Button>
                    <Button
                        onClick={() => navigate('create')}
                        sx={{ mb: '20px', fontWeight: 'bold', padding: '6px 18px' }}
                        variant="outlined"
                    >
                        <Add /> 新規作成
                    </Button>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <Grid spacing={4} container className="filterBoxTop">
                    <Grid item xs={2.5} className="filterBoxCol">
                        <span className="fieldLabel">部署</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                open={isOpenDepartment}
                                onOpen={() => setIsOpentDepartment(true)}
                                onClose={() => setIsOpentDepartment(false)}
                                onFocus={() => setIsOpentDepartment(true)}
                                multiple
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'department_id',
                                        newValue,
                                    );
                                }}
                                noOptionsText="該当なし"
                                options={departmentOptions}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2.5} className="filterBoxCol">
                        <span className="fieldLabel">役職</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                open={isOpenPosition}
                                onOpen={() => setIsOpenPosition(true)}
                                onClose={() => setIsOpenPosition(false)}
                                onFocus={() => setIsOpenPosition(true)}
                                noOptionsText="該当なし"
                                multiple
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'position_id',
                                        newValue,
                                    );
                                }}
                                options={positionOptions}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2.5} className="filterBoxCol">
                        <span className="fieldLabel">社員</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                open={isOpenWorker}
                                onOpen={() => setIsOpenWorker(true)}
                                onClose={() => setIsOpenWorker(false)}
                                onFocus={() => setIsOpenWorker(true)}
                                noOptionsText="該当なし"
                                onChange={(event, newValue: any) => {
                                    formik.setFieldValue(
                                        'worker_id',
                                        newValue?.value || '',
                                    );
                                }}
                                options={workerOptions}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2.5}>
                        <span className="fieldLabel">キーワード</span>
                        <Box
                            sx={{
                                width: '100%',
                                '.MuiTextField-root': {
                                    width: '100%',
                                    borderRadius: '8px',
                                    marginRight: '12.5px',
                                    backgroundColor: '#f3f3f3'
                                },
                                '.MuiInputBase-input': {
                                    padding: '0px !important',
                                },
                            }}
                            className="wrapTextField"
                        >
                            <TextField
                                fullWidth
                                hiddenLabel
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search sx={{ color: '#000', fontSize: '20px' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                name="free_word"
                                type="text"

                                variant="outlined"
                                onChange={formik.handleChange}
                                value={formik.values.free_word}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={5} className='!ml-[32px]'>
                        <div className="flex items-end w-full justify-between gap-x-[12px]">
                            <div className="flex-1 relative">
                                <label htmlFor="start_date" className='text-[12px] text-[#1c1e21]'>期間</label>
                                <DatePicker
                                    id="start_date"
                                    className="w-full border-[1px] border-[#f3f3f3] rounded-[6px] px-[12px] py-[4px] bg-[#f3f3f3]"
                                    name="start_date"
                                    selected={formik.values.start_date}
                                    onChange={(val) => {
                                        formik.setFieldValue('start_date', val);
                                    }}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                                <label htmlFor="start_date">
                                    <img
                                        className="absolute right-[9px] bottom-[7px]"
                                        width={'16px'}
                                        height={'16px'}
                                        src={calendarIcon}
                                    />
                                </label>
                            </div>
                            <span className="flex-0 pb-[8px]">~</span>
                            <div className="flex-1 relative">
                                <label htmlFor="start_date"></label>
                                <DatePicker
                                    id="end_date"
                                    className="w-full border-[1px] border-[#f3f3f3] rounded-[6px] px-[12px] py-[4px] bg-[#f3f3f3]"
                                    dateFormat="yyyy/MM/dd"
                                    name="end_date"
                                    autoComplete="off"
                                    locale="ja"
                                    selected={formik.values.end_date}
                                    onChange={(val) => {
                                        formik.setFieldValue('end_date', val);
                                    }}
                                />
                                <label htmlFor="end_date">
                                    <img
                                        className="absolute right-[9px] bottom-[7px]"
                                        width={'16px'}
                                        height={'16px'}
                                        src={calendarIcon}
                                    />
                                </label>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={2} className='!ml-[32px]'>
                        <span className="fieldLabel h-[18px]"></span>
                        <button
                            type="submit"
                            className="bg-main px-[30px] py-[4px] rounded-[6px] text-white h-[31px] mt-[5px]"
                        >
                            検索
                        </button>
                    </Grid>
                </Grid>
                <div className="text-[#f44336] ">{!isValidDateFilter ? "終了は開始日時以降を入力してください。" : ""}</div>
            </form>
            <div className="mt-[14.5px]">
                <ConfigProvider locale={ja_JPP}>
                    <Table
                        rowKey="id"
                        columns={columns}
                        pagination={{ showSizeChanger: false, position: ["bottomCenter"], }}
                        dataSource={workingTimeLists}
                    />
                </ConfigProvider>
                {/*<div className="flex justify-center">
                    <CustomPagination
                        totalCount={totalCount || 0}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) => console.log(page)}
                    />
                </div>*/}
                <div className='flex items-center justify-end'>
                    <div>
                        <p className='font-semibold'>勤務日数: {total?.total_working_days || '0'}</p>
                        <p className='font-semibold'>合計時間: {total?.total_working_times || '0'}</p>
                    </div>
                </div>
            </div>
        </StylesWorkingTimesManagements>
    );
};
const WorkingTimesManagement = (props: any) => {
    return (
        <WorkingTimesManagementLayout {...useWorkingTimesManagements(props)} />
    );
};

export default WorkingTimesManagement;
