import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message/message';
import AuthService from '../../services/authService';
import AuthServiceSP from 'services/authServiceSP';

let storage = localStorage.getItem('user');
const user = storage !== null && JSON.parse(storage);
export const register = createAsyncThunk(
    'auth/register',
    async (body: any, thunkAPI) => {
        try {
            const response = await AuthService.register(body);
            thunkAPI.dispatch(setMessage(response.data.message));
            return response.data;
        } catch (error) {
            // const message =
            //   (error.response &&
            //     error.response.data &&
            //     error.response.data.message) ||
            //   error.message ||
            //   error.toString();
            // thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    },
);

export const login = createAsyncThunk(
    'auth/login',
    async (body: any, thunkAPI) => {
        try {
            const data = await AuthService.login(body);
            return { user: data };
        } catch (error) {
            // const message =
            //   (error.response &&
            //     error.response.data &&
            //     error.response.data.message) ||
            //   error.message ||
            //   error.toString();
            // thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    },
);

export const loginSP = createAsyncThunk(
    'auth/loginSP',
    async (body: any, thunkAPI) => {
        try {
            const response: any = await AuthServiceSP.login(body);
            localStorage.setItem('user', JSON.stringify(response));
            return { user: response.data };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    },
);

export const refreshToken = createAsyncThunk(
    'auth/refresh-token',
    async (body: any, thunkAPI) => {
        try {
            const data = await AuthService.login(body);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(error.response.data);
        }
    },
);

export const logout = createAsyncThunk('auth/logout', async () => {
    await AuthService.logout();
});

export const logoutSP = createAsyncThunk('auth/logoutSP', async () => {
    try {
        await AuthService.logout();
        window.location.href = "/login"
    } catch (error) {
        console.log(error);
    }
});
const initialState = user?.access_token
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(
                register.fulfilled,
                (state: { isLoggedIn: boolean }, _action: any) => {
                    state.isLoggedIn = false;
                },
            )

            .addCase(
                register.rejected,
                (state: { isLoggedIn: boolean }, _action: any) => {
                    state.isLoggedIn = false;
                },
            )

            .addCase(
                login.fulfilled,
                (
                    state: { isLoggedIn: boolean; user: any },
                    action: { payload: { user: any } },
                ) => {
                    state.isLoggedIn = true;
                    state.user = action.payload.user;
                },
            )

            .addCase(
                login.rejected,
                (state: { isLoggedIn: boolean; user: null }, _action: any) => {
                    state.isLoggedIn = false;
                    state.user = null;
                },
            )

            .addCase(
                logout.fulfilled,
                (state: { isLoggedIn: boolean; user: null }, _action: any) => {
                    state.isLoggedIn = false;
                    state.user = null;
                },
            )

            .addCase(
                refreshToken.fulfilled,
                (state: { isLoggedIn: boolean }, _action: any) => {
                    state.isLoggedIn = false;
                },
            )

            .addCase(
                refreshToken.rejected,
                (state: { isLoggedIn: boolean }, _action: any) => {
                    state.isLoggedIn = false;
                },
            );
    },
    reducers: {},
});

const { reducer } = authSlice;
export default reducer;
