import { Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import { useModal } from 'react-hooks-use-modal';
import { FC, Dispatch, SetStateAction, useState } from "react";
import { formatMoney } from "utils/helper/helpers";
import StyledBillList from "./styles";
import billService from "services/billService";
import EmptyImage from "components/EmptyImage";

type IProps = {
  data: Record<string, any>[];
  setOpenEdit: Dispatch<SetStateAction<boolean>>;
  setSelectedBill: Dispatch<SetStateAction<any>>;
  setProjectId: Dispatch<SetStateAction<number | null>>;
  pageQueries: any;
  setPageQueries: any;
  fetchBills: any;
};

enum DATE_TIME_FORMAT {
  DATE = 'YYYY/MM/DD',
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#C7DCF5',
    color: '#1C1E21',
    fontWeight: 600,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: 'Zen Kaku Gothic Antique',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1C1E21',
    backgroundColor: '#FFFFFF',
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, th:nth-child(7) div:nth-child(4) th': {
    borderBottom: 0,
  },
  th: {
    borderRightWidth: '1px',
    borderRightColor: '#F5F5F5',
    borderBottomColor: '#F5F5F5',
    height: '42px',
    padding: '4px 16px',
  },
  'th:nth-child(7)': {
    padding: 'unset',
  },
  'th:nth-child(7) th': {
    flex: 1,
    textAlign: 'center',
  },
  'th:nth-child(7) th:nth-child(5)': {
    paddingBottom: '16px',
  },
  'th:nth-child(7) th:nth-child(5), th:nth-child(8)': {
    borderRightWidth: 0,
  },
}));

const header = [
  {
      title: '宛先',
      key: 'destination',
      width: '10%',
  },
  {
      title: '案件名',
      key: 'projectName',
      width: '10%',
  },
  {
      title: '請求日',
      key: 'billDate',
      width: '10%',
  },
  {
      title: '振込期限',
      key: 'paymentDeadline',
      width: '10%',
  },
  {
      title: '金額',
      key: 'price',
      width: '10%',
  },
  {
      title: '入金金額の合計',
      key: 'total_amount',
      width: '10%',
  },
  {
      title: '入金',
      key: 'payment',
      width: '10%',
  },
  {
      title: '操作',
      key: 'actions',
      width: '10%',
  },
];

const BillList: FC<IProps> = ({ 
  data = [], 
  setOpenEdit, 
  setSelectedBill, 
  setProjectId,
  pageQueries,
  setPageQueries,
  fetchBills
}) => {
  const [deleteRow, setDeleteRow] = useState<any>({});
  const [payment, setPayment] = useState<any>({});
  const [type, setType] = useState<any>({});
  const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
    'root',
    {
        preventScroll: true,
        closeOnOverlayClick: true,
    },
  );

  const handleDeleteBill = async () => {
    const response = await billService.deleteBill(
        deleteRow.id,
        deleteRow?.project?.id,
    );
    if (response !== null && response !== undefined) {
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        fetchBills({ page: 1, per_page: pageQueries.per_page });
        closeDelete();
    }
};

const handleDeletePayment = async () => {
    const response = await billService.deletePayment(
        payment.billing_id,
        payment.project_id,
        payment.id,
    );
    if (response !== null && response !== undefined) {
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        fetchBills({ page: 1, per_page: pageQueries.per_page });
        closeDelete();
    }
};

  return (
    <>
      <StyledBillList>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <TableContainer component={Paper} table-layout="auto">
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {header.map((item, index) => (
                      <StyledTableCell 
                        className="text-overflow1"
                        {...(index !== 0 && {
                          align: 'center',
                        })}
                        key={item.key} 
                        style={{ width: item.width }}
                      >
                        <div style={{ whiteSpace: 'nowrap' }}>{item.title}</div>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    data && data.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row" style={{ width: '10%', verticalAlign: 'top' }}>
                          <div className="text-overflow1" style={{ whiteSpace: 'nowrap' }}>
                            {row.client_company?.company?.name || ""}
                          </div>
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row" style={{ width: '10%', verticalAlign: 'top' }}>
                          <div className="text-overflow1" style={{ whiteSpace: 'nowrap' }}>
                            {row?.project?.name || ""}

                          </div>
                        </StyledTableCell>

                        <StyledTableCell align="center" component="th" scope="row" style={{ width: '10%', verticalAlign: 'top' }}>
                          <div className="text-overflow1" style={{ whiteSpace: 'nowrap' }}>
                            {
                              row.submitted_at &&
                                moment(row.submitted_at).format(DATE_TIME_FORMAT.DATE)
                            }

                          </div>
                        </StyledTableCell>

                        <StyledTableCell align="center" component="th" scope="row" style={{ width: '10%', verticalAlign: 'top' }}>
                          <div className="text-overflow1" style={{ whiteSpace: 'nowrap' }}>
                            {
                              row.payment_deadline &&
                                moment(row.payment_deadline).format(DATE_TIME_FORMAT.DATE)
                            }
                          </div>
                        </StyledTableCell>

                        <StyledTableCell align="center" component="th" scope="row" style={{ width: '10%', verticalAlign: 'top' }}>
                          <div className="text-overflow1" style={{ whiteSpace: 'nowrap' }}>
                            {
                              row.amount &&
                                '¥' + formatMoney(String(row.amount || 0))
                            }
                          </div>
                        </StyledTableCell>
                        
                        <StyledTableCell align="center" component="th" scope="row" style={{ width: '10%', verticalAlign: 'top' }}>
                          <div className="text-overflow1" style={{ whiteSpace: 'nowrap' }}>
                          {
                            row.total_amount_paid &&
                              '¥' + formatMoney(String(row.total_amount_paid || 0))
                          }
                          </div>
                        </StyledTableCell>

                        {/* TODO */}
                        <StyledTableCell align="center" component="th" scope="row" style={{ width: '10%' }}>
                          {row.payments && row.payments.map((detail) => (
                            <div className={`d-flex flex-row payment-wrapper ${row.payments.length === 1 && 'row-single'}`}>
                              <StyledTableCell component="th" scope="row">
                                  <div style={{ width: '100px' }} className="text-overflow1">
                                    {
                                      detail.paid_at &&
                                        moment(detail.paid_at).format(DATE_TIME_FORMAT.DATE)
                                    }
                                  </div>
                              </StyledTableCell>

                              <StyledTableCell component="th" scope="row">
                                  <div className="text-overflow1">
                                    {
                                      detail.amount &&
                                        '¥' + formatMoney(String(detail.amount || 0))
                                    }
                                  </div>
                              </StyledTableCell>

                              <StyledTableCell component="th" scope="row">
                                  <div className="d-flex flex-row">
                                      {/* <button className="button-tb" onClick={() => {
                                        setOpenEdit(true);
                                        setSelectedBill(row);
                                        setProjectId(row.project?.id || null);
                                      }}>編集</button> */}
                                      <button className="button-tb btn-delete" onClick={() => {
                                        setType('payment');
                                        setPayment(
                                            {
                                                id: detail.id,
                                                billing_id:
                                                    row.id,
                                                project_id:
                                                    row
                                                        ?.project
                                                        ?.id,
                                            },
                                        );
                                        openDelete();
                                      }}>削除</button>
                                  </div>
                              </StyledTableCell>
                            </div>
                          ))}
                        </StyledTableCell>
                        
                        <StyledTableCell align="center" component="th" scope="row" className="actions" style={{ minWidth: '200px' }}>
                            {/* <button className="button-tb" onClick={() => {
                              setOpenEdit(true);
                              setSelectedBill(row);
                              setProjectId(row.project?.id || null);
                            }}>入金</button> */}
                            <button className="button-tb" onClick={() => {
                              setOpenEdit(true);
                              setSelectedBill(row);
                              setProjectId(row.project?.id || null);
                            }}>編集</button>
                            <button className="button-tb btn-delete" onClick={() => {
                              setType('bill');
                              setDeleteRow(row);
                              openDelete();
                            }}>削除</button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                  {data?.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={8}>
                        <div className='text-center py-[40px]'>
                          <EmptyImage classNameCustom='inline-block' />
                          <p className='mt-[6px] text-[#00000040] text-sm'>データがありません</p>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </StyledBillList>
      <ModalDelete>
                <div
                    className="modal-create-user d-flex flex-column"
                    style={{ height: '152px' }}
                >
                    <div className="modal-create-user-title d-flex flex-row">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            onClick={closeDelete}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            onClick={() => {
                                if (type === 'bill') {
                                    handleDeleteBill();
                                } else {
                                    handleDeletePayment();
                                }
                            }}
                        >
                            はい
                        </button>
                    </div>
                </div>
            </ModalDelete>
    </>
  )
}

export default BillList;
