import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import purchaseRequestService from 'services/purchaseRequestService';
import { useModal } from 'react-hooks-use-modal';
import { IPurchase } from './type';

export type ReceivedProps = Record<string, never>;

const usePR = (props: ReceivedProps) => {
    const navigate = useNavigate();
    const [data, setData] = useState<IPurchase[]>([]);
    const [deleteData, setDeleteData] = useState<IPurchase>({
        content: '',
        created_at: '',
        id: 0,
        maker_worker_id: 0,
        name: '',
        status: '',
        updated_at: '',
        images_list: []
    });
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: true,
        },
    );

    const fetchPurchaseRequests = async (params = {}) => {
        try {
            const res =
                await purchaseRequestService.getListPurchaseRequests(
                    {
                        page: 1,
                        per_page: 0,
                        ...params
                    },
                );
                if(!(res as {purchase_requests: IPurchase[]})?.purchase_requests) return;
                const purchase_requests = (res as {purchase_requests: IPurchase[]})?.purchase_requests;
                setData(purchase_requests);
        } catch (error) {
            console.log(error)
        }
    };

    const handleDelete = async () => {
        try {
            await purchaseRequestService.deletePurchaseRequest(deleteData.id);
            fetchPurchaseRequests();
            closeDelete()
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchPurchaseRequests();
    }, []);

    return {
        ...props,
        data,
        navigate,
        handleDelete,
        setDeleteData,
        ModalDelete,
        openDelete,
        closeDelete
    };
};

export type Props = ReturnType<typeof usePR>;

export default usePR;
