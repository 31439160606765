import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { validation } from './schema';
import { ReceivedProps, IFormData } from '../type';
import InternalOJTService from 'services/internalOJTService';
import { useEffect, useState } from 'react';
interface IFile {
    file_name?: string;
    file_type?: string;
    image_path?: string;
    signed_id?: string;
    name?: string;
}
const useCreateInternalOJT = (props: ReceivedProps) => {
    const [internalOJT, setInternalOJT] = useState<any>({});
    const [currentData, setCurrenData] = useState<File>();
    const [fileData, setFileData] = useState<string | null>();
    const [typeFile, setTypeFile] = useState("");
    const [newFiles, setNewFiles] = useState<File[] >([]);
    const [showErrorFileSize, setShowErrorFileSize] = useState('');
    const [errorTypeFile, setErrorTypeFile] = useState('');
    const [oldFilesDetail, setOldFilesDetail] = useState<IFile[]>();
    const navigate = useNavigate();
    const initialValues: IFormData = {
        subject: '',
        content: '',
        files: '',
    };
    const { id } = useParams();

    const handleCreateInternalOJT = async (data: IFormData) => {
        if(!data?.subject?.trim() || !data?.content?.trim()) return;
        try {
            const formData = new FormData();
            const filesArr = data?.files ? [...data?.files] : [];
            formData.append('subject', data.subject.trim());
            formData.append('content', data.content.trim());
            filesArr.forEach((file, i) => {
                formData.append(`files[]`, file);
            });
            if(filesArr?.length <= 0) return;
            if (id) {
                if (newFiles && newFiles?.length > 0) {
                    let fileFDs = new FormData();
                    const newFilesArr = [...newFiles];
                    newFilesArr?.forEach((file) => {
                        fileFDs.append(`files[]`, file);
                    });
                    await InternalOJTService.addInternalOJTFile(id, fileFDs);
                }

                let oldFilesID = internalOJT?.files.map(item => item.signed_id);
                let currentfilesID = filesArr?.map(item => item.signed_id)
                let signedIdDelete = oldFilesID?.filter(x => !currentfilesID.includes(x));
                signedIdDelete &&
                    signedIdDelete.map(async (item) => {
                        await InternalOJTService.deleteInternalOJTFile(id, {
                            signed_id: item,
                        });
                    });

                const files = new FormData();
                filesArr.forEach((file, i) => {
                    files.append(`files[]`, file);
                });
                await InternalOJTService.updateInternalOJT(id, formData);
    
            } else {
                await InternalOJTService.createInternalOJT(formData);
            }
            navigate('/internal-ojt');
        } catch (error) {
            console.log(error)
         }
    };

    const getInternalOJTDetail = async () => {
        if (!id) return;
        try {
            const { internal_ojt }: any =
                await InternalOJTService.getInternalOJTDetail(id);
            setInternalOJT(internal_ojt);
            setOldFilesDetail(internal_ojt?.files)
            formik.resetForm({
                values: {
                    subject: internal_ojt?.subject,
                    content: internal_ojt.content,
                    files: internal_ojt?.files?.map(file => {
                        return {...file, name: file?.file_name}
                    }),
                },
            });
        } catch (error) {}
    };
    const formik = useFormik({
        initialValues,
        validationSchema: validation,
        onSubmit: (values) => handleCreateInternalOJT(values),
    });

    const handleFileUpload = async(e) => {
        let arrFiles: FileList | File[] = []
        arrFiles = Array.from(e.target.files);
        const findFilesBigger6MB = arrFiles?.filter((item) => item?.size > 6144 * 1024);
        const findFilesValid = arrFiles?.filter((item) => ((item?.type === 'image/gif' || item?.type === 'image/png' || item?.type === "image/jpeg" || item?.type === 'image/webp' || item?.type?.includes("pdf")) && item.size <= 6144 * 1024)).concat(newFiles);
        setShowErrorFileSize(findFilesBigger6MB?.length > 0 ? "6MB以上がアップロードできません。" : "")

        const findFileWrongType = arrFiles?.filter((item) => !(item?.type === 'image/gif' || item?.type === 'image/png' || item?.type === "image/jpeg" || item?.type === 'image/webp' || item?.type?.includes("pdf")));
        setErrorTypeFile(findFileWrongType?.length > 0 ? "ファイル形式が正しくありません。" : "")
        
        setNewFiles(findFilesValid)
        let oldFiles = oldFilesDetail?.map(item => {
            return {...item, name: item?.file_name}
        })
        if(oldFiles && oldFiles?.length > 0) {
            formik.setFieldValue('files', oldFiles.concat(findFilesValid));
        } else {
            formik.setFieldValue('files', findFilesValid);
        }
    };

    const handleViewFile = async (values) => {
        try {
            setCurrenData(values);
            setTypeFile(values?.type || values?.file_type)
            const response = await fetch(values?.image_path);
            const data = await response.blob();
            const file = window.URL.createObjectURL(data);
            values?.image_path ? setFileData(file) : setFileData(URL.createObjectURL(values));
        } catch (error) {}
    };

    const handleDeleteFile = async (signed_id: string, arrFile, lastModified: number | string, index: number) => {
        if (signed_id) {
            const arrFilters = arrFile?.filter(item => item?.signed_id !== signed_id)
            formik.setFieldValue('files', arrFilters);
            setOldFilesDetail(arrFilters)
        } else {
            if(lastModified) {
                const arrFilters = arrFile?.filter((item, idx) => !(item?.lastModified == lastModified && idx === index))
                formik.setFieldValue('files', arrFilters);
                const arrFiltersSendToBE = arrFile?.filter((item, idx) => !(item?.lastModified == lastModified && idx === index) && item.lastModified)
                setNewFiles(arrFiltersSendToBE)
            }
            
        }

    }

    useEffect(() => {
        getInternalOJTDetail();
    }, []);

    return {
        ...props,
        formik,
        navigate,
        handleFileUpload,
        handleViewFile,
        currentData,
        fileData,
        setFileData,
        id,
        typeFile,
        handleDeleteFile,
        showErrorFileSize,
        errorTypeFile
    };
};
export type Props = ReturnType<typeof useCreateInternalOJT>;

export default useCreateInternalOJT;
