import { useState, useEffect } from 'react';
import { Controller, useForm, FieldValues } from 'react-hook-form';
import { Grid, Autocomplete, TextField, DialogTitle } from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import createWorkingLogValidation from 'utils/validation/registers/workingLogs/createWorkingLogValidation';
import { getFilePreview } from 'utils/helper/file';
import calendarIcon from 'assets/icons/calendar.png';
import deleteIcon from 'assets/icons/deleteIcon.svg';
import magnifyingGlass from 'assets/icons/MagnifyingGlass.svg';
import { StyledCreateWorkingLog, StyledDialog } from './style';
import Dropdown from 'react-dropdown';
import workersService from 'services/wokersService';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import PlanService from 'services/planServiceSP';
import WorkingLogService from 'services/workingLogServiceSP';
import { getRole } from 'utils/helper/userConfigHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Input = styled('input')({
    display: 'none !important',
});

type FormProps<T extends FieldValues = FieldValues> = {
    defaultValues: T;
};
interface IFile {
    image_path?: string;
    signed_id?: string;
    file?: File;
}
const CreateWorkingLogSP = () => {
    const navigate = useNavigate();
    const [plans, setPlans] = useState<any[]>([]);
    const [workers, setWorkers] = useState<any[]>([]);
    const { t } = useTranslation();
    const [fileUpload, setFileUpload] = useState<any[]>([]);
    const { id, detail } = useParams();
    const [searchParams] = useSearchParams();
    const isDetail = searchParams.get('detail');
    const workingLogId = searchParams.get('workingLogId');
    const [workingLog, setWorkingLog] = useState<any>(null);
    const [hasUploadedImagesDeleted, setHasUploadedImagesDeleted] =
        useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<IFile>();
    const [openDialog, setOpenDialog] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [isDisable, setIsDisable] = useState(false);
    const [errorSize, setErrorSize] = useState(false);
    const [errorQuantityFiles, setErrorQuantityFiles] = useState(false);
    const [errorTypeFile, setErrorTypeFile] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [errorName, setErrorname] = useState(false);
    const role = getRole();
    const { userData }: any = useSelector((state: RootState) => state.users);
    const {
        register,
        control,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
        getValues,
        reset,
    } = useForm<FormProps['defaultValues']>({
        defaultValues: {
            worker: {
                value: userData?.worker?.id,
                label: userData?.worker?.name,
            },
            worked_at: new Date(),
        },
    });

    const getWorkingLogsDetails = async () => {
        try {
            const response: any = await WorkingLogService.getWorkingLogDetail(
                Number(workingLogId),
                { project_id: id },
            );
            setWorkingLog(response?.working_log);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRemoveUploadedImage = (signed_id) => {
        setFileUpload((prev) => {
            const newFilesArray = [...prev];
            let target = newFilesArray.find(
                (item) => item.signed_id === signed_id,
            );
            const targetIndex = newFilesArray.findIndex(
                (item) => item.signed_id === signed_id,
            );
            target = { ...target, type: 'destroy' };
            newFilesArray[targetIndex] = target;
            return newFilesArray;
        });
        setErrorQuantityFiles(false);
        if (!hasUploadedImagesDeleted) {
            setHasUploadedImagesDeleted(true);
        }
    };

    const handleUpdateDeletedImage = () => {
        const promisesArray: Promise<unknown>[] = [];
        fileUpload.forEach((item) => {
            if (item.type == 'destroy' && !item?.file?.lastModified) {
                promisesArray.push(
                    new Promise(async (resolve, reject) => {
                        await WorkingLogService.deleteImage(
                            Number(workingLogId),
                            { project_id: id, signed_id: item.signed_id },
                        )
                            .then(() => resolve(true))
                            .catch((err) => reject(err));
                    }),
                );
            }
        });
        return Promise.all(promisesArray);
    };

    const handleUpdateNewImage = async () => {
        const files = fileUpload.filter((item) => item?.type == 'upload');
        if (files.length <= 0) return;
        const formData: any = new FormData();
        formData.append('project_id', id);
        formData.append('id', workingLogId);
        files.forEach((item) => {
            if (item.type == 'upload') {
                formData.append('working_log_images[]', item?.file);
            }
        });

        try {
            const response = await WorkingLogService.uploadImage(
                Number(workingLogId),
                formData,
            );
            if (response) {
                return true;
            }
        } catch (error) {
            // empty
        }
    };

    const handleUploadFile = async (files) => {
        let current = fileUpload ? [...fileUpload] : [];
        let currentUpload = fileUpload ? [...fileUpload] : [];
        if (files.length > 0) {
            const fileArr = [...files];
            fileArr?.map((item) => {
                current.push({ file: item, type: 'upload', size: item?.size });
            })
            let total = current?.reduce((sum, item) => {
                return sum + item?.size
            }, 0)
            let currentFilterWrongType = current?.filter(item => !(item?.file?.type === 'image/jpeg' || item?.file?.type === 'image/jpg' || item?.file?.type === 'image/svg+xml' || item?.file?.type === 'image/png' || item?.file?.type === 'image/gif' || item?.file?.type === 'image/tiff' || (item?.file?.name?.includes('.heic') && item?.file?.type === '') || (item?.file?.name?.includes('.heif') && item?.file?.type === '')) && !item?.signed_id)
            
            let currentFilterValidType = current?.filter(item => (item?.file?.type === 'image/jpeg' || item?.file?.type === 'image/jpg' || item?.file?.type === 'image/svg+xml' || item?.file?.type === 'image/png' || item?.file?.type === 'image/gif' || item?.file?.type === 'image/tiff' || (item?.file?.name?.includes('.heic') && item?.file?.type === '') || (item?.file?.name?.includes('.heif') && item?.file?.type === '')) || item?.signed_id)

            if(currentFilterWrongType?.length > 0) {
                setErrorTypeFile(true)
            }else{
                setErrorTypeFile(false)
            }
            if(total > 104857600 || current?.length > 100) {
                setErrorSize(total > 104857600 ? true : false)
                setErrorQuantityFiles(current?.length > 100 ? true : false)
                const arrRemoveFileUpload = current?.filter(item => item?.type != 'upload').concat(currentUpload?.filter(item => item?.type == 'upload'))
                setFileUpload(arrRemoveFileUpload);
            } else {
                setFileUpload(currentFilterValidType);
                setErrorQuantityFiles(false)
                setErrorSize(false)
            }
            
        }
    };

    const removeImage = (lastModified, index) => {
        let current = [...fileUpload];
        let res = current?.map(((item, index) => {
            return {...item, ind: index}
        }))
        current = res.filter((item) => !(item?.file?.lastModified == lastModified && item?.ind == index));
    
        setFileUpload(current);
        setErrorQuantityFiles(false)
        setErrorTypeFile(false)
    };
    const handleShowError = (arrFiles) => {
        if(!arrFiles) return;
        let total = 0;
        arrFiles.filter(
            (item) => item?.type != 'destroy',
        )?.map(it => {
            total += it?.size
        })
        setTotalSize(total)                              
    }
    const getListWorkers = async () => {
        try {
            const responseWorker = await workersService.getListWorkers();
            if (responseWorker !== null && responseWorker !== undefined) {
                setWorkers(responseWorker.workers);
            }
        } catch (error) {
            // empty
        }
    };

    const getPlansLists = async () => {
        try {
            const response: any = await PlanService.getListPlans({
                page: 1,
                per_page: 0,
                project_id: id,
            });
            setValue('plan_id', {
                value: response?.plans?.[0].id,
                label: response?.plans?.[0].name,
            });
            setPlans(
                response?.plans.map((plan) => ({
                    label: plan.name,
                    value: plan.id,
                })) || [],
            );
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getListWorkers();
        getPlansLists();
    }, []);

    useEffect(() => {
        if (!!workingLogId && plans.length > 0 && workers.length > 0) {
            getWorkingLogsDetails();
        }
    }, [workingLogId, plans, workers]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (workingLog) {
            reset({
                name: workingLog?.name,
                note: workingLog?.note,
                plan_id: {
                    label: workingLog?.plan?.name,
                    value: workingLog?.plan?.id,
                },
                worked_at: new Date(workingLog?.worked_at),
                worker: {
                    label: workingLog?.worker?.name,
                    value: workingLog?.worker?.id,
                },
            });
            setFileUpload(workingLog?.working_log_images?.[0]?.images || []);
        }
    }, [workingLog]);
    useEffect(() => {
        handleShowError(fileUpload)
    }, [fileUpload])
    const onCreate = async (data) => {
        if(totalSize > 104857600 || errorQuantityFiles) return;
        if(!data?.name.trim()) {
            setErrorname(true);
            return;
        }
        setIsDisable(true);
        try {
            const formData = new FormData();

            formData.append('project_id', id as any);
            formData.append(
                'worker_id',
                data?.worker?.value || (userData?.worker as any).id,
            );
            formData.append('name', data?.name.trim());
            formData.append('note', data?.note);
            formData.append('worked_at', data?.worked_at);
            formData.append('plan_id', data?.plan_id?.value);

            if (!!workingLogId) {
                if (hasUploadedImagesDeleted) {
                    await handleUpdateDeletedImage();
                }
                await handleUpdateNewImage();

                await WorkingLogService.updateWorkingLog(
                    Number(workingLogId),
                    formData,
                );
            } else {
                fileUpload?.forEach((file) => {
                    formData.append('working_log_images[]', file.file);
                });
                await WorkingLogService.createWorkingLog(formData);
            }
            setIsDisable(false);
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <StyledCreateWorkingLog>
            <StyledDialog
                onClose={() => { setSelectedImage({}); setOpenDialog(false); setSelectedImageIndex(0) }}
                sx={{
                    width: '100%',
                    '.MuiPaper-root': {
                        width: "unset",
                        height: "unset",
                        margin: '0px',
                    },

                    '.rpv-core__inner-container': {
                        paddingBottom: '30px !important',
                    },
                }}
                open={openDialog}
                //fullWidth
                //fullScreen
            >
                <DialogTitle className='flex justify-end'>
                    <span onClick={() => { setSelectedImage({}); setOpenDialog(false); setSelectedImageIndex(0) }}><CloseIcon /></span>
                </DialogTitle>
                <DialogContent>
                    <Swiper
                        zoom={true}
                        navigation={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Zoom, Navigation, Pagination]}
                        className="mySwiper"
                        initialSlide={selectedImageIndex}
                    >
                        {fileUpload &&
                            fileUpload
                                ?.filter(
                                    (item, index) => item?.type != 'destroy',
                                )
                                ?.map((item, idx) => {
                                    return (
                                        <SwiperSlide>
                                            <div className="swiper-zoom-container">
                                                <img src={
                                                    item?.image_path
                                                        ? item.image_path
                                                        : getFilePreview(
                                                            item?.file,
                                                        )
                                                }
                                                    onClick={() => { setSelectedImage(item); setOpenDialog(true) }}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                    </Swiper>
                </DialogContent>
            </StyledDialog>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onCreate)}>
                <div className="formTitle">日報</div>
                <div className="formFieldRow">
                    <p>工程</p>
                    <Controller
                        name="plan_id"
                        control={control}
                        render={({ field }) => (
                            <>
                                <Dropdown
                                    disabled={!!isDetail || !plans.length}
                                    {...register(
                                        'plan_id',
                                        createWorkingLogValidation(t).plan_id(),
                                    )}
                                    {...field}
                                    className=" dropdown-create-user1 height36 mt-[16px]"
                                    controlClassName="dropdown-control-user height36"
                                    options={plans}
                                    placeholder=""
                                />
                            </>
                        )}
                    />
                    {errors.plan_id && (
                        <span className="error">{errors.plan_id.message}</span>
                    )}
                </div>
                <div className="formFieldRow">
                    <p>作業名</p>
                    <input
                        autoComplete="off"
                        type="name"
                        disabled={!!isDetail}
                        {...register(
                            'name',
                        )}
                        className="textfield"
                        onChange={(e) => setErrorname(!e.target.value.trim() ? true : false)}
                    />
                    {errorName && <span className="error">必須です。</span>}
                </div>
                <div className="formFieldRow">
                    <p>作業日</p>
                    <div className="datepicker-wrapper">
                        <Controller
                            name="worked_at"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    disabled={!!isDetail}
                                    selected={field.value}
                                    {...register(
                                        'worked_at',
                                        createWorkingLogValidation(
                                            t,
                                        ).worked_at(),
                                    )}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    autoComplete="off"
                                    locale="ja"
                                    id="worked_at"
                                />
                            )}
                        />
                        <label htmlFor="worked_at">
                            <img
                                className="calendar-datepicker-icon"
                                src={calendarIcon}
                            ></img>
                        </label>
                    </div>
                    {errors.worked_at && (
                        <span className="error">
                            {errors.worked_at?.message}
                        </span>
                    )}
                </div>
                {role === 'admin' || role === 'manager' && (
                    <div className="formFieldRow">
                        <p>担当</p>
                        <div className="d-flex" style={{ width: '100%' }}>
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                }}
                            >
                                <img
                                    src={magnifyingGlass}
                                    alt="magnifying glass icon"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '10px',
                                        transform: 'translate(0%, -50%)',
                                    }}
                                />
                                <Controller
                                    name="worker"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Dropdown
                                                disabled={!!isDetail}
                                                {...register(
                                                    'worker',
                                                    createWorkingLogValidation(
                                                        t,
                                                    ).worker(),
                                                )}
                                                {...field}
                                                className="dropdown-create-user1 height36 mt-[16px]"
                                                controlClassName="dropdown-control-user height36"
                                                options={workers.map(
                                                    (item) => ({
                                                        value: item.id,
                                                        label: item.name,
                                                    }),
                                                )}
                                                placeholder=""
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        {errors.worker && (
                            <span className="error">
                                {errors.worker.message}
                            </span>
                        )}
                    </div>
                )}
                <div className="formFieldRow">
                    <p>備考</p>
                    <textarea
                        disabled={!!isDetail}
                        autoComplete="off"
                        className="textarea"
                        {...register(
                            'note',
                        )}
                    />
                    {errors.note && (
                        <span className="error">{errors.note.message}</span>
                    )}
                </div>
                <div className="formFieldRow">
                    <p>画像</p>
                    <Grid container>
                        <Grid item xs={3}>
                            <label
                                htmlFor="contained-button-file"
                                className="file-uploader-wrapper"
                            >
                                <Input
                                    accept="image/*, .heic, .heif"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={(e) =>
                                        handleUploadFile(e.target.files)
                                    }
                                    disabled={!isDetail ? false : true}
                                />
                                <div className={`addFileBtn mb-[12px] ${isDetail ? 'opacity-70' : ''}`}>追加</div>
                            </label>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="imagesContainer">
                                {fileUpload &&
                                    fileUpload
                                        .filter(
                                            (item, index) => item?.type != 'destroy',
                                        )
                                        .map((item, idx) => {
                                            return (
                                                <div
                                                    className="imageWrapper"
                                                    key={`image-preview-${idx}`}
                                                >
                                                    <img
                                                        src={
                                                            item?.image_path
                                                                ? item.image_path
                                                                : getFilePreview(
                                                                      item?.file,
                                                                  )
                                                        }
                                                        onClick={() => {setSelectedImage(item); setOpenDialog(true); setSelectedImageIndex(idx)}}
                                                    />
                                                    {!isDetail && (
                                                        <img
                                                            className="deleteIcon"
                                                            src={deleteIcon}
                                                            onClick={() =>
                                                                {
                                                                item.signed_id
                                                                    ? handleRemoveUploadedImage(
                                                                          item.signed_id,
                                                                      )
                                                                    : removeImage(
                                                                          item?.file?.lastModified, idx
                                                                      )
                                                                }
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            );
                                        })}
                            </div>
                        </Grid>
                        
                        <span className="error">
                            {totalSize > 104857600 || errorSize ? "アップロードできるサイズは100MBまでです。" : ""}
                        </span>
                        {errorQuantityFiles && <span className="error">100枚を超える画像のアップロードは許可されていません。</span>}
                        {errorTypeFile && <span className="error">ファイル形式が正しくありません。</span>}
                    </Grid>
                </div>

                <div className="formButtonGroup">
                    <button
                        type="button"
                        className="cancelBtn"
                        onClick={() => {
                            setFileUpload([]);
                            navigate(`/sp/working_logs/${id}`, { replace: true });
                        }}
                    >
                        戻る
                    </button>
                    {!isDetail && <button
                        disabled={!!isDetail || isDisable}
                        className="registerBtn"
                        type="submit"
                    >
                        {isDisable ? <div className='flex items-center justify-center'><CircularProgress className='!w-[20px] !h-[20px]' color="inherit" /><span className='!ml-[4px]'>ローディング中</span></div> : '登録'}
                        
                    </button>
                    }
                </div>
            </form>
        </StyledCreateWorkingLog>
    );
};

export default CreateWorkingLogSP;
