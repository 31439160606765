import { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import departmentsService from 'services/departmentsService';
import PositionsService from 'services/positionService';
import workersService from 'services/wokersService';
import { IWorkersOptions } from './FilterBox/hook';

export type ReceivedProps = Record<string, any>;

const useSchedules = (props: ReceivedProps) => {
    const { userData } = useSelector((state: RootState) => state.users);
    const [openScheduleFormDialog, setOpenScheduleFormDialog] =
        useState<boolean>(false);
    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState<boolean>(false);
    const [openPlanUpdateModal ,setOpenPlanUpdateModal] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<number>(userData.worker ? 0 : 1);
    const [workerOptions, setWorkerOptions] = useState<IWorkersOptions[]>();
    const [positionOptions, setPositionOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [departmentOptions, setDepartmentOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [searchParam, setSearchParam] = useState<any>({});

    const fetchWorkers = useCallback(async () => {
        try {
            const { workers } = await workersService.getListWorkers({
                page: 1,
                per_page: 9999,
            });
            setWorkerOptions(
                workers.map((item) => ({
                    id: item.id,
                    name: item.name,
                    departments: item?.department ? [item?.department?.id] : null,
                    position: item?.position ? item?.position?.id : null,
                    departments2: item?.departments
                })),
            );
        } catch (error) {
            //
        }
    }, []);

    const fetchPositions = useCallback(async () => {
        try {
            const { positions } = await PositionsService.getListPositions({
                page: 1,
                per_page: 9999,
            });
            setPositionOptions(
                positions.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            //
        }
    }, []);

    const fetchDepartments = useCallback(async () => {
        try {
            const { departments } =
                await departmentsService.getListDepartments();
            setDepartmentOptions(
                departments.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            //
        }
    }, []);

    useEffect(() => {
        fetchWorkers();
        fetchPositions();
        fetchDepartments();
    }, [fetchWorkers, fetchPositions, fetchDepartments]);

    return {
        ...props,
        openScheduleFormDialog,
        openDeleteConfirmationDialog,
        openPlanUpdateModal,
        activeTab,
        workerOptions,
        positionOptions,
        departmentOptions,
        searchParam,
        setSearchParam,
        setActiveTab,
        setOpenDeleteConfirmationDialog,
        setOpenScheduleFormDialog,
        setOpenPlanUpdateModal,
    };
};

export type Props = ReturnType<typeof useSchedules>;

export default useSchedules;
