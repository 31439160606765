import { useEffect, useState } from 'react';
import moment from 'moment';
import { useModal } from 'react-hooks-use-modal';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'

import estimateService from 'services/estimationService';
import estimateItemService from 'services/estimateItemService';
import childEstimateItemService from 'services/childEstimateItemService';

import ModalCreateNew from './components/ListDetail/ModalCreateNew';
import FormEdit from './components/FormEdit';
import ListDetail from './components/ListDetail';
import CommentBox from './components/CommentBox';
import { useLogicEstimate } from '../hooks';
import { Main } from './styled'


const EstimationEdit = (props) => {
  const [ModalCreateEstimateItem, openCreateEstimateItem, closeCreateEstimateItem, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
    'root',
    {
      preventScroll: true,
      closeOnOverlayClick: false,
    },
  );
  const { estimationId = null } = useParams();
  const navigate = useNavigate();

  const {
    id,
    info = {},
    project = {},
    workers = [],
    statusOptions = [],
    loading = false,
    estimate = {},
    detailEstimateItem = [],

    setLoading,
    getProfileUser,
    getListWorkers,
    getProjectDetail,
    getDetailEstimate,
    getDetailEstimateItem
  } = useLogicEstimate();
  const [rowData, setRowData] = useState<any>({});
  const [deleteItemRow, setDeleteItemRow] = useState<any>({});
  const [dataEstimateItem, setDataEstimateItem] = useState<any>([]);
  const [searchParams] = useSearchParams();


  useEffect(() => {
    if (id && estimationId) {
      getProjectDetail(Number(id));
      getListWorkers();
      getDetailEstimate(Number(estimationId));
      getProfileUser()
    }
  }, [])


  const submitForm = async (data) => {
    setLoading(true)

    return await estimateService
      .updateEstimate(Number(estimationId), {
        project_id: id,
        status: data?.status?.value,
        assigned_worker_id: data?.assigned_worker?.value,
        approver_worker_id: data?.approver_worker?.value,
        // estimate_items_attributes: data.estimate_items,
        approve_expired_at: moment(data?.approve_expired_at).format('YYYY-MM-DD')

      })
      .then((res) => {
        if (res?.estimate?.id && !data.isGotoEdit) {
          if (!searchParams.get('from')) {
            return navigate(`/projects/detail/${id}/plan?tab=1`)
          }
          return navigate(-1)
        }

        if (res?.estimate?.id && data.isGotoEdit) {
          return navigate(`/projects/estimation/${id}/edit/${res?.estimate?.id}`)
        }
      })
      .catch((err) => {
      }).finally(() => setLoading(false));
  };

  const submitFormCreateNewEstimateItem = async (data) => {
    setLoading(true)
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("summary", data.summary);
    formData.append("note", data.note);
    formData.append("is_parent", data.is_parent);
    formData.append("estimate_id", String(estimationId));
    formData.append("project_id", String(id));

    if (!data.is_parent) {
      formData.append("amount", data.amount);
      formData.append("unit", data.unit);
      formData.append("unit_price", data.unit_price);
      formData.append("price", data.price);
    }

    if (!!_.size(dataEstimateItem)) {
      formData.append("parent_estimate_item_id", String(rowData?.id));

      return await estimateItemService
        .createChildEstimateItem(formData)
        .then((res) => {
          if (res?.estimate_item?.id) {
            closeCreateEstimateItem();

            if (!!_.size(dataEstimateItem)) {
              getListEstimateChildItem(rowData.id);
            } else {
              getDetailEstimate(Number(estimationId));
            }

          }
        })
        .catch((err) => {

        }).finally(() => setLoading(false));
    }

    return await estimateItemService
      .createEstimateItem(formData)
      .then((res) => {
        if (res?.estimate_item?.id) {
          closeCreateEstimateItem();

          if (!!_.size(dataEstimateItem)) {
            getListEstimateChildItem(rowData.id);
          } else {
            getDetailEstimate(Number(estimationId));
          }

        }
      })
      .catch((err) => {

      }).finally(() => setLoading(false));
  };

  const getListEstimateChildItem = (child_estimate_items_id: string) => {
    getDetailEstimateItem({
      project_id: String(id),
      estimate_id: estimationId,
      id: child_estimate_items_id
    });
  }

  const handleDeleteEstimateItem = async () => {
    const rowId = !!_.size(dataEstimateItem) ? dataEstimateItem[_.size(dataEstimateItem) - 1]?.id : estimationId
    const response = await estimateItemService.deleteEstimateItem({
      project_id: String(id),
      estimate_id: estimationId,
      id: deleteItemRow.id
    });

    // call func data
    if (response !== null && response !== undefined) {
      closeDelete();

      if (!!_.size(dataEstimateItem)) getListEstimateChildItem(rowId)
      else getDetailEstimate(Number(estimationId));
    }
  };

  const goBackDetailParentEstimate = () => {
    // update array estimate
    dataEstimateItem.pop();
    setDataEstimateItem(dataEstimateItem);

    // update object children estimate item
    const row = _.size(dataEstimateItem) ? dataEstimateItem[_.size(dataEstimateItem) - 1] : {};
    setRowData(row);

    // call api detail estimate
    if (row?.id) getListEstimateChildItem(row?.id);
    else getDetailEstimate(Number(estimationId));
  }

  const onDeleteEstimateItem = (row) => {
    setDeleteItemRow(row);
    openDelete()
  }

  const onGetDataChildrenEstimateItem = (row) => {
    setRowData(row)
    setDataEstimateItem([...dataEstimateItem, { ...row }]);
    getListEstimateChildItem(row?.id)
  }

  return (
    <Main>
      <FormEdit
        id={id}
        loading={loading}
        project={project}
        status={statusOptions}
        workers={workers}
        estimate={estimate}
        submitForm={submitForm}
      />

      <div className='d-flex'>
        <ListDetail
          item={rowData}
          data={!!_.size(dataEstimateItem) ? detailEstimateItem.child_estimate_items : _.get(estimate, 'estimate_items', [])}
          totalPrice={!!_.size(dataEstimateItem) ? detailEstimateItem.total_price : estimate.total_price}
          onAddLineNew={openCreateEstimateItem}
          onBack={goBackDetailParentEstimate}
          showDataEstimateItem={!!_.size(dataEstimateItem)}
          openDelete={onDeleteEstimateItem}
          onGetDetail={onGetDataChildrenEstimateItem}
        />

        <CommentBox
          infoUser={info}
          estimateId={Number(estimationId)}
          showDataEstimateItem={!!_.size(dataEstimateItem)}
          estimateItemId={!!_.size(dataEstimateItem) && dataEstimateItem[0]?.id}
        />
      </div>

      <ModalCreateEstimateItem>
        <ModalCreateNew
          item={rowData}
          onClose={closeCreateEstimateItem}
          submitForm={submitFormCreateNewEstimateItem}
          showDataEstimateItem={!!_.size(dataEstimateItem)}
        />
      </ModalCreateEstimateItem>
      <ModalDelete>
        <div className='modal-create-user d-flex flex-column' style={{ height: "152px" }}>
          <div className='modal-create-user-title d-flex flex-row'>本当に削除しますか?</div>
          <div className='modal-create-user-buttons d-flex flex-row' style={{ marginTop: "50px" }}>
            <button className='modal-create-user-button1 d-flex flex-row' onClick={closeDelete}>キャンセル</button>
            <button
              className='modal-create-user-button2 d-flex flex-row'
              style={{ background: '#FF5045', }}
              onClick={handleDeleteEstimateItem}
            >はい</button>
          </div>
        </div>
      </ModalDelete>
    </Main>
  );
};

export default EstimationEdit;
