import styled from 'styled-components';

export const StylesTimeKeeping = styled.div`
    margin: 24px;
    .time--title {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .timekeeping {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #000;
        font-weight: 500;
        font-size: 16px;
    }
    .editBtn {
        width: 60px;
        height: 30px;
        border-radius: 4px;
        background: #215493;
        color: #ffffff;
    }
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
        padding: 8px 8px !important; 
    }
`;
