import fetch from './api/config';

const API = {
    MEETING_MINUTES: () => `/meeting_minutes`,
    MEETING_MINUTES_DETAIL: (id) => `/meeting_minutes/${id}`,
    MEETING_MINUTES_ADD_FILE: (id) => `meeting_minutes/${id}/add_files`,
    MEETING_MINUTES_DELETE_FILE: (id, signed_id) =>
        `meeting_minutes/${id}/meeting_files/${signed_id}`,
    MEETING_MINUTES_PREVIEW: () => `/upload_files/preview`,
};

export default class MeetingMinutesService {
    static getMeetingMinutes = (params) =>
        fetch.get(API.MEETING_MINUTES(), params);

    static getMeetingMinutesDetail = (id) =>
        fetch.get(API.MEETING_MINUTES_DETAIL(id), {});

    static createMeetingMinutes = (params) =>
        fetch.post(API.MEETING_MINUTES(), params);

    static deleteMeetingMinutes = (id) =>
        fetch.delete(API.MEETING_MINUTES_DETAIL(id), {});

    static updateMeetingMinutes = (id, params) =>
        fetch.put(API.MEETING_MINUTES_DETAIL(id), params);

    static meetingMinutesAddFiles = (id, params) =>
        fetch.put(API.MEETING_MINUTES_ADD_FILE(id), params);

    static meetingMinutesDeleteFiles = (id, signed_id) =>
        fetch.delete(API.MEETING_MINUTES_DELETE_FILE(id, signed_id), {});

    static meetingMinutesPreviewFiles = (params) =>
        fetch.post(API.MEETING_MINUTES_PREVIEW(), params);
}
