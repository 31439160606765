import Api from './api/projectApi';
import ApiSP from './api/projectApiSP';
import pick from 'lodash/pick';
// import projectList from '../shared/mocks/projectLists.json';
import moment from 'moment';

const getListProjects = async (input: any = {}) => {
    // return projectList; // TODO remove debug
    let data = pick(input, ['page', 'per_page', 'free_word']);
    if (input.client_company_id)
        data.client_company_id = input.client_company_id?.value;
    if (input.business_type) data.business_type = input.business_type?.value;
    if (input.estimate_assigned_worker_id)
        data.estimate_assigned_worker_id =
            input.estimate_assigned_worker_id?.value;
    if (input.manager_worker_id)
        data.manager_worker_id = input.manager_worker_id?.value;
    if (input?.start_at) {
        data.start_at = moment(input.start_at)
            .startOf('day')
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
    }
    if (input?.end_at) {
        data.end_at = moment(input.end_at)
            .endOf('day')
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
    }
    if (input.status) data.status = input.status.value;
    const params = new URLSearchParams(data);
    try {
        return await Api()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (err) {
        window.location.href = '/login';
        return null;
    }
};

const getProject = async (id: number) => {
    return await Api()
        .get(`${id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const createProject = async (input) => {
    return await Api()
        .post('', input, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateProject = async (body, id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .put(`${id}`, body, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log(err);
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const deleteProject = async (id) => {
    return await Api()
        .delete(`${id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteImage = async (id, signed_id) => {
    return await Api()
        .delete(`${id}/delete_image?signed_id=${signed_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteFiles = async (id, signed_file_ids) => {
    return await Api()
        .delete(`${id}/delete_files?signed_file_ids=${signed_file_ids}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getListChat = async (id: number, input?: any) => {
    let data = pick(input, ['page', 'per_page']);
    if (id) {
        data.id = id;
    }
    const params = new URLSearchParams(data);
    return await Api()
        .get(`${id}/chats?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getListChatSP = async (id: number, input?: any) => {
    let data = pick(input, ['page', 'per_page']);
    if (id) {
        data.id = id;
    }
    const params = new URLSearchParams(data);
    return await ApiSP()
        .get(`${id}/chats?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getChat = async (project_id: any, chat_id: any) => {
    return await Api()
        .get(`${project_id}/chats/${chat_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getChatSP = async (project_id: any, chat_id: any) => {
    return await ApiSP()
        .get(`${project_id}/chats/${chat_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const createChat = async (id: any, payload: any) => {
    return await Api()
        .post(`${id}/chats`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};


const createChatSP = async (id: any, payload: any) => {
    return await ApiSP()
        .post(`${id}/chats`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateChat = async (project_id: any, chat_id: any, payload: any) => {
    return await Api()
        .put(`${project_id}/chats/${chat_id}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteChat = async (project_id: any, chat_id: any) => {
    return await Api().delete(`${project_id}/chats/${chat_id}`);
};

const deleteChatSP = async (project_id: any, chat_id: any) => {
    return await ApiSP().delete(`${project_id}/chats/${chat_id}`);
};

const uploadPastImages = async (project_id, payload) => {
    const res = await Api().post(`${project_id}/upload_images`, payload, {});
    return res;
}

const deletePastImage = async (project_id, signed_id) => {
    const res = await Api().delete(`${project_id}/delete_project_image?signed_id=${signed_id}`, {});
    return res;
}

const uploadPdfDocuments = async (project_id, payload) => {
    const res = await Api().post(`${project_id}/upload_pdf_documents`, payload, {});
    return res;
}

const getPdfDocuments = async (project_id, text) => {
    const res = !text ? await Api().get(`${project_id}/pdf_documents`, {}) : await Api().get(`${project_id}/pdf_documents/?filename=${text}`, {});
    return res;
}

const updatePdfDocumentFilename = async (project_id, signed_id, payload) => {
    const res = await Api().put(`${project_id}/pdf_document/${signed_id}/update_name`, payload, {});
    return res;
}

const deletePdfDocument = async (project_id, signed_id) => {
    const res = await Api().delete(`${project_id}/pdf_document/${signed_id}`, {});
    return res;
}

const projectsService = {
    getListProjects,
    createProject,
    updateProject,
    deleteProject,
    getProject,
    deleteImage,
    getListChat,
    getChat,
    createChat,
    updateChat,
    deleteChat,
    deleteChatSP,
    getListChatSP,
    createChatSP,
    getChatSP,
    deleteFiles,
    uploadPastImages,
    deletePastImage,
    uploadPdfDocuments,
    getPdfDocuments,
    updatePdfDocumentFilename,
    deletePdfDocument,
};

export default projectsService;
