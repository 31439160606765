import { FC } from 'react';
import {  useForm } from 'react-hook-form';
import useEstimateDetail, { ReceivedProps, Props } from './hook';
import { StylesEstimateDetailSp } from './styled';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import ListDetail from 'pages/project/projectPlan/Estimations/EstimationEdit/components/ListDetail';
import _ from 'lodash';

const EstimateDetailSpLayout: FC<Props> = ({
  project,
  estimate,
  handleViewFile,
  fileData,
  setFileData,
  statusOptions,
  rowData,
  dataEstimateItem,
  detailEstimateItem,
  openCreateEstimateItem,
  goBackDetailParentEstimate,
  onGetDataChildrenEstimateItem,
  convertTZ,
}) => {

  const renderContentPreview = () => {
    if (!fileData) return;
    return <Worker workerUrl={`${process.env.REACT_APP_WORKER_URL}`}>
      <Viewer
        fileUrl={fileData}
        defaultScale={SpecialZoomLevel.PageWidth}
      />
    </Worker>
  }

  return <StylesEstimateDetailSp>
    {fileData && (
      <Dialog
        onClose={() => setFileData(null)}
        sx={{
          width: '100%',
          '.MuiPaper-root': {
            width: "100%",
            height: "100%",
            margin: '0px',
          },

          '.rpv-core__inner-container': {
            paddingBottom: '30px !important',
          },
        }}
        open={!!fileData}
      >
        <DialogTitle className='flex justify-end'>
          <span onClick={() => setFileData(null)}><CloseIcon /></span>
        </DialogTitle>
        <DialogContent>
          {
            renderContentPreview()
          }
        </DialogContent>
      </Dialog>
    )}
    <p className="estimateSp--title">見積詳細</p>
    <div className="form-create-estimation-form d-flex flex-column">
      <div className="input-add-estimation d-flex">
        <p className="d-flex">案件名</p>
        <div className="d-flex content-right">
          {project?.name}
        </div>
      </div>
      <div className="input-add-estimation d-flex">
        <p className="d-flex">見積担当</p>
        <div className="d-flex content-right">
          <p className='!mb-0'>{estimate?.assigned_worker?.name || ''}</p>
        </div>
      </div>
      <div className="input-add-estimation d-flex">
        <p className="d-flex">状態</p>
        <div className="d-flex content-right">
          <p className='!mb-0'>{statusOptions?.filter(st => st?.value === estimate?.status)[0]?.label || ''}</p>
        </div>
      </div>
      <div className="input-add-estimation d-flex">
        <p className="d-flex">承認担当</p>
        <div className="d-flex content-right">
          <p className='!mb-0'>{estimate?.approver_worker?.name || ''}</p>
        </div>
      </div>
      <div className="input-add-estimation d-flex">
        <p className="d-flex">承認期限</p>
        <div className="content-right d-flex">
          <p className='!mb-0'>{estimate?.approve_expired_at ? moment(convertTZ(estimate?.approve_expired_at, "Asia/Tokyo"))?.format('YYYY-MM-DD') : ''}</p>
        </div>
      </div>
      <div className="input-add-estimation d-flex">
        <p className="d-flex">ファイル</p>
        <div className='content-right flex items-center gap-x-[24px]'>
          <div className='flex items-center gap-x-[12px]'>
            <div className="mb-0">
              {estimate?.document?.name || ''}
              {
                estimate?.document && <div className="text-[#195192] text-[14px] font-[500] cursor-pointer underline mb-0" onClick={() => { handleViewFile(estimate?.document) }}>プレビュー</div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    <ListDetail
      classNameMain='!p-0 mt-[24px] mb-[63px] !text-[14px]'
      isShowDeleteBtn={false}
      classNameBtn='!w-[52px] !m-[5px] !text-[14px] !px-[12px] !py-[0px] !h-[25px]'
      item={rowData}
      data={!!_.size(dataEstimateItem) ? detailEstimateItem.child_estimate_items : _.get(estimate, 'estimate_items', [])}
      totalPrice={!!_.size(dataEstimateItem) ? detailEstimateItem.total_price : estimate.total_price}
      onAddLineNew={openCreateEstimateItem}
      onBack={goBackDetailParentEstimate}
      showDataEstimateItem={!!_.size(dataEstimateItem)}
      openDelete={() => { }}
      onGetDetail={onGetDataChildrenEstimateItem}
    />
  </StylesEstimateDetailSp>
}

const EstimateDetailSp: FC<ReceivedProps> = (props) => {
  return <EstimateDetailSpLayout {...useEstimateDetail(props)} />;
};

export default EstimateDetailSp;
