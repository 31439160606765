import { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PlanService from 'services/planServiceSP';
import projectsService from 'services/projectsService';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const ProjectPlanSP = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState<any>(null);
    const [plans, setPlans] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const getPlansLists = async () => {
        try {
            setLoading(true);
            const response: any = await PlanService.getListPlans({
                page: 1,
                per_page: 0,
                project_id: id,
            });
            setPlans(response?.plans);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    const getDetailProject = async () => {
        if (!id) return;
        try {
            setLoading(true);
            const response = await projectsService.getProject(Number(id));
            setProject(response.project);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
            navigate('/projects');
        }
    };

    useEffect(() => {
        getDetailProject();
        getPlansLists();
    }, []);

    const data = [
        {
            title: '一般建屋解体',
            value: '10% ～4月14日',
            isDelay: true,
        },
        {
            title: '内装解体',
            value: '60% ～4月20日',
            isDelay: false,
        },
        {
            title: '搬出',
            value: '30% ～4月25日',
            isDelay: false,
        },
    ];

    if(loading) return <div className='h-[100vh] flex justify-center mt-[40px]'><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div>

    return (
        <Box
            sx={{
                '.MuiTypography-root, button': {
                    fontFamily: 'Zen Kaku Gothic Antique',
                },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
            padding="16px"
        >
            <Box width="100%">
                <Typography
                    fontWeight="700"
                    fontSize="18px"
                    lineHeight="26px"
                    color="#1C1E21"
                    sx={{ marginBottom: '24px' }}
                >
                    {project?.name}
                </Typography>
                <Typography
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="20px"
                    color="#344054"
                    sx={{ marginBottom: '12px' }}
                >
                    {project?.start_at ? moment(project?.start_at).format('YYYY年M月D日') : ''}
                </Typography>
                <Box
                    width="100%"
                    paddingTop="12px"
                    borderTop="1px solid #CDD1D5"
                >
                    <Grid
                        sx={{
                            '.MuiTypography-root': {
                                fontWeight: '500',
                                fontSize: '14px',
                                lineHeight: '20px',
                            },
                            '.MuiGrid-item': {
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '12px',
                            },
                        }}
                        container
                        // columnSpacing={4}
                    >
                        {plans?.map((item, indx) => (
                            <>
                                <Grid item xs={3}>
                                    <Typography color="#344054">
                                        {item.name}
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{ justifyContent: 'center' }}
                                    textAlign="center"
                                    item
                                    xs={4}
                                >
                                    <Typography color="#344054">
                                        {`${
                                            item.progress_percentage
                                        }% ~${moment(item?.end_at).format(
                                            'M月D日',
                                        )}`}
                                    </Typography>
                                </Grid>
                                <Grid textAlign="center" item xs={2}>
                                    <Typography color="#FF5045">
                                        {item?.status_type}
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{ justifyContent: 'flex-end' }}
                                    textAlign="right"
                                    item
                                    xs={3}
                                >
                                    <Link to={`/sp/working_logs/create/${id}`}>
                                        <Button
                                            sx={{
                                                backgroundColor: '#215493',
                                                padding: '4px 12px',
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                lineHeight: '16px',
                                                borderRadius: '6px',
                                                boxShadow:
                                                    '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                            }}
                                            variant="contained"
                                        >
                                            作業登録
                                        </Button>
                                    </Link>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </Box>
            </Box>
            {/*<Link to={`/sp/project/${id}`}>*/}
                <Button
                    sx={{
                        padding: '10px 67.75px',
                        border: '1px solid rgba(33, 84, 147, 0.3)',
                        borderRadius: '8px',
                        filter:
                            'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))',
                        fontSize: '16px',
                        fontWeight: '500',
                    }}
                    variant="outlined"
                    onClick={() => navigate(-1)}
                >
                    戻る
                </Button>
            {/*</Link>*/}
        </Box>
    );
};
export default ProjectPlanSP;
