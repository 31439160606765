import * as yup from 'yup';

export const validation = yup.object().shape({
    subject: yup.string().required('必須項目です'),
    content: yup.string().required('必須項目です'),
    files: yup
        .mixed()
        .required('必須項目です')
        //.test('fileSize', '6MB以上がアップロードできません。', (value: any) => {
        //    if(value?.image_path) return true
        //    return value && value.size <= 6144 * 1024;
        //}),
});
