import styled from 'styled-components';
import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    height: unset !important;
    max-width: 450px !important;
    border-radius: 16px !important;
  }
  .modal-create-user-button1:focus-visible {
    border: 2px solid #000000;
  }
  .modal-create-user-button2:focus-visible {
      border: 2px solid #000000;
  }
  .modal-create-user-button2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 49%;
    height: 42px;
    background: #215493;
    /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); */
    border-radius: 8px;
    outline: none;
    border-color: #215493;
    color: #FFFFFF;
    outline: none;
    border: none
  }
  .modal-create-user-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 49%;
    height: 42px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid rgba(211,216,225,255);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 8px;
    outline: none;
    border-color: #d3d8e1;
    color:  #272727;;
  }
  .icon-info {
    margin: 0 auto;
  }
`;
