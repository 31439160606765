import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useModal } from 'react-hooks-use-modal';
import pick from 'lodash/pick';

import { RootState } from 'store';
import suggestionService from 'services/suggestionService';
import workersService from 'services/wokersService';
import { validate } from './validation';

const useSuggestion = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const isCreate = location?.pathname === '/suggestions/create';

    const { userData } = useSelector((state: RootState) => state.users);
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: true,
        },
    );
    const [workerOptions, setWorkerOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [data, setData] = useState<any>({});
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);

    const initialValues = isCreate
        ? {
              title: '',
              amount: '',
              from_worker_id: (userData as any).worker?.id,
              to_worker_id: null,
          }
        : {
              title: '',
              amount: 0,
              from_worker_id: (userData as any).worker?.id,
              to_worker_id: null,
              repayment_amount: 0,
          };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validate,
        onSubmit: (values) => submitForm(values),
    });

    const fetchWorkers = useCallback(async () => {
        try {
            const { workers } = await workersService.getListWorkers({
                page: 1,
                per_page: 9999,
            });
            setWorkerOptions(
                workers.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            //
        }
    }, []);

    const fetchSuggestion = useCallback(async () => {
        if (!id) return;

        try {
            const { suggestion } = await suggestionService.getSuggestion(id);
            const { to_worker } = suggestion;

            setData(suggestion);

            formik.setValues({
                ...pick(suggestion, ['title', 'amount', 'repayment_amount']),
                to_worker_id: {
                    id: to_worker.id,
                    name: to_worker.name,
                },
            });
        } catch (error) {}
    }, [id]);

    const submitForm = async (response: Record<string, any>) => {
        const payload = {
            ...response,
            to_worker_id: response.to_worker_id.id,
        };
        try {
            if (id) {
                await suggestionService.updateSuggestion(
                    id,
                    payload,
                );
                openDelete()
            } else {
                const { suggestion } = await suggestionService.createSuggestion(
                    payload,
                );
                formik.resetForm();
                navigate(`/suggestion/detail/${suggestion.id}`);
            }
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        fetchSuggestion();
        fetchWorkers();
    }, [fetchSuggestion]);

    return {
        data,
        initialValues,
        formik,
        isCreate,
        workerOptions,
        ModalDelete,
        isOpenDelete,
        closeDelete,
        isOpenDropdown,
        setIsOpenDropdown
    };
};

export type Props = ReturnType<typeof useSuggestion>;

export default useSuggestion;
