import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { ReceivedProps, DataType, IStockNote } from './type';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import StockNotesService from 'services/stockNotesService';

export const validation = yup.object().shape({
    name: yup.string().required('必須項目です'),
});

const useInternalOJT = (props: ReceivedProps) => {
    const [deleteData, setDeleteData] = useState<DataType>({
        id: 1,
        name: '',
        created_at: '',
        file_url: '',
        updated_at: '',
    });
    const [stockNotesList, setStockNotesList] = useState<IStockNote[]>([]);
    const [refreshList, setRefreshList] = useState<boolean>(false);
    const [fileSelected, setFileSelected] = useState<any>();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: (values) => fetchListStockNotes(values),
    });
    const updateFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
        },
        validationSchema: validation,
        onSubmit: (values) => handleUpdate(values),
    });

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: true,
        },
    );
    const [ModalUpdate, openUpdate, closeUpdate, isOpenUpdate] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: true,
        },
    );

    const handleUpdate = async (values: { name: string; }) => {
        const formData = new FormData();
        formData.append('name', `${values.name}.pdf`);
        try {
            await StockNotesService.updateStockNotes(
                deleteData.id,
                formData,
            );
            setRefreshList((preState) => !preState);
            closeUpdate();
        } catch (error) {}
    };

    const fetchListStockNotes = async (params = {}) => {
        try {
            const res =
                await StockNotesService.getListStockNotes({
                    page: 1,
                    per_page: 0,
                    ...params
                });
                if(!(res as {stock_notes: IStockNote[]})?.stock_notes) return;
                const stock_notes = (res as {stock_notes: IStockNote[]})?.stock_notes;
                setStockNotesList(stock_notes);
        } catch (error) {
            console.log(error);
        }
    };

    const handleOpenDeleleModal = (data: IStockNote) => {
        setDeleteData(data);
        openDelete();
    };
    const handleOpenUpdateModal = (data: IStockNote) => {
        setDeleteData(data);
        updateFormik.setFieldValue('name', data?.name?.replace('.pdf', ''));
        openUpdate();
    };

    const handleDelete = async () => {
        try {
            await StockNotesService.deleteStockNotes(
                deleteData.id,
            );
            closeDelete();
            setRefreshList((preState) => !preState);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchListStockNotes();
    }, [refreshList]);

    return {
        ...props,
        formik,
        ModalDelete,
        isOpenDelete,
        stockNotesList,
        openDelete,
        closeDelete,
        handleOpenDeleleModal,
        navigate,
        handleDelete,
        ModalUpdate,
        openUpdate,
        closeUpdate,
        handleOpenUpdateModal,
        updateFormik,
        fileSelected,
        setFileSelected,
    };
};
export type Props = ReturnType<typeof useInternalOJT>;

export default useInternalOJT;
