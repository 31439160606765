import { useState, useEffect } from 'react';
import './workingLog.css';
import { Table, TextField } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import 'react-step-progress/dist/index.css';
import { useModal } from 'react-hooks-use-modal';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);
import workingLogService from '../../../services/workingLogService';
import plansService from '../../../services/plansService';
import moment from 'moment';
import workersService from '../../../services/wokersService';
import plus from '../../../assets/icons/Plus.png';
import calendarIcon from '../../../assets/icons/calendar.png';
import Autocomplete from '@mui/material/Autocomplete';
import CustomPagination from 'components/CustomPagination';
import { IPageQueries } from 'models';
import WorkingLogDetailDialog from './components/WorkingLogDetail';
import UpdateWorkingLogDialog from './components/UpdateWorkingLog';
import CreateWorkingLogDialog from './components/CreateWorkingLog';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    border: '1px solid #CDD1D5',
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        // border: 0,
    },
}));

const initialSearchObject = {
    plan: null,
    worker: null,
    start_at: null,
    end_at: null,
    free_word: '',
};

const ProjectsDetailPlan = (props) => {
    const { project_id } = props;
    const [workingLogs, setWorkingLogs] = useState<any[]>([]);
    const [workers, setWorkers] = useState<any[]>([]);
    const [plans, setPlans] = useState<any[]>([]);
    const [workingDeleteRow, setWorkingDeleteRow] = useState<any>();
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [searchObject, setSearchObject] = useState<any>(initialSearchObject);
    const [searchedState, setSearchedState] = useState({});
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });

    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [openUpdate, setOpenUpdate] = useState<boolean>(false);
    const [openDetail, setOpenDetail] = useState<boolean>(false);
    const [isErrorDate, setIsErrorDate] = useState(false);
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            closeOnOverlayClick: false,
        },
    );

    useEffect(() => {
        if (!openDetail) {
            setSelectedRow(null);
        }
    }, [openDetail]);

    useEffect(() => {
        if (!openUpdate) {
            setSelectedRow(null);
        }
    }, [openUpdate]);

    const getWorkingLogs = async ({ page = 1, per_page = 0, data = {} }) => {
        try {
            const response = await workingLogService.getListWorkingLogs({
                project_id,
                page,
                per_page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                setWorkingLogs(response.working_logs);
                setTotalCount(response.meta.total_count);
            }
        } catch (err) {
            console.log('err', err);
        }
    };
    
    const getWorkingLogDetail = async (id, projectId) => {
        if (!id || !projectId) return;
        try {
            const res = await workingLogService.getWorkingLog(id, {project_id: projectId});
            setSelectedRow(res?.working_log)
        } catch (error) {
            console.log('error: ', error);
        }
    }

    const getListWorkers = async () => {
        try {
            const responseWorker = await workersService.getListWorkers();
            if (responseWorker !== null && responseWorker !== undefined) {
                setWorkers(responseWorker.workers);
            }
        } catch (error) {
            // empty
        }
    };

    const getListPlans = async () => {
        try {
            const responsePlan = await await plansService.getListPlan(
                project_id,
            );
            if (responsePlan !== null && responsePlan !== undefined) {
                setPlans(responsePlan.plans);
            }
        } catch (error) {
            // empty
        }
    };

    useEffect(() => {
        getWorkingLogs({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
        getListPlans();
        getListWorkers();
    }, []);

    const handleSearch = () => {
        setSearchedState({ ...searchObject });
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        getWorkingLogs({
            page: 1,
            per_page: pageQueries.per_page,
            data: { ...searchObject },
        });
    };

    const handleDeleteWorkingLog = async () => {
        const response = await workingLogService.deleteWorkingLog(
            workingDeleteRow.id,
            workingDeleteRow?.project?.id,
            // workingDeleteRow?.plan?.id
        );
        if (response !== null && response !== undefined) {
            if (pageQueries.page !== 1) {
                setPageQueries((prevState) => ({ ...prevState, page: 1 }));
            }
            getWorkingLogs({
                page: 1,
                per_page: pageQueries.per_page,
                data: { ...searchedState },
            });
            closeDelete();
        }
    };

    const checkValidDate = (startDate, endDate) => {
        if(!startDate || !endDate) return true;
        if(new Date(startDate).getTime() > new Date(endDate).getTime()) {
            setIsErrorDate(true)
        } else {
            setIsErrorDate(false)
        }
    }
    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-column">
                <div className="d-flex flex-row flex-wrap gap-y-[12px]">
                    <div className="d-flex flex-column">
                        <span className="label">工程</span>
                        <Autocomplete
                            sx={{
                                border: '1px solid #CDD1D5',
                                borderRadius: '8px',
                            }}
                            disablePortal
                            id="combo-box-demo"
                            options={plans.map((item) => ({
                                value: item.id,
                                label: item.name,
                            }))}
                            value={searchObject.plan || null}
                            onChange={(event, newValue) => {
                                let search = { ...searchObject };
                                search.plan = newValue || null;
                                setSearchObject(search);
                            }}
                            classes={{
                                root: 'search-select-style',
                                input: 'search-select-style',
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="" />
                            )}
                            noOptionsText="該当なし"
                        />
                    </div>
                    <div className="d-flex flex-column">
                        <span className="label">担当</span>
                        <Autocomplete
                            sx={{
                                border: '1px solid #CDD1D5',
                                borderRadius: '8px',
                            }}
                            disablePortal
                            id="combo-box-demo"
                            options={workers.map((item) => ({
                                value: item.id,
                                label: item.name,
                            }))}
                            value={searchObject.worker || null}
                            onChange={(event, newValue) => {
                                let search = { ...searchObject };
                                search.worker = newValue || null;
                                setSearchObject(search);
                            }}
                            classes={{
                                root: 'search-select-style',
                                input: 'search-select-style',
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="" />
                            )}
                            noOptionsText="該当なし"
                        />
                    </div>
                    <div className="d-flex flex-column mr-[16px]">
                        <span className="label">作業日</span>
                        <div className="select-date-range">
                            <div className="datepicker-wrap">
                                <div className="target-datepicker-wrap d-flex flex-row">
                                    <div
                                        className="input-date"
                                        style={{ position: 'relative' }}
                                    >
                                        <DatePicker
                                            id="start_at"
                                            selected={searchObject.start_at}
                                            onChange={(date) => {
                                                let search = {
                                                    ...searchObject,
                                                };
                                                search.start_at = date;
                                                setSearchObject(search);
                                                checkValidDate(date, search?.end_at)
                                            }}
                                            startDate={searchObject.start_at}
                                            selectsStart
                                            dateFormat="yyyy-MM-dd"
                                            autoComplete="off"
                                            locale="ja"
                                        />
                                        <label htmlFor="start_at">
                                            <img
                                                className="input-date-img"
                                                width={'16px'}
                                                height={'16px'}
                                                src={calendarIcon}
                                            ></img>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <span className="spread-select">{'~'}</span>
                            <div className="datepicker-wrap">
                                <div className="target-datepicker-wrap d-flex flex-row">
                                    <div
                                        className="input-date"
                                        style={{ position: 'relative' }}
                                    >
                                        <DatePicker
                                            id="end_at"
                                            selected={searchObject.end_at}
                                            onChange={(date) => {
                                                let search = {
                                                    ...searchObject,
                                                };
                                                search.end_at = date;
                                                setSearchObject(search);
                                                checkValidDate(search?.start_at, date)
                                            }}
                                            startDate={searchObject.end_at}
                                            selectsStart
                                            dateFormat="yyyy-MM-dd"
                                            autoComplete="off"
                                            locale="ja"
                                        />
                                        <label htmlFor="end_at">
                                            <img
                                                className="input-date-img"
                                                width={'16px'}
                                                height={'16px'}
                                                src={calendarIcon}
                                            ></img>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isErrorDate && <p className="error mb-0">終了は開始日時以降を入力してください。</p>}
                    </div>
                    <div className="d-flex flex-row searchRow">
                        <div className="d-flex flex-column mr-[14px]">
                            <span className="label">キーワード</span>
                            <TextField
                                sx={{
                                    border: '1px solid #CDD1D5',
                                    borderRadius: '8px',
                                }}
                                className="search-free-style !w-[350px]"
                                value={searchObject.free_word}
                                onChange={(e) => {
                                    let search = { ...searchObject };
                                    search.free_word = e.target.value;
                                    setSearchObject(search);
                                }}
                            />
                        </div>
                        <div className="actionArea">
                            <button
                                className="button-tb"
                                style={{ width: '150px', margin: '2px' }}
                                onClick={handleSearch}
                                disabled={new Date(searchObject?.start_at).getTime() > new Date(searchObject?.end_at).getTime()}
                            >
                                検索
                            </button>
                            <button
                                className="button-create ml-[8px]"
                                onClick={() => {
                                    setOpenCreate(true);
                                }}
                            >
                                <img
                                    width={'16px'}
                                    height={'16px'}
                                    src={plus}
                                ></img>
                                新規作成
                            </button>
                        </div>
                    </div>
                </div>
                
                <div className="d-flex flex-column">
                    <div className="tb-ct d-flex flex-column">
                        <TableContainer table-layout={'auto'}>
                            <Table
                                sx={{ minWidth: 700 }}
                                aria-label="customized table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                作業日
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                工程
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div>担当者</div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div>作業名</div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="text-overflow1"
                                            align="right"
                                            style={{ width: '10%' }}
                                        >
                                            <div
                                                style={{ marginRight: '110px' }}
                                            >
                                                操作
                                            </div>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {workingLogs &&
                                        workingLogs.map((row, ind) => {
                                            return (
                                                <StyledTableRow key={ind}>
                                                    <StyledTableCell
                                                        align="center"
                                                        component="th"
                                                        scope="row"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {row.worked_at
                                                                ? moment(
                                                                      row.worked_at,
                                                                  ).format(
                                                                      'YYYY/MM/DD',
                                                                  )
                                                                : ''}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {row?.plan?.name}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {row?.worker?.name}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div className="text-overflow1">
                                                            {row.name}
                                                        </div>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            width: '30%',
                                                            minWidth: '300px',
                                                        }}
                                                    >
                                                        <button
                                                            className="button-tb"
                                                            style={{
                                                                width: '72px',
                                                                margin: '5px',
                                                            }}
                                                            onClick={() => {
                                                                setOpenDetail(
                                                                    true,
                                                                );
                                                                //setSelectedRow(
                                                                //    row,
                                                                //);
                                                                getWorkingLogDetail(row?.id, row?.project_id)
                                                            }}
                                                        >
                                                            詳細
                                                        </button>
                                                        <button
                                                            className="button-tb"
                                                            style={{
                                                                width: '72px',
                                                                margin: '5px',
                                                            }}
                                                            onClick={() => {
                                                                //setSelectedRow(
                                                                //    row,
                                                                //);
                                                                setOpenUpdate(
                                                                    true,
                                                                );
                                                                getWorkingLogDetail(row?.id, row?.project_id)
                                                            }}
                                                        >
                                                            編集
                                                        </button>
                                                        <button
                                                            className="button-tb"
                                                            style={{
                                                                width: '72px',
                                                                margin: '5px',
                                                                backgroundColor:
                                                                    '#FF5045',
                                                                color: 'white',
                                                            }}
                                                            onClick={() => {
                                                                openDelete();
                                                                setWorkingDeleteRow(
                                                                    row,
                                                                );
                                                            }}
                                                        >
                                                            削除
                                                        </button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <ModalDelete>
                            <div
                                className="modal-create-user d-flex flex-column"
                                style={{ height: '152px' }}
                            >
                                <div className="modal-create-user-title d-flex flex-row">
                                    本当に削除しますか?
                                </div>
                                <div
                                    className="modal-create-user-buttons d-flex flex-row"
                                    style={{ marginTop: '50px' }}
                                >
                                    <button
                                        className="modal-create-user-button1 d-flex flex-row"
                                        onClick={closeDelete}
                                    >
                                        キャンセル
                                    </button>
                                    <button
                                        className="modal-create-user-button2 d-flex flex-row"
                                        onClick={() => handleDeleteWorkingLog()}
                                    >
                                        はい
                                    </button>
                                </div>
                            </div>
                        </ModalDelete>

                        {selectedRow && (
                            <WorkingLogDetailDialog
                                open={openDetail}
                                setOpen={setOpenDetail}
                                workingLog={selectedRow}
                            />
                        )}

                        {selectedRow && (
                            <UpdateWorkingLogDialog
                                open={openUpdate}
                                setOpen={setOpenUpdate}
                                project_id={project_id}
                                selectedWorkingLog={selectedRow}
                                onFinishUpdate={() => {
                                    setOpenUpdate(false);
                                    if (pageQueries.page !== 1) {
                                        setPageQueries((prevState) => ({
                                            ...prevState,
                                            page: 1,
                                        }));
                                    }
                                    getWorkingLogs({
                                        page: 1,
                                        per_page: pageQueries.per_page,
                                    });
                                    setSearchObject(initialSearchObject);
                                    setSearchedState({});
                                }}
                                plans={plans}
                                workers={workers}
                            />
                        )}

                        {openCreate && (
                            <CreateWorkingLogDialog
                                open={openCreate}
                                setOpen={setOpenCreate}
                                project_id={project_id}
                                onFinishCreate={() => {
                                    if (pageQueries.page !== 1) {
                                        setPageQueries((prevState) => ({
                                            ...prevState,
                                            page: 1,
                                        }));
                                    }
                                    getWorkingLogs({
                                        page: 1,
                                        per_page: pageQueries.per_page,
                                    });
                                    setSearchObject(initialSearchObject);
                                    setSearchedState({});
                                    setOpenCreate(false);
                                }}
                                plans={plans}
                                workers={workers}
                                currentPlan={selectedRow}
                            />
                        )}
                    </div>
                </div>
                {workingLogs && workingLogs.length > 0 && (
                    <CustomPagination
                        totalCount={totalCount}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) =>
                            getWorkingLogs({
                                page,
                                per_page: pageQueries.per_page,
                                data: { ...searchedState },
                            })
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default ProjectsDetailPlan;
