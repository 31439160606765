import { useEffect, useState } from 'react';
import './progressStep.css';
import Dialog from '@mui/material/Dialog';

import InvoicingModal from './components/modals/Invoicing';
import PaymentConfirmModal from './components/modals/PaymentConfirm';
import BreakingGroundModal from './components/modals/BreakingGround';
import WasteRemovalPlanModal from './components/modals/WasteRemovalPlan';
import SafetyDocsPrepModal from './components/modals/SafetyDocsPrep';
import ContractWritingModal from './components/modals/ContractWriting';
import PriceNegotiationModal from './components/modals/PriceNegotiation';
import SendQuotationModal from './components/modals/SendQuotation';
import QuotationModal from './components/modals/Quotation';
import RequestQuotationModal from './components/modals/RequestQuotation';
import PreConstructReviewModal from './components/modals/PreConstructReview';
import PreConstructMeetingModal from './components/modals/PreConstructMeeting';
import OverallProcessMeetingModal from './components/modals/OverallProcessMeeting';
import WeeklyReportModal from './components/modals/WeeklyReport';
import VolumeAdjustmentModal from './components/modals/VolumeAdjustment';
import StepDiagram from './components/StepDiagram';
import workersService from 'services/wokersService';
import sugorokuService from 'services/sugorokuService';
import { SugorokuIndexEnum, WasteSugorokuIndexEnum } from 'utils/enums';
import { convertSugorokuArrayToObject } from 'utils/helper/sugorokuHelper';
import { ISugorokuList } from 'models';
import CompanyService from 'services/clientCompanyService';
import {
    convertClientCompaniesToDropdownOptions,
    removeUndefinedCompany,
} from 'utils/helper/clientCompanyHelper';
import CooperativeCompanyService from 'services/cooperativeCompaniesService';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import DoneModal from './components/modals/Done';
import ProjectSteps from './components/ProjectSteps';
import GotOrderListModal from './components/modals/IndustrialWasteType/GotOrderList';
import BringOutModal from './components/modals/IndustrialWasteType/BringOut';
import DeliverIntermediateFacilityModal from './components/modals/IndustrialWasteType/DeliverIntermediateFacility';
import IntermediateFacilityModal from './components/modals/IndustrialWasteType/IntermediateFacility';
import DeliverFinalFacilityModal from './components/modals/IndustrialWasteType/DeliverFinalFacility';
import FinalFacilityModal from './components/modals/IndustrialWasteType/FinalFacility';
import FinishManifestModal from './components/modals/IndustrialWasteType/FinishManifest';
import FinishedBillModal from './components/modals/IndustrialWasteType/FinishedBill';
import FinishedPaymentModal from './components/modals/IndustrialWasteType/FinishedPayment';
import CompleteModal from './components/modals/IndustrialWasteType/Complete';
interface IProps {
    project_id: number;
    business_type: boolean;
    readOnly?: boolean;
}

const ProgressStep = (props: IProps) => {
    const { project_id, business_type } = props;

    const [openStep, setOpenStep] = useState(false);
    const [step, setStep] = useState(10);
    const [workerOptions, setWorkerOptions] = useState<Record<string, any>[]>(
        [],
    );
    const [companyOptions, setCompanyOptions] = useState<Record<string, any>[]>(
        [],
    );
    const [sugorokuList, setSugorokuList] = useState<ISugorokuList | null>(
        null,
    );
    const [dataSugorukuIndexs, setDataSugorukuIndexs] = useState<string[]>();

    const getListWorkers = async () => {
        try {
            const data = await workersService.getListWorkers();
            if (data) {
                setWorkerOptions(convertObjectToDropdownOptions(data.workers));
            }
        } catch (error) {
            // empty
        }
    };

    const fetchSugorokuEventsList = async () => {
        try {
            let data;

            if (business_type) {
                data = await sugorokuService.getListOfWasteTypeSugoroku(
                    project_id,
                );
            } else {
                data = await sugorokuService.getListOfSugoroku(project_id);
            }

            if (data) {
                setSugorokuList(
                    convertSugorokuArrayToObject(data.sugoroku_events),
                );
            }
            setDataSugorukuIndexs(data?.sugoroku_events?.map(item => item?.sugoroku_index))
        } catch (error) {
            // empty
        }
    };

    const fetchAllCompanies = async () => {
        try {
            let clientList;
            let cooperativeList;

            const clientData = await CompanyService.getListCompanies();
            if (clientData) {
                clientList = convertClientCompaniesToDropdownOptions(
                    clientData.client_companies,
                );
            }

            const cooperativeData =
                await CooperativeCompanyService.getListCooperativeCompanies();
            if (cooperativeData) {
                cooperativeList = convertClientCompaniesToDropdownOptions(
                    cooperativeData.cooperative_companies,
                );
            }

            const fullList = removeUndefinedCompany(
                clientList.concat(cooperativeList),
            );
            setCompanyOptions(fullList);
        } catch (error) {
            // empty
        }
    };

    const handleFinishUpdateSugorokus = () => {
        fetchSugorokuEventsList();
        setOpenStep(false);
    };
    
    const includesAll = (arr, values) => values.every(v => arr.includes(v));

    useEffect(() => {
        getListWorkers();
        fetchAllCompanies();
        fetchSugorokuEventsList();
    }, []);
    return (
        <>
            <div
                className={
                    !business_type
                        ? 'step-progress-container d-f fc margin-bottom-24'
                        : ''
                }
            >
                {sugorokuList && (
                    <>
                        {business_type ? (
                            <ProjectSteps
                                setStep={setStep}
                                setOpenStep={setOpenStep}
                                sugorokuList={sugorokuList}
                                readOnly={!!props.readOnly}
                            />
                        ) : (
                            <StepDiagram
                                setStep={setStep}
                                setOpenStep={setOpenStep}
                                sugorokuList={sugorokuList}
                                readOnly={!!props.readOnly}
                            />
                        )}
                    </>
                )}

                <Dialog
                    onClose={(event, reason) => {
                        if (
                            reason &&
                            reason == 'backdropClick' &&
                            'escapeKeyDown'
                        )
                            // return;
                            setOpenStep(false);
                    }}
                    open={openStep}
                    className="modal-sugoroku"
                    disableEscapeKeyDown
                >
                    {openStep &&
                        step ===
                            SugorokuIndexEnum.REQUEST_FOR_QUOTATION.value && (
                            <RequestQuotationModal
                                step={step}
                                data_id={
                                    sugorokuList?.request_for_quotation?.id
                                }
                                workerOptions={workerOptions}
                                companyOptions={companyOptions}
                                onClose={() => setOpenStep(false)}
                                onFinish={handleFinishUpdateSugorokus}
                            />
                        )}
                    {openStep && step === SugorokuIndexEnum.QUOTATION.value && (
                        <QuotationModal
                            step={step}
                            onClose={() => setOpenStep(false)}
                            data_id={sugorokuList?.quotation?.id}
                            workerOptions={workerOptions}
                            companyOptions={companyOptions}
                            onFinish={handleFinishUpdateSugorokus}
                            disabled={!dataSugorukuIndexs?.includes(`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`)}
                        />
                    )}
                    {openStep &&
                        step === SugorokuIndexEnum.SEND_QUOTATION.value && (
                            <SendQuotationModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={sugorokuList?.send_quotation?.id}
                                workerOptions={workerOptions}
                                companyOptions={companyOptions}
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`])}
                            />
                        )}
                    {openStep &&
                        step === SugorokuIndexEnum.PRICE_NEGOTIATION.value && (
                            <PriceNegotiationModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={sugorokuList?.price_negotiation?.id}
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`])}
                            />
                        )}
                    {openStep &&
                        step === SugorokuIndexEnum.CONTRACT_WRITING.value && (
                            <ContractWritingModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={sugorokuList?.contract_writing?.id}
                                workerOptions={workerOptions}
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`])}
                            />
                        )}

                    {openStep &&
                        step ===
                            SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS
                                .value && (
                            <SafetyDocsPrepModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={
                                    sugorokuList
                                        ?.preparation_of_safety_documents?.id
                                }
                                workerOptions={workerOptions}
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`, `${SugorokuIndexEnum.CONTRACT_WRITING.name}`])}
                            />
                        )}
                    {openStep &&
                        step === SugorokuIndexEnum.WASTE_REMOVAL_PLAN.value && (
                            <WasteRemovalPlanModal
                                step={step}
                                onClose={() => {
                                    setOpenStep(false);
                                }}
                                data_id={sugorokuList?.waste_removal_plan?.id}
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`, `${SugorokuIndexEnum.CONTRACT_WRITING.name}`, `${SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.name}`])}
                            />
                        )}
                    {openStep &&
                        step ===
                            SugorokuIndexEnum.PRE_CONSTRUCTION_REVIEW_MEETING
                                .value && (
                            <PreConstructReviewModal
                                step={step}
                                onClose={() => {
                                    setOpenStep(false);
                                }}
                                workerOptions={workerOptions}
                                data_id={
                                    sugorokuList
                                        ?.pre_construction_review_meeting?.id
                                }
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`, `${SugorokuIndexEnum.CONTRACT_WRITING.name}`, `${SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.name}`, `${SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name}`])}
                            />
                        )}

                    {openStep &&
                        step ===
                            SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING
                                .value && (
                            <PreConstructMeetingModal
                                step={step}
                                onClose={() => {
                                    setOpenStep(false);
                                }}
                                workerOptions={workerOptions}
                                data_id={
                                    sugorokuList?.pre_construction_meeting?.id
                                }
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`, `${SugorokuIndexEnum.CONTRACT_WRITING.name}`, `${SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.name}`, `${SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_REVIEW_MEETING.name}`])}
                            />
                        )}
                    {openStep &&
                        step ===
                            SugorokuIndexEnum.OVERALL_PROCESS_MEETING.value && (
                            <OverallProcessMeetingModal
                                step={step}
                                onClose={() => {
                                    setOpenStep(false);
                                }}
                                workerOptions={workerOptions}
                                data_id={
                                    sugorokuList?.overall_process_meeting?.id
                                }
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`, `${SugorokuIndexEnum.CONTRACT_WRITING.name}`, `${SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.name}`, `${SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_REVIEW_MEETING.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING.name}`])}
                            />
                        )}
                    {openStep &&
                        step === SugorokuIndexEnum.BREAKING_GROUND.value && (
                            <BreakingGroundModal
                                step={step}
                                onClose={() => {
                                    setOpenStep(false);
                                }}
                                workerOptions={workerOptions}
                                data_id={sugorokuList?.breaking_ground?.id}
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`, `${SugorokuIndexEnum.CONTRACT_WRITING.name}`, `${SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.name}`, `${SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_REVIEW_MEETING.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING.name}`])}
                            />
                        )}
                    {openStep &&
                        step === SugorokuIndexEnum.WEEKLY_REPORT.value && (
                            <WeeklyReportModal
                                step={step}
                                onClose={() => {
                                    setOpenStep(false);
                                }}
                                workerOptions={workerOptions}
                                data_id={sugorokuList?.weekly_report?.id}
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`, `${SugorokuIndexEnum.CONTRACT_WRITING.name}`, `${SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.name}`, `${SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_REVIEW_MEETING.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING.name}`, `${SugorokuIndexEnum.BREAKING_GROUND.name}`])}
                            />
                        )}
                    {openStep &&
                        step ===
                            SugorokuIndexEnum.VOLUME_ADJUSTMENT_BOOK.value && (
                            <VolumeAdjustmentModal
                                step={step}
                                onClose={() => {
                                    setOpenStep(false);
                                }}
                                workerOptions={workerOptions}
                                data_id={
                                    sugorokuList?.volume_adjustment_book?.id
                                }
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`, `${SugorokuIndexEnum.CONTRACT_WRITING.name}`, `${SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.name}`, `${SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_REVIEW_MEETING.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING.name}`, `${SugorokuIndexEnum.OVERALL_PROCESS_MEETING.name}`, `${SugorokuIndexEnum.BREAKING_GROUND.name}`, `${SugorokuIndexEnum.WEEKLY_REPORT.name}`])}
                            />
                        )}

                    {openStep && step === SugorokuIndexEnum.INVOICING.value && (
                        <InvoicingModal
                            step={step}
                            onClose={() => setOpenStep(false)}
                            workerOptions={workerOptions}
                            data_id={sugorokuList?.invoicing?.id}
                            onFinish={handleFinishUpdateSugorokus}
                            disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`, `${SugorokuIndexEnum.CONTRACT_WRITING.name}`, `${SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.name}`, `${SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_REVIEW_MEETING.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING.name}`, `${SugorokuIndexEnum.OVERALL_PROCESS_MEETING.name}`, `${SugorokuIndexEnum.BREAKING_GROUND.name}`, `${SugorokuIndexEnum.WEEKLY_REPORT.name}`, `${SugorokuIndexEnum.VOLUME_ADJUSTMENT_BOOK.name}`])}
                        />
                    )}

                    {openStep &&
                        step === SugorokuIndexEnum.CONFIRM_PAYMENT.value && (
                            <PaymentConfirmModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={sugorokuList?.confirm_payment?.id}
                                workerOptions={workerOptions}
                                companyOptions={companyOptions}
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`, `${SugorokuIndexEnum.CONTRACT_WRITING.name}`, `${SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.name}`, `${SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_REVIEW_MEETING.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING.name}`, `${SugorokuIndexEnum.OVERALL_PROCESS_MEETING.name}`, `${SugorokuIndexEnum.BREAKING_GROUND.name}`, `${SugorokuIndexEnum.WEEKLY_REPORT.name}`, `${SugorokuIndexEnum.VOLUME_ADJUSTMENT_BOOK.name}`, `${SugorokuIndexEnum.INVOICING.name}`])}
                            />
                        )}

                    {openStep && step === SugorokuIndexEnum.DONE.value && (
                        <DoneModal
                            step={step}
                            onClose={() => setOpenStep(false)}
                            data_id={sugorokuList?.done?.id}
                            onFinish={handleFinishUpdateSugorokus}
                            disabled={!includesAll(dataSugorukuIndexs, [`${SugorokuIndexEnum.REQUEST_FOR_QUOTATION.name}`, `${SugorokuIndexEnum.QUOTATION.name}`, `${SugorokuIndexEnum.SEND_QUOTATION.name}`, `${SugorokuIndexEnum.PRICE_NEGOTIATION.name}`, `${SugorokuIndexEnum.CONTRACT_WRITING.name}`, `${SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.name}`, `${SugorokuIndexEnum.WASTE_REMOVAL_PLAN.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_REVIEW_MEETING.name}`, `${SugorokuIndexEnum.PRE_CONSTRUCTION_MEETING.name}`, `${SugorokuIndexEnum.BREAKING_GROUND.name}`, `${SugorokuIndexEnum.WEEKLY_REPORT.name}`])}
                        />
                    )}

                    {openStep &&
                        step ===
                            WasteSugorokuIndexEnum.GOT_ORDER_LIST.value && (
                            <GotOrderListModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={sugorokuList?.got_order_list?.id}
                                onFinish={handleFinishUpdateSugorokus}
                                workerOptions={workerOptions}
                                disabled={false}
                            />
                        )}

                    {openStep &&
                        step === WasteSugorokuIndexEnum.BRING_OUT.value && (
                            <BringOutModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={
                                    sugorokuList
                                        ?.bring_out_the_industrial_wastes?.id
                                }
                                onFinish={handleFinishUpdateSugorokus}
                                workerOptions={workerOptions}
                                disabled={!dataSugorukuIndexs?.includes(`${WasteSugorokuIndexEnum.GOT_ORDER_LIST.name}`)}
                            />
                        )}

                    {openStep &&
                        step ===
                            WasteSugorokuIndexEnum
                                .DELIVER_TO_INTERMEDIATE_FACILITY.value && (
                            <DeliverIntermediateFacilityModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={
                                    sugorokuList
                                        ?.deliver_wastes_intermediate_treatment_facility
                                        ?.id
                                }
                                onFinish={handleFinishUpdateSugorokus}
                                workerOptions={workerOptions}
                                disabled={!includesAll(dataSugorukuIndexs, [`${WasteSugorokuIndexEnum.GOT_ORDER_LIST.name}`, `${WasteSugorokuIndexEnum.BRING_OUT.name}`])}
                            />
                        )}

                    {openStep &&
                        step ===
                            WasteSugorokuIndexEnum.INTERMEDIATE_FACILITY
                                .value && (
                            <IntermediateFacilityModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={
                                    sugorokuList
                                        ?.intermediate_treatment_facility?.id
                                }
                                onFinish={handleFinishUpdateSugorokus}
                                workerOptions={workerOptions}
                                disabled={!includesAll(dataSugorukuIndexs, [`${WasteSugorokuIndexEnum.GOT_ORDER_LIST.name}`, `${WasteSugorokuIndexEnum.BRING_OUT.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_INTERMEDIATE_FACILITY.name}`])}
                            />
                        )}

                    {openStep &&
                        step ===
                            WasteSugorokuIndexEnum.DELIVER_TO_FINAL_FACILITY
                                .value && (
                            <DeliverFinalFacilityModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={
                                    sugorokuList
                                        ?.deliver_wastes_final_treatment_facility
                                        ?.id
                                }
                                onFinish={handleFinishUpdateSugorokus}
                                workerOptions={workerOptions}
                                disabled={!includesAll(dataSugorukuIndexs, [`${WasteSugorokuIndexEnum.GOT_ORDER_LIST.name}`, `${WasteSugorokuIndexEnum.BRING_OUT.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_INTERMEDIATE_FACILITY.name}`, `${WasteSugorokuIndexEnum.INTERMEDIATE_FACILITY.name}`])}
                            />
                        )}

                    {openStep &&
                        step ===
                            WasteSugorokuIndexEnum.FINAL_FACILITY.value && (
                            <FinalFacilityModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={
                                    sugorokuList?.final_treatment_facility?.id
                                }
                                onFinish={handleFinishUpdateSugorokus}
                                workerOptions={workerOptions}
                                disabled={!includesAll(dataSugorukuIndexs, [`${WasteSugorokuIndexEnum.GOT_ORDER_LIST.name}`, `${WasteSugorokuIndexEnum.BRING_OUT.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_INTERMEDIATE_FACILITY.name}`, `${WasteSugorokuIndexEnum.INTERMEDIATE_FACILITY.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_FINAL_FACILITY.name}`])}
                            />
                        )}

                    {openStep &&
                        step ===
                            WasteSugorokuIndexEnum.FINISH_MANIFEST.value && (
                            <FinishManifestModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={
                                    sugorokuList?.finish_to_process_the_manifest
                                        ?.id
                                }
                                onFinish={handleFinishUpdateSugorokus}
                                workerOptions={workerOptions}
                                disabled={!includesAll(dataSugorukuIndexs, [`${WasteSugorokuIndexEnum.GOT_ORDER_LIST.name}`, `${WasteSugorokuIndexEnum.BRING_OUT.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_INTERMEDIATE_FACILITY.name}`, `${WasteSugorokuIndexEnum.INTERMEDIATE_FACILITY.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_FINAL_FACILITY.name}`, `${WasteSugorokuIndexEnum.FINAL_FACILITY.name}`])}
                            />
                        )}

                    {openStep &&
                        step === WasteSugorokuIndexEnum.FINISHED_BILL.value && (
                            <FinishedBillModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={sugorokuList?.finished_bill?.id}
                                onFinish={handleFinishUpdateSugorokus}
                                workerOptions={workerOptions}
                                companyOptions={companyOptions}
                                disabled={!includesAll(dataSugorukuIndexs, [`${WasteSugorokuIndexEnum.GOT_ORDER_LIST.name}`, `${WasteSugorokuIndexEnum.BRING_OUT.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_INTERMEDIATE_FACILITY.name}`, `${WasteSugorokuIndexEnum.INTERMEDIATE_FACILITY.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_FINAL_FACILITY.name}`, `${WasteSugorokuIndexEnum.FINAL_FACILITY.name}`, `${WasteSugorokuIndexEnum.FINISH_MANIFEST.name}`])}
                            />
                        )}

                    {openStep &&
                        step ===
                            WasteSugorokuIndexEnum.FINISHED_PAYMENT.value && (
                            <FinishedPaymentModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={sugorokuList?.finished_payment?.id}
                                onFinish={handleFinishUpdateSugorokus}
                                workerOptions={workerOptions}
                                companyOptions={companyOptions}
                                disabled={!includesAll(dataSugorukuIndexs, [`${WasteSugorokuIndexEnum.GOT_ORDER_LIST.name}`, `${WasteSugorokuIndexEnum.BRING_OUT.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_INTERMEDIATE_FACILITY.name}`, `${WasteSugorokuIndexEnum.INTERMEDIATE_FACILITY.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_FINAL_FACILITY.name}`, `${WasteSugorokuIndexEnum.FINAL_FACILITY.name}`, `${WasteSugorokuIndexEnum.FINISH_MANIFEST.name}`, `${WasteSugorokuIndexEnum.FINISHED_BILL.name}`])}
                            />
                        )}

                    {openStep &&
                        step === WasteSugorokuIndexEnum.COMPLETE.value && (
                            <CompleteModal
                                step={step}
                                onClose={() => setOpenStep(false)}
                                data_id={sugorokuList?.complete?.id}
                                onFinish={handleFinishUpdateSugorokus}
                                disabled={!includesAll(dataSugorukuIndexs, [`${WasteSugorokuIndexEnum.GOT_ORDER_LIST.name}`, `${WasteSugorokuIndexEnum.BRING_OUT.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_INTERMEDIATE_FACILITY.name}`, `${WasteSugorokuIndexEnum.INTERMEDIATE_FACILITY.name}`, `${WasteSugorokuIndexEnum.DELIVER_TO_FINAL_FACILITY.name}`, `${WasteSugorokuIndexEnum.FINAL_FACILITY.name}`, `${WasteSugorokuIndexEnum.FINISH_MANIFEST.name}`, `${WasteSugorokuIndexEnum.FINISHED_BILL.name}`, `${WasteSugorokuIndexEnum.FINISHED_PAYMENT.name}`])}
                            />
                        )}
                </Dialog>
            </div>
        </>
    );
};

export default ProgressStep;
