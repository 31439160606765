import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { Dispatch, SetStateAction } from 'react';
import { WasteSugorokuIndexEnum } from 'utils/enums';
import { convertSugorokuEnumObjectToArray } from 'utils/helper/sugorokuHelper';
import { ISugorukuItem } from '../StepDiagram';

const SugorokuEnum = [
    { name: 'request_for_quotation', value: 10, title: '委託契約' },
    { name: 'quotation', value: 20, title: '排出' },
    { name: 'send_quotation', value: 30, title: '運搬' },
    { name: 'price_negotiation', value: 40, title: '中間処理' },
    { name: 'contract_writing', value: 50, title: '運搬' },
    { name: 'preparation_of_safety_documents', value: 60, title: '最終処分' },
    { name: 'pre_construction_review_meeting', value: 70, title: 'マニ完了' },
    { name: 'pre_construction_meeting', value: 80, title: '請求' },
    { name: 'breaking_ground', value: 90, title: '入金' },
    { name: 'weekly_report', value: 100, title: '完了' },
];
interface IProjectStepProps {
    setStep: Dispatch<SetStateAction<number>>;
    setOpenStep: Dispatch<SetStateAction<boolean>>;
    sugorokuList: any;
    readOnly?: boolean;
}

const ProjectSteps = ({
    readOnly,
    sugorokuList,
    setOpenStep,
    setStep,
}: IProjectStepProps) => {
    const handleStep = (step: number, value: number) => () => {
        // if(readOnly) return
        setOpenStep(true);
        setStep(value);
    };
    const wasteSugorokuEnumList = convertSugorokuEnumObjectToArray(WasteSugorokuIndexEnum);
    const arrOrigin = wasteSugorokuEnumList?.map((item, index) => {
        return {...item, number: index + 1}
    });
    let arrWasteSugorokuEnumList: ISugorukuItem[] = [];
    let arrCompleteds: ISugorukuItem[] = [];
    for(let i = arrOrigin.length - 1; i >= 0; i--) {
        if(sugorokuList[arrOrigin[i].name]?.completed_at) {
            arrCompleteds?.push(arrOrigin[i])
        }
        
        if(arrOrigin[i].number < arrCompleteds[0]?.number && !sugorokuList[arrOrigin[i].name]?.completed_at) {
            arrWasteSugorokuEnumList.push({...arrOrigin[i], isCompleted: false})
        } else {
            arrWasteSugorokuEnumList.push({...arrOrigin[i], isCompleted: true})
        }
    }
    arrWasteSugorokuEnumList = arrWasteSugorokuEnumList?.sort((a, b) => a.number - b.number)
    return (
        <Box
            sx={{
                width: '100%',
                fontFamily: 'Zen Kaku Gothic Antique',
                mb: '40px',
                '.Mui-active': {
                    color: '#215493 !important',
                    text: {
                        fill: '#fff',
                    },
                },
                '.MuiStepLabel-labelContainer': {
                    '.Mui-active': {
                        color: '#1C1E21 !important',
                    },
                },
                '.MuiSvgIcon-root': {
                    color: '#E3E5E8',
                    width: '34px',
                    height: '34px',
                },
                '.MuiStepLabel-iconContainer': {
                    mt: '-6px',
                },
                '&.MuiStepConnector-line': {
                    borderColor: '#8D95A0',
                },

                '.MuiStepLabel-labelContainer > span': {
                    color: '#58606A',
                    fontWeight: 500,
                    fontSize: '16px',
                },
                text: {
                    fill: '#215493',
                    fontWeight: 700,
                },
            }}
        >
            <Stepper nonLinear alternativeLabel activeStep={-1}>
                {arrWasteSugorokuEnumList?.map((label, index) => (
                    <Step
                        active={sugorokuList[label.name]?.completed_at}
                        color="red"
                        key={label.value}
                    >
                        <StepButton
                            sx={{ color: '#000000',
                             '.MuiSvgIcon-root': { color: label?.isCompleted ? '#E3E5E8' : '#eb0c31' }, 
                             '.MuiStepIcon-text': { fill: label?.isCompleted ? '#215493' : '#ffffff' }, 
                            }}
                            onClick={handleStep(index, label.value)}
                            className={`${!label?.isCompleted ? 'class-bg-red' : ''}`}
                        >
                            {label.title}
                        </StepButton>
                        {sugorokuList[label.name]?.completed_at && (
                          <Typography>
                            {sugorokuList[label.name]?.completed_at}
                          </Typography>
                        )}
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default ProjectSteps;
