import { FC, useState } from 'react';
import classNames from 'classnames';
import {
    Scheduler,
    MonthView,
    WeekView,
    DayView,
    Toolbar,
    DateNavigator,
    Appointments,
    TodayButton,
    AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
    ViewState,
    EditingState,
    IntegratedEditing,
} from '@devexpress/dx-react-scheduler';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import AppointmentItem from './AppointmentItem';
import DayList from './DayList';
import CreateScheduleDialog from '../CreateScheduleDialog';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import {
    AllDayViewTimeTableCell,
    AllDayViewTimeTableRow,
    DayViewDayScaleCell,
    DayViewTimeTableCell,
    MonthViewDayScaleCell,
    MonthViewTimeTableCell,
    WeekViewDayScaleCell,
    WeekViewTimeTableCell,
} from './Components';
import useSchedulerBox, { Props, ReceivedProps, SWITCHER_LIST } from './hook';
import { SchedulerBoxWrapper } from './style';
import PlanUpdate from 'pages/project/projectPlan/components/planUpdate';
import ProjectPlanDialog from '../ProjectPlanDialog';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
    ScheduleComponent,
    EventSettingsModel,
    ViewsDirective,
    ViewDirective,
    Inject,
    TimelineViews,
    ResourcesDirective,
    ResourceDirective,
} from "@syncfusion/ej2-react-schedule";
import { useNavigate } from "react-router-dom";
import { isAllowAction } from "utils/helper/workerHelper";
import moment from 'moment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/ja';

const localizer = momentLocalizer(moment);
const messages = {
  previous: <ArrowBackIosIcon className='!text-[16px]' />,
  next: <ArrowForwardIosIcon className='!text-[16px]' />,
  today: "今日",
  showMore: function showMore(total) {
    return "他の" + total + "件";
  }
};

const SchedulerBoxLayout: FC<Props> = ({
    loading,
    showDayList,
    currentViewName,
    currentDate,
    localScheduleDate,
    scheduleList,
    openScheduleFormDialog,
    openDeleteConfirmationDialog,
    openPlanUpdateModal,
    workerOptions,
    activeTab,
    deleteSchedule,
    fetchSchedules,
    setCurrentItemId,
    setOpenDeleteConfirmationDialog,
    commitChanges,
    setCurrentDate,
    setShowDayList,
    setCurrentViewName,
    setOpenScheduleFormDialog,
    setOpenPlanUpdateModal,
    isShowData,
    setIsShowData,
    userData,
    searchParam,
    dataManager,
    filterWorker
}) => {
    const navigate = useNavigate();
    const allow = isAllowAction(userData);
    const [isAllow, setIsAllow] = useState<boolean>(allow);
    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress size={16} />
            </Box>
        );
    }
    const dataSchedulesShow = dataManager?.dataSource.json?.filter((item: any) => !item?.is_show_on_todo_list);

    const onChange = (e: CheckboxChangeEvent) => {
        setIsShowData(e.target.checked)
        localStorage.setItem("showData", `${e.target.checked}`);
    };
    const eventSettings: EventSettingsModel = {
        dataSource: isShowData ? dataManager?.dataSource.json : dataSchedulesShow,
    };

    const timeScale = { enable: false, interval: 60, slotCount: 2 };
    const getTheDay = (value) => {
        switch (value.getDay()) {
          case 0:
            return <span>日</span>;
          case 1:
            return <span>月</span>;
          case 2:
            return <span>火</span>;
          case 3:
            return <span>水</span>;
          case 4:
            return <span>木</span>;
          case 5:
            return <span>金</span>;
          case 6:
            return <span>土</span>;
          default:
            return "";
        }
      };
    const dateHeaderTemplate = (props) => {
        return (
          <div
            onClick={() => {
              if (currentViewName !== "Day") {
                setShowDayList(false);
                setCurrentViewName("Day");
                setCurrentDate(new Date(props.date));
              }
            }}
          >
            {getTheDay(props.date)}
            <div>{getDateHeaderText(props.date)}</div>
          </div>
        );
    };
    const getDateHeaderText = (value) => {
        return value.getDate();
      };
    const onPopupOpen = (args) => {
        args.cancel = true;
        if (!args.data.id) {
          setOpenScheduleFormDialog(true);
          navigate(`/calendar`, {
            state: {
              editingId: null,
            },
          });
        } else {
          if(args.data.isProject && args.data.project_id) {
            //navigate(
            //  `/projects/detail/${args.data.project_id}/plan`,
            //);
          } else {
          if (args.data.project_id) {
            setOpenPlanUpdateModal(true);
            navigate(`/calendar`, {
              state: {
                plan_id: args.data.id,
                project_id: args.data.project_id,
                currentPlan: args.data,
              },
            });
          } else {
            if (args.data.showWeekView) {
              setCurrentViewName("Week");
              setCurrentDate(args.data.startDate);
            } else {
              if (args.data.id && !args.data.groupIndex) {
                if (args?.data?.NotPublish) {
                  setOpenScheduleFormDialog(false);
                } else {
                  navigate(`/calendar`, {
                    state: {
                      editingId: args.data.id,
                    },
                  });
                  setOpenScheduleFormDialog(true);
                }
                if (
                  args?.data?.worker?.id === (userData as any)?.worker?.id
                ) {
                  setIsAllow(true);
                } else {
                  setIsAllow(false);
                }
              } else {
                if (!args.data.groupIndex) {
                  setShowDayList(false);
                  setCurrentViewName("Day");
                  setCurrentDate(new Date(args.data.StartTime));
                }
              }
            }
          }
        }}
      };
      const eventRendered = (args) => {
        let categoryColor = args.data.color;
        if (!args.element || !categoryColor) {
          return;
        }
    
        if (args.data.IsAllDay|| args.data.allDay) {
          //args.element.classList.value += " css-allDay-appointment";
          args.element.querySelector(".e-time").innerHTML = `${moment(args.data.start_at || args.data.startDate).format('YYYY/MM/DD')} - ${moment(args.data.end_at || args.data.endDate).format('YYYY/MM/DD')}`;
        } else if (!args.data.allDay) {
          args.element.querySelector(".e-time").innerHTML = `${moment(args.data.startDate).format('YYYY/MM/DD HH:mm')} - ${moment(args.data.endDate).format('YYYY/MM/DD HH:mm')}`;
        }

        if (
          args.data?.NotPublish === true &&
          !args.data?.ConferenceId.includes(userData?.worker?.id)
        ) {
          args.element.querySelector(".e-subject").innerHTML = "アポ有り";
          args.element.querySelector(".e-time").innerHTML = "";
        }
        if (
          args.data?.IsAgree === null 
        ) {
          args.element.classList.value += " css-muitiple-slash-lines";
        }
        args.element.style.backgroundColor = categoryColor;
      };
      const isShowNullWorker = searchParam?.department_id?.length > 0 || searchParam?.free_word?.length > 0 || searchParam?.position_id?.length > 0 || searchParam?.worker_id?.length > 0 || searchParam?.worker_list?.length > 0;

      const eventStyleGetter = (event, start, end, isSelected) => {
        let style = {
            backgroundColor: event?.color,
            borderRadius: '4px',
            color: 'white',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
      }

      const handleSelectedEvent = (event) => {
        if (!event.id) {
          setOpenScheduleFormDialog(true);
          navigate(`/calendar`, {
            state: {
              editingId: null,
            },
          });
        } else {
          if(event.isProject && event.project_id) {
            //navigate(
            //  `/projects/detail/${args.data.project_id}/plan`,
            //);
          } else {
          if (event.project_id) {
            setOpenPlanUpdateModal(true);
            navigate(`/calendar`, {
              state: {
                plan_id: event.id,
                project_id: event.project_id,
                currentPlan: event,
              },
            });
          } else {
            if (event.showWeekView) {
              setCurrentViewName("Week");
              setCurrentDate(event.startDate);
            } else {
              if (event.id && !event.groupIndex) {
                if (event?.NotPublish) {
                  setOpenScheduleFormDialog(false);
                } else {
                  navigate(`/calendar`, {
                    state: {
                      editingId: event.id,
                    },
                  });
                  setOpenScheduleFormDialog(true);
                }
                if (
                  event?.worker?.id === (userData as any)?.worker?.id
                ) {
                  setIsAllow(true);
                } else {
                  setIsAllow(false);
                }
              } else {
                if (!event.groupIndex) {
                  setShowDayList(false);
                  setCurrentViewName("Day");
                  setCurrentDate(new Date(event.StartTime));
                }
              }
            }
          }
        }}
      }
    return (
        <>
            {openScheduleFormDialog && (
                <CreateScheduleDialog
                    fetchSchedules={fetchSchedules}
                    open={openScheduleFormDialog}
                    openDeleteConfirmationDialog={openDeleteConfirmationDialog}
                    setOpen={setOpenScheduleFormDialog}
                    setOpenDeleteConfirmationDialog={
                        setOpenDeleteConfirmationDialog
                    }
                    activeTab={activeTab}
                    setCurrentItemId={setCurrentItemId}
                    workerOptions={workerOptions || []}
                />
            )}

            {
              openPlanUpdateModal && (
                <ProjectPlanDialog 
                  open={openPlanUpdateModal}
                  setOpen={setOpenPlanUpdateModal}
                  fetchSchedules={fetchSchedules}
                />
              )
            }


            {openDeleteConfirmationDialog && (
                <DeleteConfirmationDialog
                    open={openDeleteConfirmationDialog}
                    setOpen={setOpenDeleteConfirmationDialog}
                    onClick={deleteSchedule}
                />
            )}

            <SchedulerBoxWrapper className={currentViewName === "Day" ? "not-show" : "show"}>
                <div
                    className={classNames(
                       "!mt-[44px] schedulerWrapper",
                        showDayList ? 'hideMainTable' : '',
                    )}
                >
                    <div
                        className={
                            activeTab !== 0
                                ? "customViewSwitcher customViewSwitcher2"
                                : "customViewSwitcher"
                        }
                    >
                        <Checkbox onChange={onChange} checked={isShowData}>TODO</Checkbox>
                        {SWITCHER_LIST.map((item) => (
                            <button
                                className={classNames(
                                    item.id === currentViewName && !showDayList
                                        ? 'active'
                                        : '',
                                )}
                                key={item.id}
                                type="button"
                                onClick={() => {
                                    setShowDayList(false);
                                    setCurrentViewName(item.id);
                                    if (item.id === "Week") {
                                        setCurrentDate(new Date());
                                    }
                                }}
                            >
                                {item.name}
                            </button>
                        ))}

                        <button
                            type="button"
                            className={classNames(showDayList ? 'active' : '')}
                            onClick={() => {
                                setShowDayList(true);
                                setCurrentViewName('Day');
                                if (!currentDate || currentDate !== new Date()) {
                                    setCurrentDate(new Date());
                                }
                            }}
                        >
                            リスト
                        </button>
                    </div>
                    {currentViewName !== "Week" && currentViewName !== "Day" && (
                      <div className='calendar-container'>
                        <Calendar
                          events={isShowData ? dataManager?.dataSource.json : dataSchedulesShow}
                          step={60}
                          defaultDate={new Date()}
                          popup={false}
                          onShowMore={(events, date) => { setCurrentDate(new Date(date)), setCurrentViewName("Week") }}
                          startAccessor="start"
                          endAccessor="end"
                          localizer={localizer}
                          eventPropGetter={eventStyleGetter}
                          onSelectEvent={(e) => handleSelectedEvent(e)}
                          culture="ja"
                          messages={messages}
                        />
                      </div>
                    )}
                    {currentViewName === "Week" && (
                        <ScheduleComponent
                            id="schedule-week"
                            className="schedule-week"
                            cssClass="sj2ScheduleComponent"
                            eventSettings={eventSettings}
                            popupOpen={onPopupOpen} // click event
                            firstDayOfWeek={1}
                            timeScale={timeScale}
                            rowAutoHeight={true}
                            selectedDate={currentDate}
                            group={{ enableCompactView: false, resources: ["Worker"] }} // list workers
                            dateHeaderTemplate={dateHeaderTemplate} // custom header japanese
                            eventRendered={eventRendered} // event color
                        //timezone="Asia/Tokyo"
                        >
                            <ViewsDirective>
                                <ViewDirective option="TimelineWeek" />
                            </ViewsDirective>
                            <div className="btnToDayViewWeek" onClick={() => setCurrentViewName("Day")}>今日</div>
                            <ResourcesDirective>
                                <ResourceDirective
                                    field="ConferenceId"
                                    title="Workers"
                                    name="Worker"
                                    allowMultiple={true}
                                    dataSource={
                                        (activeTab === 0
                                            ? workerOptions?.filter(
                                                (item) => item.id === (userData as any)?.worker?.id
                                            )
                                            : searchParam?.worker_list?.length > 0
                                                ? workerOptions?.filter((v) =>
                                                    searchParam?.worker_list.includes(v.id)
                                                )
                                                : isShowNullWorker ? [] : workerOptions
                                        ).map((v) => ({
                                            text: v.name,
                                            id: v.id,
                                        })) || []
                                    }
                                    textField="text"
                                    idField="id"
                                    colorField="color"
                                />
                            </ResourcesDirective>
                            <Inject services={[TimelineViews]} />
                        </ScheduleComponent>
                    )}
                    {currentViewName === "Day" && !showDayList && (
                        <ScheduleComponent
                            id="schedule-day"
                            className="schedule-day"
                            cssClass="sj2ScheduleComponent"
                            eventSettings={eventSettings}
                            popupOpen={onPopupOpen} // click event
                            rowAutoHeight={true}
                            selectedDate={currentDate}
                            group={{ enableCompactView: false, resources: ["Worker"] }} // list workers
                            dateHeaderTemplate={dateHeaderTemplate} // custom header japanese
                            eventRendered={eventRendered} // event color
                            workHours={{ highlight: true, start: '08:00', end: '17:00' }}
                        >
                            <ViewsDirective>
                                <ViewDirective option="TimelineDay" />
                            </ViewsDirective>
                            <div className="btnToDayViewWeek" onClick={() => { setCurrentViewName("Day"); setCurrentDate(new Date()) }}>今日</div>
                            <ResourcesDirective>
                                <ResourceDirective
                                    field="ConferenceId"
                                    title="Workers"
                                    name="Worker"
                                    allowMultiple={true}
                                    dataSource={
                                        (activeTab === 0
                                            ? workerOptions?.filter(
                                                (item) => item.id === (userData as any)?.worker?.id
                                            )
                                            : searchParam?.worker_list?.length > 0
                                                ? workerOptions?.filter((v) =>
                                                    searchParam?.worker_list.includes(v.id)
                                                )
                                                : isShowNullWorker ? [] : workerOptions
                                        ).map((v) => ({
                                            text: v.name,
                                            id: v.id,
                                        })) || []
                                    }
                                    textField="text"
                                    idField="id"
                                    colorField="color"
                                />
                            </ResourcesDirective>
                            <Inject services={[TimelineViews]} />
                        </ScheduleComponent>
                    )}
                    {showDayList && (
                        <DayList
                            data={isShowData ? scheduleList?.data : scheduleList?.data?.filter((item: any) => !item?.is_show_on_todo_list)}
                            currentDate={currentDate}
                            setOpenScheduleFormDialog={
                                setOpenScheduleFormDialog
                            }
                        />
                    )}
                </div>
            </SchedulerBoxWrapper>
        </>
    );
};

const SchedulerBox: FC<ReceivedProps> = (props) => (
    <SchedulerBoxLayout {...useSchedulerBox(props)} />
);

export default SchedulerBox;
