import { useEffect, useState } from 'react';
import './WorkingReport.css';
import workingLogService from 'services/workingLogService';
import { formatDateTime } from 'utils/helper/dateHelper';
import calendarIcon from '../../../../assets/icons/calendar.png';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import { Controller, useForm } from 'react-hook-form';
import {
    Table,
    Paper,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import createWorkingReportValidation from 'utils/validation/registers/workingReports/createWorkingReportValidation';
import classNames from 'classnames';
import _ from 'lodash';
import plansService from 'services/plansService';
import { useDispatch, useSelector } from 'react-redux';
import { setLogs } from 'store/workingReport/workingReport';
import { RootState } from 'store';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '6px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    border: '1px solid #CDD1D5',
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const checkBoxStyles = {
    checkmark: {
        background: '#3DDD74',
        color: 'white',
    },
    checkbox: {
        background: '#3DDD74',
        borderColor: '#3DDD74',
        width: '15px',
        margin: '5px 15px 0 0',
        height: '15px',
    },
};

const WorkingReportLog = ({ id, project_id, onClose, onAdd }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
    } = useForm();
    const { logs = [] as any[] } = useSelector(
        (state: RootState) => state.workingReport,
    );
    const [loadedLogs, setLoadedLogs] = useState<any[]>([]);
    const [selectedLogs, setSelectedLogs] = useState<any[]>([]);
    const [plans, setPlans] = useState<any[]>([]);
    const [isErrorDate, setIsErrorDate] = useState(false);

    const isValidDate = (startDate, endDate) => {
        if(!startDate || !endDate) return true;
        if(new Date(startDate).getTime() > new Date(endDate).getTime()) {
            setIsErrorDate(true);
            return false;
        } else {
            setIsErrorDate(false);
            return true;
        }
    }

    const onSubmit = () => {
        onAdd();
        dispatch(setLogs(selectedLogs));
    };

    const footer = (
        <div className="flex justify-end mt-[16px]">
            <div
                className={classNames(
                    'grid gap-x-[16px] justify-end grid-cols-2 w-1/2 lg:w-1/3 2xl:w-1/4',
                    // showModalCreate ? 'grid-cols-3 w-1/2' : 'grid-cols-4 w-3/4',
                )}
            >
                <button
                    type="button"
                    className="border-main border-[1px] text-main px-[20px] py-[10px] rounded-[6px] whitespace-nowrap"
                    style={{ color: '#215493' }}
                    onClick={onClose}
                >
                    閉じる
                </button>
                <button
                    type="button"
                    // TODO: this button is register and sent, intergrate more API
                    className="bg-main px-[20px] py-[10px] rounded-[6px] text-white whitespace-nowrap"
                    onClick={onSubmit}
                >
                    追加
                </button>
            </div>
        </div>
    );

    const covertOptions = (plans) => {
        return plans?.map((plan: any) => {
            return {
                label: plan?.name,
                value: plan?.id,
            };
        });
    };

    const getPlans = async () => {
        if (project_id) {
            const responsePlan = await plansService.getListPlan(project_id);
            if (responsePlan !== null && responsePlan !== undefined) {
                setPlans(responsePlan.plans);
            }
        }
    };

    const getListWorkingLog = async (page = 1, data = {}) => {
        try {
            const response = await workingLogService.getListWorkingLogs({
                page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                setLoadedLogs(response?.working_logs || []);
                setSelectedLogs(response?.working_logs || logs);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const handleSearch = () => {
        if(isErrorDate) return;
        const { start_at, end_at, plan_id, free_word } = getValues();
        getListWorkingLog(1, {
            project_id: project_id,
            start_at: start_at,
            end_at: end_at,
            plan: plan_id,
            free_word: free_word,
        });
    };

    useEffect(() => {
        getPlans();
    }, []);

    useEffect(() => {
        handleSearch();
    }, []);

    useEffect(() => {
        setSelectedLogs(logs);
    }, [logs]);

    return (
        <>
            <div className="bg-[#ffffff] flex flex-col rounded-[12px] justify-center items-center p-[28px] mx-auto">
                <div className="modal-create-user-title d-flex flex-row">
                    作業記録追加
                </div>
                <form>
                    <div className="flex flex-col overflow-auto max-h-[80vh]">
                        <div className="w-full grid grid-cols-2 gap-x-[28px] mt-[28px]">
                            <div>
                                <p className="text-[14px] mb-[4px]">作業日</p>
                                <div className="flex items-baseline justify-between gap-x-[8px] mt-[8px]">
                                    <div className="flex flex-col">
                                        <Controller
                                            // rules={{ required: true }}
                                            name="start_at"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <div className="flex-1 relative">
                                                        <DatePicker
                                                            {...field}
                                                            {...register(
                                                                'start_at',
                                                            )}
                                                            className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px] mt-[8px]"
                                                            selected={getValues(
                                                                'start_at',
                                                            )}
                                                            onChange={(
                                                                date,
                                                            ) => {
                                                                setValue(
                                                                    'start_at',
                                                                    date,
                                                                );
                                                                isValidDate(date, getValues('end_at'))
                                                            }}
                                                            dateFormat="yyyy/MM/dd"
                                                            autoComplete="off"
                                                            locale="ja"
                                                            id="start_at"
                                                        />
                                                        <label htmlFor="start_at">
                                                            <img
                                                                className="absolute-bottom"
                                                                width={'16px'}
                                                                height={'16px'}
                                                                src={
                                                                    calendarIcon
                                                                }
                                                            />
                                                        </label>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <span className="flex-0 pb-[6px]">~</span>
                                    <div className="flex flex-col">
                                        <Controller
                                            name="end_at"
                                            // rules={{ required: true }}
                                            control={control}
                                            render={({ field }) => (
                                                <div className="flex-1 possition-relative">
                                                    <label htmlFor="end_at"></label>
                                                    <DatePicker
                                                        {...field}
                                                        {...register('end_at')}
                                                        className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px] mt-[8px]"
                                                        selected={getValues(
                                                            'end_at',
                                                        )}
                                                        onChange={(date) => {
                                                            setValue(
                                                                'end_at',
                                                                date,
                                                            );
                                                            isValidDate(getValues('start_at'), date);
                                                        }}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                        id="end_at"
                                                    />
                                                    <label htmlFor="end_at">
                                                        <img
                                                            className="absolute-bottom"
                                                            width={'16px'}
                                                            height={'16px'}
                                                            src={calendarIcon}
                                                        />
                                                    </label>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                {isErrorDate && <p className='text-[#f44336] mb-0'>終了は開始日時以降を入力してください。</p>}
                            </div>
                            <div className="flex flex-col">
                                <Controller
                                    name="plan_id"
                                    // rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex-1">
                                            <p className="text-[14px] mb-[4px] font-medium">
                                                工程
                                            </p>
                                            <Dropdown
                                                {...register(
                                                    'plan_id',
                                                    createWorkingReportValidation(
                                                        t,
                                                    ).manager_worker_id(),
                                                )}
                                                {...field}
                                                className="dropdown-create-user1 height36 mt-[16px]"
                                                controlClassName="dropdown-control-user height36"
                                                options={covertOptions(plans)}
                                                placeholder=""
                                                onFocus={() => {
                                                    let eleNoOptionDom = document.getElementsByClassName('Dropdown-noresults');
                                                    setTimeout(() => {
                                                        let eleNoOption = Array.from(eleNoOptionDom);
                                                        if (eleNoOption?.length > 0) {
                                                            eleNoOption[0].innerHTML = '該当データが存在しません'
                                                        }
                                                    }, 10)
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="w-full grid grid-cols-2 mt-[16px] gap-x-[28px]">
                            <Controller
                                name="free_word"
                                control={control}
                                render={({ field }) => (
                                    <div className="w-full flex flex-col">
                                        <p className="text-[14px] mb-[4px] font-medium">
                                            作業名
                                        </p>
                                        <input
                                            {...register('free_word')}
                                            {...field}
                                            className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px] mt-[8px]"
                                        />
                                    </div>
                                )}
                            />
                            <div className="justify-end w-full flex mt-[36px]">
                                <button
                                    type="button"
                                    className="border-main border-[1px] bg-main text-white px-[20px] py-[4px] rounded-[6px] flex items-center"
                                    onClick={handleSearch}
                                >
                                    検索
                                </button>
                            </div>
                            {errors.submitted_at && (
                                <span className="error">
                                    {errors.submitted_at.message}
                                </span>
                            )}
                        </div>
                        <span className="font-bold text-[16px] mt-[32px]">
                            作業記録
                        </span>
                        <div className="table-workers d-flex flex-column mt-[16px]">
                            <div className="table-content d-flex flex-column">
                                <TableContainer
                                    component={Paper}
                                    table-layout={'auto'}
                                >
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    <Checkbox
                                                        checked={
                                                            loadedLogs.length ===
                                                                selectedLogs?.length &&
                                                            selectedLogs?.length >
                                                                0
                                                        }
                                                        styles={checkBoxStyles}
                                                        onChange={() => {
                                                            setSelectedLogs(
                                                                loadedLogs.length ===
                                                                    selectedLogs?.length
                                                                    ? []
                                                                    : loadedLogs,
                                                            );
                                                        }}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    工程
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    作業名
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    作業日
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {loadedLogs !== null &&
                                                loadedLogs?.map(
                                                    (record, ind) => (
                                                        <StyledTableRow
                                                            key={ind}
                                                        >
                                                            <StyledTableCell
                                                                className="text-overflow"
                                                                align="center"
                                                                // scope="row"
                                                            >
                                                                {/* {!logs?.find(
                                                                    (log) =>
                                                                        log?.id ===
                                                                        record?.id,
                                                                ) && ( */}
                                                                <Checkbox
                                                                    checked={
                                                                        !!selectedLogs?.find(
                                                                            (
                                                                                log,
                                                                            ) =>
                                                                                log?.id ===
                                                                                record?.id,
                                                                        )
                                                                    }
                                                                    styles={
                                                                        checkBoxStyles
                                                                    }
                                                                    onChange={() => {
                                                                        const check =
                                                                            !!selectedLogs?.find(
                                                                                (
                                                                                    log,
                                                                                ) =>
                                                                                    log?.id ===
                                                                                    record?.id,
                                                                            );
                                                                        setSelectedLogs(
                                                                            check
                                                                                ? selectedLogs?.filter(
                                                                                      (
                                                                                          item,
                                                                                      ) =>
                                                                                          !_.isEqual(
                                                                                              item?.id,
                                                                                              record?.id,
                                                                                          ),
                                                                                  )
                                                                                : [
                                                                                      ...selectedLogs,
                                                                                      record,
                                                                                  ],
                                                                        );
                                                                    }}
                                                                />
                                                                {/* )} */}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow"
                                                                align="center"
                                                            >
                                                                {
                                                                    record?.plan
                                                                        ?.name
                                                                }
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow"
                                                                align="center"
                                                            >
                                                                {record?.name}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow"
                                                                align="center"
                                                            >
                                                                {formatDateTime(
                                                                    record?.worked_at,
                                                                    'YYYY/MM/DD',
                                                                )}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ),
                                                )}
                                            {/* {loadedLogs?.length === 0 && (
                                                <StyledTableRow>
                                                    <StyledTableCell
                                                        className="text-overflow"
                                                        align="center"
                                                        colSpan={4}
                                                    >
                                                        データなし
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )} */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                    {footer}
                </form>
            </div>
        </>
    );
};

export default WorkingReportLog;
