import styled from 'styled-components';

export const StylesEstimateSp = styled.div`
    padding: 12px 16px;
    .estimateSp--title {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 10px;
        line-height: normal;
    }
`;

