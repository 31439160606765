/*
 * function convert workers to dropdown options
 *
 */
export const convertWorkersToDropdownOptions = (
    array: Array<any> = [],
): Array<{
    value: string;
    label: string;
    name: string;
}> => {
    return array.map((item) => ({
        value: item.id,
        label: item.name,
        name: item.name,
    }));
};

export const isAdmin= (userData: {role: string}) => {
    if(!userData) return;
    if(userData?.role === "admin") return true;
}

export const isManager= (userData: {role: string}) => {
    if(!userData) return;
    if(userData?.role === "manager") return true;
}
export const isAllowAction = (userData: any) => {
    if (userData?.role === "admin") return true;

    if (!userData?.worker || !userData?.worker?.departments || userData?.worker?.departments?.length === 0) return false;

    return userData?.worker?.departments?.filter(department => department.name === '役員').length > 0;
}
