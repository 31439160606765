import {useState, useEffect} from 'react'
import './clientCompanyDetail.css'
import { useNavigate, useParams } from "react-router-dom";
import CompanyService from "../../../services/clientCompanyService";

const ClientCompanyDetail = () => {
    const navigate = useNavigate();
    const [company, setCompany]=useState<any>(null)
    const { id } = useParams();

    const getCompanyInf = async (company_id) => {
        try {
            const response = await CompanyService.getCompany(company_id)
            console.log(response)
            if(response !== null && response !== undefined) {
                setCompany(response.client_company)
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    useEffect(() => {
      if (id) {
        getCompanyInf(id);
      }
    }, [id]);

    return(
        <div className='container-worker-detail d-flex flex-row'>
            <div className='worker-detail-content d-flex flex-column'>
                <div className="worker-detail-name-title client-company-header d-flex flex-row">
                    {company?.company?.name}
                    <button className='create-project-button2 d-flex flex-row' 
                    onClick={() => navigate(`/companies/edit/${id}`)}>
                    編集
                    </button>
                </div>
                <div className="worker-detail-inf d-flex flex-column">
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div className="worker-detail-inf-row-title" style={{borderTopLeftRadius: 5}}>親会社</div>
                        <div className="worker-detail-inf-row-content">{company?.company?.parent_company?.name}</div>
                    </div>
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div className="worker-detail-inf-row-title">設立日</div>
                        <div className="worker-detail-inf-row-content">{company?.company?.date_of_establishment}</div>
                    </div>
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div className="worker-detail-inf-row-title">Eメール</div>
                        <div className="worker-detail-inf-row-content">{company?.company?.email}</div>
                    </div>
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div className="worker-detail-inf-row-title">資本金</div>
                        <div className="worker-detail-inf-row-content">{company?.company?.capital}</div>
                    </div>
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div className="worker-detail-inf-row-title">電話番号</div>
                        <div className="worker-detail-inf-row-content">{company?.company?.phone}</div>
                    </div>
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div className="worker-detail-inf-row-title">住所</div>
                        <div className="worker-detail-inf-row-content">{company?.company?.address}</div>
                    </div>
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div className="worker-detail-inf-row-title">従業員数</div>
                        <div className="worker-detail-inf-row-content">{company?.company?.number_of_employees}</div>
                    </div>
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div className="worker-detail-inf-row-title">FAX</div>
                        <div className="worker-detail-inf-row-content">{company?.company?.fax}</div>
                    </div>
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div className="worker-detail-inf-row-title">事業内容</div>
                        <div className="worker-detail-inf-row-content">{company?.company?.business_content}</div>
                    </div>

                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div className="worker-detail-inf-row-title" style={{borderBottomLeftRadius: 5, borderBottom: 'none'}}>郵便番号 </div>
                        <div className="worker-detail-inf-row-content">{company?.company?.zipcode}</div>
                    </div>
                </div>
                <div className="worker-detail-footer d-flex flex-row client-company-bottom">
                    <button className='back-button d-flex flex-row' onClick={() => navigate('/companies')}>
                    戻る
                    </button>
                </div>
            </div>
        </div>
        );
}

export default ClientCompanyDetail;