import { useEffect, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import Dropdown from 'react-dropdown';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import calendarIcon from 'assets/icons/calendar.png';

import { validate } from './validation';
import { Main } from './style';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
interface IFormData {
    project_id: number;
    name: string;
    status: {value: string; label: string};
    status_name: string;
    assigned_worker: {value: number; label: string};
    approver_worker: {value: number; label: string};
    approve_expired_at: string;
    isGotoEdit: boolean;
    document?: File;
}
const FormEdit = ({
    id,
    loading,
    project,
    status,
    estimate,
    workers,
    submitForm,
}) => {
    const { control } = useForm();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [fileData, setFileData] = useState<string | null>();
    const initialValues: IFormData = {
        project_id: 0,
        name: '',
        status: {
            value: '',
            label: '',
        },
        status_name: '',
        assigned_worker: {
            value: 0,
            label: '',
        },
        approver_worker: {
            value: 0,
            label: '',
        },
        approve_expired_at: '',
        isGotoEdit: false,
        document: undefined,
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validate,
        onSubmit: (values) => {
            submitForm(values);
        },
    });

    const renderContentPreview = () => {
        if (!fileData) return;
            return <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={fileData}
                    defaultScale={SpecialZoomLevel.PageWidth}
                />
            </Worker>
    }

    const handleViewFile = async (values) => {
        try {
            const response = await fetch(values?.url);
            const data = await response.blob();
            const file = window.URL.createObjectURL(data);
            values?.url ? setFileData(file) : setFileData(URL.createObjectURL(values));
        } catch (error) {}
    };
    useEffect(() => {
        if (estimate?.id) {
            const value = { ...estimate };

            if (value.approver_worker) {
                value.approver_worker = {
                    ...value.approver_worker,
                    label: value?.approver_worker?.name,
                    value: value?.approver_worker?.id,
                };
            }
            if (value.assigned_worker) {
                value.assigned_worker = {
                    ...value.assigned_worker,
                    label: value?.assigned_worker?.name,
                    value: value?.assigned_worker?.id,
                };
            }
            if (value.status) {
                const result = status.find(
                    (resl) => resl.value === value.status,
                );
                // value.status_name = result?.label;
                value.status = {
                    value: result?.value,
                    label: result?.label,
                };
            }
            if (value.approve_expired_at) {
                value.approve_expired_at = new Date(
                    moment(value.approve_expired_at, 'YYYY-MM-DD').toString(),
                );
            }

            formik.setValues({
                ...value,
            });
        }
    }, [estimate]);

    return (
        <Main>
            {fileData && (
                <Dialog
                    onClose={() => setFileData(null)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: "100%",
                            height: "100%",
                            margin: '0px',
                        },

                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!fileData}
                >
                    <DialogContent>
                        {
                            renderContentPreview()
                        }
                    </DialogContent>
                </Dialog>
            )}
            <form
                onSubmit={formik.handleSubmit}
                className="form-edit-estimation d-flex flex-column"
            >
                <div className="group-buttons d-flex flex-row justify-between mb-7">
                    <div className="form-edit-estimation-title">
                        {searchParams.get('type') ? '見積詳細' : '見積編集'}
                    </div>
                    <div className="group-buttons d-flex flex-row mt-0 justify-end">
                        <Button
                            className="bnt bnt-cancel d-flex flex-row"
                            onClick={() => navigate(-1)}
                        >
                            戻る
                        </Button>
                        {searchParams.get('type') != 'detail' && (
                            <Button
                                className="bnt bnt-submit d-flex flex-row"
                                type="submit"
                                disabled={
                                    loading ||
                                    searchParams.get('type') == 'detail'
                                }
                            >
                                登録
                            </Button>
                        )}
                    </div>
                </div>

                <div className="form-create-estimation-form d-flex flex-column">
                    <div className="input-add-estimation d-flex">
                        <p className="d-flex">案件名</p>
                        <div className="d-flex content-right">
                            {project?.name}
                        </div>
                    </div>
                    <div className="input-add-estimation d-flex">
                        <p className="d-flex">見積担当</p>
                        <div className="d-flex content-right">
                            <Controller
                                control={control}
                                name="assigned_worker"
                                render={() => (
                                    <Dropdown
                                        disabled={
                                            loading ||
                                            searchParams.get('type') == 'detail'
                                        }
                                        className="modal-dropdown-create-estimation height36"
                                        controlClassName="dropdown-control-plan height36"
                                        options={workers}
                                        value={formik.values?.assigned_worker?.label ? String(
                                            formik.values?.assigned_worker?.label,
                                        ) : ''}
                                        onChange={(value: any) =>
                                            formik.setFieldValue(
                                                'assigned_worker',
                                                { ...value },
                                            )
                                        }
                                        placeholder=""
                                    />
                                )}
                            />
                            {formik.touched.assigned_worker &&
                                formik.errors.assigned_worker && (
                                    <span className="error ml-3">
                                        {formik.errors.assigned_worker}
                                    </span>
                                )}
                        </div>
                    </div>
                    <div className="input-add-estimation d-flex">
                        <p className="d-flex">状態</p>
                        <div className="d-flex content-right">
                            {/* {formik.values?.status_name} */}
                            <Controller
                                control={control}
                                name="status"
                                render={() => (
                                    <Dropdown
                                        disabled={
                                            loading ||
                                            searchParams.get('type') == 'detail'
                                        }
                                        className="modal-dropdown-create-estimation height36"
                                        controlClassName="dropdown-control-plan height36"
                                        options={status}
                                        value={formik.values.status}
                                        onChange={(value: any) =>
                                            formik.setFieldValue('status', {
                                                ...value,
                                            })
                                        }
                                        placeholder=""
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="input-add-estimation d-flex">
                        <p className="d-flex">承認担当</p>
                        <div className="d-flex content-right">
                            <Controller
                                control={control}
                                name="approver_worker"
                                render={() => (
                                    <Dropdown
                                        disabled={
                                            loading ||
                                            searchParams.get('type') == 'detail'
                                        }
                                        className="modal-dropdown-create-estimation height36"
                                        controlClassName="dropdown-control-plan height36"
                                        options={workers}
                                        value={formik?.values?.approver_worker?.label ? String(
                                            formik?.values?.approver_worker?.label,
                                        ) : ''}
                                        onChange={(value: any) =>
                                            formik.setFieldValue(
                                                'approver_worker',
                                                { ...value },
                                            )
                                        }
                                        placeholder=""
                                    />
                                )}
                            />
                            {formik.touched.approver_worker &&
                                formik.errors.approver_worker && (
                                    <span className="error ml-3">
                                        {formik.errors.approver_worker}
                                    </span>
                                )}
                        </div>
                    </div>
                    <div className="input-add-estimation d-flex">
                        <p className="d-flex">承認期限</p>
                        <div className="content-right d-flex">
                            <div className="datepicker-wrap">
                                <div className="target-datepicker-wrap d-flex flex-row">
                                    <div
                                        className="input-date"
                                        style={{ position: 'relative' }}
                                    >
                                        <Controller
                                            control={control}
                                            name="approve_expired_at"
                                            render={() => (
                                                <DatePicker
                                                    selectsStart
                                                    id="approve_expired_at"
                                                    dateFormat="yyyy-MM-dd"
                                                    autoComplete="off"
                                                    locale="ja"
                                                    selected={
                                                        formik.values
                                                            .approve_expired_at
                                                    }
                                                    startDate={
                                                        formik.values
                                                            .approve_expired_at
                                                    }
                                                    onChange={(date) => {
                                                        formik.setFieldValue(
                                                            'approve_expired_at',
                                                            date,
                                                        );
                                                    }}
                                                    disabled={
                                                        loading ||
                                                        searchParams.get(
                                                            'type',
                                                        ) == 'detail'
                                                    }
                                                />
                                            )}
                                        />
                                        <label htmlFor="approve_expired_at">
                                            <img
                                                className="input-date-img"
                                                width={'16px'}
                                                height={'16px'}
                                                src={calendarIcon}
                                            ></img>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {formik.touched.approve_expired_at &&
                                formik.errors.approve_expired_at && (
                                    <span className="error ml-3">
                                        {formik.errors.approve_expired_at}
                                    </span>
                                )}
                        </div>
                    </div>
                    <div className="input-add-estimation d-flex">
                            <p className="d-flex">ファイル</p>
                            <div className='content-right flex items-center gap-x-[24px]'>
                                <div className='pt-2 flex items-center gap-x-[12px]'>
                                    <div className="mb-0">
                                        {formik?.values?.document?.name || ''}
                                    </div>
                                    {
                                        formik?.values?.document && <div className="text-[#195192] text-[14px] font-[500] cursor-pointer underline mb-0" onClick={() => { handleViewFile(formik?.values?.document) }}>プレビュー</div>
                                    }
                                </div>
                            </div>
                        </div>
                </div>
            </form>
        </Main>
    );
};

export default FormEdit;
