import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import plansService from 'services/plansService';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts-gantt';
import { Button, Grid } from '@mui/material';
import { buildChartData } from 'utils/helper/chartHelper';
import Dropdown from 'react-dropdown';
import ChartNav from './ChartNav';
import $ from 'jquery';

Highcharts.setOptions({
    lang: {
        weekdays: ['日', '月', '火', '水', '木', '金', '土'],
    },
});
interface ChartLayoutProps {
    handleEditPlan: (row) => void;
    handleDetailPlan: (row) => void;
    handleDeletePlan: (row) => void;
    project_id?: number;
    isChangeProject: boolean;
    tabChart: any;
}

const ChartLayout = (props: ChartLayoutProps) => {
    const {
        handleEditPlan,
        handleDetailPlan,
        handleDeletePlan,
        project_id,
        isChangeProject,
        tabChart,
    } = props;
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const options1: Highcharts.Options = {};
    const [options, setOptions] = useState<any | Highcharts.Options>(null);

    const [plans, setPlans] = useState<any>([]);
    const [maxDate, setMaxDate] = useState<any>(null);

    useEffect(() => {
        plans !== null &&
            setTimeout(() => {
                $("[id*='button-detail-']").click(function (event) {
                    plans.map((row) => {
                        row.id ===
                            parseInt(event.target.id.replace(/^\D+/g, '')) &&
                            handleDetailPlan(row);
                    });
                });
                $("[id*='button-edit-']").click(function (event) {
                    plans.map((row) => {
                        row.id ===
                            parseInt(event.target.id.replace(/^\D+/g, '')) &&
                            handleEditPlan(row);
                    });
                });
                $("[id*='button-delete-']").click(function (event) {
                    plans.map((row) => {
                        row.id ===
                            parseInt(event.target.id.replace(/^\D+/g, '')) &&
                            handleDeletePlan(row);
                    });
                });
            }, 100);
    }, [tabChart, plans]);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        watch,
        setError,
        formState: { errors },
    } = useForm();

    const getListPlan = async (
        project_id: number,
        page = 1,
        per_page = 0,
        data = {},
    ) => {
        try {
            if (!project_id) return;
            const response = await plansService.getListPlan(project_id, {
                page,
                per_page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                const plansConvertName = response?.plans?.map(item => {
                    return {...item, name: item?.name?.length > 10 ? `${item?.name?.slice(0, 10)}...` : item?.name}
                })
                const { data, newMaxDate } = buildChartData(plansConvertName);
                setPlans(response.plans);
                setOptions(data);
                setMaxDate(newMaxDate);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        if (project_id) getListPlan(Number(project_id));
    }, [project_id, , isChangeProject]);

    const changeRange = (date: string) => {
        const { data, newMaxDate } = buildChartData(plans, date);
        setOptions(data);
        setMaxDate(newMaxDate);
    };

    if (!plans.length) return null;

    return (
        <Grid container spacing={2}>
            {maxDate && (
                <ChartNav currentDatetime={maxDate} changeRange={changeRange} />
            )}
            <Grid
                // sx={{
                //     '.highcharts-scrollbar': {
                //         display: 'none',
                //     },
                // }}
                item
                xs={12}
            >
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'ganttChart'}
                    options={options}
                    ref={chartComponentRef}
                />
            </Grid>
        </Grid>
    );
};

export default ChartLayout;
