import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogicEstimate } from 'pages/project/projectPlan/Estimations/hooks';
import estimatesService from 'services/estimatesService';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export type ReceivedProps = Record<string, never>;

const useEstimate = (props: ReceivedProps) => {
    const navigate = useNavigate();
    const [estimatesList, setEstimatesList] = useState<any>(null);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState({
        page: 1,
        per_page: 10,
    });
    const [loading, setLoading] = useState(false);

    const { statusOptions: estimateStatus } = useLogicEstimate();
    const { userData } = useSelector((state: RootState) => state.users);
    const isUserRole = userData?.role === 'member';

    const renderStatusItem = (statusProps = '') => {
        const item = estimateStatus.find((i) => i.value === statusProps);
        return item?.label || '';
    };
    
    const getListEstimates = async ({ page = 1, per_page = 5, data }: any) => {
        setLoading(true);
        try {
            const response = await estimatesService.getListEstimates({
                page,
                per_page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                setEstimatesList(response.estimates);
                setTotalCount(response.meta.total_count);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            console.log('err', err);
        }
    };

    useEffect(() => {
        getListEstimates({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, []);

    return {
        ...props,
        estimatesList,
        navigate,
        renderStatusItem,
        getListEstimates,
        totalCount,
        pageQueries,
        setPageQueries,
        isUserRole,
        loading,
    };
};

export type Props = ReturnType<typeof useEstimate>;

export default useEstimate;
