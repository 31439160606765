import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { validation } from './schema';
import { ReceivedProps, IFormData } from '../type';
import { useEffect, useRef, useState } from 'react';
import MeetingMinutesService from 'services/meetingMinutesService';

const useCreateMeetingMinutes = (props: ReceivedProps) => {
    const navigate = useNavigate();
    const initialValues: IFormData = {
        subject: '',
        content: '',
        meeting_image: [],
        meeting_file: [],
        signature_file: '',
    };
    const { id } = useParams();
    const [removedFiles, setRemovedFiles] = useState<any>([]);
    const [removedImages, setRemovedImages] = useState<any>([]);
    const [meetingMinutes, setMeetingMinutes] = useState<any>({});
    const [isCheckAgree, setIsCheckAgree] = useState(false);
    const [errorSubject, setErrorSubject] = useState(false);
    const [fileSelected, setFileSelected] = useState<any>();

    const signatureRef: any = useRef();

    const handleCreateInternalOJT = async (data: IFormData) => {
        if(errorSubject) return;
        try {
            const formData = new FormData();
            formData.append('subject', data.subject.trim());
            formData.append('content', '');
            formData.append('signature_file', data.signature_file);
            formData .append('approve', `${isCheckAgree}`);
            if (id) {
                await MeetingMinutesService.updateMeetingMinutes(id, formData);
                const isNewFiles =
                    data?.meeting_file?.filter((file) => !file.signed_id)
                        ?.length > 0;
                const isNewImages =
                    data?.meeting_image?.filter((file) => !file.signed_id)
                        ?.length > 0;
                if (isNewFiles || isNewImages) {
                    const files = new FormData();
                    if (isNewFiles) {
                        data.meeting_file?.forEach((file) => {
                            if (!file?.signed_id)
                                files.append('meeting_files[]', file);
                        });
                    }
                    if (isNewImages) {
                        data.meeting_image?.forEach((file) => {
                            if (!file?.signed_id)
                                files.append('meeting_images[]', file);
                        });
                    }
                    await MeetingMinutesService.meetingMinutesAddFiles(
                        id,
                        files,
                    );
                }
                if (removedFiles?.length > 0) {
                    for (let index = 0; index < removedFiles.length; index++) {
                        const element = removedFiles[index];
                        if (!!element?.signed_id) {
                            await MeetingMinutesService.meetingMinutesDeleteFiles(
                                id,
                                element?.signed_id,
                            );
                        }
                    }
                }
                const removedImagesSignedId = removedImages?.filter(file => file?.signed_id);
                if (removedImages?.length > 0 && removedImagesSignedId?.length > 0) {
                    for (let index = 0; index < removedImages.length; index++) {
                        const element = removedImages[index];
                        await MeetingMinutesService.meetingMinutesDeleteFiles(
                            id,
                            element?.signed_id,
                        );
                    }
                }
            } else {
                data.meeting_file?.forEach((file) => {
                    formData.append('meeting_files[]', file);
                });
                data.meeting_image?.forEach((file) => {
                    formData.append('meeting_images[]', file);
                });
                await MeetingMinutesService.createMeetingMinutes(formData);
            }
            navigate('/meeting-minutes');
        } catch (error) {}
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validation,
        enableReinitialize: true,
        onSubmit: (values) => handleCreateInternalOJT(values),
    });

    const getMeetingMinutesDetail = async () => {
        if (!id) return;
        try {
            const { meeting_minute }: any =
                await MeetingMinutesService.getMeetingMinutesDetail(id);
            setMeetingMinutes(meeting_minute);
            formik.setFieldValue('subject', meeting_minute?.subject);
            formik.setFieldValue(
                'signature_file',
                meeting_minute?.signature_url,
            );
            formik.setFieldValue('meeting_file', meeting_minute?.meeting_files);
            formik.setFieldValue(
                'meeting_image',
                meeting_minute?.meeting_images,
            );
            setIsCheckAgree(meeting_minute?.approve)
        } catch (error) {}
    };

    const handleFileUpload = (e, name) => {
        formik.setFieldValue(name, [...e.target.files]);
    };

    const handleFileUploadMeetingFile = (e) => {
        formik.setFieldValue('meeting_file', [
            ...formik?.values?.meeting_file,
            ...e.target.files,
        ]);
    };

    const handleRemoveMeetingFile = (idx, file) => {
        formik.setFieldValue(
            'meeting_file',
            formik.values.meeting_file.filter((item, index) => index !== idx),
        );
        setRemovedFiles((preState) => [...preState, file]);
    };
    const handleRemoveMeetingImage = (idx, file) => {
        formik.setFieldValue(
            'meeting_image',
            formik.values.meeting_image.filter((item, index) => index !== idx),
        );
        setRemovedImages((preState) => [...preState, file]);
    };

    const handleFileUploadMeetingImage = (e) => {
        formik.setFieldValue('meeting_image', [
            ...formik?.values?.meeting_image,
            ...e.target.files,
        ]);
    };

    const handlePreviewFile = async (file) => {
        if (file?.url || file?.type?.includes('image') || file?.type?.includes('pdf')) {
            setFileSelected(file);
            return;
        } else {
            try {
                const formData = new FormData();
                formData.append('meeting_files[]', file);
                const res: any = await MeetingMinutesService.meetingMinutesPreviewFiles(formData);
                if (res) {
                    const fileConvert = res?.meeting_files[0];
                    setFileSelected(fileConvert)
                }

            } catch (error) {
                console.log("error", error)
            }
        }
    }

    useEffect(() => {
        getMeetingMinutesDetail();
    }, []);

    return {
        ...props,
        formik,
        navigate,
        handleFileUpload,
        signatureRef,
        handleFileUploadMeetingImage,
        handleRemoveMeetingFile,
        handleFileUploadMeetingFile,
        handleRemoveMeetingImage,
        setRemovedFiles,
        meetingMinutes,
        isCheckAgree,
        setIsCheckAgree,
        id,
        errorSubject,
        setErrorSubject,
        fileSelected,
        setFileSelected,
        handlePreviewFile,
    };
};
export type Props = ReturnType<typeof useCreateMeetingMinutes>;

export default useCreateMeetingMinutes;
