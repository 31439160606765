import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import scheduleService from 'services/scheduleService';
import { CustomizedState } from '../SchedulerBox/hook';
import { validate } from './validate';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export type ReceivedProps = {
    open: boolean;
    openDeleteConfirmationDialog: boolean;
    workerOptions: {
        id: any;
        name: string;
    }[];
    activeTab: number;
    fetchSchedules: () => Promise<void>;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setOpenDeleteConfirmationDialog: Dispatch<SetStateAction<boolean>>;
    setCurrentItemId: Dispatch<SetStateAction<number>>;
};

export const SCHEDULER_COLORS = [
    '#195192',
    '#4950F1',
    '#D83232',
    '#E3A900',
    '#002A5B',
    '#FF6231',
    '#32CED8',
    '#009F08',
    '#754D4D',
    '#FE3571',
    '#B7B7B7',
];

const useCreateScheduleDialog = (props: ReceivedProps) => {
    const location = useLocation();
    const state = location.state as CustomizedState;
    const editingId = state?.editingId;
    const { userData } = useSelector((state: RootState) => state.users);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: (() => ({
            worker_id:
                props.activeTab === 0
                    ? props.workerOptions?.find(
                          (item) => item.id === (userData as any)?.worker?.id,
                      )
                    : null,
            title: '',
            start_date: '',
            end_date: '',
            is_all_day: false,
            worker_schedules_attributes: [],
            color: '#195192',
            content: '',
            place: '',
            is_show_on_todo_list: true,
            targeted: false,
            status: 'initial',
        }))(),
        validate,
        onSubmit: (values) => submitForm(values),
    });

    const fetchSchedule = useCallback(async () => {
        try {
            const { schedule } = await scheduleService.getSchedule(editingId);

            const worker_schedules_attributes = [
                ...schedule.worker_schedule_attributes,
            ].map((item) => ({
                ...item,
                id: item.worker_id,
            }));

            formik.setValues({
                ...schedule,
                worker_id: props.workerOptions?.find(
                    (item) => item.id === schedule?.worker?.id,
                ),
                worker_schedules_attributes,
            });
        } catch (error) {
            //
        }
    }, [editingId]);

    const submitForm = async (payload: Record<string, any>) => {
        if(!payload?.title?.trim()) {
            return;
        }
        const data: any = {
            ...payload,
            worker_id: payload.worker_id.id,
            title: payload?.title?.trim(),
        };

        if (payload.is_all_day) {
            const start_date = moment(payload.start_date).format('YYYY/MM/DD');
            const end_date = moment(payload.end_date).format('YYYY/MM/DD');

            payload.start_date = new Date(`${start_date} 00:00:01`);
            payload.end_date = new Date(`${end_date} 23:59:59`);
        } else {
            const start_date = moment(payload.start_date).toISOString();
            const end_date = moment(payload.end_date).toISOString();

            payload.start_date = start_date;
            payload.end_date = end_date;
        }

        const { worker_schedule_attributes } = payload;
        const newScheduleAttr = formik.values.worker_schedules_attributes.map(
            (workerItem: any) => ({
                worker_id: workerItem.id,
                existingOnForm: true,
            }),
        );

        const mergeArray = _.merge(
            _.keyBy(worker_schedule_attributes, 'worker_id'),
            _.keyBy(newScheduleAttr, 'worker_id'),
        );
        const newArray = _.values(mergeArray);

        data.worker_schedules_attributes = newArray.map((item) => {
            if (item.existingOnForm && !item.id) {
                return {
                    worker_id: item.worker_id,
                };
            }
            if (!item.existingOnForm && item.id) {
                return {
                    _destroy: true,
                    id: item.id.toString(),
                    worker_id: item.worker_id,
                };
            }
            return {
                id: item.id.toString(),
                worker_id: item.worker_id,
            };
        });

        delete data.worker_schedule_attributes;

        try {
            if (!editingId) {
                await scheduleService.createSchedule(data);
            } else {
                await scheduleService.updateSchedule(editingId, data);
            }
        } catch (error) {
            //
        }
        props.fetchSchedules();
        props.setOpen(false);
    };

    useEffect(() => {
        fetchSchedule();
    }, [fetchSchedule]);

    return {
        ...props,
        formik,
        editingId,
    };
};

export type Props = ReturnType<typeof useCreateScheduleDialog>;

export default useCreateScheduleDialog;
